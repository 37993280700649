import React, { Component } from "react";

import styles from "./style.module.scss";
import OrderConfirmationProduct from "./OrderConfirmationProduct";

import { getOrderConfirmationGridStyles } from "./utils";

export default class OrderConfirmationGrid extends Component {
  state = {
    isLoading: false
  };

  onAddToCheckout = (variantId, shop, ruleId) => {
    const { onClickAdd } = this.props;

    if (onClickAdd) {
      this.setState({ isLoading: true }, () => {
        onClickAdd(variantId, shop, ruleId).catch(() =>
          this.setState({ isLoading: false })
        );
      });
    }
  };

  render() {
    const {
      products,
      title,
      subtitle,
      discountType,
      discountValue,
      shop,
      configuration,
      ruleId,
      moneyFormat
    } = this.props;

    const { isLoading } = this.state;

    const {
      styleMoreInfo,
      styleAddToOrder,
      styleTitle,
      styleSubtitle,
      styleDiscountPrice,
      stylePrice,
      styleProductName,
      styleImage,
    } = getOrderConfirmationGridStyles(configuration);

    const formattedConfiguration = {
      styleMoreInfo,
      styleAddToOrder,
      styleDiscountPrice,
      stylePrice,
      styleProductName,
      styleImage,
      addOrderValue: configuration.addOrderValue,
      moreInfoValue: configuration.moreInfoValue,
      hideMoreInfoButton: configuration.hideMoreInfoButton
    };

    return (
      <div className={styles.container}>
        {title && (
          <div className={styles.title} style={styleTitle}>
            {title}
          </div>
        )}
        {subtitle && (
          <div className={styles.subtitle} style={styleSubtitle}>
            {subtitle}
          </div>
        )}
        <div className={styles.productsContainer}>
          {products &&
            products.map(product => (
              <OrderConfirmationProduct
                moneyFormat={moneyFormat}
                key={product.id}
                shop={shop}
                product={product}
                discountType={discountType}
                discountValue={discountValue}
                configuration={formattedConfiguration}
                ruleId={ruleId}
                onClickAdd={this.onAddToCheckout}
                isLoading={isLoading}
              />
            ))}
        </div>
      </div>
    );
  }
}

OrderConfirmationGrid.defaultProps = {
  configuration: {}
};
