import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import qs from "query-string";

import { Page } from "@shopify/polaris";
import {
  getShopAssetsList,
  getShopAsset,
  updateShopAsset
} from "actions/assets";

import { getCurrentShopifyThemeByShopId } from "actions/themes";

import { getStores } from "actions/stores";
import { getStoreId } from "selectors/auth";

import AssetsList from "./components/AssetsList";
import AssetCode from "./components/AssetCode";
import SnippetsCodes from "./components/SnippetsCodes";

import "./style.scss";

class EditTemplates extends Component {
  state = {
    storesOptions: [],
    assets: [],
    currentTheme: {},
    currentAsset: null,
    selectedStore: null,
    isFetchingAssetList: false,
    isFetchingAsset: false,
    isSaving: false,
    assetValue: ""
  };

  componentDidMount() {
    const { storeId, getStores, location } = this.props;
    const queryParsed = qs.parse(location.search);
    const { shopId, assetKey } = queryParsed;

    getStores(storeId).then(res => {
      const stores = res.data;
      const currentStore = stores.find(
        s => parseInt(s.id) === parseInt(shopId)
      );

      this.setState(
        {
          storesOptions: stores.map(s => ({
            label: s.name,
            value: s.id
          })),
          selectedStore: currentStore
            ? {
                label: currentStore.name,
                value: currentStore.id
              }
            : null
        },
        () => {
          if (shopId) {
            this.fetchAssetsList(shopId);
            if (assetKey) {
              this.fetchAsset(assetKey);
            }
          }
        }
      );
    });
  }

  changeUrlQueryParams = (shopId, assetKey) => {
    const { location } = this.props;
    const queryParsed = qs.parse(location.search);
    const { shopId: previousShopId, assetKey: previousAssetKey } = queryParsed;

    const newAssetKey =
      previousShopId && parseInt(shopId) !== parseInt(previousShopId)
        ? null
        : assetKey || previousAssetKey;

    const queryStringified = qs.stringify({
      shopId,
      ...(newAssetKey ? { assetKey: newAssetKey } : {})
    });

    window.history.replaceState({}, "", `?${queryStringified}`);
  };

  onChangeStore = option => {
    this.setState({ selectedStore: option, currentAsset: null }, () => {
      this.fetchAssetsList(option.value);
      this.changeUrlQueryParams(option.value);
    });
  };

  fetchAssetsList = shopId => {
    const {
      storeId,
      getShopAssetsList,
      getCurrentShopifyThemeByShopId
    } = this.props;
    this.setState({ isFetchingAssetList: true }, () => {
      getCurrentShopifyThemeByShopId(storeId, shopId).then(res =>
        this.setState({ currentTheme: res.data })
      );
      getShopAssetsList(storeId, shopId)
        .then(res => {
          this.setState({
            assets: res.data.filter(a => !a.content_type.startsWith("image")),
            isFetchingAssetList: false
          });
        })
        .catch(() => this.setState({ isFetchingAssetList: false }));
    });
  };

  fetchAsset = assetKey => {
    const { storeId, getShopAsset } = this.props;
    const { selectedStore } = this.state;
    this.setState({ isFetchingAsset: true }, () => {
      getShopAsset(storeId, selectedStore.value, assetKey)
        .then(res => {
          this.setState({
            currentAsset: res.data,
            isFetchingAsset: false
          });
        })
        .catch(() => this.setState({ isFetchingAsset: false }));
    });
  };

  onSelectAsset = assetKey => {
    const { selectedStore } = this.state;
    this.fetchAsset(assetKey);
    this.changeUrlQueryParams(selectedStore.value, assetKey);
  };

  onChangeTemplateValue = value => this.setState({ assetValue: value });

  onSave = () => {
    const { updateShopAsset, storeId } = this.props;
    const { assetValue, selectedStore, currentAsset } = this.state;

    this.setState({ isSaving: true }, () => {
      updateShopAsset(
        storeId,
        selectedStore.value,
        currentAsset.key,
        assetValue
      )
        .then(res => {
          this.setState({ isSaving: false });
        })
        .catch(() => this.setState({ isSaving: false }));
    });
  };

  render() {
    const {
      storesOptions,
      selectedStore,
      isFetchingAsset,
      isFetchingAssetList,
      currentAsset,
      assets,
      assetValue,
      isSaving,
      currentTheme
    } = this.state;
    return (
      <div className="EditTemplates">
        <Page
          title="Edit templates"
          separator
          primaryAction={{
            content: "Save",
            onClick: this.onSave,
            disabled:
              !currentAsset ||
              (currentAsset && currentAsset.value === assetValue),
            loading: isSaving
          }}
        >
          <Select
            styles={{
              menu: provided => ({
                ...provided,
                zIndex: 9999
              })
            }}
            value={selectedStore}
            onChange={this.onChangeStore}
            options={storesOptions}
          />

          <div className="mt3">
            Theme: <span className="bold">{currentTheme.name}</span>
          </div>
          <div className="content flex mt3">
            <AssetsList
              asset={currentAsset}
              assets={assets}
              isLoading={isFetchingAssetList}
              onSelectAsset={this.onSelectAsset}
            />
            <AssetCode
              onChange={this.onChangeTemplateValue}
              asset={currentAsset}
              isLoading={isFetchingAsset || isFetchingAssetList}
            />
          </div>

          <div className="mt3">
            <SnippetsCodes />
          </div>
        </Page>
      </div>
    );
  }
}

export default connect(
  state => ({
    storeId: getStoreId(state)
  }),
  {
    getStores,
    getShopAssetsList,
    getShopAsset,
    updateShopAsset,
    getCurrentShopifyThemeByShopId
  }
)(EditTemplates);
