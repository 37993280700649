import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Layout from "components/Layout";

import Homepage from "./pages/homepage";

import Charges from "./pages/charges";
import CheckingCharge from "./pages/charges/CheckingCharge";

import Login from "./pages/auth/Login";
import AuthWrapper from "./pages/auth/AuthWrapper";
import RedirectOauth from "./pages/auth/RedirectOauth";

import Dashboard from "./pages/dashboard";
import GettingStarted from "./pages/GettingStarted";

import Offers from "./pages/UpsellModalApp/offers";
import CreateOffer from "./pages/UpsellModalApp/offers/CreateUpsellModalOffer";
import UpdateUpsellModalOffer from "./pages/UpsellModalApp/offers/UpdateUpsellModalOffer";
import CustomizeDesign from "./pages/UpsellModalApp/configuration/CustomizeDesign";
import GeneralSettings from "./pages/UpsellModalApp/configuration/GeneralSettings";

import OrderConfirmationOffers from "./pages/OrderConfirmationApp/OrderConfirmationOffers";
import CreateOrderConfirmationOffer from "./pages/OrderConfirmationApp/OrderConfirmationOffers/CreateOrderConfirmationOffer";
import UpdateOrderConfirmationOffer from "./pages/OrderConfirmationApp/OrderConfirmationOffers/UpdateOrderConfirmationOffer";
import OrderConfirmationCustomizeDesign from "./pages/OrderConfirmationApp/configuration/OrderConfirmationCustomizeDesign";

import UpsellModalTroubleshootingGuide from "./pages/support/UpsellModalTroubleshootingGuide";
import OrderConfirmationTroubleshootingGuide from "./pages/support/OrderConfirmationTroubleshootingGuide";

import {
  AdminDashboard,
  Affiliations,
  CreateAffiliation,
  UpdateAffiliation,
  EditTemplates
} from "./pages/admin";

import { MyAffiliations } from "./pages/affiliations";

import PrivacyPolicy from "./pages/PrivacyPolicy";

import RedirectToAppStore from "./pages/RedirectToAppStore";

const renderComponentWithLayout = (
  props,
  Component,
  checkAdminRole = false,
  checkAffiliateRole = false
) => (
  <AuthWrapper
    {...props}
    checkAdminRole={checkAdminRole}
    checkAffiliateRole={checkAffiliateRole}
  >
    <Layout>
      <Component {...props} />
    </Layout>
  </AuthWrapper>
);

export default class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path="/app/:affiliation_code"
          component={RedirectToAppStore}
        />
        <Route
          exact
          path="/getting-started"
          render={props => renderComponentWithLayout(props, GettingStarted)}
        />
        <Route
          exact
          path="/dashboard"
          render={props => renderComponentWithLayout(props, Dashboard)}
        />
        <Route
          exact
          path="/upsell/modal/offers"
          render={props => renderComponentWithLayout(props, Offers)}
        />
        <Route
          exact
          path="/upsell/modal/offers/new"
          render={props => renderComponentWithLayout(props, CreateOffer)}
        />
        <Route
          exact
          path="/upsell/modal/offers/:offerId"
          render={props =>
            renderComponentWithLayout(props, UpdateUpsellModalOffer)
          }
        />

        <Redirect
          exact
          from="/upsell/modal/configuration"
          to="/upsell/modal/configuration/design"
        />

        <Route
          exact
          path="/upsell/modal/configuration/design"
          render={props => renderComponentWithLayout(props, CustomizeDesign)}
        />

        <Route
          exact
          path="/upsell/modal/configuration/general"
          render={props => renderComponentWithLayout(props, GeneralSettings)}
        />

        <Route
          exact
          path="/upsell/order-confirmation/offers"
          render={props =>
            renderComponentWithLayout(props, OrderConfirmationOffers)
          }
        />
        <Route
          exact
          path="/upsell/order-confirmation/offers/new"
          render={props =>
            renderComponentWithLayout(props, CreateOrderConfirmationOffer)
          }
        />
        <Route
          exact
          path="/upsell/order-confirmation/offers/:offerId"
          render={props =>
            renderComponentWithLayout(props, UpdateOrderConfirmationOffer)
          }
        />

        <Route
          exact
          path="/upsell/order-confirmation/configuration"
          render={props =>
            renderComponentWithLayout(props, OrderConfirmationCustomizeDesign)
          }
        />

        <Route
          exact
          path="/support/troubleshooting-guide/upsell-modal"
          render={props =>
            renderComponentWithLayout(props, UpsellModalTroubleshootingGuide)
          }
        />

        <Route
          exact
          path="/support/troubleshooting-guide/order-confirmation"
          render={props =>
            renderComponentWithLayout(
              props,
              OrderConfirmationTroubleshootingGuide
            )
          }
        />

        <Route
          exact
          path="/charges"
          render={props => (
            <AuthWrapper {...props}>
              <Charges {...props} />
            </AuthWrapper>
          )}
        />

        <Route
          exact
          path="/charges/checking"
          render={props => (
            <AuthWrapper {...props}>
              <CheckingCharge {...props} />
            </AuthWrapper>
          )}
        />

        <Redirect exact from="/admin" to="/admin/dashboard" />

        <Route
          exact
          path="/admin/dashboard"
          render={props =>
            renderComponentWithLayout(props, AdminDashboard, true)
          }
        />

        <Route
          exact
          path="/admin/affiliations"
          render={props => renderComponentWithLayout(props, Affiliations, true)}
        />

        <Route
          exact
          path="/admin/affiliations/new"
          render={props =>
            renderComponentWithLayout(props, CreateAffiliation, true)
          }
        />

        <Route
          exact
          path="/admin/affiliations/:affiliationId"
          render={props =>
            renderComponentWithLayout(props, UpdateAffiliation, true)
          }
        />

        <Route
          exact
          path="/admin/edit-templates"
          render={props =>
            renderComponentWithLayout(props, EditTemplates, true)
          }
        />

        <Route
          exact
          path="/affiliations/my-affiliations"
          render={props =>
            renderComponentWithLayout(props, MyAffiliations, false, true)
          }
        />

        <Route exact path="/login" component={Login} />

        <Route exact path="/privacy" component={PrivacyPolicy} />

        <Route exact path="/oauth/callback" component={RedirectOauth} />

        <Route exact path="/" component={Homepage} />

        <Redirect from="/checking" to="/charges/checking" />
      </Switch>
    );
  }
}
