import React from "react";
import * as R from "ramda";
import StripePlanItem from "./StripePlanItem";
import ShopifyPlanItem from "./ShopifyPlanItem";
import { useTranslation } from "react-i18next";

import "./style.scss";

const BASIC_PRODUCT_ID = process.env.REACT_APP_STRIPE_PLAN_ID;

const PlansList = ({ hasAffiliation, isActive, storePlanName }) => {
  const { t } = useTranslation();

  const SHOPIFY_BILLING = true;

  const PRODUCT_PLANS = [
    {
      id: BASIC_PRODUCT_ID,
      name: t("charges.plans.basic.title"),
      details: [
        t("charges.plans.basic.details.0"),
        t("charges.plans.basic.details.1"),
        t("charges.plans.basic.details.2"),
        t("charges.plans.basic.details.3")
      ],
      price: 19.9,
      trialDays: 30,
      affiliateTrialDays: 30
    }
  ];

  const SHOPIFY_PLAN_CHARGES = {
    trial: {
      name: t("charges.plans.basic.title"),
      price: 19.99
    },
    affiliate: {
      name: t("charges.plans.basic.title"),
      price: 19.99
    },
    basic: {
      name: t("charges.plans.basic.title"),
      price: 19.99
    },
    professional: {
      name: t("charges.plans.professional.title"),
      price: 29.99
    },
    unlimited: {
      name: t("charges.plans.advanced.title"),
      price: 44.99
    },
    enterprise: {
      name: t("charges.plans.advanced.title"),
      price: 44.99
    }
  };

  const shopifyPlanName = R.pathOr(
    SHOPIFY_PLAN_CHARGES.basic.name,
    [storePlanName, "name"],
    SHOPIFY_PLAN_CHARGES
  );

  const shopifyPlanPrice = R.pathOr(
    SHOPIFY_PLAN_CHARGES.basic.price,
    [storePlanName, "price"],
    SHOPIFY_PLAN_CHARGES
  );

  const SHOPIFY_PRODUCT_PLANS = [
    {
      name: shopifyPlanName,
      details: [
        t("charges.plans.basic.details.0"),
        t("charges.plans.basic.details.1"),
        t("charges.plans.basic.details.2"),
        t("charges.plans.basic.details.3")
      ],
      price: shopifyPlanPrice
    }
  ];

  const PlanComponent = SHOPIFY_BILLING ? ShopifyPlanItem : StripePlanItem;

  const productPlans = SHOPIFY_BILLING ? SHOPIFY_PRODUCT_PLANS : PRODUCT_PLANS;

  return (
    <div className="PlansList">
      {productPlans.map(plan => (
        <PlanComponent
          hideTrialDays={true}
          key={plan.id}
          id={plan.id}
          name={plan.name}
          price={plan.price}
          details={plan.details}
          affiliateTrialDays={plan.affiliateTrialDays}
          trialDays={plan.trialDays}
          hasAffiliation={hasAffiliation}
          isActive={isActive}
        />
      ))}
    </div>
  );
};

export default PlansList;
