import React from "react";
import Modal from "../Modal";
import UpsellContent from "./UpsellContent";
import UpsellContentMultipleProducts from "./UpsellContentMultipleProducts";

export default function UpsellModal({
  open,
  onClose,
  onSubmit,
  styleNoThanks,
  styleAddToCart,
  styleReplaceCart,
  labelAddToCart,
  labelReplaceCart,
  title,
  subtitle,
  description,
  products,
  discountType,
  currency,
  labelCancel,
  styleModal,
  styleTitle,
  styleSubtitle,
  styleDiscountPrice,
  stylePrice,
  styleProductDescription,
  styleProductName,
  cart,
  moneyFormat,
  upsellType,
  variantSelectorType,
  variantOptionsConfig,
  styleVariants
}) {
  const hasOnlyOneProduct = products.length === 1;

  return (
    <Modal open={open} onClose={onClose} style={styleModal}>
      {hasOnlyOneProduct ? (
        <UpsellContent
          upsellType={upsellType}
          cart={cart}
          moneyFormat={moneyFormat}
          productName={products[0].title}
          productOptions={products[0].options}
          variants={products[0].variants}
          description={description}
          discountType={discountType}
          labelAddToCart={labelAddToCart}
          labelReplaceCart={labelReplaceCart}
          styleAddToCard={styleAddToCart}
          styleReplaceCart={styleReplaceCart}
          styleNoThanks={styleNoThanks}
          onClose={onClose}
          onSubmit={onSubmit}
          currency={currency}
          styleAddToCart={styleAddToCart}
          labelCancel={labelCancel}
          styleModal={styleModal}
          title={title}
          styleTitle={styleTitle}
          subtitle={subtitle}
          styleSubtitle={styleSubtitle}
          styleDiscountPrice={styleDiscountPrice}
          stylePrice={stylePrice}
          styleProductDescription={styleProductDescription}
          styleProductName={styleProductName}
          styleVariants={styleVariants}
          variantSelectorType={variantSelectorType}
          variantOptionsConfig={variantOptionsConfig}
        />
      ) : (
        <UpsellContentMultipleProducts
          products={products}
          upsellType={upsellType}
          cart={cart}
          moneyFormat={moneyFormat}
          discountType={discountType}
          labelAddToCart={labelAddToCart}
          labelReplaceCart={labelReplaceCart}
          styleAddToCard={styleAddToCart}
          styleReplaceCart={styleReplaceCart}
          styleNoThanks={styleNoThanks}
          onClose={onClose}
          onSubmit={onSubmit}
          currency={currency}
          styleAddToCart={styleAddToCart}
          labelCancel={labelCancel}
          styleModal={styleModal}
          title={title}
          styleTitle={styleTitle}
          subtitle={subtitle}
          styleSubtitle={styleSubtitle}
          styleDiscountPrice={styleDiscountPrice}
          stylePrice={stylePrice}
          styleProductName={styleProductName}
        />
      )}
    </Modal>
  );
}

UpsellModal.defaultProps = {
  title: "Promo!",
  labelAddToCart: "Add to cart",
  styleAddToCard: {},
  styleNoThanks: {}
};
