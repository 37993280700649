import { createAction } from "redux-actions";

export const fetchUpsellModalOffers = createAction(
  "API:OFFERS_FETCH_OFFERS",
  storeId => ({
    url: `/api/v1/stores/${storeId}/upsell-modal/rules`
  })
);

export const fetchUpsellModalOffer = createAction(
  "API:OFFERS_FETCH_OFFER",
  (storeId, offerId) => ({
    url: `/api/v1/stores/${storeId}/upsell-modal/rules/${offerId}`
  })
);

export const addUpsellModalOffer = createAction(
  "API:OFFERS_ADD_OFFER",
  (
    storeId,
    name,
    title,
    subtitle,
    description,
    showCondition,
    showConditionCollections,
    shopifyProductIds,
    discountType,
    discountValue,
    prefixDiscountCode,
    minQuantity,
    minQuantityType,
    upsellType,
    removeItemMethod,
    hideOutOfStockVariants,
    variantSelectorType,
    variantOptionsConfig
  ) => ({
    method: "POST",
    url: `/api/v1/stores/${storeId}/upsell-modal/rules`,
    data: {
      name,
      title,
      subtitle,
      description,
      enable: false,
      show_condition: showCondition,
      show_condition_collections: showConditionCollections,
      product_ids: shopifyProductIds,
      discount_type: discountType,
      discount_value: discountValue,
      prefix_discount_code: prefixDiscountCode,
      min_quantity: minQuantity,
      min_quantity_type: minQuantityType,
      upsell_type: upsellType,
      remove_item_method: removeItemMethod,
      hide_out_of_stock_variants: hideOutOfStockVariants,
      variant_selector_type: variantSelectorType,
      variant_options_config: variantOptionsConfig
    }
  })
);

export const updateUpsellModalOffer = createAction(
  "API:OFFERS_UPDATE_OFFER",
  (storeId, ruleId, data) => ({
    method: "PUT",
    url: `/api/v1/stores/${storeId}/upsell-modal/rules/${ruleId}`,
    data
  })
);

export const deleteUpsellModalOffer = createAction(
  "API:OFFERS_DELETE_OFFER",
  (storeId, ruleId) => ({
    method: "DELETE",
    url: `/api/v1/stores/${storeId}/upsell-modal/rules/${ruleId}`
  })
);
