import React, { Component } from "react";
import cx from "classnames";
import { Toast } from "@shopify/polaris";

import "./style.scss";

class CopyToClipboard extends Component {
  state = {
    showToast: false
  };

  onCopyCode = () => {
    if (!this.code) {
      return;
    }

    this.code.select();
    document.execCommand("copy");

    this.setState({ showToast: true });
  };

  toggleToast = () => {
    this.setState(({ showToast }) => ({ showToast: !showToast }));
  };

  render() {
    const { className, value, children, contentToast } = this.props;
    const { showToast } = this.state;

    return (
      <div className={cx("CopyToClipboard", className)}>
        <textarea
          ref={ref => (this.code = ref)}
          className="hiddenScript"
          value={value}
          readOnly
        />

        <span onClick={this.onCopyCode}>{children}</span>

        {showToast ? (
          <Toast content={contentToast} onDismiss={this.toggleToast} />
        ) : null}
      </div>
    );
  }
}

export default CopyToClipboard;
