import { createAction } from "redux-actions";

export const getShopAssetsList = createAction(
  "API:ASSETS_GET_SHOP_ASSETS_LIST",
  (storeId, shopId) => ({
    method: "GET",
    url: `/api/v1/stores/${storeId}/shop/${shopId}/assets`
  })
);

export const getShopAsset = createAction(
  "API:ASSETS_GET_SHOP_ASSET",
  (storeId, shopId, assetKey) => ({
    method: "GET",
    url: `/api/v1/stores/${storeId}/shop/${shopId}/assets?assetKey=${assetKey}`
  })
);

export const updateShopAsset = createAction(
  "API:ASSETS_UPDATE_SHOP_ASSET",
  (storeId, shopId, assetKey, value) => ({
    method: "PUT",
    url: `/api/v1/stores/${storeId}/shop/${shopId}/assets?assetKey=${assetKey}`,
    data: {
      value
    }
  })
);

export const installScriptsAndLiquidApp = createAction(
  "API:ASSETS_INSTALL_SCRIPTS_AND_LIQUID_APP",
  storeId => ({
    method: "post",
    url: `/api/v1/stores/${storeId}/assets/install`
  })
);

export const uploadImage = createAction(
  "API:ASSETS_UPLOAD_IMAGE",
  (storeId, imgName, imgData, previousAssetKey) => ({
    method: "put",
    url: `/api/v1/stores/${storeId}/assets/image/upload`,
    data: {
      assetName: imgName,
      data: {
        attachment: imgData
      },
      previousAssetKey
    }
  })
);

export const deleteImage = createAction(
  "API:ASSETS_DELETE_IMAGE",
  (storeId, imgKey) => ({
    method: "delete",
    url: `/api/v1/stores/${storeId}/assets/delete?key=${imgKey}`
  })
);
