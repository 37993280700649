import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { Loading } from "@shopify/polaris";

import OrderConfirmationGrid from "components/OrderConfirmationGrid";
import {
  fetchOrderConfirmationOffers,
  fetchOrderConfirmationOffer
} from "actions/orderConfirmationOffers";
import { getStoreId, getShopifyMoneyFormat } from "selectors/auth";

import { PRODUCTS_DATA } from "./data";

import "./style.scss";

class PreviewOrderConfirmationGrid extends Component {
  state = {
    rules: [],
    title: "Here is the title",
    subtitle: "Here is the subtitle",
    products: PRODUCTS_DATA,
    discountType: "percent",
    discountValue: 40,
    isLoadingRules: true,
    isFetchingRule: false
  };

  componentDidMount() {
    const { storeId, fetchOrderConfirmationOffers } = this.props;
    fetchOrderConfirmationOffers(storeId).then(res =>
      this.setState({
        rules: res.data,
        isLoadingRules: false
      })
    );
  }

  onChangeSelect = option => {
    const { fetchOrderConfirmationOffer, storeId } = this.props;
    this.setState(
      {
        isFetchingRule: true
      },
      () => {
        fetchOrderConfirmationOffer(storeId, option.value).then(res => {
          this.setState({
            title: res.data.title,
            subtitle: res.data.subtitle,
            products: res.data.products,
            discountType: res.data.discount_type,
            discountValue: res.data.discount_value,
            isFetchingRule: false
          });
        });
      }
    );
  };

  onClickAdd = () =>
    new Promise((_resolve, reject) => {
      setTimeout(reject, 3000);
    });

  render() {
    const { configuration, t, shopifyMoneyFormat } = this.props;
    const {
      rules,
      title,
      subtitle,
      products,
      discountType,
      discountValue,
      isLoadingRules,
      isFetchingRule
    } = this.state;

    const options = rules.map(rule => ({ label: rule.name, value: rule.id }));

    return (
      <div className="PreviewOrderConfirmationGrid">
        <Select
          className="PreviewOrderConfirmationGrid--select mb2"
          options={options}
          placeholder={t(
            "orderConfirmationApp.configuration.selectRule.placeholder"
          )}
          onChange={this.onChangeSelect}
          isLoading={isLoadingRules}
        />
        <div className="PreviewOrderConfirmationGrid--container p3">
          <OrderConfirmationGrid
            moneyFormat={shopifyMoneyFormat}
            title={title}
            subtitle={subtitle}
            products={products}
            discountType={discountType}
            discountValue={discountValue}
            configuration={configuration}
            onClickAdd={this.onClickAdd}
          />
          {isFetchingRule && (
            <div className="loadingContainer">
              <Loading size="large" color="teal" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    storeId: getStoreId(state),
    shopifyMoneyFormat: getShopifyMoneyFormat(state)
  }),
  {
    fetchOrderConfirmationOffers,
    fetchOrderConfirmationOffer
  }
)(withTranslation()(PreviewOrderConfirmationGrid));
