import React, { Component } from "react";
import cx from "classnames";

import "./style.scss";

export default class AssetRow extends Component {
  render() {
    const { name, onClick, isSelected } = this.props;
    return (
      <div
        className={cx("AssetRow", { selected: isSelected })}
        onClick={onClick}
      >
        {name}
      </div>
    );
  }
}
