import React, { useState, useEffect } from "react";
import cx from "classnames";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import TagTextField from "components/TagTextField";
import ColorPicker from "components/ColorPicker";
import ImagePicker from "./ImagePicker";

import "./style.scss";

export default function TagsWithColorInput({
  defaultTags = [],
  onChange,
  color: colorProp,
  type: typeProp,
  imageUrl: imageUrlProp
}) {
  const { t } = useTranslation();

  const TYPE_OPTIONS = [
    {
      label: t(
        "upsellModalApp.form.variants.inputs.option.tagsWithColor.types.0"
      ),
      value: "color"
    },
    {
      label: t(
        "upsellModalApp.form.variants.inputs.option.tagsWithColor.types.1"
      ),
      value: "variant-image"
    },
    {
      label: t(
        "upsellModalApp.form.variants.inputs.option.tagsWithColor.types.2"
      ),
      value: "custom-image"
    }
  ];

  const [color, setColor] = useState(colorProp);
  const [imageUrl, setImageUrl] = useState(imageUrlProp);
  const [tags, setTags] = useState(defaultTags);
  const [type, setType] = useState(typeProp);

  useEffect(() => {
    if (onChange) {
      onChange(tags, color, type, imageUrl);
    }
  }, [tags, color, type, imageUrl]);

  const onChangeTags = tags => setTags(tags);

  const findOption = value => {
    return TYPE_OPTIONS.find(v => v.value === value);
  };

  return (
    <div className={cx("TagsWithColorInput flex")}>
      <div
        className={cx("typeContainer flex", { "flex-center": !tags.length })}
      >
        <div className="w100 mr1">
          <Select
            styles={{
              menu: provided => ({
                ...provided,
                zIndex: 9999999
              })
            }}
            value={findOption(type)}
            className="w100 mr2"
            options={TYPE_OPTIONS}
            onChange={option => setType(option.value)}
          />
        </div>
        {type === "color" && (
          <div className="mr1">
            <ColorPicker
              color={color}
              onChange={setColor}
              hintLabel={t(
                "upsellModalApp.form.variants.inputs.option.tagsWithColor.swatchColor"
              )}
            />
          </div>
        )}
        {type === "custom-image" && (
          <div className="mr1">
            <ImagePicker imageUrl={imageUrl} onChange={setImageUrl} />
          </div>
        )}
      </div>
      <TagTextField
        defaultTags={defaultTags}
        onChange={onChangeTags}
        placeholder={"Ex: Blue, Sky blue, Dark blue"}
      />
    </div>
  );
}
