import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Modal, TextContainer } from "@shopify/polaris";

class DeleteOfferModal extends Component {
  onDelete = () => {
    const { onSubmit } = this.props;
    onSubmit();
  };

  onCancel = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    const { open, onClose, rule, isLoading, t } = this.props;

    return (
      <Modal
        open={open}
        onClose={onClose}
        title={t("orderConfirmationApp.list.modals.delete.title", {
          name: rule.name
        })}
        primaryAction={{
          destructive: true,
          content: t("orderConfirmationApp.list.modals.delete.deleteButton"),
          onAction: this.onDelete,
          loading: isLoading
        }}
        secondaryActions={[
          {
            content: t("orderConfirmationApp.list.modals.delete.cancelButton"),
            onAction: this.onCancel
          }
        ]}
      >
        <Modal.Section>
          <TextContainer>
            {t("orderConfirmationApp.list.modals.delete.description")}
          </TextContainer>
        </Modal.Section>
      </Modal>
    );
  }
}

export default withTranslation()(DeleteOfferModal);
