import React, { Component } from "react";
import { I18nextProvider } from "react-i18next";
import { AppProvider } from "@shopify/polaris";
import { Link } from "react-router-dom";

import Toasts from "components/Toasts";

import Routes from "./routes";

import i18n from "./i18n";

const CustomLinkComponent = ({ children, url, ...rest }) => (
  <Link to={url} {...rest}>
    {children}
  </Link>
);

class App extends Component {
  render() {
    const theme = {
      colors: {
        topBar: {
          background: "#202E78"
        }
      },
      logo: {
        width: 124,
        topBarSource: "/images/awesome-upsell-icon.svg",
        url: "/",
        accessibilityLabel: "Awesome Upsell"
      }
    };

    return (
      <div className="App">
        <I18nextProvider i18n={i18n}>
          <AppProvider theme={theme} linkComponent={CustomLinkComponent}>
            <div>
              <Routes />
              <Toasts />
            </div>
          </AppProvider>
        </I18nextProvider>
      </div>
    );
  }
}

export default App;
