import { createAction } from "redux-actions";

export const createShopifyRecurringApplicationCharge = createAction(
  "API:CHARGES_CREATE_RECURRING_APPLICATION_CHARGE",
  (storeId, data) => ({
    method: "post",
    url: `/api/v1/stores/${storeId}/shopify_charges`,
    data
  })
);
