import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { TopBar } from "@shopify/polaris";
import { LogOutMinor } from "@shopify/polaris-icons";

import { logOut } from "actions/auth";
import { getStoreName } from "selectors/auth";
import Cookie from "js-cookie";

class LayoutTopBar extends Component {
  state = {
    isUserMenuOpen: false
  };

  toggleIsUserMenuOpen = () =>
    this.setState(({ isUserMenuOpen }) => ({
      isUserMenuOpen: !isUserMenuOpen
    }));

  logOut = () => {
    const { logOut, history } = this.props;
    Cookie.remove("credentials");
    logOut();
    history.push("/");
  };

  render() {
    const { storeName, onNavigationToggle, t } = this.props;
    const { isUserMenuOpen } = this.state;

    const name = storeName ? storeName.replace(".myshopify.com", "") : "";
    const firstLetter = storeName ? storeName.slice(0, 1).toUpperCase() : "";

    const userMenuMarkup = (
      <TopBar.UserMenu
        actions={[
          {
            items: [
              {
                content: t("components.LayoutTopBar.logOut"),
                icon: LogOutMinor,
                onAction: this.logOut
              }
            ]
          }
        ]}
        name={name}
        detail={name}
        initials={firstLetter}
        open={isUserMenuOpen}
        onToggle={this.toggleIsUserMenuOpen}
      />
    );

    return (
      <TopBar
        showNavigationToggle
        userMenu={userMenuMarkup}
        onNavigationToggle={onNavigationToggle}
      />
    );
  }
}

export default connect(
  state => ({ storeName: getStoreName(state) }),
  { logOut }
)(withRouter(withTranslation()(LayoutTopBar)));
