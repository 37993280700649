import { createAction } from "redux-actions";

export const fetchConfiguration = createAction(
  "API:CONFIGURATIONS_FETCH_CONFIGURATION",
  storeId => ({
    url: `/api/v1/stores/${storeId}/configuration`
  })
);

export const createConfiguration = createAction(
  "API:CONFIGURATIONS_CREATE_CONFIGURATION",
  (storeId, data) => ({
    method: "post",
    url: `/api/v1/stores/${storeId}/configurations`,
    data
  })
);

export const updateConfiguration = createAction(
  "API:CONFIGURATIONS_UPDATE_CONFIGURATION",
  (configurationId, storeId, data) => ({
    method: "put",
    url: `/api/v1/stores/${storeId}/configurations/${configurationId}`,
    data
  })
);
