import React, { Component } from "react";
import { connect } from "react-redux";
import { Page } from "@shopify/polaris";

import {
  updateAffiliation,
  getAffiliation,
  getStoresWithoutAffiliation,
  deleteAffiliation
} from "actions/affiliations";

import { getStoreId } from "selectors/auth";

import AffiliationForm from "../components/AffiliationForm";
import DeleteAffiliationModal from "./DeleteAffiliationModal";

class UpdateAffiliation extends Component {
  state = {
    stores: [],
    defaultValues: {},
    isUpdating: false,
    isFetching: true,
    showDeleteModal: false,
    isDeleting: false
  };

  componentDidMount() {
    const {
      storeId,
      getStoresWithoutAffiliation,
      match,
      getAffiliation
    } = this.props;
    const { params } = match;
    const { affiliationId } = params;

    getStoresWithoutAffiliation(storeId).then(res => {
      this.setState(
        {
          stores: res.data.map(store => ({
            label: store.name,
            value: store.id
          }))
        },
        () => {
          getAffiliation(storeId, affiliationId).then(res => {
            this.setState(prevState => ({
              defaultValues: {
                firstName: res.data.first_name,
                lastName: res.data.last_name,
                email: res.data.email,
                affiliationCode: res.data.affiliation_code,
                affiliationPercent: res.data.affiliation_percent,
                affiliationShop: prevState.stores.find(
                  s => s.value === res.data.store_id
                )
              },
              isFetching: false
            }));
          });
        }
      );
    });
  }

  onUpdateAffiliation = data => {
    const { history, storeId, updateAffiliation, match } = this.props;
    const { params } = match;
    const { affiliationId } = params;

    this.setState({ isUpdating: true }, () => {
      updateAffiliation(storeId, affiliationId, {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        store_id: data.affiliationShop.value,
        affiliation_percent: data.affiliationPercent,
        affiliation_code: data.affiliationCode
      })
        .then(
          this.setState({ isUpdating: false }, () => {
            history.push("/admin/affiliations");
          })
        )
        .catch(() => this.setState({ isUpdating: false }));
    });
  };

  onCancel = () => {
    const { history } = this.props;
    history.push("/admin/affiliations");
  };

  onDelete = () => {
    const { history, storeId, deleteAffiliation, match } = this.props;
    const { params } = match;
    const { affiliationId } = params;

    this.setState({ isDeleting: true }, () => {
      deleteAffiliation(storeId, affiliationId)
        .then(() => {
          this.setState({ showDeleteModal: false, isDeleting: false }, () => {
            history.push("/admin/affiliations");
          });
        })
        .catch(() => this.setState({ isDeleting: false }));
    });
  };

  toggleDeleteModal = () =>
    this.setState(({ showDeleteModal }) => ({
      showDeleteModal: !showDeleteModal
    }));

  render() {
    const {
      stores,
      isFetching,
      isUpdating,
      isDeleting,
      defaultValues,
      showDeleteModal
    } = this.state;

    return (
      <Page title="Update affiliation" separator>
        {!isFetching && (
          <AffiliationForm
            defaultValues={defaultValues}
            storesOptions={stores}
            isLoading={isFetching}
            isSubmitting={isUpdating}
            onSubmit={this.onUpdateAffiliation}
            onCancel={this.onCancel}
            onDelete={this.toggleDeleteModal}
            labelSubmit="Update affiliation"
          />
        )}

        <DeleteAffiliationModal
          open={showDeleteModal}
          onSubmit={this.onDelete}
          onClose={this.toggleDeleteModal}
          isLoading={isDeleting}
        />
      </Page>
    );
  }
}

export default connect(
  state => ({
    storeId: getStoreId(state)
  }),
  {
    updateAffiliation,
    getStoresWithoutAffiliation,
    getAffiliation,
    deleteAffiliation
  }
)(UpdateAffiliation);
