export const PRODUCTS_DATA = [
  {
    id: 4352331481141,
    title: "T-shirt Blue",
    body_html:
      '<meta charset="utf-8">\n<div class="h-container h-align-left">\n<p as="p" class="h-text h-color-black detail h-bold h-all-caps h-p-bottom-s"><span style="text-decoration: underline;"><strong>MATIÈRE ET ENTRETIEN</strong></span></p>\n<div class="h-container h-m-bottom-xl h-align-left">\n<p as="p" class="h-text h-color-black body h-m-bottom-xs"><span as="span" class="h-text h-color-black body h-bold"><strong>Composition:</strong> </span><span as="span" class="h-text h-color-black body">100% coton</span></p>\n<p as="p" class="h-text h-color-black body h-m-bottom-xs"><span as="span" class="h-text h-color-black body h-bold"><strong>Matière:</strong> </span><span as="span" class="h-text h-color-black body">Jersey</span></p>\n<p as="p" class="h-text h-color-black body h-m-bottom-xs"><span as="span" class="h-text h-color-black body h-bold"><strong>Conseils d\'entretien:</strong> </span><span as="span" class="h-text h-color-black body">Lavage en machine à 40°C</span></p>\n</div>\n</div>\n<div class="h-container h-align-left">\n<p as="p" class="h-text h-color-black detail h-bold h-all-caps h-p-bottom-s"><span style="text-decoration: underline;"><strong>DÉTAILS DU PRODUIT</strong></span></p>\n<div class="h-container h-m-bottom-xl h-align-left">\n<p as="p" class="h-text h-color-black body h-m-bottom-xs"><span as="span" class="h-text h-color-black body h-bold"><strong>Forme du col:</strong> </span><span as="span" class="h-text h-color-black body">Col rond</span></p>\n<p as="p" class="h-text h-color-black body h-m-bottom-xs"><span as="span" class="h-text h-color-black body h-bold"><strong>Motif / Couleur:</strong> </span><span as="span" class="h-text h-color-black body">Couleur unie</span></p>\n<p as="p" class="h-text h-color-black body h-m-bottom-xs"><span as="span" class="h-text h-color-black body h-bold"><strong>Référence:</strong> </span><span as="span" class="h-text h-color-black body">BB182A3202K-K11</span></p>\n</div>\n</div>',
    vendor: "awesome-upsell",
    product_type: "",
    created_at: "2019-11-18T08:57:39-05:00",
    handle: "t-shirt-blue",
    updated_at: "2019-11-18T09:49:05-05:00",
    published_at: "2019-11-18T08:54:00-05:00",
    template_suffix: "",
    tags: "",
    published_scope: "web",
    admin_graphql_api_id: "gid://shopify/Product/4352331481141",
    variants: [
      {
        id: 31231159369781,
        product_id: 4352331481141,
        title: "S",
        price: "20.00",
        sku: "",
        position: 1,
        inventory_policy: "deny",
        compare_at_price: null,
        fulfillment_service: "manual",
        inventory_management: "shopify",
        option1: "S",
        option2: null,
        option3: null,
        created_at: "2019-11-18T09:03:12-05:00",
        updated_at: "2019-11-18T09:49:05-05:00",
        taxable: true,
        barcode: "",
        grams: 200,
        image_id: null,
        weight: 0.2,
        weight_unit: "kg",
        inventory_item_id: 32736439795765,
        inventory_quantity: 999,
        old_inventory_quantity: 999,
        requires_shipping: true,
        admin_graphql_api_id: "gid://shopify/ProductVariant/31231159369781"
      },
      {
        id: 31231159402549,
        product_id: 4352331481141,
        title: "M",
        price: "20.00",
        sku: "",
        position: 2,
        inventory_policy: "deny",
        compare_at_price: null,
        fulfillment_service: "manual",
        inventory_management: "shopify",
        option1: "M",
        option2: null,
        option3: null,
        created_at: "2019-11-18T09:03:12-05:00",
        updated_at: "2019-11-18T09:03:12-05:00",
        taxable: true,
        barcode: "",
        grams: 200,
        image_id: null,
        weight: 0.2,
        weight_unit: "kg",
        inventory_item_id: 32736439828533,
        inventory_quantity: 0,
        old_inventory_quantity: 0,
        requires_shipping: true,
        admin_graphql_api_id: "gid://shopify/ProductVariant/31231159402549"
      },
      {
        id: 31231159435317,
        product_id: 4352331481141,
        title: "L",
        price: "20.00",
        sku: "",
        position: 3,
        inventory_policy: "deny",
        compare_at_price: null,
        fulfillment_service: "manual",
        inventory_management: "shopify",
        option1: "L",
        option2: null,
        option3: null,
        created_at: "2019-11-18T09:03:12-05:00",
        updated_at: "2019-11-18T09:03:12-05:00",
        taxable: true,
        barcode: "",
        grams: 200,
        image_id: null,
        weight: 0.2,
        weight_unit: "kg",
        inventory_item_id: 32736439861301,
        inventory_quantity: 0,
        old_inventory_quantity: 0,
        requires_shipping: true,
        admin_graphql_api_id: "gid://shopify/ProductVariant/31231159435317"
      },
      {
        id: 31231159468085,
        product_id: 4352331481141,
        title: "XL",
        price: "20.00",
        sku: "",
        position: 4,
        inventory_policy: "deny",
        compare_at_price: null,
        fulfillment_service: "manual",
        inventory_management: "shopify",
        option1: "XL",
        option2: null,
        option3: null,
        created_at: "2019-11-18T09:03:12-05:00",
        updated_at: "2019-11-18T09:03:12-05:00",
        taxable: true,
        barcode: "",
        grams: 200,
        image_id: null,
        weight: 0.2,
        weight_unit: "kg",
        inventory_item_id: 32736439894069,
        inventory_quantity: 0,
        old_inventory_quantity: 0,
        requires_shipping: true,
        admin_graphql_api_id: "gid://shopify/ProductVariant/31231159468085"
      }
    ],
    options: [
      {
        id: 5658024083509,
        product_id: 4352331481141,
        name: "Size",
        position: 1,
        values: ["S", "M", "L", "XL"]
      }
    ],
    images: [
      {
        id: 13534857003061,
        product_id: 4352331481141,
        position: 1,
        created_at: "2019-11-18T08:57:40-05:00",
        updated_at: "2019-11-18T08:57:40-05:00",
        alt: null,
        width: 827,
        height: 1000,
        src: "/images/t-shirt-blue.jpg",
        variant_ids: [],
        admin_graphql_api_id: "gid://shopify/ProductImage/13534857003061"
      }
    ],
    image: {
      id: 13534857003061,
      product_id: 4352331481141,
      position: 1,
      created_at: "2019-11-18T08:57:40-05:00",
      updated_at: "2019-11-18T08:57:40-05:00",
      alt: null,
      width: 827,
      height: 1000,
      src: "/images/t-shirt-blue.jpg",
      variant_ids: [],
      admin_graphql_api_id: "gid://shopify/ProductImage/13534857003061"
    }
  },
  {
    id: 4352332136501,
    title: "T-shirt Red",
    body_html:
      '<meta charset="utf-8">\n<div class="h-container h-align-left">\n<p as="p" class="h-text h-color-black detail h-bold h-all-caps h-p-bottom-s"><span style="text-decoration: underline;"><strong>MATIÈRE ET ENTRETIEN</strong></span></p>\n<div class="h-container h-m-bottom-xl h-align-left">\n<p as="p" class="h-text h-color-black body h-m-bottom-xs"><span as="span" class="h-text h-color-black body h-bold"><strong>Composition:</strong> </span><span as="span" class="h-text h-color-black body">100% coton</span></p>\n<p as="p" class="h-text h-color-black body h-m-bottom-xs"><span as="span" class="h-text h-color-black body h-bold"><strong>Matière:</strong> </span><span as="span" class="h-text h-color-black body">Jersey</span></p>\n<p as="p" class="h-text h-color-black body h-m-bottom-xs"><span as="span" class="h-text h-color-black body h-bold"><strong>Conseils d\'entretien:</strong> </span><span as="span" class="h-text h-color-black body">Lavage en machine à 40°C</span></p>\n</div>\n</div>\n<div class="h-container h-align-left">\n<p as="p" class="h-text h-color-black detail h-bold h-all-caps h-p-bottom-s"><span style="text-decoration: underline;"><strong>DÉTAILS DU PRODUIT</strong></span></p>\n<div class="h-container h-m-bottom-xl h-align-left">\n<p as="p" class="h-text h-color-black body h-m-bottom-xs"><span as="span" class="h-text h-color-black body h-bold"><strong>Forme du col:</strong> </span><span as="span" class="h-text h-color-black body">Col rond</span></p>\n<p as="p" class="h-text h-color-black body h-m-bottom-xs"><span as="span" class="h-text h-color-black body h-bold"><strong>Motif / Couleur:</strong> </span><span as="span" class="h-text h-color-black body">Couleur unie</span></p>\n<p as="p" class="h-text h-color-black body h-m-bottom-xs"><span as="span" class="h-text h-color-black body h-bold"><strong>Référence:</strong> RR</span><span as="span" class="h-text h-color-black body">182A3202K-K11</span></p>\n</div>\n</div>',
    vendor: "awesome-upsell",
    product_type: "",
    created_at: "2019-11-18T08:57:56-05:00",
    handle: "t-shirt-red",
    updated_at: "2019-11-18T09:04:25-05:00",
    published_at: "2019-11-18T08:57:56-05:00",
    template_suffix: "",
    tags: "",
    published_scope: "web",
    admin_graphql_api_id: "gid://shopify/Product/4352332136501",
    variants: [
      {
        id: 31231165857845,
        product_id: 4352332136501,
        title: "S",
        price: "12.00",
        sku: "",
        position: 1,
        inventory_policy: "deny",
        compare_at_price: null,
        fulfillment_service: "manual",
        inventory_management: "shopify",
        option1: "S",
        option2: null,
        option3: null,
        created_at: "2019-11-18T09:04:25-05:00",
        updated_at: "2019-11-18T09:04:25-05:00",
        taxable: true,
        barcode: "",
        grams: 200,
        image_id: null,
        weight: 0.2,
        weight_unit: "kg",
        inventory_item_id: 32736447758389,
        inventory_quantity: 1000,
        old_inventory_quantity: 1000,
        requires_shipping: true,
        admin_graphql_api_id: "gid://shopify/ProductVariant/31231165857845"
      },
      {
        id: 31231165890613,
        product_id: 4352332136501,
        title: "M",
        price: "12.00",
        sku: "",
        position: 2,
        inventory_policy: "deny",
        compare_at_price: null,
        fulfillment_service: "manual",
        inventory_management: "shopify",
        option1: "M",
        option2: null,
        option3: null,
        created_at: "2019-11-18T09:04:25-05:00",
        updated_at: "2019-11-18T09:04:25-05:00",
        taxable: true,
        barcode: "",
        grams: 200,
        image_id: null,
        weight: 0.2,
        weight_unit: "kg",
        inventory_item_id: 32736447791157,
        inventory_quantity: 0,
        old_inventory_quantity: 0,
        requires_shipping: true,
        admin_graphql_api_id: "gid://shopify/ProductVariant/31231165890613"
      },
      {
        id: 31231165923381,
        product_id: 4352332136501,
        title: "L",
        price: "12.00",
        sku: "",
        position: 3,
        inventory_policy: "deny",
        compare_at_price: null,
        fulfillment_service: "manual",
        inventory_management: "shopify",
        option1: "L",
        option2: null,
        option3: null,
        created_at: "2019-11-18T09:04:25-05:00",
        updated_at: "2019-11-18T09:04:25-05:00",
        taxable: true,
        barcode: "",
        grams: 200,
        image_id: null,
        weight: 0.2,
        weight_unit: "kg",
        inventory_item_id: 32736447823925,
        inventory_quantity: 0,
        old_inventory_quantity: 0,
        requires_shipping: true,
        admin_graphql_api_id: "gid://shopify/ProductVariant/31231165923381"
      },
      {
        id: 31231165956149,
        product_id: 4352332136501,
        title: "XL",
        price: "12.00",
        sku: "",
        position: 4,
        inventory_policy: "deny",
        compare_at_price: null,
        fulfillment_service: "manual",
        inventory_management: "shopify",
        option1: "XL",
        option2: null,
        option3: null,
        created_at: "2019-11-18T09:04:25-05:00",
        updated_at: "2019-11-18T09:04:25-05:00",
        taxable: true,
        barcode: "",
        grams: 200,
        image_id: null,
        weight: 0.2,
        weight_unit: "kg",
        inventory_item_id: 32736447856693,
        inventory_quantity: 0,
        old_inventory_quantity: 0,
        requires_shipping: true,
        admin_graphql_api_id: "gid://shopify/ProductVariant/31231165956149"
      }
    ],
    options: [
      {
        id: 5658025033781,
        product_id: 4352332136501,
        name: "Size",
        position: 1,
        values: ["S", "M", "L", "XL"]
      }
    ],
    images: [
      {
        id: 13534872174645,
        product_id: 4352332136501,
        position: 1,
        created_at: "2019-11-18T08:58:48-05:00",
        updated_at: "2019-11-18T08:58:48-05:00",
        alt: null,
        width: 861,
        height: 1000,
        src: "/images/t-shirt-red.jpg",
        variant_ids: [],
        admin_graphql_api_id: "gid://shopify/ProductImage/13534872174645"
      }
    ],
    image: {
      id: 13534872174645,
      product_id: 4352332136501,
      position: 1,
      created_at: "2019-11-18T08:58:48-05:00",
      updated_at: "2019-11-18T08:58:48-05:00",
      alt: null,
      width: 861,
      height: 1000,
      src: "/images/t-shirt-red.jpg",
      variant_ids: [],
      admin_graphql_api_id: "gid://shopify/ProductImage/13534872174645"
    }
  }
];
