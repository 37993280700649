import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';

import { Icon } from '@shopify/polaris';

import {
  CircleTickMajor,
  CircleInformationMajor,
  CircleCancelMajor
} from '@shopify/polaris-icons';

import { removeToast } from 'actions/toasts';

import './style.scss';

const iconsMap = {
  success: <Icon source={CircleTickMajor} color="white" />,
  info: <Icon source={CircleInformationMajor} color="white" />,
  error: <Icon source={CircleCancelMajor} color="white" />,
};

export class Toasts extends Component {
  static propTypes = {
    toasts: PropTypes.array,
    removeToast: PropTypes.func,
  };

  onClickToast = toast => () => {
    const { removeToast } = this.props;
    const { opts } = toast;

    if (!opts || !opts.keepOpen) {
      removeToast(toast.id);
    }
  };

  render() {
    const { toasts } = this.props;

    return (
      <div className="Toasts">
        {toasts.map(toast => <Toast key={toast.id} toast={toast} onClick={this.onClickToast(toast)} />)}
      </div>
    );
  }
}

export function Toast({ toast, onClick }) {
  return (
    <div className={cx('Toast slideup', toast.type)}>
      <div className="Toast-icon" onClick={onClick}>
        <i className={cx('s-icon')}>
          {iconsMap[toast.type]}
        </i>
      </div>
      <div className="Toast-content">
        {typeof toast.message === 'function' ? toast.message(onClick) : toast.message}
      </div>
    </div>
  );
}

export default connect(state => ({ toasts: state.toasts }), { removeToast })(Toasts);
