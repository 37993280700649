import React, { Component } from "react";

import "./style.scss";

export default class IncreasesBox extends Component {
  render() {
    const { imageSrc, title, description, backgroundColor } = this.props;

    return (
      <div className="IncreasesBox flex flex-center flex-column">
        <div className="imageContainer" style={{ backgroundColor }}>
          <img className="image" src={imageSrc} alt={title} />
        </div>
        <h5 className="title mt3">{title}</h5>
        <div className="description">{description}</div>
      </div>
    );
  }
}
