import * as R from "ramda";

/**
 * Convert inline style to css string
 * @param target = .myClass
 * @param inlineStyle = {fontSize: "normal", backgroundColor: "red"}
 * @returns {string} = .myClass {font-size: normal, background-color: red}
 */
export const convertInlineStyleToCss = (target, inlineStyle) => {
  const regexInlineStyle = new RegExp(/([a-z])([A-Z])/);

  const stringifyStyle = R.compose(
    value => value.replace(/,/g, ";"),
    value => JSON.stringify(value).replace(/"/g, ""),
    R.fromPairs,
    R.filter(([key, value]) => value),
    R.map(([key, value]) => [
      key.replace(regexInlineStyle, "$1-$2").toLowerCase(),
      value
    ]),
    R.toPairs
  )(inlineStyle);

  return `${target} ${stringifyStyle}`;
};
