import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Page, PageActions } from "@shopify/polaris";

import { addUpsellModalOffer } from "actions/upsellModalOffers";
import { addToast } from "actions/toasts";
import { getStoreId } from "selectors/auth";

import OfferForm from "../components/OfferForm";

import "./style.scss";

class CreateUpsellModalOffer extends Component {
  state = {
    shouldDisableAddButton: false,
    isSaving: false,
    data: null
  };

  onChange = (data, shouldDisableAddButton) => {
    this.setState({ data, shouldDisableAddButton });
  };

  onSave = () => {
    const { history } = this.props;
    const { data } = this.state;
    const {
      name,
      conditionProducts,
      conditionCollections,
      selectedProducts,
      discountType,
      discountValue,
      title,
      subtitle,
      description,
      prefixDiscountCode,
      minQuantity,
      minQuantityType,
      upsellType,
      removeItemMethod,
      hideOutOfStockVariants,
      variantSelectorType,
      variantOptionsConfig
    } = data;

    const conditionProductsIds = conditionProducts.map(p => p.value);
    const conditionConditionsIds = conditionCollections.map(p => p.value);
    const selectedProductsIds = selectedProducts.map(p => p.value);

    this.setState({ isSaving: true }, () => {
      const { storeId, addUpsellModalOffer } = this.props;

      return addUpsellModalOffer(
        storeId,
        name,
        title,
        subtitle,
        description,
        conditionProductsIds,
        conditionConditionsIds,
        selectedProductsIds,
        discountType.value,
        discountValue,
        prefixDiscountCode,
        minQuantity,
        minQuantityType,
        upsellType,
        removeItemMethod[0],
        !!hideOutOfStockVariants,
        variantSelectorType.value,
        variantOptionsConfig
      ).then(() => {
        addToast("Offer has been created!", "success");
        history.push("/upsell/modal/offers");
      });
    });
  };

  onCancel = () => {
    const { history } = this.props;
    history.push("/upsell/modal/offers");
  };

  render() {
    const { history, t } = this.props;
    const { shouldDisableAddButton, isSaving } = this.state;

    return (
      <Page
        title={t("upsellModalApp.new.title")}
        separator
        breadcrumbs={[
          {
            content: t("upsellModalApp.new.backToList"),
            onAction: () => history.push("/upsell/modal/offers")
          }
        ]}
        primaryAction={{
          content: t("upsellModalApp.new.submitButton"),
          disabled: shouldDisableAddButton,
          onClick: this.onSave,
          loading: isSaving
        }}
      >
        <OfferForm onChange={this.onChange} />

        <div className="mt3">
          <PageActions
            primaryAction={{
              content: t("upsellModalApp.new.submitButton"),
              disabled: shouldDisableAddButton,
              onClick: this.onSave,
              loading: isSaving
            }}
            secondaryActions={[
              {
                content: t("upsellModalApp.new.cancelButton"),
                onClick: this.onCancel
              }
            ]}
          />
        </div>
      </Page>
    );
  }
}

export default connect(
  state => ({
    storeId: getStoreId(state)
  }),
  {
    addUpsellModalOffer,
    addToast
  }
)(withTranslation()(CreateUpsellModalOffer));
