import React, { Component } from "react";
import i18next from "i18next";
import Select, { components } from "react-select";

const LANGUAGE_OPTIONS = [
  {
    label: "English",
    value: "en-US",
    iconFlag: "/images/flags/en-flag.svg"
  },
  {
    label: "Français",
    value: "fr-FR",
    iconFlag: "/images/flags/fr-flag.svg"
  }
];

const Option = props => {
  return (
    <components.Option {...props}>
      <div className="flex flex-center">
        <img
          width="25"
          className="mr2"
          src={props.data.iconFlag}
          alt={props.label}
        />
        <div style={{ color: "#333" }}>{props.label}</div>
      </div>
    </components.Option>
  );
};

class SelectLanguage extends Component {
  state = {
    lang:
      LANGUAGE_OPTIONS.find(o => o.value === i18next.language) ||
      LANGUAGE_OPTIONS[0]
  };

  onChangeLanguage = option =>
    this.setState({ lang: option }, () => i18next.changeLanguage(option.value));

  render() {
    const { inputColor } = this.props;
    const { lang } = this.state;

    return (
      <Select
        value={lang}
        options={LANGUAGE_OPTIONS}
        styles={{
          control: provided => ({
            ...provided,
            backgroundColor: "transparent",
            border: "none",
            minWidth: 100
          }),
          singleValue: provided => ({
            ...provided,
            color: inputColor || "#222"
          }),
          menu: provided => ({
            ...provided,
            minWidth: 130
          }),
          indicatorsContainer: provided => ({
            ...provided
          }),
          indicatorSeparator: () => ({})
        }}
        onChange={this.onChangeLanguage}
        components={{ Option }}
      />
    );
  }
}

export default SelectLanguage;
