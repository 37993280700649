import React, { Component } from "react";
import queryString from "query-string";
import * as R from "ramda";
import Cookie from "js-cookie";
import AOS from "aos";

import NavigationTopBar from "./components/NavigationTopBar";
import Footer from "./components/Footer";
import StatsSection from "./components/sections/StatsSection";

import {
  MainSection,
  IncreasesSection,
  PricingSection,
  DemoSection
} from "./components/sections";

import "aos/dist/aos.css";
import "./style.scss";

export default class Homepage extends Component {
  componentDidMount() {
    const { history } = this.props;
    AOS.init({
      once: true,
      disable: "mobile"
    });

    window.addEventListener("load", this.AOSRefresh);

    const queryParams = queryString.parse(window.location.search);
    const { hmac, shop, timestamp } = queryParams;
    const affiliationCode = R.prop("via", queryParams);

    if (hmac && shop && timestamp) {
      const { REACT_APP_BACKEND_URL, REACT_APP_CLIENT_URL } = process.env;
      const redirectUri = `${REACT_APP_CLIENT_URL}/oauth/callback`;
      const state = Math.floor(Math.random() * 16777215);
      const url = `${REACT_APP_BACKEND_URL}/api/v1/oauth?shop=${shop}&redirect_uri=${redirectUri}&state=${state}`;
      window.location = url;
      return;
    }

    if (affiliationCode) {
      Cookie.set("affiliation_code", R.prop("via", queryParams), {
        expires: 1
      });
      return;
    }

    const hasCredentials = !!Cookie.get("credentials");
    if (hasCredentials) {
      history.push("/getting-started");
    }
  }

  componentWillUnmount() {
    window.removeEventListener("load", this.AOSRefresh);
  }

  AOSRefresh = () => {
    AOS.refresh();
  };

  render() {
    return (
      <div className="Homepage">
        <NavigationTopBar />
        <MainSection />
        <div id="features" style={{ paddingTop: 40 }} />
        <IncreasesSection />
        <StatsSection />
        <div id="demo" style={{ marginBottom: 80 }} />
        <DemoSection />
        <div id="pricing" style={{ marginBottom: 80 }} />
        <PricingSection />
        <Footer />
      </div>
    );
  }
}
