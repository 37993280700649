import { createAction } from "redux-actions";

export const getAwesomeQuantityNotificationPopup = createAction(
  "API:EVENTS_GET_AWESOME_QUANTITY_NOTIFICATION_POPUP",
  storeId => ({
    method: "post",
    url: `/api/v1/stores/${storeId}/events/awesome_quantity_notification`,
    noToast: true
  })
);
