import React from "react";
import { useTranslation } from "react-i18next";
import { Card, Heading, TextStyle, SkeletonBodyText } from "@shopify/polaris";
import moment from "moment";

import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  Bar
} from "recharts";

import "./style.scss";

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="custom-tooltip">
        <p className="tooltip-title">{`${moment(label).format(
          "MMMM YYYY"
        )}`}</p>
        {payload.map(p => (
          <p className="tooltip-label mt1" style={{ color: p.fill }}>{`${
            p.name
          } : ${Math.floor(p.value * 100) / 100}`}</p>
        ))}
      </div>
    );
  }

  return null;
};

export default function OrdersPerMonthCharts({ data, currency, isLoading }) {
  const { t } = useTranslation();

  const title = t("dashboard.ordersPerMonthCharts.title");
  const subtitle = t("dashboard.ordersPerMonthCharts.subtitle");
  const config = [
    {
      label: t("dashboard.ordersPerMonthCharts.legend.ordersLabel", {
        currency
      }),
      dataKey: "orders",
      fill: "#5C6AC4",
      stackId: "orders"
    },
    {
      label: t("dashboard.ordersPerMonthCharts.legend.upsellModalOrdersLabel", {
        currency
      }),
      dataKey: "upsell_modal",
      fill: "#B3BCF5",
      stackId: "orders"
    },
    {
      label: t(
        "dashboard.ordersPerMonthCharts.legend.upsellConfirmationOrdersLabel",
        {
          currency
        }
      ),
      dataKey: "order_confirmation",
      fill: "#E3D0FF",
      stackId: "orders"
    }
  ];

  return (
    <Card>
      <div className="OrdersPerMonthCharts p2">
        <div className="p2 flex flex-content-between">
          <Heading>{title}</Heading>
          <TextStyle variation="subdued">{subtitle}</TextStyle>
        </div>
        <div className="p2">
          {isLoading ? (
            <div className="loadingContainer">
              <SkeletonBodyText lines={10} />
            </div>
          ) : (
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={data}
                margin={{
                  top: 20,
                  right: 30,
                  bottom: 5
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis allowDecimals type="number" />
                <Tooltip
                  cursor={{ fill: "rgba(0,0,0,0.1)" }}
                  content={CustomTooltip}
                />
                <Legend />
                {config.map(c => (
                  <Bar
                    key={c.dataKey}
                    name={c.label}
                    dataKey={c.dataKey}
                    stackId={c.stackId}
                    fill={c.fill}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    </Card>
  );
}
