import React from "react";
import { useTranslation } from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import StartFreeTrialButton from "../../StartFreeTrialButton";

import "./style.scss";

function MainSection() {
  const { t } = useTranslation();

  return (
    <section className="MainSection">
      <div className="bannerSection">
        <img
          src="images/homepage/banner-mask.png"
          className="bannerMask"
          alt=""
        />
      </div>
      <div className="MainSection--content container full-flex-center flex-column">
        <div className="center">
          <div
            className="title mb3"
            data-aos="fade-up"
            data-aos-easing="ease"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            {t("homepage.mainSection.title")}
          </div>
          <div
            className="description mb4"
            data-aos="fade-up"
            data-aos-easing="ease"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            {t("homepage.mainSection.description")}
          </div>

          <div className="flex mb3 full-flex-center flex-wrap">
            <div
              className="full-flex-center mr2 mt2"
              data-aos="fade-up"
              data-aos-easing="ease"
              data-aos-duration="1000"
              data-aos-delay="400"
            >
              <StartFreeTrialButton />
            </div>

            <a
              className="full-flex-center"
              href="https://apps.shopify.com/awesome-upsell"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="shopifyAppStoreButton mt2"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-duration="1000"
                data-aos-delay="600"
                alt="Shopify App Store"
                src="/images/homepage/shopify-app-store-badge-black.svg"
              />
            </a>
          </div>
        </div>
        <div
          className="carouselContainer flex flex-center flex-content-center flex-column mt4"
          data-aos="fade-up"
          data-aos-easing="ease"
          data-aos-duration="1000"
          data-aos-delay="200"
        >
          <Carousel
            autoPlay
            infiniteLoop
            interval={5000}
            showArrows={false}
            showStatus={false}
            showThumbs={false}
          >
            <img
              className="preview-devices"
              src={t("homepage.mainSection.imageUrlUpsellModal")}
              alt="Preview Awesome Upsell"
            />

            <img
              className="preview-devices"
              src={t("homepage.mainSection.imageUrlOrderConfirmation")}
              alt="Preview Awesome Upsell"
            />

            <img
              className="preview-devices"
              src={t("homepage.mainSection.dashboardImageUrl")}
              alt="Awesome Upsell Dashboard"
            />
          </Carousel>
        </div>
      </div>
    </section>
  );
}

export default MainSection;
