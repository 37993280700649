import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Banner } from "@shopify/polaris";

import { uploadImage } from "actions/assets";
import { getStoreId } from "selectors/auth";

import ImageCropModal from "components/ImageCropModal";

import "./style.scss";

export default function ImagePicker({ imageUrl, onChange }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const storeId = useSelector(getStoreId);
  const [open, setOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const openModal = () => setOpen(true);

  const closeModal = () => setOpen(false);

  const onUpload = (imgData, nameFile) => {
    setIsUploading(true);
    const formattedNameFile = `as_crop_150x150_${nameFile}`.replace(/\s/g, "");

    return dispatch(uploadImage(storeId, formattedNameFile, imgData))
      .then(res => {
        onChange(res.data.public_url, res.data.key);
        setOpen(false);
        setIsUploading(false);
      })
      .catch(() => {
        setIsUploading(false);
      });
  };

  return (
    <div className="ImagePicker">
      <div
        onClick={openModal}
        className="hint--top relative"
        aria-label={t(
          "upsellModalApp.form.variants.inputs.option.tagsWithColor.customImage"
        )}
      >
        <img className="ImagePicker--content" src={imageUrl} alt="preview" />
      </div>

      <ImageCropModal
        open={open}
        onClose={closeModal}
        onUpload={onUpload}
        image={imageUrl}
        isUploading={isUploading}
        banner={
          <Banner status="info">
            {t(
              "upsellModalApp.form.variants.inputs.option.tagsWithColor.bannerImage"
            )}
          </Banner>
        }
      />
    </div>
  );
}
