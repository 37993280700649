import React, { useState } from "react";
import * as R from "ramda";
import cx from "classnames";
import { Card } from "@shopify/polaris";

import "./style.scss";

export default function TabsCard({
  tabs = [],
  description,
  defaultTabIndex = 0
}) {
  const [currentTab, setCurrentTab] = useState(defaultTabIndex);

  const onClickTab = index => () => {
    setCurrentTab(index);
  };

  return (
    <div className="TabsCard">
      <Card>
        {description && (
          <Card.Section>
            <div className="description">{description}</div>
          </Card.Section>
        )}
        <Card.Section>
          <div className="TabsCard--container">
            <ul className="tabList">
              {tabs.map((tab, index) => (
                <li
                  key={index}
                  className={cx("tab", { active: currentTab === index })}
                  onClick={onClickTab(index)}
                >
                  <button className="button">
                    {tab.IconComponent && (
                      <div className="iconComponent">{tab.IconComponent}</div>
                    )}
                    <div>{tab.title}</div>
                  </button>
                </li>
              ))}
            </ul>
            <div className="content">
              {R.path([currentTab, "content"], tabs)}
            </div>
          </div>
        </Card.Section>
      </Card>
    </div>
  );
}
