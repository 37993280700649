export const productsInitState = [];

export default (state = {}, action) => {
  switch (action.type) {
    case "PRODUCTS_GET_SHOPIFY_PRODUCTS_SUCCESS":
      return action.payload.data;
    default:
      return state;
  }
};
