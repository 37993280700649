import { createAction } from "redux-actions";

export const getAffiliations = createAction(
  "API:AFFILIATIONS_GET_AFFILIATIONS",
  storeId => ({
    method: "GET",
    url: `/api/v1/stores/${storeId}/affiliations`
  })
);

export const getAffiliation = createAction(
  "API:AFFILIATIONS_GET_AFFILIATIONS",
  (storeId, affiliationStoreId) => ({
    method: "GET",
    url: `/api/v1/stores/${storeId}/affiliations/${affiliationStoreId}`
  })
);

export const getStoresWithoutAffiliation = createAction(
  "API:AFFILIATIONS_GET_STORES_WITHOUT_AFFILIATION",
  storeId => ({
    method: "GET",
    url: `/api/v1/stores/${storeId}/affiliations/stores_without_affiliation`
  })
);

export const createAffiliation = createAction(
  "API:AFFILIATION_CREATE_AFFILIATION",
  (storeId, data) => ({
    method: "POST",
    url: `/api/v1/stores/${storeId}/affiliations`,
    data
  })
);

export const updateAffiliation = createAction(
  "API:AFFILIATION_UPDATE_AFFILIATION",
  (storeId, affiliationId, data) => ({
    method: "PUT",
    url: `/api/v1/stores/${storeId}/affiliations/${affiliationId}`,
    data
  })
);

export const deleteAffiliation = createAction(
  "API:AFFILIATION_UPDATE_AFFILIATION",
  (storeId, affiliationId) => ({
    method: "DELETE",
    url: `/api/v1/stores/${storeId}/affiliations/${affiliationId}`
  })
);

export const getUserAffiliates = createAction(
  "API:AFFILIATIONS_GET_USER_AFFILIATES",
  storeId => ({
    method: "GET",
    url: `/api/v1/stores/${storeId}/affiliations/affiliates`
  })
);
