import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as R from "ramda";

import Select from "react-select";
import { Banner, Button, TextField, Card } from "@shopify/polaris";
import { DeleteMajor, PlusMinor } from "@shopify/polaris-icons";

import TagsWithColorInput from "./TagsWithColorInput";
import InfoOptionNameTypeIcon from "./InfoOptionNameTypeIcon";

import "./style.scss";

export default function VariantOptionsForm({
  options: optionProps = [],
  onChange
}) {
  const { t } = useTranslation();
  const [options, setOptions] = useState(optionProps);

  const OPTION_TYPE_OPTIONS = [
    {
      label: "Badge",
      value: "badge"
    },
    {
      label: "Swatch",
      value: "swatch"
    }
  ];

  useEffect(() => {
    if (onChange) {
      onChange(options);
    }
  }, [options]);

  const findOption = value => {
    return OPTION_TYPE_OPTIONS.find(o => o.value === value);
  };

  const addOptionToCustomize = () => {
    const emptyOption = {
      value: "",
      type: OPTION_TYPE_OPTIONS[1].value,
      id: new Date().getTime(),
      options: []
    };

    setOptions([...options, emptyOption]);
  };

  const addVariantOption = indexOption => () => {
    const emptyOption = {
      tags: [],
      color: "",
      id: new Date().getTime()
    };

    const updatedOptions = R.clone(options);
    const currentOption = updatedOptions[indexOption];
    const updatedOptionOptions = [...currentOption.options, emptyOption];
    updatedOptions[indexOption].options = updatedOptionOptions;

    setOptions(updatedOptions);
  };

  const removeOptionRow = index => () => {
    setOptions(options => R.remove(index, 1, options));
  };

  const removeVariantOptionColor = (indexRow, index) => () => {
    const updatedOptions = R.clone(options);
    const currentOption = updatedOptions[indexRow];
    const currentVariantOptions = R.remove(index, 1, currentOption.options);

    currentOption.options = currentVariantOptions;
    updatedOptions[indexRow] = currentOption;

    setOptions(updatedOptions);
  };

  const onChangeOption = (index, key) => value => {
    const updatedOptions = R.clone(options);
    const currentOption = updatedOptions[index];

    currentOption[key] = value;
    updatedOptions[index] = currentOption;

    setOptions(updatedOptions);
  };

  const onChangeOptionsTagsColor = (indexOption, index) => (
    tags,
    color,
    type,
    imageUrl
  ) => {
    const updatedOptions = R.clone(options);
    const currentOption = updatedOptions[indexOption];
    const currentVariantOptions = currentOption.options[index];

    currentVariantOptions.tags = tags;
    currentVariantOptions.color = color;
    currentVariantOptions.type = type;
    currentVariantOptions.imageUrl = imageUrl;

    updatedOptions[indexOption].options[index] = currentVariantOptions;
    setOptions(updatedOptions);
  };

  return (
    <div className="VariantOptionsForm">
      <Banner status="">{t("upsellModalApp.form.variants.infoBanner")}</Banner>

      <div className="mt3">
        {options.length === 0 && (
          <div>{t("upsellModalApp.form.variants.noCustomVariantOptions")}</div>
        )}

        {options.length > 0 && (
          <Card>
            {options.map((option, indexOption) => {
              return (
                <Card.Section
                  key={option.id}
                  title={t("upsellModalApp.form.variants.inputs.option.title", {
                    count: indexOption + 1
                  })}
                  actions={[
                    {
                      content: "Remove",
                      destructive: true,
                      onClick: removeOptionRow(indexOption)
                    }
                  ]}
                >
                  <div className="OptionContainer">
                    <div className="flex flex-center mb1">
                      <div className="mr1 w100 optionTextField">
                        <TextField
                          label={
                            <span className="flex">
                              {t(
                                "upsellModalApp.form.variants.inputs.option.name"
                              )}{" "}
                              <InfoOptionNameTypeIcon />
                            </span>
                          }
                          value={options[indexOption].value}
                          placeholder="Ex: Color"
                          onChange={onChangeOption(indexOption, "value")}
                        />
                      </div>
                      <div className="w100">
                        <div className="mb1 flex flex-center">
                          {t("upsellModalApp.form.variants.inputs.option.type")}
                        </div>
                        <div className="w100">
                          <Select
                            styles={{
                              menu: provided => ({
                                ...provided,
                                zIndex: 9999999
                              })
                            }}
                            value={findOption(options[indexOption].type)}
                            className="w100 mr2"
                            options={OPTION_TYPE_OPTIONS}
                            onChange={option =>
                              onChangeOption(indexOption, "type")(option.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {option.type === "swatch" && (
                      <div className="tagsColorContainer bigBorderLeft">
                        {option.options.map((option, index) => {
                          return (
                            <div key={option.id}>
                              <div className="labelVariantOption">
                                Option {index + 1}
                              </div>
                              <div className="mb1 flex flex-center w100">
                                <div className="mr1 w100">
                                  <TagsWithColorInput
                                    defaultTags={option.tags}
                                    color={option.color}
                                    type={option.type}
                                    imageUrl={option.imageUrl}
                                    onChange={onChangeOptionsTagsColor(
                                      indexOption,
                                      index
                                    )}
                                  />
                                </div>
                                <div
                                  className="hint--top relative"
                                  aria-label={t(
                                    "upsellModalApp.form.variants.inputs.option.deleteIcon"
                                  )}
                                >
                                  <Button
                                    icon={DeleteMajor}
                                    size="slim"
                                    onClick={removeVariantOptionColor(
                                      indexOption,
                                      index
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <div className="mt2">
                          <Button
                            icon={PlusMinor}
                            onClick={addVariantOption(indexOption)}
                          >
                            {t(
                              "upsellModalApp.form.variants.addNewColorButton"
                            )}
                          </Button>
                        </div>
                      </div>
                    )}
                    {option.type === "badge" && (
                      <div className="bigBorderLeft">
                        {t("upsellModalApp.form.variants.noBadgeCustomisation")}
                      </div>
                    )}
                  </div>
                </Card.Section>
              );
            })}
          </Card>
        )}
      </div>
      <div className="mt3">
        <Button icon={PlusMinor} primary onClick={addOptionToCustomize}>
          {t("upsellModalApp.form.variants.addCustomOption")}
        </Button>
      </div>
    </div>
  );
}
