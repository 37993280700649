import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import cx from "classnames";
import Cookie from "js-cookie";

import { Button } from "@shopify/polaris";
import { loadImage } from "utils/images";

import "./style.scss";

import STEPS from "./steps";

class OnBoarding extends Component {
  state = {
    step: 0,
    isClosed: !!Cookie.get("onboarded")
  };

  componentDidMount() {
    this.addListener();
    const { i18n } = this.props;

    loadImage("/images/logo_awesomeapps_1024x1024.jpg");

    if (i18n.language === "en-US") {
      loadImage("/images/onboarding/onboarding-upsell-modal-EN.png");
      loadImage("/images/onboarding/onboarding-order-confirmation-EN.png");
      loadImage("/images/onboarding/onboarding-navigation-offers-EN.png");
      loadImage(
        "/images/onboarding/onboarding-navigation-configuration-EN.png"
      );
    }

    if (i18n.language === "fr-FR") {
      loadImage("/images/onboarding/onboarding-upsell-modal-FR.png");
      loadImage("/images/onboarding/onboarding-order-confirmation-FR.png");
      loadImage("/images/onboarding/onboarding-navigation-offers-FR.png");
      loadImage(
        "/images/onboarding/onboarding-navigation-configuration-FR.png"
      );
    }
  }

  componentWillUnmount() {
    this.removeListener();
  }

  addListener() {
    if (this._listening) {
      return false;
    }
    window.addEventListener("keydown", this.handleKey);
    this._listening = true;
    return true;
  }

  removeListener() {
    window.removeEventListener("keydown", this.handleKey);
    this._listening = false;
  }

  handleKey = key => {
    switch (key.which) {
      case 37: {
        this.prev();
        break;
      }
      case 39:
      case 32: {
        this.next();
        break;
      }
      case 13: {
        if (this.state.step > 0) {
          this.next();
        }
        break;
      }
      default:
        break;
    }
  };

  prev = () => this.goto(this.state.step - 1);

  next = () => this.goto(this.state.step + 1);

  goto = step => {
    if (!STEPS[step]) {
      return;
    }
    this.setState({ step });
  };

  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
    this.setState({ isClosed: true }, () =>
      Cookie.set("onboarded", true, { expires: 365 })
    );
  };

  preventClose = e => e.stopPropagation();

  render() {
    const { t } = this.props;
    const { step, isClosed } = this.state;

    if (isClosed) {
      return null;
    }

    return (
      <div className="OnBoarding">
        <div
          onClick={this.preventClose}
          className={cx("OnBoarding--Card", STEPS[step].className)}
        >
          <div className="OnBoarding--Content">
            {STEPS[step].render(t, this.onClose)}
          </div>
          <div className="OnBoarding--Nav">
            <div
              className={cx("OnBoarding--Arrow", {
                invisible: step === 0
              })}
            >
              <div className="hide-mobile">
                <Button onClick={this.prev}>{t("onboarding.previous")}</Button>
              </div>
              <div className="hide-desktop">
                <Button onClick={this.prev}>{"<"}</Button>
              </div>
            </div>

            {STEPS.length > 1 && (
              <div className="OnBoarding--Bullets">
                {STEPS.map((s, i) => (
                  <div
                    key={i}
                    onClick={() => this.goto(i)}
                    className={cx("OnBoarding--Bullet", {
                      active: i <= step,
                      current: i === step
                    })}
                  />
                ))}
              </div>
            )}

            <div
              className={cx("OnBoarding--Arrow", {
                invisible: step === STEPS.length - 1
              })}
            >
              <div className="hide-mobile">
                <Button onClick={this.next} primary>
                  {t("onboarding.next")}
                </Button>
              </div>

              <div className="hide-desktop">
                <Button onClick={this.next} primary>
                  {">"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OnBoarding.propTypes = {
  onClose: PropTypes.func,
  addToast: PropTypes.func,
  inviteCollaborator: PropTypes.func,
  inviteCollaboratorLoader: PropTypes.bool
};

export default withTranslation()(OnBoarding);
