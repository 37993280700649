import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import AvatarEditor from "react-avatar-editor";
import { Modal, DropZone, RangeSlider, Card } from "@shopify/polaris";

const WIDTH_IMAGE = 150;
const HEIGHT_IMAGE = 150;

class ImageCropModal extends Component {
  static propTypes = {
    open: PropTypes.bool,
    onUpload: PropTypes.func,
    isUploading: PropTypes.bool,
    t: PropTypes.object
  };

  state = {
    image: null,
    zoom: 1
  };

  onUpload = () => {
    const { onUpload } = this.props;
    const { image } = this.state;

    if (this.editor) {
      const canvasScaled = this.editor
        .getImageScaledToCanvas()
        .toDataURL(image.type)
        .replace(/^data:image\/(.*);base64,/, "");

      onUpload(canvasScaled, image.name, image.type).then(() =>
        this.setState({ zoom: 1, image: null })
      );
    }
  };

  onClose = () => {
    const { onClose } = this.props;
    this.setState({ image: null, zoom: 1 }, onClose);
  };

  onChangeZoom = value => this.setState({ zoom: value });

  render() {
    const { open, isUploading, t, banner } = this.props;
    const { image, zoom } = this.state;

    return (
      <Modal
        open={open}
        onClose={this.onClose}
        title={t("components.UploadImage.texts.title")}
        primaryAction={{
          content: t("components.UploadImage.texts.buttons.upload"),
          onAction: this.onUpload,
          loading: isUploading
        }}
        secondaryActions={[
          {
            content: t("components.UploadImage.texts.buttons.cancel"),
            onAction: this.onClose
          }
        ]}
      >
        <Modal.Section>
          {!!banner && <div className="mb2">{banner}</div>}
          {!image && (
            <DropZone
              allowMultiple={false}
              accept="image/*"
              type="image"
              onDrop={(files, acceptedFiles, rejectedFiles) => {
                if (acceptedFiles.length) {
                  this.setState({ image: acceptedFiles[0] });
                }
              }}
            >
              <DropZone.FileUpload
                actionTitle={t("components.UploadImage.texts.buttons.add")}
                actionHint={t("components.UploadImage.texts.description")}
              />
            </DropZone>
          )}

          {image && (
            <div className="flex flex-center flex-content-center flex-column">
              <AvatarEditor
                ref={ref => (this.editor = ref)}
                image={image}
                width={WIDTH_IMAGE}
                height={HEIGHT_IMAGE}
                border={25}
                color={[0, 0, 0, 0.5]}
                scale={zoom}
                rotate={0}
              />

              <div className="mt2">
                <Card>
                  <div className="p2" style={{ color: "#454F5B" }}>
                    <div className="flex">
                      <div className="mr1" style={{ width: 20, height: 20 }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="#454F5B"
                            d="M256 48C141.2 48 48 141.2 48 256s93.2 208 208 208 208-93.2 208-208S370.8 48 256 48zm21 312h-42V235h42v125zm0-166h-42v-42h42v42z"
                          />
                        </svg>
                      </div>
                      <div>
                        {`Note: The image format is ${WIDTH_IMAGE}x${HEIGHT_IMAGE} pixels`}
                      </div>
                    </div>
                  </div>
                </Card>
              </div>

              <div className="mt2" style={{ width: 400 }}>
                <RangeSlider
                  label="Zoom"
                  value={zoom}
                  onChange={this.onChangeZoom}
                  min={1}
                  max={3}
                  step={0.1}
                />
              </div>
            </div>
          )}
        </Modal.Section>
      </Modal>
    );
  }
}

export default withTranslation()(ImageCropModal);
