import React, { Component } from "react";
import { Modal, TextContainer } from "@shopify/polaris";

export default class DeleteAffiliationModal extends Component {
  render() {
    const { onSubmit, onClose, open, isLoading } = this.props;
    return (
      <Modal
        open={open}
        onClose={onClose}
        title="Are you sure ?"
        primaryAction={{
          content: "Delete affiliation",
          onAction: onSubmit,
          loading: isLoading,
          destructive: true
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: onClose
          }
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>You will delete this affiliation.</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    );
  }
}
