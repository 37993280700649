import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Icon, Stack, TextContainer, Tooltip } from "@shopify/polaris";
import { InfoMinor } from "@shopify/polaris-icons";

export default function InfoOptionNameTypeIcon() {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  const toggleModal = useCallback(() => setOpenModal(value => !value), []);

  return (
    <>
      <span className="pointer ml1" style={{ zIndex: 0 }} onClick={toggleModal}>
        <Tooltip
          content={t(
            "upsellModalApp.form.variants.inputs.option.infoName.tooltip"
          )}
        >
          <Icon source={InfoMinor} color="inkLight" />
        </Tooltip>
      </span>
      <Modal
        open={openModal}
        onClose={toggleModal}
        title={t("upsellModalApp.form.variants.inputs.option.infoName.title")}
        primaryAction={{
          content: t(
            "upsellModalApp.form.variants.inputs.option.infoName.actionButton"
          ),
          onAction: toggleModal
        }}
      >
        <Modal.Section>
          <Stack vertical>
            <Stack.Item>
              <TextContainer>
                <p>
                  {t(
                    "upsellModalApp.form.variants.inputs.option.infoName.description"
                  )}
                </p>
              </TextContainer>
            </Stack.Item>
            <Stack.Item>
              <img
                src="/images/offer/option_name.png"
                alt="Option name"
                style={{
                  width: "100%"
                }}
              />
            </Stack.Item>
          </Stack>
        </Modal.Section>
      </Modal>
    </>
  );
}
