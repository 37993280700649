import { createAction } from "redux-actions";

export const sendEmail = createAction(
  "API:EMAIL_SEND_EMAIL",
  (storeId, data) => ({
    url: `/api/v1/stores/${storeId}/email/send`,
    method: "POST",
    data
  })
);
