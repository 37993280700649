import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Page, Button, Loading } from "@shopify/polaris";

import { getAffiliations } from "actions/affiliations";

import { getStoreId } from "selectors/auth";

import AffiliationsList from "./components/AffiliationsList";
import { calculateAffiliationPotentialGain } from "../../affiliations/utils";

class Affiliations extends Component {
  state = {
    affiliations: [],
    isLoading: true
  };

  componentDidMount() {
    const { getAffiliations, storeId } = this.props;

    getAffiliations(storeId).then(res => {
      this.setState({
        affiliations: res.data.map(a => {
          const affiliationCount = parseInt(a.affiliation_count);

          return {
            id: a.id,
            name: `${a.first_name} ${a.last_name}`,
            description: `${affiliationCount} affiliation${
              affiliationCount > 1 ? "s" : ""
            }`,
            url: `/admin/affiliations/${a.id}`,
            avatarInitial: `${a.first_name[0]}`,
            affiliates: a.affiliates,
            affiliationPercent: a.affiliation_percent,
            potentialGainPerMonth: `${calculateAffiliationPotentialGain(
              a.affiliates,
              a
            )} € / month`
          };
        }),
        isLoading: false
      });
    });
  }

  render() {
    const { affiliations, isLoading } = this.state;

    return (
      <Page title="Affiliations" separator>
        {isLoading && <Loading />}
        <div className="mb2">
          <Link className="link--silent" to="/admin/affiliations/new">
            <Button primary>Add affiliation</Button>
          </Link>
        </div>
        <AffiliationsList items={affiliations} isLoading={isLoading} />
      </Page>
    );
  }
}

export default connect(
  state => ({
    storeId: getStoreId(state)
  }),
  { getAffiliations }
)(Affiliations);
