import React, { Component } from "react";
import cx from "classnames";

import { Toast, Button, TextField } from "@shopify/polaris";

import "./style.scss";

class AffiliationUrlCopy extends Component {
  state = {
    showToast: false
  };

  onCopyCode = () => {
    if (!this.code) {
      return;
    }

    this.code.select();
    document.execCommand("copy");

    this.setState({ showToast: true });
  };

  toggleToast = () => {
    this.setState(({ showToast }) => ({ showToast: !showToast }));
  };

  render() {
    const { className, affiliationUrl } = this.props;
    const { showToast } = this.state;

    return (
      <div className={cx("AffiliationUrlCopy", className)}>
        <div className="flex flex-center flex-column">
          <textarea
            ref={ref => (this.code = ref)}
            className="hiddenAffiliationUrl"
            value={affiliationUrl}
            readOnly
          />
          <div className="flex w100 mb2">
            <div className="w100">
              <TextField value={affiliationUrl} type="url" readOnly />
            </div>
            <div className="buttonCopy ml2">
              <Button onClick={this.onCopyCode} primary>
                COPY TO CLIPBOARD
              </Button>
            </div>
          </div>

          {showToast ? (
            <Toast
              content="Affiliation link copied to clipboard"
              onDismiss={this.toggleToast}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default AffiliationUrlCopy;
