import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { ResourceList, TextStyle, Toast } from "@shopify/polaris";
import { updateOrderConfirmationOffer } from "actions/orderConfirmationOffers";
import { getShopifyMoneyFormat } from "selectors/auth";
import { formatShopifyMoney } from "utils/price";

import Switch from "components/Switch";
import MoreActionsButton from "./MoreActionsButton";
import "./style.scss";

class OffersItemRow extends Component {
  state = {
    isEnabled: this.props.rule.enable,
    isUpdatingStatus: false,
    showToast: false
  };

  toggleStatus = value => {
    const { rule, updateOrderConfirmationOffer } = this.props;
    const { store_id: storeId, id } = rule;

    this.setState(
      { isEnabled: value, isUpdatingStatus: true, showToast: false },
      () => {
        updateOrderConfirmationOffer(storeId, id, { enable: value }).then(
          res => {
            this.setState({
              isEnabled: res.data.enable,
              isUpdatingStatus: false,
              showToast: true
            });
          }
        );
      }
    );
  };

  updateOrderConfirmationOffer = data => {
    const { rule, updateOrderConfirmationOffer } = this.props;
    const { store_id: storeId, id } = rule;

    return updateOrderConfirmationOffer(storeId, id, {
      title: data.title,
      subtitle: data.subtitle
    });
  };

  toggleToast = () =>
    this.setState(({ showToast }) => ({ showToast: !showToast }));

  render() {
    const { rule, onDeleteOffer, t, shopifyMoneyFormat } = this.props;
    const { isEnabled, isUpdatingStatus, showToast } = this.state;

    const {
      variantId,
      name,
      count_added_to_cart,
      count_orders,
      discount_type,
      discount_value
    } = rule;

    const discount =
      discount_type === "percent"
        ? `-${discount_value}%`
        : `${formatShopifyMoney(discount_value * 100, shopifyMoneyFormat)} ${t(
            "orderConfirmationApp.list.headerList.currencyOff"
          )}`;

    return (
      <Fragment>
        <ResourceList.Item id={variantId}>
          {showToast && (
            <Toast
              content={t("orderConfirmationApp.list.toastStatus", {
                status: isEnabled
                  ? t("orderConfirmationApp.list.statusON")
                  : t("orderConfirmationApp.list.statusOFF")
              })}
              onDismiss={this.toggleToast}
            />
          )}
          <div className="OrderConfirmationOffersItemRow flex flex-center flex-content-between flex-wrap">
            <div className="flex flex-center flex-content-between flex-wrap">
              <div className="cell">
                <h3>
                  <TextStyle variation="strong">
                    {t("orderConfirmationApp.list.headerList.name")}
                  </TextStyle>
                </h3>
                <div>{name}</div>
              </div>

              <div className="cell">
                <h3>
                  <TextStyle variation="strong">
                    {t("orderConfirmationApp.list.headerList.status")}
                  </TextStyle>
                </h3>
                <Switch
                  onChange={this.toggleStatus}
                  checked={isEnabled}
                  disabled={isUpdatingStatus}
                />
              </div>

              <div className="cell">
                <h3>
                  <TextStyle variation="strong">
                    {t("orderConfirmationApp.list.headerList.discount")}
                  </TextStyle>
                </h3>
                <div dangerouslySetInnerHTML={{ __html: discount }} />
              </div>

              <div className="cell">
                <h3>
                  <TextStyle variation="strong">
                    {t("orderConfirmationApp.list.headerList.addedToCart")}
                  </TextStyle>
                </h3>
                <div>{count_added_to_cart || 0}</div>
              </div>

              <div className="cell">
                <h3>
                  <TextStyle variation="strong">
                    {t("orderConfirmationApp.list.headerList.countOrders")}
                  </TextStyle>
                </h3>
                <div>{count_orders || 0}</div>
              </div>
            </div>
            <div>
              <MoreActionsButton
                rule={rule}
                onDeleteOffer={onDeleteOffer}
                onEditOffer={this.updateOrderConfirmationOffer}
              />
            </div>
          </div>
        </ResourceList.Item>
      </Fragment>
    );
  }
}

export default connect(
  state => ({
    shopifyMoneyFormat: getShopifyMoneyFormat(state)
  }),
  { updateOrderConfirmationOffer }
)(withTranslation()(OffersItemRow));
