import map from "ramda/es/map";
import range from "ramda/es/range";

export const getFontStyle = value => {
  const option = FONT_STYLE_OPTIONS.find(o => o.value === value) || {};
  return option.style || {};
};

export const getFontStyleOptionByValues = (fontStyle, fontWeight) =>
  FONT_STYLE_OPTIONS.find(o => o.value === `${fontStyle} ${fontWeight}`);

export const FONT_SIZE_OPTIONS = [
  { label: "auto", value: "inherit" },
  ...map(v => ({ label: `${v}px`, value: `${v}px` }), range(8, 31))
];

export const FONT_STYLE_OPTIONS = [
  {
    label: "Normal",
    value: "normal normal",
    style: {
      fontStyle: "normal",
      fontWeight: "normal"
    }
  },
  {
    label: "Italic",
    value: "italic normal",
    style: {
      fontStyle: "italic",
      fontWeight: "normal"
    }
  },
  {
    label: "Bold",
    value: "normal bold",
    style: {
      fontStyle: "normal",
      fontWeight: "bold"
    }
  },
  {
    label: "Italic Bold",
    value: "italic bold",
    style: {
      fontStyle: "italic",
      fontWeight: "bold"
    }
  }
];
