import { createAction } from "redux-actions";

export const fetchOrderConfirmationOffers = createAction(
  "API:OFFERS_FETCH_OFFERS",
  storeId => ({
    url: `/api/v1/stores/${storeId}/upsell-order-confirmation/rules`
  })
);

export const fetchOrderConfirmationOffer = createAction(
  "API:OFFERS_FETCH_OFFER",
  (storeId, offerId) => ({
    url: `/api/v1/stores/${storeId}/upsell-order-confirmation/rules/${offerId}`
  })
);

export const addOrderConfirmationOffer = createAction(
  "API:OFFERS_ADD_OFFER",
  (
    storeId,
    name,
    title,
    subtitle,
    description,
    showCondition,
    showConditionCollection,
    productsIds,
    discountType,
    discountValue,
    prefixDiscountCode,
    hiddenAfterUpsell,
    minQuantity,
    minQuantityType
  ) => ({
    method: "POST",
    url: `/api/v1/stores/${storeId}/upsell-order-confirmation/rules`,
    data: {
      name,
      title,
      subtitle,
      description,
      enable: false,
      show_condition: showCondition,
      show_condition_collections: showConditionCollection,
      product_ids: productsIds,
      discount_type: discountType,
      discount_value: discountValue,
      prefix_discount_code: prefixDiscountCode,
      hidden_after_upsell: hiddenAfterUpsell,
      min_quantity: minQuantity,
      min_quantity_type: minQuantityType
    }
  })
);

export const updateOrderConfirmationOffer = createAction(
  "API:OFFERS_UPDATE_OFFER",
  (storeId, ruleId, data) => ({
    method: "PUT",
    url: `/api/v1/stores/${storeId}/upsell-order-confirmation/rules/${ruleId}`,
    data
  })
);

export const deleteOrderConfirmationOffer = createAction(
  "API:OFFERS_DELETE_OFFER",
  (storeId, ruleId) => ({
    method: "DELETE",
    url: `/api/v1/stores/${storeId}/upsell-order-confirmation/rules/${ruleId}`
  })
);
