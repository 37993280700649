import React, { Component } from "react";
import { connect } from "react-redux";

import PortalModal from "components/PortalModal";
import ContainerUpsellModal from "components/ContainerUpsellModal";

import { getUpsellModalByRuleId } from "actions/upsell";
import { getShopifyMoneyFormat } from "selectors/auth";

class PreviewOfferModal extends Component {
  state = {
    isLoading: true,
    data: null
  };

  componentDidUpdate(prevProps) {
    const { open } = this.props;
    if (open && !prevProps.open) {
      this.fetchUpsell();
    }
  }

  fetchUpsell = () => {
    const { rule, onClose, getUpsellModalByRuleId } = this.props;
    const { id: ruleId, store_id: storeId } = rule;

    this.setState({ isLoading: true }, () =>
      getUpsellModalByRuleId(storeId, ruleId)
        .then(res => {
          this.setState({ isLoading: false, data: res.data });
        })
        .catch(onClose)
    );
  };

  render() {
    const { open, onClose, shopifyMoneyFormat } = this.props;
    const { isLoading, data } = this.state;

    return (
      <div className="PreviewOfferModal">
        {open && !isLoading && data && (
          <PortalModal>
            <ContainerUpsellModal
              data={{ ...data, money_format: shopifyMoneyFormat }}
              onClose={onClose}
            />
          </PortalModal>
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    shopifyMoneyFormat: getShopifyMoneyFormat(state)
  }),
  { getUpsellModalByRuleId }
)(PreviewOfferModal);
