import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import devTools from "middlewares/dev-tools";
import api from "middlewares/api";
import rootReducer, { initialState } from "./reducers";

const middlewares = [thunk, api];

const enhancers = compose(
  applyMiddleware(...middlewares),
  devTools
);

const store = createStore(rootReducer, initialState, enhancers);

export default store;
