import React, { Component, Fragment } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Page,
  Layout,
  Loading,
  Toast,
  Heading,
  PageActions,
  Tabs
} from "@shopify/polaris";
import { getStyles } from "components/ContainerUpsellModal/utils";

import { getStoreId } from "selectors/auth";

import {
  fetchConfiguration,
  updateConfiguration
} from "actions/configurations";

import { DEFAULT_MODAL_SETTINGS_VALUES } from "data/settings";

import SettingsForm from "./components/SettingsForm";
import PreviewUpsell from "./components/PreviewUpsell";
import PreviewCartDiscountUpsell from "./components/PreviewCartDiscountUpsell";
import DiscountCartStyleForm from "./components/DiscountCartStyleForm";
import DiscountCartBanner from "./components/DiscountCartBanner";

import "./style.scss";

const NEW_CONFIG = {
  replaceCartValue: "REPLACE",
  ...DEFAULT_MODAL_SETTINGS_VALUES
};

class Configuration extends Component {
  state = {
    data: null,
    config: {},
    initConfig: {},
    isLoading: true,
    isModified: false,
    isSaving: false,
    showToast: false,
    selectedTab: 0
  };

  componentDidMount() {
    const { storeId, fetchConfiguration } = this.props;
    fetchConfiguration(storeId)
      .then(res => {
        this.setState({
          data: res.data.configuration,
          config: {
            ...NEW_CONFIG,
            ...res.data.configuration.modal_style
          },
          initConfig: res.data.configuration.modal_style
        });

        return res;
      })
      .then(() => this.setState({ isLoading: false }));
  }

  setDefaultValues = () => {
    this.setState({ config: DEFAULT_MODAL_SETTINGS_VALUES });
  };

  onChange = value => {
    this.setState({ config: value });
  };

  onSave = () => {
    const { storeId, updateConfiguration } = this.props;
    const { config, data } = this.state;
    const configId = data.id;

    this.setState({ isSaving: true }, () => {
      updateConfiguration(configId, storeId, {
        modal_style: R.omit(["upsellType"], config)
      })
        .then(res => {
          this.setState(prevState => ({
            data: res.data,
            config: {
              ...res.data.modal_style,
              upsellType: prevState.config.upsellType
            },
            initConfig: res.data.modal_style,
            isSaving: false,
            showToast: true
          }));
        })
        .catch(err => {
          this.setState({ isSaving: false });
        });
    });
  };

  toggleToast = () => {
    this.setState(({ showToast }) => ({ showToast: !showToast }));
  };

  formatAddToCartProps = () => {
    const { config } = this.state;
    return {
      labelAddToCart: config.addCartValue
    };
  };

  formatReplaceCartProps = () => {
    const { config } = this.state;
    return {
      labelReplaceCart: config.replaceCartValue
    };
  };

  formatCancelProps = () => {
    const { config } = this.state;
    return {
      labelCancel: config.cancelValue
    };
  };

  formatStyles = () => {
    const { config } = this.state;
    return getStyles(config);
  };

  formatDiscountCart = () => {
    const { config } = this.state;
    return {
      isOverridingDiscountCartStyle: config.isOverridingDiscountCartStyle,
      cartDiscountNote: config.cartDiscountNote,
      originalPriceColor: config.originalPriceColor,
      originalPriceFontSize: config.originalPriceFontSize,
      originalPriceFontStyle: config.originalPriceFontStyle,
      savedAmountPriceColor: config.savedAmountPriceColor,
      savedAmountPriceFontSize: config.savedAmountPriceFontSize,
      savedAmountPriceFontStyle: config.savedAmountPriceFontStyle,
      discountPriceColor: config.discountPriceColor,
      discountPriceFontSize: config.discountPriceFontSize,
      discountPriceFontStyle: config.discountPriceFontStyle,
      discountNoteColor: config.discountNoteColor,
      discountNoteFontSize: config.discountNoteFontSize,
      discountNoteFontStyle: config.discountNoteFontStyle
    };
  };

  render() {
    const { t } = this.props;
    const {
      isLoading,
      isSaving,
      showToast,
      config,
      initConfig,
      selectedTab
    } = this.state;

    const omittedKeys = ["upsellType"];
    const isModified = !R.equals(
      R.omit(omittedKeys, config),
      R.omit(omittedKeys, initConfig)
    );

    const tabs = [
      {
        id: "upsell-modal",
        content: t("upsellModalApp.configuration.modal.tabName"),
        accessibilityLabel: t("upsellModalApp.configuration.modal.tabName"),
        panelID: "upsell-modal",
        components: (
          <Layout>
            <Layout.Section>
              <Heading>
                {t("upsellModalApp.configuration.modal.previewTitle")}
              </Heading>
              <div className="mt2 mb2">
                {t("upsellModalApp.configuration.modal.previewSubtitle")}
              </div>
              <PreviewUpsell
                upsellType={config.upsellType}
                {...this.formatAddToCartProps()}
                {...this.formatReplaceCartProps()}
                {...this.formatCancelProps()}
                {...this.formatStyles()}
              />
            </Layout.Section>
            <Layout.Section>
              <div className="mt3">
                <SettingsForm config={config} onChange={this.onChange} />
              </div>
            </Layout.Section>
          </Layout>
        )
      },
      {
        id: "discount-cart",
        content: t("upsellModalApp.configuration.cart.tabName"),
        accessibilityLabel: t("upsellModalApp.configuration.cart.tabName"),
        panelID: "discount-cart",
        components: (
          <Layout>
            <Layout.Section>
              <DiscountCartBanner className="mb3" />
              <Heading>
                {t("upsellModalApp.configuration.cart.previewTitle")}
              </Heading>
              <div className="mt2 mb2">
                {t("upsellModalApp.configuration.cart.previewSubtitle")}
              </div>
              <PreviewCartDiscountUpsell {...this.formatDiscountCart()} />
            </Layout.Section>
            <Layout.Section>
              <div className="mt3 mb3">
                <DiscountCartStyleForm
                  config={config}
                  onChange={this.onChange}
                />
              </div>
            </Layout.Section>
          </Layout>
        )
      }
    ];

    return (
      <Page
        title={t("upsellModalApp.configuration.title")}
        separator
        primaryAction={{
          content: t("upsellModalApp.configuration.submitButton"),
          disabled: !isModified,
          onClick: this.onSave,
          loading: isSaving
        }}
      >
        {showToast && (
          <Toast
            content={t("upsellModalApp.configuration.toastSave")}
            onDismiss={this.toggleToast}
          />
        )}

        <div className="Configuration">
          {isLoading ? (
            <Loading size="large" color="teal" />
          ) : (
            <Fragment>
              <Tabs
                tabs={tabs}
                selected={selectedTab}
                onSelect={value => this.setState({ selectedTab: value })}
              >
                <div className="mt3">{tabs[selectedTab].components}</div>
              </Tabs>
              <PageActions
                primaryAction={{
                  content: t("upsellModalApp.configuration.submitButton"),
                  disabled: !isModified,
                  onClick: this.onSave,
                  loading: isSaving
                }}
                secondaryActions={[
                  {
                    content: t("upsellModalApp.configuration.resetButton"),
                    onClick: this.setDefaultValues
                  }
                ]}
              />
            </Fragment>
          )}
        </div>
      </Page>
    );
  }
}

export default connect(
  state => ({
    storeId: getStoreId(state)
  }),
  {
    fetchConfiguration,
    updateConfiguration
  }
)(withTranslation()(Configuration));
