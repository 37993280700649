import React from "react";
import { useTranslation } from "react-i18next";
import { Banner } from "@shopify/polaris";

const PrefixDiscountCodeBanner = ({ prefixDiscountCode, className }) => {
  const { t } = useTranslation();
  return (
    <div className={className}>
      <Banner status="info">
        {t("components.PrefixDiscountCodeBanner.description", {
          code: prefixDiscountCode
        })}
      </Banner>
    </div>
  );
};

export default PrefixDiscountCodeBanner;
