import { getFontStyle } from "../ColorPickerWithFont/options";

export const getStyles = config => {
  const styleNoThanks = {
    color: config.cancelLabelColor,
    backgroundColor: config.cancelBackgroundColor
  };

  const styleAddToCart = {
    color: config.addCartLabelColor,
    backgroundColor: config.addCartBackgroundColor,
    borderRadius: config.addToCartBorderRadius
  };

  const styleReplaceCart = {
    color: config.replaceCartLabelColor,
    backgroundColor: config.replaceCartBackgroundColor
  };

  const styleTitle = {
    color: config.titleColor,
    fontSize: config.titleFontSize,
    ...getFontStyle(config.titleFontStyle)
  };

  const styleSubtitle = {
    color: config.subtitleColor,
    fontSize: config.subtitleFontSize,
    ...getFontStyle(config.subtitleFontStyle)
  };

  const styleDiscountPrice = {
    color: config.discountPriceTextColor,
    fontSize: config.discountPriceTextFontSize,
    ...getFontStyle(config.discountPriceTextFontStyle)
  };

  const stylePrice = {
    color: config.priceTextColor,
    fontSize: config.priceTextFontSize,
    ...getFontStyle(config.priceTextFontStyle)
  };

  const styleModal = {
    backgroundColor: config.modalBackgroundColor,
    padding: config.modalPadding,
    borderRadius: config.modalBorderRadius
  };

  const styleProductDescription = {
    color: config.productDescriptionColor,
    fontSize: config.productDescriptionFontSize,
    ...getFontStyle(config.productDescriptionFontStyle)
  };

  const styleProductName = {
    color: config.productNameColor,
    fontSize: config.productNameFontSize,
    ...getFontStyle(config.productNameFontStyle)
  };

  // Variants
  const styleVariantOptionLabel = {
    color: config.variantOptionLabelColor,
    fontSize: config.variantOptionLabelFontSize,
    ...getFontStyle(config.variantOptionLabelFontStyle)
  };

  const styleVariantOptionButton = {
    paddingLeft: config.variantOptionButtonPaddingLeftRight,
    paddingRight: config.variantOptionButtonPaddingLeftRight,
    paddingTop: config.variantOptionButtonPaddingTopBottom,
    paddingBottom: config.variantOptionButtonPaddingTopBottom,
    backgroundColor: config.variantOptionButtonBackgroundColor,
    color: config.variantOptionButtonColor,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: config.variantOptionButtonBorderColor,
    borderRadius: config.variantOptionButtonBorderRadius,
    fontSize: config.variantOptionButtonFontSize,
    ...getFontStyle(config.variantOptionButtonFontStyle)
  };

  const styleVariantOptionSwatch = {
    height: config.variantOptionSwatchSize,
    width: config.variantOptionSwatchSize,
    borderWidth: 1,
    borderColor: config.variantOptionSwatchBorderColor,
    borderRadius: config.variantOptionSwatchBorderRadius
  };

  const styleVariants = {
    labelOutOfStock: config.variantLabelOutOfStockValue,
    enableTooltip: config.variantOptionSwatchEnableTooltip,
    variantOptionLabel: styleVariantOptionLabel,
    variantOptionButton: styleVariantOptionButton,
    variantOptionSwatch: styleVariantOptionSwatch,
    selected: {
      selectedVariantOptionSwatch: {
        borderColor: config.variantOptionSwatchSelectedBorderColor
      },
      selectedVariantOption: {
        backgroundColor: config.variantOptionButtonSelectedBackgroundColor,
        color: config.variantOptionButtonSelectedColor,
        borderColor: config.variantOptionButtonSelectedBorderColor
      }
    }
  };

  return {
    styleNoThanks,
    styleAddToCart,
    styleReplaceCart,
    styleTitle,
    styleSubtitle,
    styleDiscountPrice,
    stylePrice,
    styleModal,
    styleProductDescription,
    styleProductName,
    styleVariants
  };
};

export const getVariantsWithDiscountPrice = (
  variants,
  discountType,
  discountValue
) => {
  return variants.map(variant => {
    const variantPrice = variant.price;
    const discountPrice =
      discountType === "percent"
        ? variantPrice - (variantPrice * discountValue) / 100
        : variantPrice - discountValue;

    return {
      ...variant,
      discountPrice: Math.floor(discountPrice * 100)
    };
  });
};
