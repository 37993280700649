import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { Icon, Toast, Tooltip } from "@shopify/polaris";
import { QuestionMarkMinor } from "@shopify/polaris-icons";

import "./style.scss";

export default function LabelField({ label, isDisabled, toolTip, copyText }) {
  const textareaEl = useRef(null);
  const { t } = useTranslation();
  const [showToast, setShowToast] = useState(false);

  function onCopy(e) {
    if (!textareaEl || !copyText) {
      return;
    }

    textareaEl.current.select();
    document.execCommand("copy");
    e.target.focus();
    setShowToast(true);
  }

  return (
    <div className={cx("LabelField flex flex-center", { isDisabled })}>
      {label}
      {toolTip && (
        <Tooltip content={toolTip}>
          <div className="icon pointer" onClick={onCopy}>
            <Icon source={QuestionMarkMinor} color="inkLight" />
          </div>
        </Tooltip>
      )}

      {showToast ? (
        <Toast
          content={t("upsellModalApp.configuration.cart.toastSuccess", {
            name: copyText
          })}
          onDismiss={() => setShowToast(!showToast)}
        />
      ) : null}

      <textarea ref={textareaEl} className="hiddenText" value={copyText} />
    </div>
  );
}
