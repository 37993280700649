import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Loading, Page } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import {
  fetchUpsellModalOffers,
  deleteUpsellModalOffer
} from "actions/upsellModalOffers";
import { getStoreId } from "selectors/auth";

import { Button } from "@shopify/polaris";
import OffersList from "./components/OffersList";

import "./style.scss";

class Offers extends Component {
  state = {
    rules: [],
    isLoading: true
  };

  componentDidMount() {
    this.getAllOffers();
  }

  getAllOffers = () => {
    const { storeId, fetchUpsellModalOffers } = this.props;
    fetchUpsellModalOffers(storeId).then(res => {
      this.setState({ rules: res.data, isLoading: false });
    });
  };

  deleteUpsellModalOffer = ruleId => {
    const { storeId, deleteUpsellModalOffer } = this.props;
    return deleteUpsellModalOffer(storeId, ruleId).then(this.getAllOffers);
  };

  render() {
    const { t } = this.props;
    const { rules, isLoading } = this.state;

    return (
      <Page title={t("upsellModalApp.list.title")} separator>
        <div className="OffersPage">
          <div className="mb3">
            <Link className="createOfferButton" to="/upsell/modal/offers/new">
              <Button primary size="large">
                {t("upsellModalApp.list.addButton")}
              </Button>
            </Link>
          </div>

          {isLoading ? (
            <div className="mt2">
              <Loading size="large" color="teal" />
            </div>
          ) : (
            <OffersList
              rules={rules}
              onDeleteOffer={this.deleteUpsellModalOffer}
            />
          )}
        </div>
      </Page>
    );
  }
}

export default connect(
  state => ({
    storeId: getStoreId(state)
  }),
  {
    fetchUpsellModalOffers,
    deleteUpsellModalOffer
  }
)(withTranslation()(Offers));
