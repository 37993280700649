import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import * as R from "ramda";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Card, DataTable, Heading, SkeletonBodyText } from "@shopify/polaris";
import { getShopifyCurrency, getShopifyMoneyFormat } from "selectors/auth";
import { formatShopifyMoney } from "utils/price";

import "./style.scss";

const getAppType = {
  "upsell-order-confirmation": <b>Order Confirmation Upsell app</b>,
  "upsell-modal": <b>Upsell Modal app</b>
};

function OrdersList({
  orders,
  storeName,
  isLoading,
  shopifyCurrency,
  shopifyMoneyFormat
}) {
  const { t } = useTranslation();
  const rows = orders.map(o => [
    <a
      className="link"
      href={`https://${storeName}/admin/orders/${o.id}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      Order #{o.id}
    </a>,
    getAppType[R.prop("type_app", o)],
    R.pathOr("-", ["shipping_address", "first_name"], o),
    moment(o.created_at).format(
      i18next.language === "fr" ? "DD/MM/YYYY HH:mm" : "MM/DD/YYYY HH:mm"
    ),
    <span
      dangerouslySetInnerHTML={{
        __html: formatShopifyMoney(o.total_price, shopifyMoneyFormat)
      }}
    />
  ]);

  return (
    <Card>
      <div className="OrdersList p2">
        <div className="p2">
          <Heading>{t("dashboard.orders.title")}</Heading>
        </div>

        {isLoading ? (
          <div className="p2">
            <div className="mb2">
              <SkeletonBodyText />
            </div>
            <SkeletonBodyText />
          </div>
        ) : orders.length ? (
          <DataTable
            columnContentTypes={["text", "text", "date", "text"]}
            headings={[
              t("dashboard.orders.headingTable.order"),
              t("dashboard.orders.headingTable.appUsed"),
              t("dashboard.orders.headingTable.name"),
              t("dashboard.orders.headingTable.date"),
              t("dashboard.orders.headingTable.total", { shopifyCurrency })
            ]}
            rows={rows}
          />
        ) : (
          <div className="px3 py2 fw-500">{t("dashboard.orders.noOrders")}</div>
        )}
      </div>
    </Card>
  );
}

export default connect(state => ({
  shopifyMoneyFormat: getShopifyMoneyFormat(state),
  shopifyCurrency: getShopifyCurrency(state)
}))(OrdersList);
