import React, { Component } from "react";
import equals from "ramda/es/equals";

import { formatShopifyMoney } from "utils/price";
import SelectVariantItemContent from "../SelectVariantItemContent";

import { cs } from "../../../utils/upsell";
import {
  getVariantLists,
  findVariantIdByOptions
} from "../SelectVariantItemContent/utils";

import styles from "./style.module.scss";

export default class UpsellContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      variantId: this.getDefaultVariantId()
    };
  }

  getDefaultVariantId = () => {
    const { cart, variants } = this.props;

    if (!cart) {
      return variants.length ? variants[0].id : null;
    }

    if (!cart.items.length) {
      return variants.length ? variants[0].id : null;
    }

    const optionsValues = cart.items.map(i => {
      return i.options_with_values.map(o => o.value);
    });

    const possibilities = optionsValues.reduce((accumulator, value) => {
      const newOptions = value.reduce((accumulator, value) => {
        const newValue = [...accumulator, value].join(" / ");
        accumulator.push(newValue);
        return accumulator;
      }, []);

      accumulator.push(newOptions);

      return accumulator;
    }, []);

    const allCartVariantPossibilities = possibilities.reduce(
      (acc, val) => acc.concat(val),
      []
    );

    const intersection = (array1, array2) =>
      array1.filter(value => array2.includes(value));

    const filteredVariants = variants.filter(v => {
      const values = v.name.split("/").map(v => v.trim());
      return intersection(allCartVariantPossibilities, values).length;
    });

    if (filteredVariants.length) {
      const sameVariant = filteredVariants.find(v =>
        allCartVariantPossibilities.includes(v.name)
      );

      if (sameVariant) {
        return sameVariant.id;
      }

      const lastVariant = filteredVariants.pop();
      return lastVariant.id;
    }

    return variants.length ? variants[0].id : null;
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { variants } = prevProps;
    const { variants: currentVariants } = this.props;

    if (!equals(variants, currentVariants)) {
      this.setState({
        variantId: currentVariants ? currentVariants[0].id : null
      });
    }
  }

  onChangeSelect = event =>
    this.setState({ variantId: parseInt(event.target.value) });

  onChangeVariant = (options, selectedOption) => {
    const { variants } = this.props;
    const selectedVariantId = findVariantIdByOptions(
      variants,
      options,
      selectedOption
    );

    this.setState({ variantId: parseInt(selectedVariantId) });
  };

  formatPrice = value => {
    const { moneyFormat } = this.props;
    return formatShopifyMoney(value, moneyFormat);
  };

  addToCart = () => {
    try {
      const { onSubmit } = this.props;
      const { variantId } = this.state;

      const data = {
        id: variantId,
        quantity: 1
      };

      if (onSubmit) {
        onSubmit(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const {
      styleModal,
      stylePrice,
      styleDiscountPrice,
      styleTitle,
      styleSubtitle,
      styleNoThanks,
      styleAddToCart,
      styleReplaceCart,
      labelAddToCart,
      labelReplaceCart,
      labelCancel,
      title,
      subtitle,
      description,
      variants,
      productName,
      styleProductDescription,
      styleProductName,
      upsellType,
      onClose,

      // Variant swatches
      variantSelectorType,
      variantOptionsConfig,
      productOptions,
      labelOutOfStock,
      enableTooltip,
      styleVariants
    } = this.props;
    const { variantId } = this.state;

    const hasOnlyOneVariant = variants.length <= 1;

    const isVariantId = variantId => v => v.id === variantId;
    const currentVariant = variants.find(isVariantId(variantId)) || {};
    const hasDiscountPrice =
      currentVariant.price > currentVariant.discountPrice;

    const variantsList = getVariantLists(
      variants,
      productOptions.map(o => o.name)
    );

    const selectedVariant = variantsList
      .map(list => {
        const options = list[1];

        const currentOption = options.find(o => {
          const variantIds = o.variants.map(v => v.id);

          return variantIds.includes(variantId);
        });

        return currentOption;
      })
      .filter(i => i);

    return (
      <div
        className={cs(styles.modal, "awesome-upsell-modal")}
        style={styleModal}
      >
        <div
          className={cs(
            styles.containerTitle,
            "awesome-upsell-container-title"
          )}
        >
          <div
            className={cs(styles.title, "awesome-upsell-title")}
            style={styleTitle}
          >
            {title}
          </div>
          <div
            className={cs(styles.subtitle, "awesome-upsell-subtitle")}
            style={styleSubtitle}
          >
            {subtitle}
          </div>
        </div>
        <div
          className={cs(
            styles.containerProduct,
            "awesome-upsell-container-product"
          )}
        >
          <div
            className={cs(
              styles.containerProductImage,
              "awesome-upsell-container-product-image"
            )}
          >
            <img
              className={cs(
                styles.productImage,
                "awesome-upsell-product-image"
              )}
              src={currentVariant.imageUrl}
              alt={productName}
            />
          </div>
          <div
            className={cs(
              styles.containerButtons,
              "awesome-upsell-container-buttons"
            )}
          >
            <div
              className={cs(styles.productName, "awesome-upsell-product-name")}
              style={styleProductName}
            >
              {productName}
            </div>
            {description && (
              <div
                className={cs(styles.description, "awesome-upsell-description")}
                style={styleProductDescription}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}

            <div
              className={cs(
                styles.containerPrice,
                "awesome-upsell-container-price"
              )}
            >
              <div
                className={styles.price}
                style={{
                  ...stylePrice,
                  ...(!hasDiscountPrice ? { textDecoration: "none" } : {})
                }}
                dangerouslySetInnerHTML={{
                  __html: this.formatPrice(currentVariant.price)
                }}
              />
              {hasDiscountPrice && (
                <div
                  className={cs(
                    styles.discountPrice,
                    "awesome-upsell-discount-price"
                  )}
                  style={styleDiscountPrice}
                  dangerouslySetInnerHTML={{
                    __html: this.formatPrice(currentVariant.discountPrice)
                  }}
                />
              )}
            </div>

            {!hasOnlyOneVariant ? (
              variantSelectorType === "select" ? (
                <div
                  className={cs(
                    styles.containerSelect,
                    "awesome-upsell-container-select"
                  )}
                >
                  <select
                    className={cs(styles.select, "awesome-upsell-select")}
                    value={variantId}
                    onChange={this.onChangeSelect}
                  >
                    {variants.map(v => (
                      <option key={v.id} value={v.id}>
                        {v.name}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                <div>
                  <SelectVariantItemContent
                    labelOutOfStock={labelOutOfStock}
                    enableTooltip={enableTooltip}
                    values={selectedVariant}
                    optionConfig={variantOptionsConfig}
                    variantsList={variantsList}
                    onChange={this.onChangeVariant}
                    customStyles={styleVariants}
                  />
                </div>
              )
            ) : null}

            {upsellType === "replace_product" ? (
              <div
                className={cs(
                  styles.btn,
                  styles.addToCart,
                  "awesome-quantity-replace-button"
                )}
                onClick={this.addToCart}
                style={styleReplaceCart}
              >
                {labelReplaceCart}
              </div>
            ) : (
              <div
                className={cs(
                  styles.btn,
                  styles.addToCart,
                  "awesome-quantity-add-button"
                )}
                onClick={this.addToCart}
                style={styleAddToCart}
              >
                {labelAddToCart}
              </div>
            )}
            <div
              className={cs(styles.cancel, "awesome-quantity-cancel-button")}
              onClick={onClose}
              style={styleNoThanks}
            >
              {labelCancel}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UpsellContent.defaultProps = {
  title: "Promo!",
  labelAddToCart: "Add to cart",
  labelReplaceCart: "Replace",
  styleAddToCard: {},
  labelCancel: "No thanks",
  styleNoThanks: {},
  styleModal: {}
};
