import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EmailContactButton from "components/EmailContactButton";

import "./style.scss";

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export default function Footer() {
  const { t } = useTranslation();

  const onSendEmail = (name, email, message) =>
    axios.post(`${BASE_URL}/api/v1/email/send`, {
      name,
      email,
      message
    });

  return (
    <div className="Footer">
      <div className="container flex flex-center flex-content-between">
        <EmailContactButton onCustomSendEmail={onSendEmail} />

        <div className="full-flex-center">
          <Link to="/privacy" className="privacyLink link--silent ml2 mr2">
            {t("homepage.footer.privacyPolicy")}
          </Link>
          <div>© 2020, AwesomeApps</div>
        </div>
      </div>
    </div>
  );
}
