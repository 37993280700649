import React from "react";
import { Link } from "react-router-dom";

import "./style.scss";

export default function PlanBox({
  title,
  price,
  description,
  buttonLabel,
  rows,
  borderTopStyle
}) {
  return (
    <div
      className="PlanBox flex flex-column flex-content-between"
      style={{ borderTop: borderTopStyle }}
    >
      <div className="full-flex-center flex-column">
        <div className="title">{title}</div>
        <div className="price mt3">{price}</div>
        <div className="description mb3">{description}</div>

        <ul className="rows">
          {rows.map((row, index) => (
            <li key={index} style={row.style}>
              {row.label}
            </li>
          ))}
        </ul>
      </div>

      <Link to="/login" className="link--silent installButton mt3">
        <div className="">{buttonLabel}</div>
      </Link>
    </div>
  );
}
