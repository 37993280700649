import React, { useState, useEffect } from "react";
import cx from "classnames";
import { TextField, Tag } from "@shopify/polaris";

import "./style.scss";

export default function TagTextField({
  defaultTags = [],
  onChange,
  label,
  placeholder
}) {
  const [value, setValue] = useState("");
  const [tags, setTags] = useState(defaultTags);
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    if (onChange) {
      onChange(tags);
    }
  }, [tags.length]);

  const onChangeText = value => {
    setValue(value);
  };

  const onCreateTags = () => {
    const newTags = value.split(",");
    if (value.trim() && value !== ",") {
      setTags([...tags, ...newTags]);
    }

    // FIXME setTimeout needed to remove comma char when adding tags
    setTimeout(() => setValue(""), 10);
  };

  const onRemoveTag = index => () => {
    const tagsWithRemovedIndex = tags.filter(
      (_tag, indexTag) => indexTag !== index
    );
    setTags(tagsWithRemovedIndex);
  };

  const onKeyPress = e => {
    const KEY_CODES = {
      comma: 188,
      enter: 13
    };

    if (KEY_CODES.comma === e.keyCode || KEY_CODES.enter === e.keyCode) {
      onCreateTags();
      setValue("");
    }
  };

  const onBlur = () => {
    onCreateTags();
    setIsFocus(false);
  };

  const onFocus = () => setIsFocus(true);

  return (
    <div className="TagTextField">
      {label && <div className="TagTextField--label">{label}</div>}
      <div
        className={cx("TagTextField--content", { hasTags: tags.length > 0 })}
      >
        {tags.map((tag, index) => (
          <span className="tagContainer" key={`${index}-${tag}`}>
            <Tag onRemove={onRemoveTag(index)}>{tag}</Tag>
          </span>
        ))}

        <div
          onKeyDown={onKeyPress}
          className={cx({ fixMarginTop: tags.length > 0 })}
        >
          <TextField
            value={value}
            onChange={onChangeText}
            onBlur={onBlur}
            onFocus={onFocus}
            placeholder={
              tags.length === 0
                ? placeholder || "Separate options with a comma"
                : ""
            }
          />
        </div>
        <div className={cx("backdrop", { isFocus })} />
      </div>
    </div>
  );
}
