import React, { Component } from "react";

import {
  Card,
  ResourceList,
  TextStyle,
  Avatar,
  SkeletonBodyText
} from "@shopify/polaris";

import AffiliatesListModal from "../AffiliatesListModal";

export default class AffiliationsList extends Component {
  state = {
    affiliates: [],
    affiliationName: "",
    openModal: false
  };

  renderItem = item => {
    const {
      id,
      url,
      avatarInitial,
      name,
      description,
      potentialGainPerMonth,
      affiliates,
      affiliationPercent
    } = item;
    const shortcutActions = [
      {
        content: "View affiliates",
        onAction: () =>
          this.setState({
            affiliates: affiliates,
            openModal: true,
            affiliationName: name
          })
      }
    ];

    return (
      <ResourceList.Item
        id={id}
        url={url}
        media={
          <Avatar
            customer={false}
            size="medium"
            name={name}
            initials={avatarInitial}
          />
        }
        shortcutActions={shortcutActions}
        accessibilityLabel={`View details for ${name}`}
        name={name}
      >
        <div className="flex flex-center flex-content-between">
          <div className="flex flex-center">
            <div>
              <h3>
                <TextStyle variation="strong">{name}</TextStyle>
              </h3>
              <div>{description}</div>
            </div>
            <div className="ml2 mr2">
              <div className="bold">Percentage</div>
              <div>{affiliationPercent}%</div>
            </div>
          </div>
          <div className="bold">{potentialGainPerMonth}</div>
        </div>
      </ResourceList.Item>
    );
  };

  onCloseAffiliateModal = () =>
    this.setState({ affiliates: [], affiliationName: "", openModal: false });

  render() {
    const { items, isLoading } = this.props;
    const { affiliates, openModal } = this.state;

    if (isLoading) {
      return (
        <Card sectioned>
          <SkeletonBodyText lines={6} />
        </Card>
      );
    }

    return (
      <div className="AffiliationsList">
        <AffiliatesListModal
          open={openModal}
          affiliates={affiliates}
          onClose={this.onCloseAffiliateModal}
        />
        {items.length > 0 ? (
          <Card>
            <ResourceList
              resourceName={{ singular: "customer", plural: "customers" }}
              items={items}
              renderItem={this.renderItem}
            />
          </Card>
        ) : (
          <div className="mt3">There is no affiliation yet.</div>
        )}
      </div>
    );
  }
}
