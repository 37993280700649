import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "@shopify/polaris";

import VariantOptionsForm from "./VariantOptionsForm";
import InfoVariantSelectorTypeIcon from "./InfoVariantSelectorTypeIcon";

import Select from "react-select";

export const VARIANT_SELECTOR_TYPE_OPTIONS = t => [
  {
    label: t(
      "upsellModalApp.form.variants.inputs.variantSelectorType.options.1"
    ),
    value: "select"
  },
  {
    label: t(
      "upsellModalApp.form.variants.inputs.variantSelectorType.options.0"
    ),
    value: "badge"
  }
];

function VariantSettingsForm({
  variantOptionsConfig,
  variantSelectorType,
  onChange
}) {
  const { t } = useTranslation();

  return (
    <Card>
      <div className="VariantSettingsForm">
        <div>
          <Card.Section title={t("upsellModalApp.form.variants.sections.0")}>
            <div className="mt2">
              <div className="mb1 flex">
                <div>
                  {t(
                    "upsellModalApp.form.variants.inputs.variantSelectorType.label"
                  )}
                </div>
                <InfoVariantSelectorTypeIcon />
              </div>
              <div>
                <Select
                  styles={{
                    menu: provided => ({
                      ...provided,
                      zIndex: 9999999
                    })
                  }}
                  className="w100"
                  value={variantSelectorType}
                  options={VARIANT_SELECTOR_TYPE_OPTIONS(t)}
                  onChange={onChange("variantSelectorType")}
                />
              </div>
            </div>
          </Card.Section>
          {variantSelectorType.value === "badge" && (
            <Card.Section title={t("upsellModalApp.form.variants.sections.1")}>
              <VariantOptionsForm
                options={variantOptionsConfig}
                onChange={onChange("variantOptionsConfig")}
              />
            </Card.Section>
          )}
        </div>
      </div>
    </Card>
  );
}

export default React.memo(VariantSettingsForm);
