import React, { PureComponent } from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import cx from "classnames";
import { withTranslation } from "react-i18next";

import Select, { components } from "react-select";
import {
  TextField,
  Card,
  Icon,
  ButtonGroup,
  Button,
  ChoiceList
} from "@shopify/polaris";
import {
  CircleAlertMajor,
  AddProductMajor,
  ReplaceMajor
} from "@shopify/polaris-icons";
import { getShopifyProducts } from "actions/products";
import { getShopifyCollections } from "actions/collections";

import {
  getStoreId,
  getShopifyCurrency,
  getShopifyMoneyFormat
} from "selectors/auth";

import WarningPriceBanner from "./WarningPriceBanner";
import NoDiscountPriceBanner from "./NoDiscountPriceBanner";
import ConfigSummary from "./ConfigSummary";

import "./style.scss";

const UPSELL_TYPE = {
  ADD_PRODUCT: "add_product",
  REPLACE_PRODUCT: "replace_product"
};

class UpsellGeneralForm extends PureComponent {
  static defaultProps = {
    defaultValues: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      name: props.defaultValues.name || "",
      productsOptions: [],
      collectionsOptions: [],
      upsellType: props.defaultValues.upsellType || UPSELL_TYPE.ADD_PRODUCT,
      discountType: this.getDiscountTypeOption(
        props.defaultValues.discountType
      ),
      discountValue:
        parseFloat(props.defaultValues.discountValue) === 0
          ? 0
          : props.defaultValues.discountValue || 20,
      selectedProducts: [],
      conditionProducts: [],
      conditionCollections: [],
      minQuantity: props.defaultValues.minQuantity || "0",
      minQuantityType: this.getMinQuantityTypeOption(
        props.defaultValues.minQuantityType
      ),
      removeItemMethod: props.defaultValues.removeItemMethod
        ? [props.defaultValues.removeItemMethod]
        : ["all"],
      shopifyShop: {},
      isLoadingProducts: true,
      isLoadingCollections: true
    };
  }

  componentDidMount() {
    this.fetchShopProducts();
    this.fetchShopCollections();
  }

  componentDidUpdate(prevProps, prevState) {
    const { onChange } = this.props;
    if (!R.equals(prevState, this.state)) {
      if (onChange) {
        const {
          selectedProducts,
          conditionProducts,
          conditionCollections,
          discountValue,
          name,
          minQuantity
        } = this.state;
        const isDiscountValueValid = this.isDiscountValueValid();

        const shouldDisableAddButton =
          R.isEmpty(selectedProducts) ||
          (conditionProducts.length === 0 &&
            conditionCollections.length === 0) ||
          R.isEmpty(discountValue) ||
          !isDiscountValueValid ||
          !name ||
          !minQuantity;

        onChange(this.state, shouldDisableAddButton);
      }
    }
  }

  getDiscountTypeOptions = () => {
    const { t, shopifyCurrency } = this.props;

    return [
      {
        label: t("upsellModalApp.form.general.inputs.discount.options.percent"),
        value: "percent"
      },
      {
        label: t(
          "upsellModalApp.form.general.inputs.discount.options.currency",
          { shopifyCurrency }
        ),
        value: "currency"
      }
    ];
  };

  getMinQuantityTypeOptions = () => {
    const { t } = this.props;

    return [
      {
        label: t(
          "upsellModalApp.form.general.inputs.minQuantity.options.cart_total"
        ),
        value: "cart_quantity"
      },
      {
        label: t(
          "upsellModalApp.form.general.inputs.minQuantity.options.each_triggers_quantity"
        ),
        value: "each_triggers_quantity"
      }
    ];
  };

  getDiscountTypeOption = discountType => {
    const DISCOUNT_TYPE_OPTIONS = this.getDiscountTypeOptions();

    return (
      DISCOUNT_TYPE_OPTIONS.find(o => o.value === discountType) ||
      DISCOUNT_TYPE_OPTIONS[0]
    );
  };

  getMinQuantityTypeOption = minQuantityType => {
    const MIN_QUANTITY_TYPE_OPTIONS = this.getMinQuantityTypeOptions();

    return (
      MIN_QUANTITY_TYPE_OPTIONS.find(o => o.value === minQuantityType) ||
      MIN_QUANTITY_TYPE_OPTIONS[0]
    );
  };

  onCancel = () => {
    const { history } = this.props;
    history.push("/offers");
  };

  getMinPriceVariant = product => {
    const prices = R.compose(
      R.flatten,
      R.map(p => R.map(R.prop("price"), p.variants))
    )(product);

    return Math.min(...prices);
  };

  isDiscountValueValid = () => {
    const { selectedProducts, discountValue, discountType } = this.state;

    if (
      R.isNil(selectedProducts) ||
      R.isEmpty(selectedProducts) ||
      discountValue < 0
    ) {
      return false;
    }

    if (discountType.value === "percent") {
      return true;
    }

    // If discountType is currency, check variants prices to ensure that the price is not below than 0
    return this.getMinPriceVariant(selectedProducts) - discountValue > 0;
  };

  selectProductsCondition = options =>
    this.setState({ conditionProducts: options });

  selectCollectionsCondition = options =>
    this.setState({ conditionCollections: options });

  fetchShopProducts = () => {
    const { storeId, getShopifyProducts, defaultValues } = this.props;

    getShopifyProducts(storeId).then(res => {
      const productsOptions = res.data.map(p => ({
        value: p.id,
        label: p.title,
        name: p.title,
        price: R.path(["variants", 0, "price"], p),
        variants: p.variants,
        data: p,
        image: R.pathOr("/images/no-image.svg", ["image", "src"], p)
      }));

      const { selectedProductId, conditionProductsIds } = defaultValues;

      const selectedProducts = selectedProductId
        ? productsOptions.filter(p => selectedProductId.includes(p.value))
        : [];

      const conditionProducts = productsOptions.filter(
        o => conditionProductsIds && conditionProductsIds.includes(o.value)
      );

      this.setState({
        productsOptions,
        selectedProducts,
        conditionProducts,
        isLoadingProducts: false
      });
    });
  };

  fetchShopCollections = () => {
    const { storeId, getShopifyCollections, defaultValues } = this.props;

    getShopifyCollections(storeId).then(res => {
      const collectionsOptions = res.data.map(p => ({
        value: p.id,
        label: p.title,
        image: R.pathOr("/images/no-image.svg", ["image", "src"], p)
      }));

      const { conditionCollectionsIds } = defaultValues;

      const conditionCollections = collectionsOptions.filter(
        o =>
          conditionCollectionsIds && conditionCollectionsIds.includes(o.value)
      );

      this.setState({
        collectionsOptions,
        conditionCollections,
        isLoadingCollections: false
      });
    });
  };

  formatGroupLabel = data => (
    <div className="flex flex-center flex-content-between">
      <span>{data.label}</span>
      <span className="badge">{data.options.length}</span>
    </div>
  );

  customProductOption = props => {
    return (
      <div className="flex flex-center" {...props.innerProps}>
        <img
          style={{
            width: 40,
            height: 40,
            objectFit: "contain",
            padding: 5
          }}
          src={props.data.image}
          alt={props.data.label}
        />
        <components.Option {...props} />
      </div>
    );
  };

  customProductSingleValue = ({ children, ...props }) => {
    return (
      <components.SingleValue {...props}>
        <div className="flex flex-center">
          {children && (
            <img
              style={{
                width: 24,
                height: 24,
                objectFit: "contain",
                marginRight: 5
              }}
              src={props.data.image}
              alt={props.data.label}
            />
          )}
          <div>{children}</div>
        </div>
      </components.SingleValue>
    );
  };

  customProductMultiValue = props => {
    return (
      <div className="flex flex-center">
        <img
          style={{ width: 22, height: 22, objectFit: "contain" }}
          src={props.data.image}
          alt={props.data.label}
        />
        <components.MultiValueLabel {...props} />{" "}
      </div>
    );
  };

  render() {
    const {
      t,
      shopifyMoneyFormat,
      configuration,
      enableQuantity,
      enableUpsellType,
      maxOptions
    } = this.props;
    const {
      productsOptions,
      discountType,
      discountValue,
      selectedProducts,
      conditionProducts,
      collectionsOptions,
      conditionCollections,
      minQuantity,
      minQuantityType,
      name,
      isLoadingProducts,
      isLoadingCollections,
      upsellType,
      removeItemMethod
    } = this.state;

    const isDiscountValueValid = this.isDiscountValueValid();
    const hasselectedProducts = !R.isEmpty(selectedProducts);
    const showPriceWarning =
      hasselectedProducts && !isDiscountValueValid && !R.isEmpty(discountValue);
    const minVariantPrice =
      hasselectedProducts && discountType.value === "currency"
        ? this.getMinPriceVariant(selectedProducts)
        : null;

    const showTriggersResume =
      !R.isEmpty(selectedProducts) &&
      hasselectedProducts &&
      (!R.isEmpty(conditionProducts) || !R.isEmpty(conditionCollections));

    // Only for Upsell Modal app
    const isProductPageConfiguration =
      enableQuantity && configuration.modal_page_type === "product";

    return (
      <div className="UpsellGeneralForm">
        {showPriceWarning && (
          <div className="mb2">
            <WarningPriceBanner price={minVariantPrice} />
          </div>
        )}

        {parseFloat(discountValue) === 0 && (
          <div className="mb2">
            <NoDiscountPriceBanner />
          </div>
        )}

        <Card sectioned>
          <div>
            <div className="mb1">
              {t("upsellModalApp.form.general.inputs.name.label")}
            </div>
            <div style={{ zIndex: 0, position: "relative" }}>
              <TextField
                value={name}
                onChange={value => this.setState({ name: value })}
                helpText={t("upsellModalApp.form.general.inputs.name.helpText")}
              />
            </div>
          </div>

          {enableUpsellType && (
            <div className="mt3">
              <div className="mb1">
                {t("upsellModalApp.form.general.inputs.upsellType.label")}
              </div>
              <ButtonGroup fullWidth segmented>
                <Button
                  fullWidth
                  size="medium"
                  icon={AddProductMajor}
                  primary={upsellType === UPSELL_TYPE.ADD_PRODUCT}
                  onClick={() =>
                    this.setState({ upsellType: UPSELL_TYPE.ADD_PRODUCT })
                  }
                >
                  {t(
                    "upsellModalApp.form.general.inputs.upsellType.addProductUpsell.label"
                  )}
                </Button>
                <Button
                  fullWidth
                  size="medium"
                  icon={ReplaceMajor}
                  primary={upsellType === UPSELL_TYPE.REPLACE_PRODUCT}
                  onClick={() =>
                    this.setState({ upsellType: UPSELL_TYPE.REPLACE_PRODUCT })
                  }
                >
                  {t(
                    "upsellModalApp.form.general.inputs.upsellType.replaceProductUpsell.label"
                  )}
                </Button>
              </ButtonGroup>
            </div>
          )}

          <div className="mt3">
            <div className="mb1">
              {t("upsellModalApp.form.general.inputs.productToShow.label")}
            </div>
            <Select
              components={{
                Option: this.customProductOption,
                SingleValue: this.customProductSingleValue,
                MultiValueLabel: this.customProductMultiValue
              }}
              value={selectedProducts}
              options={
                selectedProducts.length === maxOptions ? [] : productsOptions
              }
              formatGroupLabel={this.formatGroupLabel}
              isLoading={isLoadingProducts}
              onChange={option => this.setState({ selectedProducts: option })}
              noOptionsMessage={() => {
                return selectedProducts.length === maxOptions
                  ? t("components.select.maxOptionsLabel")
                  : t("components.select.noOptionLabel");
              }}
              isMulti
            />
          </div>
          <div className="mt3">
            <div className="mb1">
              {t("upsellModalApp.form.general.inputs.conditions.label")}
            </div>
            <Select
              isMulti
              components={{
                Option: this.customProductOption,
                SingleValue: this.customProductSingleValue,
                MultiValueLabel: this.customProductMultiValue
              }}
              value={conditionProducts}
              options={productsOptions}
              isLoading={isLoadingProducts}
              placeholder={t(
                "upsellModalApp.form.general.inputs.conditions.placeholder"
              )}
              styles={{
                menuList: provided => ({
                  ...provided,
                  maxHeight: 230
                })
              }}
              onChange={this.selectProductsCondition}
            />
          </div>
          <div className="mt3">
            <div className="mb1">
              {t(
                "upsellModalApp.form.general.inputs.conditionsCollection.label"
              )}
            </div>
            <Select
              isMulti
              components={{
                Option: this.customProductOption,
                MultiValueLabel: this.customProductMultiValue
              }}
              value={conditionCollections}
              options={collectionsOptions}
              isLoading={isLoadingCollections}
              placeholder={t(
                "upsellModalApp.form.general.inputs.conditionsCollection.placeholder"
              )}
              styles={{
                menuList: provided => ({
                  ...provided,
                  maxHeight: 230
                })
              }}
              onChange={this.selectCollectionsCondition}
            />
          </div>
          {enableQuantity && (
            <div className="mt3">
              <div
                className={cx("mb1", {
                  disabledLabel: isProductPageConfiguration
                })}
              >
                {t("upsellModalApp.form.general.inputs.minQuantity.label")}
              </div>
              <div className="flex">
                <div style={{ maxWidth: 100, zIndex: 0 }}>
                  <TextField
                    value={minQuantity}
                    type="number"
                    min={0}
                    onChange={value => this.setState({ minQuantity: value })}
                    disabled={isProductPageConfiguration}
                  />
                </div>
                <Select
                  value={minQuantityType}
                  options={this.getMinQuantityTypeOptions()}
                  className="ml2 w100"
                  onChange={option =>
                    this.setState({ minQuantityType: option })
                  }
                  isDisabled={isProductPageConfiguration}
                />
              </div>
              <div className="helpText">
                {isProductPageConfiguration ? (
                  <span className="hintWarningContainer flex flex-center">
                    <Icon
                      source={CircleAlertMajor}
                      color="indigo"
                      backdrop
                    />
                    <span className="ml1">
                      {t(
                        "upsellModalApp.form.general.inputs.minQuantity.noQuantityHintText"
                      )}
                    </span>
                  </span>
                ) : (
                  t("upsellModalApp.form.general.inputs.minQuantity.hintText")
                )}
              </div>
            </div>
          )}
          <div className="mt3">
            <div className="mb1">
              {t("upsellModalApp.form.general.inputs.discount.label")}
            </div>
            <div className="flex">
              <div style={{ maxWidth: 100, zIndex: 0 }}>
                <TextField
                  value={discountValue}
                  type="number"
                  min={0}
                  onChange={value => this.setState({ discountValue: value })}
                />
              </div>
              <Select
                value={discountType}
                options={this.getDiscountTypeOptions()}
                className="ml2 w100"
                onChange={option => this.setState({ discountType: option })}
              />
            </div>
          </div>

          {upsellType === UPSELL_TYPE.REPLACE_PRODUCT && (
            <div className="mt3">
              <div className="mb1">
                {t(
                  "upsellModalApp.form.general.inputs.replaceChoiceList.label"
                )}
              </div>
              <ChoiceList
                selected={removeItemMethod}
                choices={[
                  {
                    label: t(
                      "upsellModalApp.form.general.inputs.replaceChoiceList.options.0.label"
                    ),
                    value: "all"
                  },
                  {
                    label: t(
                      "upsellModalApp.form.general.inputs.replaceChoiceList.options.1.label"
                    ),
                    value: "one"
                  }
                ]}
                onChange={value => this.setState({ removeItemMethod: value })}
              />
            </div>
          )}

          {showTriggersResume && (
            <>
              <hr />
              <ConfigSummary
                removeItemMethod={removeItemMethod}
                configuration={configuration}
                shopifyMoneyFormat={shopifyMoneyFormat}
                discountValue={discountValue}
                discountType={discountType}
                minQuantity={minQuantity}
                minQuantityType={minQuantityType}
                upsellType={upsellType}
                selectedProduct={selectedProducts}
                conditionProducts={conditionProducts}
                conditionCollections={conditionCollections}
              />
            </>
          )}
        </Card>
      </div>
    );
  }
}

export default connect(
  state => ({
    storeId: getStoreId(state),
    shopifyCurrency: getShopifyCurrency(state),
    shopifyMoneyFormat: getShopifyMoneyFormat(state)
  }),
  {
    getShopifyProducts,
    getShopifyCollections
  }
)(withTranslation()(UpsellGeneralForm));
