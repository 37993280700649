import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/fr";

import i18next from "i18next";

import { withTranslation } from "react-i18next";
import { Page } from "@shopify/polaris";

import {
  getStoreId,
  getStoreName,
  getShopifyCurrency,
  getShopifyMoneyFormat
} from "selectors/auth";
import { getStatistics } from "actions/statistics";

import { formatShopifyMoney } from "utils/price";

import OnBoarding from "components/OnBoarding";

import MigrationOrderConfirmationAppBanner from "components/MigrationOrderConfirmationAppBanner";

import NumberBox from "./components/NumberBox";
import OrdersList from "./components/OrdersList";
import OrdersPerMonthCharts from "./components/OrdersPerMonthCharts";

import "./style.scss";

const MIGRATION_ORDER_CONFIRMATION_DATE = moment("2020-04-22T14:00:00+02:00");

class Dashboard extends Component {
  state = {
    countShopifyOrders: 0,
    countUpsellOrders: 0,
    totalPrice: 0,
    totalShopifyPrice: 0,
    orders: [],
    isLoading: true,
    startsAt: null,
    updatedAt: null,
    ordersPerMonth: []
  };

  componentDidMount() {
    const { storeId, getStatistics } = this.props;
    let attempts = 0;

    getStatistics(storeId).then(res => {
      switch (res.data.job_status) {
        case "IN_PROGRESS":
          this.intervalStats = setInterval(async () => {
            attempts++;
            try {
              const res = await getStatistics(storeId, attempts);

              if (res.data.job_status === "DONE") {
                this.initStats(res);
              }
            } catch (e) {
              /* TODO HANDLE ERROR IN FRONT */
              clearInterval(this.intervalStats);
            }
          }, 5000);

          break;
        case "DONE":
          this.initStats(res);
          break;
        default:
          break;
      }
    });
  }

  componentWillUnmount() {
    clearInterval(this.intervalStats);
  }

  initStats = res => {
    clearInterval(this.intervalStats);
    this.setState({
      countUpsellOrders: res.data.count_orders,
      countShopifyOrders: res.data.count_shopify_orders,
      totalPrice: res.data.total_price_orders,
      totalShopifyPrice: res.data.total_shopify_price_orders,
      orders: res.data.last_10_orders,
      ordersPerMonth: res.data.orders_per_month.slice(-12),
      isLoading: false,
      startsAt: res.data.starts_at,
      updatedAt: res.data.updated_at
    });
  };

  render() {
    const { storeName, t, shopifyMoneyFormat, currency } = this.props;
    const {
      countUpsellOrders,
      countShopifyOrders,
      totalPrice,
      totalShopifyPrice,
      orders,
      startsAt,
      updatedAt,
      isLoading,
      ordersPerMonth
    } = this.state;

    return (
      <Page title={t("dashboard.title")} separator>
        <div className="Dashboard">
          <OnBoarding />
          <div className="mt3">
            {startsAt &&
              moment(startsAt).isBefore(MIGRATION_ORDER_CONFIRMATION_DATE) && (
                <div className="mb3">
                  <MigrationOrderConfirmationAppBanner />
                </div>
              )}
            <div className="pleaseNote">
              {t("dashboard.description")}{" "}
              {startsAt
                ? `(${moment(startsAt)
                    .locale(i18next.language)
                    .format("MMMM Do YYYY, HH:mm")})`
                : null}
            </div>
            <div className="pleaseNote mb2">
              {t("dashboard.secondDescription", {
                date: updatedAt
                  ? `${moment(updatedAt)
                      .locale(i18next.language)
                      .format("MMMM Do YYYY, HH:mm")}`
                  : "..."
              })}
            </div>
            <div className="flex flex-wrap flex-center flex-content-around mb2">
              <NumberBox
                className="marginBox"
                title={t("dashboard.statistics.ordersTotal")}
                value={formatShopifyMoney(
                  totalShopifyPrice,
                  shopifyMoneyFormat
                )}
                isLoading={isLoading}
              />
              <NumberBox
                className="marginBox"
                title={t("dashboard.statistics.upsellValue")}
                value={formatShopifyMoney(totalPrice, shopifyMoneyFormat)}
                isLoading={isLoading}
              />
              <NumberBox
                className="marginBox"
                title={t("dashboard.statistics.countOrders")}
                value={countShopifyOrders}
                isLoading={isLoading}
              />
              <NumberBox
                className="marginBox"
                title={t("dashboard.statistics.countUpsellOrders")}
                value={countUpsellOrders}
                isLoading={isLoading}
              />
            </div>
            <div className="mt2 mb3">
              <OrdersPerMonthCharts
                currency={currency}
                isLoading={isLoading}
                data={ordersPerMonth}
              />
            </div>
            <OrdersList
              orders={orders}
              storeName={storeName}
              isLoading={isLoading}
            />
          </div>
        </div>
      </Page>
    );
  }
}

export default connect(
  state => ({
    storeId: getStoreId(state),
    storeName: getStoreName(state),
    currency: getShopifyCurrency(state),
    shopifyMoneyFormat: getShopifyMoneyFormat(state)
  }),
  { getStatistics }
)(withTranslation()(Dashboard));
