import React from "react";
import { useTranslation } from "react-i18next";
import { Page, Layout, Card, SkeletonBodyText } from "@shopify/polaris";

export default function LoadingUpdateOfferPage({ history }) {
  const { t } = useTranslation();

  return (
    <Page
      title={t("orderConfirmationApp.update.title")}
      separator
      breadcrumbs={[
        {
          content: t("orderConfirmationApp.update.backToList"),
          onAction: () => history.push("/offers")
        }
      ]}
    >
      <Layout>
        <Layout.AnnotatedSection
          title={t("orderConfirmationApp.form.general.title")}
          description={t("orderConfirmationApp.form.general.description")}
        >
          <Card sectioned>
            <SkeletonBodyText lines={10} />
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title={t("orderConfirmationApp.form.optional.title")}
          description={t("orderConfirmationApp.form.optional.description")}
        >
          <Card sectioned>
            <SkeletonBodyText lines={6} />
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title={t("orderConfirmationApp.form.preview.title")}
          description={t("orderConfirmationApp.form.preview.description")}
        >
          <Card sectioned>
            <SkeletonBodyText lines={5} />
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );
}
