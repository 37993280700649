import * as R from "ramda";
import axios from "axios";
import Cookie from "js-cookie";
import CryptoJS from "crypto-js";

import { addToast } from "actions/toasts";

const getRealParameter = (param, state) =>
  typeof param === "function" ? param(state) : param;

const noop = () => {};

export default store => next => action => {
  if (!action.type.startsWith("API:")) {
    return next(action);
  }

  const { dispatch, getState } = store;
  const state = getState();
  const prefix = action.type.split(":")[1];

  const {
    url,
    method = "get",
    data,
    headers,
    shouldFetch,
    onSuccess = noop,
    onError = noop,
    extra,
    query,
    requireTokens,
    noToast = false
  } = action.payload;

  if (shouldFetch && !shouldFetch(state)) {
    return Promise.resolve();
  }

  if (requireTokens && (!state.auth || R.isEmpty(state.auth))) {
    return Promise.resolve();
  }

  const baseUrl = process.env.REACT_APP_BACKEND_URL;

  let cookieCredentials = Cookie.get("credentials");

  try {
    cookieCredentials = Cookie.get("credentials")
      ? CryptoJS.AES.decrypt(
          cookieCredentials,
          process.env.REACT_APP_MD5_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      : null;
  } catch (e) {
    cookieCredentials = null;
  }

  const credentials = !R.isEmpty(state.auth)
    ? state.auth
    : cookieCredentials
    ? JSON.parse(cookieCredentials)
    : {};

  const accessToken = credentials.access_token;

  const request = {
    url: `${baseUrl}${url}`,
    method,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
      ...headers
    },
    ...(data ? { data: getRealParameter(data, state) } : {}),
    ...(query ? { query: getRealParameter(query, state) } : {})
  };

  return axios(request)
    .then(({ data, headers, status }) => {
      const response = {
        status,
        data: (data && (data.data || data.results)) || data,
        headers,
        extra: extra ? getRealParameter(extra, state) : {}
      };

      try {
        onSuccess(response, dispatch, state);
        dispatch({ type: `${prefix}_SUCCESS`, payload: response });
      } catch (e) {
        console.error(`>>>>>>> CODE ERR`, e.stack);
      }

      return response;
    })
    .catch(err => {
      if (!noToast) {
        const message = R.path(["response", "data", "message"], err);
        const status = R.path(["response", "status"], err);
        const errorMessage = `${message} (${status} status)`;

        dispatch(addToast(errorMessage, "error"));
      }

      onError(err, dispatch, R.path(["response", "status"], err), state);
      dispatch({ type: `${prefix}_ERROR`, payload: err.response });
      throw err;
    });
};
