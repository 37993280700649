import React, { Component } from "react";

import UpsellModal from "../UpsellModal";

import { getStyles } from "./utils";

export default class ContainerUpsellModal extends Component {
  state = {
    showModal: true
  };

  onCancelRule = value =>
    this.setState({ showModal: false }, () => {
      const { onClose } = this.props;
      if (onClose) {
        onClose(value);
      }
    });

  onSubmit = data => {
    const { onSubmit } = this.props;
    if (onSubmit) {
      onSubmit(data).then(() => this.setState({ showModal: false }));
    }
  };

  render() {
    const { data, currency, cart } = this.props;
    const { showModal } = this.state;

    const {
      styleNoThanks,
      styleReplaceCart,
      styleAddToCart,
      styleTitle,
      styleSubtitle,
      styleDiscountPrice,
      stylePrice,
      styleModal,
      styleProductDescription,
      styleProductName,
      styleVariants
    } = getStyles(data.configuration);

    return (
      <UpsellModal
        moneyFormat={data.money_format}
        open={showModal}
        onClose={this.onCancelRule}
        onSubmit={this.onSubmit}
        products={data.products}
        upsellType={data.rule.upsell_type}
        description={data.rule.description}
        discountValue={data.rule.discount_price}
        discountType={data.rule.discount_type}
        currency={currency}
        labelAddToCart={data.configuration.addCartValue}
        labelReplaceCart={data.configuration.replaceCartValue}
        styleAddToCard={styleAddToCart}
        styleNoThanks={styleNoThanks}
        styleAddToCart={styleAddToCart}
        styleReplaceCart={styleReplaceCart}
        labelCancel={data.configuration.cancelValue}
        styleModal={styleModal}
        title={data.rule.title}
        styleTitle={styleTitle}
        subtitle={data.rule.subtitle}
        styleSubtitle={styleSubtitle}
        styleDiscountPrice={styleDiscountPrice}
        stylePrice={stylePrice}
        styleProductDescription={styleProductDescription}
        styleProductName={styleProductName}
        styleVariants={styleVariants}
        variantSelectorType={data.rule.variant_selector_type}
        variantOptionsConfig={data.rule.variant_options_config}
        cart={cart}
      />
    );
  }
}
