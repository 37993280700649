import React from "react";
import { useTranslation } from "react-i18next";

import DescriptionDemoBox from "./DescriptionDemoBox";

import "./style.scss";

export default function DemoSection() {
  const { t } = useTranslation();

  return (
    <section className="DemoSection container" id="demoSection">
      <div
        className="sectionTitle"
        data-aos="fade-down"
        data-aos-easing="ease"
        data-aos-duration="400"
        data-aos-offset="180"
        data-aos-anchor="#demoSection"
      >
        <h3 className="title">{t("homepage.demoSection.title")}</h3>
        <p className="subtitle">{t("homepage.demoSection.subtitle")}</p>
      </div>

      <div className="flex flex-wrap-reverse full-flex-center">
        <div
          className="boxContent col-5 paddingRight mt3"
          data-aos="fade-right"
          data-aos-easing="ease"
          data-aos-duration="500"
          data-aos-offset="250"
          data-aos-anchor="#demoSection"
        >
          <DescriptionDemoBox
            title={t("homepage.demoSection.rows.0.title")}
            subtitle={t("homepage.demoSection.rows.0.subtitle")}
            description={t("homepage.demoSection.rows.0.description")}
            buttonLabel={t("homepage.demoSection.rows.0.buttonLabel")}
            buttonURL={t("homepage.demoSection.rows.0.buttonURL")}
          />
        </div>
        <img
          className="previewApp col-7"
          src={t("homepage.mainSection.imageUrlUpsellModal")}
          alt="Demo Upsell Modal App"
          data-aos="fade-left"
          data-aos-easing="ease"
          data-aos-duration="400"
          data-aos-offset="250"
          data-aos-delay="200"
          data-aos-anchor="#demoSection"
        />
      </div>

      <div className="flex flex-wrap full-flex-center marginTop">
        <img
          className="previewApp col-7"
          src={t("homepage.mainSection.imageUrlOrderConfirmation")}
          alt="Demo Order Confirmation App"
          data-aos="fade-right"
          data-aos-easing="ease"
          data-aos-duration="400"
          data-aos-offset="400"
          data-aos-delay="200"
          data-aos-anchor="#demoSection"
        />
        <div
          className="boxContent col-5 paddingLeft"
          data-aos="fade-left"
          data-aos-easing="ease"
          data-aos-duration="400"
          data-aos-offset="400"
          data-aos-anchor="#demoSection"
        >
          <DescriptionDemoBox
            title={t("homepage.demoSection.rows.1.title")}
            subtitle={t("homepage.demoSection.rows.1.subtitle")}
            description={t("homepage.demoSection.rows.1.description")}
            buttonLabel={t("homepage.demoSection.rows.1.buttonLabel")}
            buttonURL={t("homepage.demoSection.rows.1.buttonURL")}
            note={t("homepage.demoSection.rows.1.note")}
          />
        </div>
      </div>
    </section>
  );
}
