import React, { Component } from "react";

import styles from "./style.module.scss";

export default class Modal extends Component {
  componentDidMount() {
    const { closeClickOutside } = this.props;

    if (closeClickOutside) {
      document.addEventListener("click", this.handleKeyUp);
      // FIXME use setTimeout for opening modal without closing immediatly
      setTimeout(() => {
        document.addEventListener("click", this.handleOutsideClick);
      }, 100);
    }
  }

  componentWillUnmount() {
    const { closeClickOutside } = this.props;
    if (closeClickOutside) {
      document.removeEventListener("click", this.handleKeyUp);
      document.removeEventListener("click", this.handleOutsideClick);
    }
  }

  // Handle the key press event.
  handleKeyUp = e => {
    const keys = {
      27: () => {
        e.preventDefault();
        this.onClose();
      }
    };

    if (keys[e.keyCode]) {
      keys[e.keyCode]();
    }
  };

  // Handle the mouse click on browser window.
  handleOutsideClick = e => {
    e.preventDefault();
    if (this.modal) {
      if (!this.modal.contains(e.target)) {
        this.onClose();
      }
    }
  };

  onClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    const { onClose, children, open, style } = this.props;

    if (!open) {
      return null;
    }

    const styleModal = style
      ? {
          borderRadius: style.borderRadius
        }
      : {};

    return (
      <div className={styles["as-modal"]} id="as-modal">
        <div className={styles["as-container"]} id="as-modal-container">
          <div
            id="as-modal-content"
            style={styleModal}
            className={styles["as-content"]}
            ref={node => (this.modal = node)}
          >
            <div>{children}</div>
            <button
              id="as-close-button"
              type="button"
              className={styles["as-close-button"]}
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    );
  }
}
