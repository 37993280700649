import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { fr, en } from "./locales";

const options = {
  interpolation: {
    escapeValue: false // not needed for react!!
  },

  debug: true,

  // lng: 'en',

  resources: {
    fr: {
      translation: fr.fr
    },
    en: {
      translation: en.en
    }
  },

  fallbackLng: "en",

  react: {
    wait: false,
    bindI18n: "languageChanged loaded",
    bindStore: "added removed",
    nsMode: "default"
  }
};

i18n.use(LanguageDetector).init(options);

export default i18n;
