import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { ResourceList, TextStyle, Toast } from "@shopify/polaris";
import Switch from "components/Switch";

import MoreActionsButton from "./MoreActionsButton";

import { updateUpsellModalOffer } from "actions/upsellModalOffers";
import { getShopifyMoneyFormat } from "selectors/auth";
import { formatShopifyMoney } from "utils/price";

import "./style.scss";

class OffersItemRow extends Component {
  state = {
    isEnabled: this.props.rule.enable,
    isUpdatingStatus: false,
    showToast: false
  };

  toggleStatus = value => {
    const { rule, updateUpsellModalOffer } = this.props;
    const { store_id: storeId, id } = rule;

    this.setState(
      { isEnabled: value, isUpdatingStatus: true, showToast: false },
      () => {
        updateUpsellModalOffer(storeId, id, { enable: value }).then(res => {
          this.setState({
            isEnabled: res.data.enable,
            isUpdatingStatus: false,
            showToast: true
          });
        });
      }
    );
  };

  updateUpsellModalOffer = data => {
    const { rule, updateUpsellModalOffer } = this.props;
    const { store_id: storeId, id } = rule;

    return updateUpsellModalOffer(storeId, id, {
      title: data.title,
      subtitle: data.subtitle
    });
  };

  toggleToast = () =>
    this.setState(({ showToast }) => ({ showToast: !showToast }));

  render() {
    const { rule, onDeleteOffer, t, shopifyMoneyFormat } = this.props;
    const { isEnabled, isUpdatingStatus, showToast } = this.state;

    const {
      variantId,
      name,
      count_added_to_cart,
      count_orders,
      discount_value,
      discount_type
    } = rule;

    const discount =
      discount_type === "percent"
        ? `-${discount_value}%`
        : `${formatShopifyMoney(discount_value * 100, shopifyMoneyFormat)} ${t(
            "upsellModalApp.list.headerList.currencyOff"
          )}`;

    return (
      <Fragment>
        <ResourceList.Item id={variantId}>
          {showToast && (
            <Toast
              content={t("upsellModalApp.list.toastStatus", {
                status: isEnabled
                  ? t("upsellModalApp.list.statusON")
                  : t("upsellModalApp.list.statusOFF")
              })}
              onDismiss={this.toggleToast}
            />
          )}

          <div className="UpsellModalOffersItemRow flex flex-center flex-content-between flex-wrap">
            <div className="flex flex-center flex-content-between flex-wrap">
              <div className="cell">
                <h3>
                  <TextStyle variation="strong">
                    {t("upsellModalApp.list.headerList.name")}
                  </TextStyle>
                </h3>
                <div>{name}</div>
              </div>

              <div className="cell">
                <h3>
                  <TextStyle variation="strong">
                    {t("upsellModalApp.list.headerList.status")}
                  </TextStyle>
                </h3>
                <Switch
                  onChange={this.toggleStatus}
                  checked={isEnabled}
                  disabled={isUpdatingStatus}
                />
              </div>

              <div className="cell">
                <h3>
                  <TextStyle variation="strong">
                    {t("upsellModalApp.list.headerList.discount")}
                  </TextStyle>
                </h3>
                <div dangerouslySetInnerHTML={{ __html: discount }} />
              </div>

              <div className="cell">
                <h3>
                  <TextStyle variation="strong">
                    {t("upsellModalApp.list.headerList.addedToCart")}
                  </TextStyle>
                </h3>
                <div>{count_added_to_cart || 0}</div>
              </div>

              <div className="cell">
                <h3>
                  <TextStyle variation="strong">
                    {t("upsellModalApp.list.headerList.countOrders")}
                  </TextStyle>
                </h3>
                <div>{count_orders || 0}</div>
              </div>
            </div>
            <div>
              <MoreActionsButton
                rule={rule}
                onDeleteOffer={onDeleteOffer}
                onEditOffer={this.updateUpsellModalOffer}
              />
            </div>
          </div>
        </ResourceList.Item>
      </Fragment>
    );
  }
}

export default connect(
  state => ({
    shopifyMoneyFormat: getShopifyMoneyFormat(state)
  }),
  { updateUpsellModalOffer }
)(withTranslation()(OffersItemRow));
