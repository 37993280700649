import { createAction } from "redux-actions";
import queryString from "query-string";
import Cookie from "js-cookie";
import CryptoJS from "crypto-js";

import { setAdminAuthCredentials } from "./adminAuth";

export const fetchShopifyToken = createAction(
  "API:AUTH_SET_CREDENTIALS",
  (code, hmac, shop, state, timestamp, affiliation_code) => ({
    url: `/api/v1/oauth/callback?${queryString.stringify({
      code,
      hmac,
      shop,
      state,
      timestamp,
      affiliation_code
    })}`,
    onSuccess: (res, dispatch) => {
      const data = JSON.stringify(res.data);
      Cookie.set(
        "credentials",
        CryptoJS.AES.encrypt(data, process.env.REACT_APP_MD5_SECRET_KEY),
        { expires: 365 }
      );
      if (res.data.role === "ROLE_ADMIN") {
        dispatch(setAdminAuthCredentials(res.data));
      }
    },
    onError: () => {
      Cookie.remove("credentials");
    }
  })
);

export const validate = createAction("API:AUTH_SET_CREDENTIALS", shop => ({
  method: "POST",
  url: "/api/v1/oauth/validate",
  data: { shop },
  onSuccess: (res, dispatch) => {
    const data = JSON.stringify(res.data);
    Cookie.set(
      "credentials",
      CryptoJS.AES.encrypt(data, process.env.REACT_APP_MD5_SECRET_KEY),
      { expires: 365 }
    );
    if (res.data.role === "ROLE_ADMIN") {
      dispatch(setAdminAuthCredentials(res.data));
    }
  },
  onError: (err, dispatch) => {
    Cookie.remove("credentials");
  }
}));

export const logOut = createAction("AUTH_USER_LOGOUT");

export const setStoreCredentials = createAction("AUTH_SET_STORE_CREDENTIALS");
