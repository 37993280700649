import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getFontStyle } from "components/ColorPickerWithFont/options";
import { getShopifyMoneyFormat } from "selectors/auth";
import { formatShopifyMoney } from "utils/price";
import "./style.scss";

class PreviewCartDiscountUpsell extends Component {
  render() {
    const {
      shopifyMoneyFormat,
      t,
      cartDiscountNote,
      originalPriceColor,
      originalPriceFontSize,
      originalPriceFontStyle,
      savedAmountPriceColor,
      savedAmountPriceFontSize,
      savedAmountPriceFontStyle,
      discountPriceColor,
      discountPriceFontSize,
      discountPriceFontStyle,
      discountNoteColor,
      discountNoteFontSize,
      discountNoteFontStyle,
      isOverridingDiscountCartStyle
    } = this.props;

    const originalPriceStyle = isOverridingDiscountCartStyle
      ? {
          color: originalPriceColor,
          fontSize: originalPriceFontSize,
          ...getFontStyle(originalPriceFontStyle)
        }
      : {};

    const savedAmountPriceStyle = isOverridingDiscountCartStyle
      ? {
          color: savedAmountPriceColor,
          fontSize: savedAmountPriceFontSize,
          ...getFontStyle(savedAmountPriceFontStyle)
        }
      : {};

    const discountPriceStyle = isOverridingDiscountCartStyle
      ? {
          color: discountPriceColor,
          fontSize: discountPriceFontSize,
          ...getFontStyle(discountPriceFontStyle)
        }
      : {};

    const discountNoteStyle = isOverridingDiscountCartStyle
      ? {
          color: discountNoteColor,
          fontSize: discountNoteFontSize,
          ...getFontStyle(discountNoteFontStyle)
        }
      : {};

    return (
      <div className="PreviewCartDiscountUpsell">
        <div>
          <div className="cart-subtotal">
            <span className="cart-subtotal__title">
              {t("upsellModalApp.configuration.cart.subtotal")}
            </span>
            <span className="cart-subtotal__price">
              <span className="as-original-cart-total">
                <span
                  className="as-original-price"
                  style={{
                    textDecoration: "line-through",
                    ...originalPriceStyle
                  }}
                  dangerouslySetInnerHTML={{
                    __html: formatShopifyMoney(3000, shopifyMoneyFormat)
                  }}
                />
              </span>
              <span className="as-cart-total">
                <span className="as-messages">
                  <div id="as-summary-item"></div>
                </span>
                <span className="as-discount-price">
                  <span className="as-summary-line-discount">
                    <div className="as-summary-item">
                      <span
                        className="as-summary-line-note"
                        style={discountNoteStyle}
                      >
                        {cartDiscountNote}
                      </span>
                      <span
                        className="as-summary-line-saved-amount"
                        style={savedAmountPriceStyle}
                        dangerouslySetInnerHTML={{
                          __html: formatShopifyMoney(-360, shopifyMoneyFormat)
                        }}
                      />
                    </div>
                    <span
                      className="as-summary-line-discount-value"
                      style={discountPriceStyle}
                      dangerouslySetInnerHTML={{
                        __html: formatShopifyMoney(2640, shopifyMoneyFormat)
                      }}
                    />
                  </span>
                </span>
              </span>
            </span>
          </div>
        </div>
        <div className="mt3">
          <div className="btn continue mr2">
            {" "}
            {t("upsellModalApp.configuration.cart.continueButton")}
          </div>
          <div className="btn checkout">
            {" "}
            {t("upsellModalApp.configuration.cart.checkoutButton")}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  shopifyMoneyFormat: getShopifyMoneyFormat(state)
}))(withTranslation()(PreviewCartDiscountUpsell));
