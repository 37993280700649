import React from "react";
import { cs, findVariantByOptionValue, isVariantAvailable } from "./utils";

import Tooltip from "./Tooltip";

import styles from "./style.module.scss";

export default function SelectVariantItemContent({
  values = [],
  variantsList,
  onChange,
  labelOutOfStock,
  enableTooltip = false,
  optionConfig = [],
  customStyles = {}
}) {
  const onChangeVariant = (index, selectedOption) => () => {
    // Do not allow to change variant if selected value has no stock
    if (selectedOption.isDisabled) {
      return;
    }

    const updatedValues = [...values];
    updatedValues[index] = selectedOption;
    onChange(updatedValues, selectedOption);
  };

  const applySelectedStyle = (key, isSelected) =>
    isSelected && customStyles.selected ? customStyles.selected[key] : {};

  const formatAvailableOptions = (options, indexList) => {
    if (indexList === 0) {
      return options.map(o => ({
        ...o,
        isDisabled: o.variants.every(v => !isVariantAvailable(v))
      }));
    }

    const listVariantIds = values
      .slice(0, indexList)
      .map(l => l.variants.filter(isVariantAvailable).map(v => v.id));

    return options.map(o => ({
      ...o,
      isDisabled:
        o.variants.filter(v => {
          return (
            listVariantIds.every(ids => ids.includes(v.id)) &&
            isVariantAvailable(v)
          );
        }).length === 0
    }));
  };

  return (
    <div
      className={cs(styles.container, "awesome-upsell-variants-container")}
      style={customStyles.variantsContainer}
    >
      {variantsList.map((variant, indexList) => {
        const title = variant[0];
        const options = formatAvailableOptions(variant[1], indexList);

        const currentOptionConfig = optionConfig.find(o => o.value === title);

        const isSwatchOption =
          currentOptionConfig && currentOptionConfig.type === "swatch";

        return (
          <div key={title} className={styles.variantRow}>
            <div
              className={cs(
                styles.label,
                "awesome-upsell-variant-option-label"
              )}
              style={customStyles.variantOptionLabel}
            >
              {title}
            </div>

            <div className={styles.optionsContainer}>
              {options.map(option => {
                const isSelected = values[indexList].option === option.option;
                const isDisabled = option.isDisabled;

                if (isSwatchOption) {
                  const configRule = currentOptionConfig.options.find(o =>
                    o.tags.includes(option.option)
                  );

                  const swatchType = configRule ? configRule.type : "color";
                  const swatchColor = configRule ? configRule.color : "#d2d2d2";
                  const firstLetter = option.option[0];

                  const currentVariant =
                    findVariantByOptionValue(
                      option.option,
                      indexList + 1,
                      option.variants
                    ) || {};

                  const swatchBackgroundImage = currentVariant.featured_image
                    ? `url(${currentVariant.featured_image.src})`
                    : currentVariant.imageUrl
                    ? `url(${currentVariant.imageUrl})`
                    : "";

                  const swatchCustomBackgroundImage =
                    configRule && swatchType === "custom-image"
                      ? `url(${configRule.imageUrl})`
                      : "";

                  return (
                    <div
                      key={option.option}
                      className={cs(
                        styles.swatchOptionContainer,
                        "awesome-upsell-variant-swatch-option",
                        isSelected ? styles.selectedSwatchOption : null
                      )}
                      style={{
                        ...{
                          borderRadius:
                            customStyles.variantOptionSwatch.borderRadius,
                          borderColor:
                            customStyles.variantOptionSwatch.borderColor
                        },
                        ...applySelectedStyle(
                          "selectedVariantOptionSwatch",
                          isSelected
                        )
                      }}
                    >
                      <Tooltip text={option.option} enabled={enableTooltip}>
                        <div
                          onClick={onChangeVariant(indexList, option)}
                          className={cs(
                            styles.swatchOption,
                            "awesome-upsell-variant-swatch-option-content",
                            isDisabled ? styles.isDisabled : null
                          )}
                          style={{
                            ...(swatchType === "color"
                              ? {
                                  backgroundColor: swatchColor
                                }
                              : {}),
                            ...(swatchType === "variant-image"
                              ? {
                                  backgroundImage: swatchBackgroundImage
                                }
                              : {}),
                            ...(swatchType === "custom-image"
                              ? {
                                  backgroundImage: swatchCustomBackgroundImage,
                                  backgroundSize: "100%"
                                }
                              : {}),
                            ...customStyles.variantOptionSwatch
                          }}
                        >
                          {!configRule && (
                            <div className={styles.swatchFirstLetter}>
                              {firstLetter}
                            </div>
                          )}
                        </div>
                      </Tooltip>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={option.option}
                      onClick={onChangeVariant(indexList, option)}
                      className={cs(
                        styles.option,
                        "awesome-upsell-variant-option",
                        isSelected ? styles.selectedOption : null,
                        isDisabled ? styles.isDisabled : null
                      )}
                      style={{
                        ...customStyles.variantOptionButton,
                        ...applySelectedStyle(
                          "selectedVariantOption",
                          isSelected
                        )
                      }}
                    >
                      {labelOutOfStock && isDisabled
                        ? `${option.option} ${labelOutOfStock}`
                        : option.option}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
