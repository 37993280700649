import React, { Component } from "react";
import { connect } from "react-redux";
import Cookie from "js-cookie";
import CryptoJS from "crypto-js";

import { validate } from "actions/auth";
import { checkRecurringApplicationCharge } from "actions/charges";

import { isLogged, isAdmin, isAffiliate } from "selectors/auth";

import LoadingPage from "components/LoadingPage";

class AuthWrapper extends Component {
  state = {
    isLoading: true
  };

  componentDidMount() {
    const {
      validate,
      checkRecurringApplicationCharge,
      history,
      isLogged
    } = this.props;
    const { push } = history;

    try {
      const credentials = Cookie.get("credentials")
        ? CryptoJS.AES.decrypt(
            Cookie.get("credentials"),
            process.env.REACT_APP_MD5_SECRET_KEY
          ).toString(CryptoJS.enc.Utf8)
        : null;

      // If already logged, no need to check
      if (isLogged) {
        this.setState({ isLoading: false });
        return;
      }

      try {
        if (credentials) {
          const { name, id } = JSON.parse(credentials);
          return validate(name)
            .catch(() => {
              Cookie.remove("credentials");
              push("/login");
            })
            .then(res => {
              if (!res || (res.data && res.data.has_uninstalled)) {
                push("/login");
              }
              return checkRecurringApplicationCharge(id).catch(() => {
                // push("/charges");
              });
            })
            .then(() => this.stopLoading());
        }

        Cookie.remove("credentials");
        this.stopLoading(() => push("/login"));
      } catch (err) {
        Cookie.remove("credentials");
        this.stopLoading(() => push("/login"));
      }
    } catch (e) {
      Cookie.remove("credentials");
      push("/login");
    }
  }

  componentDidUpdate(prevProps) {
    const {
      checkAdminRole,
      checkAffiliateRole,
      isAdmin,
      isAffiliate,
      history
    } = this.props;

    if (checkAdminRole && !isAdmin) {
      history.push("/getting-started");
      return;
    }

    if (checkAffiliateRole && (!isAffiliate && !isAdmin)) {
      history.push("/getting-started");
    }
  }

  stopLoading = callback => {
    setTimeout(() => this.setState({ isLoading: false }, callback), 1000);
  };

  render() {
    const { children } = this.props;
    const { isLoading } = this.state;

    if (isLoading) {
      return <LoadingPage />;
    }

    return <div className="fade-on-load">{children}</div>;
  }
}

export default connect(
  state => ({
    isLogged: isLogged(state),
    isAdmin: isAdmin(state),
    isAffiliate: isAffiliate(state)
  }),
  { validate, checkRecurringApplicationCharge }
)(AuthWrapper);
