import React from "react";
import { Trans } from "react-i18next";
import { Button } from "@shopify/polaris";

export default [
  {
    className: "",
    render: t => (
      <div className="flex flex-center flex-column">
        <img
          alt="Awesome Upsell - Logo"
          className="block rounded"
          src="/images/logo_awesomeapps_1024x1024.jpg"
          width="250"
        />
        <div className="center m0 bold mt2" style={{ fontSize: 24 }}>
          {t("onboarding.steps.0.title")}
        </div>
        <p className="center mt3">
          <Trans i18nKey="onboarding.steps.0.description">
            <b>Awesome Upsell</b>
          </Trans>
        </p>
      </div>
    )
  },
  {
    className: "",
    render: t => (
      <div className="center">
        <div className="center m0 bold mt2" style={{ fontSize: 22 }}>
          {t("onboarding.steps.1.title")}
        </div>
        <div className="mt3 flex flex-content-around flex-wrap">
          <div className="p2" style={{ width: 380 }}>
            <div className="mb2" style={{ fontSize: 18 }}>
              <b>{t("onboarding.steps.1.upsellModalTitle")}</b>
            </div>
            <img
              alt="Upsell Modal App"
              src={t("onboarding.steps.1.upsellModalImageUrl")}
              width={214}
            />
            <div className="mt2">
              {t("onboarding.steps.1.upsellModalDescription")}
            </div>
          </div>

          <div className="p2" style={{ width: 380 }}>
            <div className="mb2" style={{ fontSize: 18 }}>
              <b>{t("onboarding.steps.1.orderConfirmationTitle")}</b>
            </div>
            <img
              alt="Upsell Order Confirmation App"
              src={t("onboarding.steps.1.orderConfirmationImageUrl")}
              width={250}
            />
            <div className="mt2">
              {t("onboarding.steps.1.orderConfirmationDescription")}
            </div>
          </div>
        </div>
      </div>
    )
  },
  {
    className: "",
    render: t => (
      <div className="center">
        <div className="mt3 flex flex-content-center flex-wrap">
          <img
            src={t("onboarding.steps.2.imageUrl")}
            alt="Offers"
            width={200}
          />
          <div className="mt2 ml2" style={{ width: 300 }}>
            <div className="bold" style={{ fontSize: 24 }}>
              {t("onboarding.steps.2.title")}
            </div>
            <div className="mt3">{t("onboarding.steps.2.description")}</div>
          </div>
        </div>
      </div>
    )
  },
  {
    className: "",
    render: t => (
      <div className="center">
        <div className="mt3 flex flex-content-center flex-wrap">
          <div className="mt2 mr2" style={{ width: 300 }}>
            <div className="bold" style={{ fontSize: 24 }}>
              {t("onboarding.steps.3.title")}
            </div>
            <div className="mt3">{t("onboarding.steps.3.description")}</div>
          </div>
          <img
            src={t("onboarding.steps.3.imageUrl")}
            alt="Offers"
            width={200}
          />
        </div>
      </div>
    )
  },
  {
    className: "",
    render: (t, onClose) => (
      <div className="center">
        <h1 style={{ fontSize: 32 }} className="mb4">
          <b>{t("onboarding.steps.4.title")}</b>
        </h1>
        <Button primary onClick={onClose} size="large">
          {t("onboarding.steps.4.buttonLabel")}
        </Button>
      </div>
    )
  }
];
