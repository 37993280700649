import React from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Select } from "@shopify/polaris";

import ColorPicker from "components/ColorPicker";

import { FONT_SIZE_OPTIONS, FONT_STYLE_OPTIONS } from "./options";

import "./style.scss";

export default function ColorPickerWithFont({
  color,
  fontSize,
  fontStyle,
  onChange,
  onChangeFontSize,
  onChangeFontStyle,
  className,
  isDisabled
}) {
  const { t } = useTranslation();

  return (
    <div className={cx("ColorPickerWithFont flex flex-center w100", className)}>
      <ColorPicker
        color={color}
        onChange={onChange}
        hintLabel={t("generic.form.textColor")}
        isDisabled={isDisabled}
      />
      <div
        className="selectContainer fontSizeSelect ml1 hint--top"
        aria-label={t("generic.form.fontSize")}
      >
        <Select
          value={fontSize}
          options={FONT_SIZE_OPTIONS}
          onChange={onChangeFontSize}
          disabled={isDisabled}
        />
      </div>
      <div
        className="selectContainer fontStyleSelect ml1 hint--top"
        aria-label={t("generic.form.fontStyle")}
      >
        <Select
          value={fontStyle}
          options={FONT_STYLE_OPTIONS}
          onChange={onChangeFontStyle}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
}
