import React, { Component } from "react";
import { connect } from "react-redux";
import { Page } from "@shopify/polaris";

import {
  createAffiliation,
  getStoresWithoutAffiliation
} from "actions/affiliations";

import { getStoreId } from "selectors/auth";

import AffiliationForm from "../components/AffiliationForm";

class CreateAffiliation extends Component {
  state = {
    stores: [],
    isCreating: false,
    isFetchingStore: true
  };

  componentDidMount() {
    const { storeId, getStoresWithoutAffiliation } = this.props;

    getStoresWithoutAffiliation(storeId).then(res => {
      this.setState({
        isFetchingStore: false,
        stores: res.data.map(store => ({
          label: store.name,
          value: store.id
        }))
      });
    });
  }

  onCreateAffiliation = data => {
    const { history, storeId, createAffiliation } = this.props;
    this.setState({ isCreating: true }, () => {

      createAffiliation(storeId, {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        store_id: data.affiliationShop.value,
        affiliation_percent: data.affiliationPercent,
        affiliation_code: data.affiliationCode
      })
        .then(
          this.setState({ isCreating: false }, () => {
            history.push("/admin/affiliations");
          })
        )
        .catch(() => this.setState({ isCreating: false }));
    });
  };

  onCancel = () => {
    const { history } = this.props;
    history.push("/admin/affiliations");
  };

  render() {
    const { stores, isFetchingStore, isCreating } = this.state;

    return (
      <Page title="Create affiliation" separator>
        <AffiliationForm
          defaultValues={{}}
          storesOptions={stores}
          isLoading={isFetchingStore}
          isSubmitting={isCreating}
          onSubmit={this.onCreateAffiliation}
          onCancel={this.onCancel}
          labelSubmit="Create new affiliation"
        />
      </Page>
    );
  }
}

export default connect(
  state => ({
    storeId: getStoreId(state)
  }),
  { createAffiliation, getStoresWithoutAffiliation }
)(CreateAffiliation);
