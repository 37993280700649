import React, { useState } from "react";

import { Button } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import EmailContactButtonModal from "./EmailContactButtonModal";

export default function EmailContactButton({ type, onCustomSendEmail }) {
  const [openModal, setOpenModal] = useState(false);

  const { t } = useTranslation();

  return (
    <div className="EmailContactButton">
      <Button primary onClick={() => setOpenModal(true)}>
        {t("components.EmailContactButton.labelButton")}
      </Button>

      <EmailContactButtonModal
        type={type}
        open={openModal}
        onClose={() => setOpenModal(false)}
        onCustomSendEmail={onCustomSendEmail}
      />
    </div>
  );
}
