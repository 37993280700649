import React, { Component } from "react";
import { Navigation } from "@shopify/polaris";
import { AffiliateMajor } from "@shopify/polaris-icons";

export default class AffiliationsNavigationSection extends Component {
  render() {
    const { location } = this.props;

    return (
      <Navigation.Section
        separator
        title="Affiliations"
        items={[
          {
            label: "My affiliations",
            icon: AffiliateMajor,
            url: "/affiliations/my-affiliations",
            selected: location.pathname.startsWith(
              "/affiliations/my-affiliations"
            )
          }
        ]}
      />
    );
  }
}
