import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.scss";

export default () => {
  const { t } = useTranslation();
  return (
    <Link to="/login" className="StartFreeTrialButton">
      {t("homepage.startFreeTrialButton")}
    </Link>
  );
};
