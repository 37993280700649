import React from "react";
import { Link } from "react-router-dom";
import { Heading, TextContainer } from "@shopify/polaris";

import "./style.scss";

export default function PrivacyPolicy() {
  return (
    <div className="PrivacyPolicy container mt3">
      <div className="mb3">
        <Link to="/" className="link--silent">
          {"< Back"}
        </Link>
      </div>
      <TextContainer>
        <Heading element="h1">AwesomeApps Privacy Policy</Heading>
        <p>
          AwesomeApps provides applications "the Service" to merchants who use
          Shopify to power their stores. This Privacy Policy describes how
          personal information is collected, used, and shared when you install
          or use the App in connection with your Shopify-supported store.
        </p>
        <Heading>Personal Information the App Collects</Heading>
        <p>
          When you install the App, we are automatically able to access certain
          types of information from your Shopify account:{" "}
          <a
            href="https://help.shopify.com/api/getting-started/authentication/oauth/scopes"
            target="_blank"
            rel="noopener noreferrer"
          >
            The full list available through Shopify can be seen here
          </a>
        </p>
        <p>
          We collect personal information directly from the relevant individual,
          through your Shopify account, or using the following technologies:
          “Cookies” are data files that are placed on your device or computer
          and often include an anonymous unique identifier. For more information
          about cookies, and how to disable cookies, visit 
          <a href="http://www.allaboutcookies.org" rel="noopener noreferrer">
            http://www.allaboutcookies.org
          </a>
          . “Log files” track actions occurring on the Site, and collect data
          including your IP address, browser type, Internet service provider,
          referring/exit pages, and date/time stamps. “Web beacons,” “tags,” and
          “pixels” are electronic files used to record information about how you
          browse the Site.
        </p>
        <Heading>How Do We Use Your Personal Information?</Heading>
        <p>
          We use the personal information we collect from you and your customers
          in order to provide the Service and to operate the App. Additionally,
          we use this personal information to: Communicate with you; Optimize or
          improve the App; and Provide you with information or advertising
          relating to our products or services.
        </p>
        <Heading>Sharing Your Personal Information</Heading>
        <p>
          Finally, we may also share your Personal Information to comply with
          applicable laws and regulations, to respond to a subpoena, search
          warrant or other lawful request for information we receive, or to
          otherwise protect our rights.
        </p>
        <Heading>Your Rights</Heading>
        <p>
          If you are a European resident, you have the right to access personal
          information we hold about you and to ask that your personal
          information be corrected, updated, or deleted. If you would like to
          exercise this right, please contact us through the contact information
          below.{" "}
        </p>
        <p>
          Additionally, if you are a European resident we note that we are
          processing your information in order to fulfill contracts we might
          have with you (for example if you make an order through the Site), or
          otherwise to pursue our legitimate business interests listed above.
          Additionally, please note that your information will be transferred
          outside of Europe, including to Canada and the United States.
        </p>
        <Heading>Changes</Heading>
        <p>
          We may update this privacy policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal or regulatory reasons.
        </p>
        <Heading>Contact Us</Heading>
        <p>
          For more information about our privacy practices, if you have
          questions, or if you would like to make a complaint, please contact us
          by e-mail at contact@awesomeapps.fr.
        </p>
      </TextContainer>
    </div>
  );
}
