import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { installScriptsAndLiquidApp } from "actions/assets";
import { getStoreId } from "selectors/auth";
import { Layout, Page, Card, Button, Toast, Banner } from "@shopify/polaris";
import { ResetMinor } from "@shopify/polaris-icons";
import EmailContactButton from "components/EmailContactButton";

import StepsManualInstallation from "./components/StepsManualInstallation";

class UpsellModalTroubleshootingGuide extends Component {
  state = {
    isInstalled: false,
    showToast: false,
    syncStatus: null
  };

  syncTheme = () => {
    const { storeId, installScriptsAndLiquidApp } = this.props;
    this.setState(
      {
        isInstalling: true,
        syncStatus: null
      },
      () => {
        installScriptsAndLiquidApp(storeId)
          .then(() => {
            this.setState({
              isInstalling: false,
              showToast: true,
              syncStatus: "success"
            });
          })
          .catch(() => {
            this.setState({ isInstalling: false, syncStatus: "warning" });
          });
      }
    );
  };

  toggleToast = () => {
    this.setState(({ showToast }) => ({ showToast: !showToast }));
  };

  render() {
    const { t } = this.props;
    const { isInstalling, showToast, syncStatus } = this.state;

    const bannerSyncText =
      syncStatus === "success"
        ? t("support.upsellModalApp.sections.firstSection.successSync")
        : t("support.upsellModalApp.sections.firstSection.failSync");

    return (
      <Page title={t("support.upsellModalApp.title")} separator>
        {showToast ? (
          <Toast
            content={t(
              "support.upsellModalApp.sections.firstSection.toastSuccess"
            )}
            onDismiss={this.toggleToast}
          />
        ) : null}

        <Layout>
          <Layout.AnnotatedSection
            title={t("support.upsellModalApp.sections.firstSection.title")}
            description={t(
              "support.upsellModalApp.sections.firstSection.description"
            )}
          >
            <Card sectioned>
              {syncStatus && (
                <div className="mb2">
                  <Banner title={bannerSyncText} status={syncStatus} />
                </div>
              )}
              <div className="mb2">
                {t("support.upsellModalApp.sections.firstSection.text1")}
              </div>
              <Button
                onClick={this.syncTheme}
                icon={ResetMinor}
                loading={isInstalling}
              >
                {t("support.upsellModalApp.sections.firstSection.syncButton")}
              </Button>
              <div className="mt3">
                <Banner>
                  <Trans i18nKey="support.upsellModalApp.sections.firstSection.text2">
                    <strong>Bold content</strong>
                    <strong>sections/cart-template.liquid</strong>
                    <strong>templates/cart.liquid</strong>
                    <strong>cart.total_price</strong>
                  </Trans>
                </Banner>
              </div>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title={t("support.upsellModalApp.sections.secondSection.title")}
            description={t(
              "support.upsellModalApp.sections.secondSection.description"
            )}
          >
            <Card sectioned>
              <StepsManualInstallation />
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title={t("support.upsellModalApp.sections.thirdSection.title")}
            description={t(
              "support.upsellModalApp.sections.thirdSection.description"
            )}
          >
            <Card sectioned>
              <div className="full-flex-center">
                <EmailContactButton type="Upsell Modal App" />
              </div>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
      </Page>
    );
  }
}

export default connect(
  state => ({ storeId: getStoreId(state) }),
  { installScriptsAndLiquidApp }
)(withTranslation()(UpsellModalTroubleshootingGuide));
