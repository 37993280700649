import React from "react";
import * as R from "ramda";
import { useTranslation, Trans } from "react-i18next";
import { Heading } from "@shopify/polaris";

import { formatShopifyMoney } from "utils/price";

import "./style.scss";

export default function ConfigSummary({
  selectedProduct,
  upsellType,
  discountValue,
  discountType,
  shopifyMoneyFormat,
  minQuantity,
  minQuantityType,
  conditionProducts,
  conditionCollections,
  configuration,
  removeItemMethod
}) {
  const { t } = useTranslation();

  const selectedProductNames = Array.isArray(selectedProduct)
    ? selectedProduct.map(p => p.name).join(", ")
    : selectedProduct.name;

  const formatOptions = options => {
    const mapIndexed = R.addIndex(R.map);

    return R.compose(
      mapIndexed((node, index) => <span key={index}>{node}</span>),
      R.intersperse(<span>, </span>),
      R.map(o => <b>{o.label}</b>)
    )(options);
  };

  const formatSelectProductLabel = (
    productNames,
    upsellType,
    removeItemMethod
  ) => {
    const CONFIG_SELECTED_PRODUCT = {
      add_product: (
        <span>
          <Trans
            i18nKey="components.ConfigSummary.selectedProduct.addProduct"
            discountValue={discountValue}
            count={Array.isArray(selectedProduct) ? selectedProduct.length : 1}
          >
            <strong>{{ productNames }}</strong>
          </Trans>
        </span>
      ),
      replace_product: (
        <span>
          <Trans
            i18nKey={
              removeItemMethod === "all"
                ? "components.ConfigSummary.selectedProduct.replaceProduct.all"
                : "components.ConfigSummary.selectedProduct.replaceProduct.one"
            }
            discountValue={discountValue}
          >
            <strong>{{ productNames }}</strong>
          </Trans>
        </span>
      )
    };

    return CONFIG_SELECTED_PRODUCT[upsellType];
  };

  const formatQuantityLabel = (
    minQuantity,
    minQuantityType,
    options,
    type,
    typePage = "cart"
  ) => {
    const formattedOptions = formatOptions(options);

    const CONFIG_NO_QUANTITY = {
      product: {
        cart: t(
          "components.ConfigSummary.quantity.noQuantity.product.pageType.cart"
        ),
        product: t(
          "components.ConfigSummary.quantity.noQuantity.product.pageType.product"
        )
      },
      collection: {
        cart: t(
          "components.ConfigSummary.quantity.noQuantity.collection.pageType.cart"
        ),
        product: t(
          "components.ConfigSummary.quantity.noQuantity.collection.pageType.product"
        )
      }
    };

    const CONFIG_WITH_QUANTITIES = {
      product: {
        cart: t(
          "components.ConfigSummary.quantity.withQuantity.product.pageType.cart",
          {
            minQuantity: minQuantity
          }
        ),
        product: t(
          "components.ConfigSummary.quantity.withQuantity.product.pageType.product",
          {
            minQuantity: minQuantity
          }
        )
      },
      collection: {
        cart: t(
          "components.ConfigSummary.quantity.withQuantity.collection.pageType.cart",
          {
            minQuantity: minQuantity
          }
        ),
        product: t(
          "components.ConfigSummary.quantity.withQuantity.collection.pageType.product",
          {
            minQuantity: minQuantity
          }
        )
      }
    };

    if (parseInt(minQuantity) <= 1) {
      return (
        <span>
          <span>{CONFIG_NO_QUANTITY[type][typePage]}</span>
          <span>{formattedOptions}</span>
        </span>
      );
    }

    return (
      <span>
        <span>{CONFIG_WITH_QUANTITIES[type][typePage]}</span>
        <span>{formattedOptions}</span>
      </span>
    );
  };

  const formatDiscountLabel = (
    discountValue,
    discountType,
    shopifyMoneyFormat
  ) => {
    if (parseInt(discountValue) === 0) {
      return <span>{t("components.ConfigSummary.discount.noDiscount")}</span>;
    }

    const formattedMoney = formatShopifyMoney(
      discountValue * 100,
      shopifyMoneyFormat
    );

    switch (discountType.value) {
      case "percent":
        return (
          <span>
            <Trans
              i18nKey="components.ConfigSummary.discount.percent"
              discountValue={discountValue}
            >
              <strong>{{ discountValue }}</strong>
            </Trans>
          </span>
        );
      case "currency":
        return (
          <span>
            <Trans i18nKey="components.ConfigSummary.discount.currency">
              <strong>{{ formattedMoney }}</strong>
            </Trans>
          </span>
        );
      default:
        return "";
    }
  };

  return (
    <div className="ConfigSummary">
      <Heading>{t("components.ConfigSummary.title")}</Heading>

      <div className="mt2">
        {formatSelectProductLabel(
          selectedProductNames,
          upsellType,
          removeItemMethod[0]
        )}
        {formatDiscountLabel(discountValue, discountType, shopifyMoneyFormat)}
      </div>

      <ul>
        {!!conditionProducts.length && (
          <li>
            {formatQuantityLabel(
              minQuantity,
              minQuantityType,
              conditionProducts,
              "product",
              configuration.modal_page_type
            )}
          </li>
        )}

        {!!conditionCollections.length && (
          <li>
            {formatQuantityLabel(
              minQuantity,
              minQuantityType,
              conditionCollections,
              "collection",
              configuration.modal_page_type
            )}
          </li>
        )}
      </ul>
    </div>
  );
}
