import * as R from "ramda";

export const getCredentials = state => state.auth;

export const getStoreId = state => state.auth.id;

export const getStoreName = state => state.auth.name;

export const getStoreOwner = state => state.auth.shop_owner;

export const getRole = state => state.auth.role;

export const isLogged = state => !!getStoreId(state);

export const isAdmin = state =>
  getRole(state) === "ROLE_ADMIN" || !R.isEmpty(getAdminCredentials(state));

export const isAffiliate = state => getRole(state) === "ROLE_AFFILIATE";

export const hasAffiliation = state => !!state.auth.affiliation_id;

export const getAdminCredentials = state => state.adminAuth;

export const isAdminLoggedOnExternalStore = state =>
  !R.isEmpty(getAdminCredentials(state)) &&
  !R.equals(state.adminAuth, state.auth);

export const getShopifyMoneyFormat = state => state.auth.money_format;

export const getShopifyCurrency = state => state.auth.currency;

export const getShopifyStorePlan = state => state.auth.plan_name;
