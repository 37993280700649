import * as R from "ramda";

export const getCharge = state => state.charge;

export const getChargeStatus = state => R.path(["charge", "status"], state);

export const isTrialing = state =>
  R.path(["charge", "is_trial"], state) === true;

export const getMaxTrialCount = state =>
  R.path(["charge", "max_trial_count"], state);

export const hasChargeIssue = state =>
  getChargeStatus(state) !== "active" && getChargeStatus(state) !== "trialing";
