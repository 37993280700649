import React, { Component } from "react";

import { formatShopifyMoney } from "utils/price";

import Modal from "components/Modal";

import styles from "./style.module.scss";

export default class OrderConfirmationProduct extends Component {
  state = {
    variantId: this.props.product.variants[0].id,
    openModal: false
  };

  onChangeSelect = event =>
    this.setState({ variantId: parseInt(event.target.value) });

  addToOrder = () => {
    const { shop, ruleId, onClickAdd, isLoading } = this.props;
    const { variantId } = this.state;

    if (isLoading) {
      return;
    }

    if (onClickAdd) {
      onClickAdd(variantId, shop, ruleId);
    }
  };

  getDiscountedValue = () => {
    const { product, discountValue, discountType, moneyFormat } = this.props;
    const { variantId } = this.state;
    const variant = product.variants.find(v => v.id === variantId);

    if (!variant) {
      return {
        currentPrice: "",
        discountedPrice: ""
      };
    }

    const variantPrice = parseFloat(variant.price).toFixed(2);

    const discountPrice =
      discountType === "percent"
        ? variantPrice - (variantPrice * discountValue) / 100
        : variantPrice - discountValue;

    const hasDiscountPrice = variantPrice > discountPrice;

    return {
      currentPrice: formatShopifyMoney(variantPrice * 100, moneyFormat),
      discountedPrice: formatShopifyMoney(discountPrice * 100, moneyFormat),
      hasDiscountPrice
    };
  };

  render() {
    const { product, configuration, isLoading } = this.props;
    const { variantId, openModal } = this.state;

    const urlImage = product.image ? product.image.src : "";
    const hasOnlyOneVariant = product.variants.length <= 1;

    const {
      currentPrice,
      discountedPrice,
      hasDiscountPrice
    } = this.getDiscountedValue();

    return (
      <div className={styles.container}>
        <div>
          <img
            className={styles.productImage}
            src={urlImage}
            alt={product.title}
            style={configuration.styleImage}
          />
          <div
            className={styles.productName}
            style={configuration.styleProductName}
          >
            {product.title}
          </div>
          <div className={styles.priceContainer}>
            <span
              className={styles.currentPrice}
              style={{
                ...configuration.stylePrice,
                ...(!hasDiscountPrice ? { textDecoration: "none" } : {})
              }}
              dangerouslySetInnerHTML={{ __html: currentPrice }}
            />
            {hasDiscountPrice && (
              <span
                className={styles.discountedPrice}
                style={configuration.styleDiscountPrice}
                dangerouslySetInnerHTML={{ __html: discountedPrice }}
              />
            )}
          </div>
          {!hasOnlyOneVariant && (
            <div className={styles.containerSelect}>
              <select
                className={styles.select}
                value={variantId}
                onChange={this.onChangeSelect}
              >
                {product.variants.map(v => (
                  <option key={v.id} value={v.id}>
                    {v.title}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
        <div>
          {!configuration.hideMoreInfoButton && (
            <div
              className={`${styles.btn} ${styles.moreInfo}`}
              style={configuration.styleMoreInfo}
              onClick={() => this.setState({ openModal: true })}
            >
              {configuration.moreInfoValue}
            </div>
          )}
          <div
            className={`${styles.btn} ${styles.addToOrder} ${
              isLoading ? styles.loading : ""
            }`}
            onClick={this.addToOrder}
            style={configuration.styleAddToOrder}
          >
            {configuration.addOrderValue}
          </div>
        </div>

        {openModal && (
          <Modal
            closeClickOutside
            open
            onClose={() => this.setState({ openModal: false })}
          >
            <div className={styles.modalContent}>
              <img
                className={styles.productImage}
                src={urlImage}
                alt={product.title}
              />
              <div
                className={styles.modalProductName}
                style={configuration.styleProductName}
              >
                {product.title}
              </div>
              <div
                className={styles.bodyHtmlContent}
                dangerouslySetInnerHTML={{ __html: product.body_html }}
              />
            </div>
          </Modal>
        )}
      </div>
    );
  }
}
