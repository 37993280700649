export const cs = (...params) => params.join(" ");

/**
 * Find value of option1, option2 or option3
 * @param optionValue = RED
 * @param indexOption = Can be 1, 2 or 3
 * @param variants = [{..., option1: RED, option2: S,...}, ...]
 */
export const findVariantByOptionValue = (
  optionValue,
  indexOption,
  variants
) => {
  const optionKey = `option${indexOption}`;

  return variants.find(v => v[optionKey] === optionValue);
};

/**
 * Check if variant is available
 */
export const isVariantAvailable = variant => {
  return (
    variant.available ||
    variant.inventoryQuantity > 0 ||
    variant.inventory_quantity > 0 ||
    variant.inventory_policy === "continue" ||
    variant.inventory_management === null
  );
};

/**
 * Find available variant id
 * @param variants
 * @returns {null}
 */
export const findVariantIdAvailable = variants => {
  const availableVariant = variants.find(isVariantAvailable);
  return availableVariant ? availableVariant.id : null;
};

/**
 * Get the variants which has the most resemble with options values
 * @param variants
 * @param options
 */
export const findMostResembleOptionVariantIdAvailable = (variants, options) => {
  const optionValues = options.map(o => o.option);

  const formatVariantWithOptions = variant => {
    if (typeof variant.options === "object") {
      return variant;
    }

    return {
      ...variant,
      options: [variant.option1, variant.option2, variant.option3].filter(
        o => o
      )
    };
  };

  const sortedVariants = [...variants].sort((a, b) => {
    return (
      formatVariantWithOptions(b).options.filter(o => optionValues.includes(o))
        .length -
      formatVariantWithOptions(a).options.filter(o => optionValues.includes(o))
        .length
    );
  });

  return findVariantIdAvailable(sortedVariants);
};

/**
 * Format variants
 * @param variants
 * @param productOptions
 * @returns ["Size", [
 *    { option: "S", variants: [...] },
 *    { option: "M", variants: [...] }
 *  ],
 *  ["Color", [
 *    { option: "Red", variants: [...] },
 *    { option: "Blue", variants: [...] }]
 *  ]]
 */
export const getVariantLists = (variants, productOptions) => {
  const options1 = [];
  const options2 = [];
  const options3 = [];

  for (let v of variants) {
    options1.push({ option: v.option1, variant: v });
    options2.push({ option: v.option2, variant: v });
    options3.push({ option: v.option3, variant: v });
  }

  const formatOptions = array =>
    array.reduce((acc, value) => {
      const cloneAcc = [...acc];
      const option = value.option;

      const indexOption = acc.findIndex(o => o.option === option);

      if (indexOption !== -1) {
        cloneAcc[indexOption].variants.push(value.variant);
      } else {
        cloneAcc.push({ option: value.option, variants: [value.variant] });
      }

      return cloneAcc;
    }, []);

  const options = [
    formatOptions(options1),
    formatOptions(options2),
    formatOptions(options3)
  ];

  return productOptions.map((variantLabel, index) => {
    return [variantLabel, options[index]];
  });
};

export const findVariantIdByOptions = (variants, options, selectedOption) => {
  const countOptions = options.length;

  const availableVariants = variants.filter(isVariantAvailable);

  const variant = availableVariants.find(v => {
    switch (countOptions) {
      case 1:
        return v.option1 === options[0].option;
      case 2:
        return (
          v.option1 === options[0].option && v.option2 === options[1].option
        );
      case 3:
        return (
          v.option1 === options[0].option &&
          v.option2 === options[1].option &&
          v.option3 === options[2].option
        );
      default:
        return false;
    }
  });

  return variant
    ? variant.id
    : findMostResembleOptionVariantIdAvailable(
        selectedOption.variants,
        options
      );
};
