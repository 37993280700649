import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Page, PageActions } from "@shopify/polaris";

import { addOrderConfirmationOffer } from "actions/orderConfirmationOffers";
import { addToast } from "actions/toasts";
import { getStoreId } from "selectors/auth";

import OfferForm from "../components/OfferForm";

import "./style.scss";

class CreateOrderConfirmationOffer extends Component {
  state = {
    shouldDisableAddButton: false,
    isSaving: false,
    data: null
  };

  onChange = (data, shouldDisableAddButton) => {
    this.setState({ data, shouldDisableAddButton });
  };

  onSave = () => {
    const { history } = this.props;
    const { data } = this.state;
    const {
      name,
      conditionProducts,
      conditionCollections,
      selectedProducts,
      discountType,
      discountValue,
      title,
      subtitle,
      description,
      prefixDiscountCode,
      hiddenAfterUpsell,
      minQuantity,
      minQuantityType
    } = data;

    const conditionProductsIds = conditionProducts.map(p => p.value);
    const conditionCollectionsIds = conditionCollections.map(p => p.value);
    const selectedProductsIds = selectedProducts.map(p => p.value);

    this.setState({ isSaving: true }, () => {
      const { storeId, addOrderConfirmationOffer } = this.props;

      return addOrderConfirmationOffer(
        storeId,
        name,
        title,
        subtitle,
        description,
        conditionProductsIds,
        conditionCollectionsIds,
        selectedProductsIds,
        discountType.value,
        discountValue,
        prefixDiscountCode,
        hiddenAfterUpsell,
        minQuantity,
        minQuantityType
      )
        .then(() => {
          addToast("Offer has been created!", "success");
          history.push("/upsell/order-confirmation/offers");
        })
        .catch(() => {
          this.setState({ isSaving: false });
        });
    });
  };

  onCancel = () => {
    const { history } = this.props;
    history.push("/upsell/order-confirmation/offers");
  };

  render() {
    const { history, t } = this.props;
    const { shouldDisableAddButton, isSaving } = this.state;

    return (
      <Page
        title={t("orderConfirmationApp.new.title")}
        separator
        breadcrumbs={[
          {
            content: t("orderConfirmationApp.new.backToList"),
            onAction: () => history.push("/upsell/order-confirmation/offers")
          }
        ]}
        primaryAction={{
          content: t("orderConfirmationApp.new.submitButton"),
          disabled: shouldDisableAddButton,
          onClick: this.onSave,
          loading: isSaving
        }}
      >
        <OfferForm onChange={this.onChange} />

        <div className="mt3">
          <PageActions
            primaryAction={{
              content: t("orderConfirmationApp.new.submitButton"),
              disabled: shouldDisableAddButton,
              onClick: this.onSave,
              loading: isSaving
            }}
            secondaryActions={[
              {
                content: t("orderConfirmationApp.new.cancelButton"),
                onClick: this.onCancel
              }
            ]}
          />
        </div>
      </Page>
    );
  }
}

export default connect(
  state => ({
    storeId: getStoreId(state)
  }),
  {
    addOrderConfirmationOffer,
    addToast
  }
)(withTranslation()(CreateOrderConfirmationOffer));
