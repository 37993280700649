import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Card, ResourceList } from "@shopify/polaris";

import OffersItemRow from "./OffersItemRow";

import "./style.scss";

class OffersList extends Component {
  state = {};

  renderItem = rule => {
    const { onDeleteOffer } = this.props;
    return (
      <OffersItemRow rule={rule} onDeleteOffer={() => onDeleteOffer(rule.id)} />
    );
  };

  render() {
    const { rules, t } = this.props;

    const resourceName = {
      singular: "product",
      plural: "products"
    };

    return (
      <div className="OffersList">
        {rules.length ? (
          <Card>
            <ResourceList
              resourceName={resourceName}
              items={rules}
              renderItem={this.renderItem}
            />
          </Card>
        ) : (
          <div>{t("upsellModalApp.list.noRules")}</div>
        )}
      </div>
    );
  }
}

export default withTranslation()(OffersList);
