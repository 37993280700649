export const loadImage = src =>
  new Promise((resolve, reject) => {
    const img = new Image(); // eslint-disable-line no-undef
    img.src = src;
    img.onload = resolve;
    img.onerror = () => {
      reject(new Error(`Cant load ${src}`));
    };
  });

export const preloadImages = srcs => Promise.all(srcs.map(loadImage));

const hexToRgb = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
};

/**
 * Returns brightness number of a hex color
 * @param hex color (#aabcde)
 * @returns {number} [0 - 255]
 */
export const getColorBrightness = hex => {
  if (!hex) {
    return 0;
  }
  const rgbColor = hexToRgb(hex);
  return (rgbColor.r * 299 + rgbColor.g * 587 + rgbColor.b * 114) / 1000;
};
