import React, { Component } from "react";

import styles from "./style.module.scss";
import ProductRow from "./ProductRow";
import { cs } from "../../../utils/upsell";

export default class UpsellContentMultipleProducts extends Component {
  render() {
    const {
      products,
      styleModal,
      stylePrice,
      styleDiscountPrice,
      styleTitle,
      styleSubtitle,
      styleNoThanks,
      styleAddToCart,
      styleReplaceCart,
      labelAddToCart,
      labelReplaceCart,
      labelCancel,
      title,
      subtitle,
      styleProductDescription,
      styleProductName,
      upsellType,
      onSubmit,
      cart,
      moneyFormat,
      onClose
    } = this.props;

    return (
      <div
        className={cs(styles.modal, "awesome-upsell-modal")}
        style={styleModal}
      >
        <div
          className={cs(
            styles.containerTitle,
            "awesome-upsell-container-title"
          )}
        >
          <div
            className={cs(styles.title, "awesome-upsell-title")}
            style={styleTitle}
          >
            {title}
          </div>
          <div
            className={cs(styles.subtitle, "awesome-upsell-subtitle")}
            style={styleSubtitle}
          >
            {subtitle}
          </div>
        </div>
        <div
          className={cs(
            styles.containerProduct,
            "awesome-upsell-container-product"
          )}
        >
          {products.map((product, index) => {
            return (
              <ProductRow
                key={index}
                moneyFormat={moneyFormat}
                productName={product.title}
                variants={product.variants}
                stylePrice={stylePrice}
                styleDiscountPrice={styleDiscountPrice}
                styleAddToCart={styleAddToCart}
                styleReplaceCart={styleReplaceCart}
                labelAddToCart={labelAddToCart}
                labelReplaceCart={labelReplaceCart}
                styleProductDescription={styleProductDescription}
                styleProductName={styleProductName}
                upsellType={upsellType}
                onSubmit={onSubmit}
                cart={cart}
              />
            );
          })}
        </div>
        <div
          className={cs(styles.cancel, "awesome-quantity-cancel-button")}
          onClick={onClose}
          style={styleNoThanks}
        >
          {labelCancel}
        </div>
      </div>
    );
  }
}

UpsellContentMultipleProducts.defaultProps = {
  title: "Promo!",
  labelAddToCart: "Add to cart",
  labelReplaceCart: "Replace",
  styleAddToCard: {},
  labelCancel: "No thanks",
  styleNoThanks: {},
  styleModal: {}
};
