export const authInitState = {};

export default (state = {}, action) => {
  switch (action.type) {
    case "AUTH_SET_STORE_CREDENTIALS":
      return action.payload;
    case "AUTH_SET_CREDENTIALS_SUCCESS":
      return action.payload.data;
    case "AUTH_SET_CREDENTIALS_ERROR":
      return {};
    default:
      return state;
  }
};
