import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import {
  ProductsMajor,
  SettingsMajor,
  TroubleshootMajor,
  AnalyticsMajor,
  HomeMajor
} from "@shopify/polaris-icons";

import { Navigation } from "@shopify/polaris";

import {
  isAdmin,
  isAffiliate,
  isAdminLoggedOnExternalStore
} from "selectors/auth";

import SelectLanguage from "components/SelectLanguage";

import AdminNavigationSection from "./AdminNavigationSection";
import AffiliationsNavigationSection from "./AffiliationsNavigationSection";

import HeartIcon from "./HeartIcon";

class NavigationMenu extends Component {
  render() {
    const {
      location,
      onDismiss,
      isAdmin,
      isAffiliate,
      t,
      isAdminLoggedOnExternalStore
    } = this.props;

    return (
      <Navigation location="/" onDismiss={onDismiss}>
        <Navigation.Section
          items={[
            {
              label: t("navigationMenu.review.title"),
              icon: HeartIcon,
              url: "",
              selected: false,
              onClick: () => {
                const url =
                  "https://apps.shopify.com/awesome-upsell#modal-show=ReviewListingModal";
                window.open(url, "_blank").focus();
              }
            }
          ]}
        />
        <Navigation.Section
          items={[
            {
              label: t("navigationMenu.gettingStarted.title"),
              icon: HomeMajor,
              url: "/getting-started",
              selected: location.pathname.startsWith("/getting-started")
            },
            {
              label: t("navigationMenu.dashboard.title"),
              icon: AnalyticsMajor,
              url: "/dashboard",
              selected: location.pathname.startsWith("/dashboard")
            }
          ]}
        />
        <Navigation.Section
          separator
          title={t("navigationMenu.upsellModalApp.title")}
          items={[
            {
              label: t("navigationMenu.upsellModalApp.offers.title"),
              icon: ProductsMajor,
              url: "/upsell/modal/offers",
              selected: location.pathname.startsWith("/upsell/modal/offers"),
              subNavigationItems: [
                {
                  label: t("navigationMenu.upsellModalApp.offers.listOffers"),
                  url: "/upsell/modal/offers",
                  matches: location.pathname === "/upsell/modal/offers"
                },
                {
                  label: t("navigationMenu.upsellModalApp.offers.newOffer"),
                  url: "/upsell/modal/offers/new",
                  matches: location.pathname === "/upsell/modal/offers/new"
                }
              ]
            },
            {
              label: t("navigationMenu.upsellModalApp.settings.title"),
              icon: SettingsMajor,
              selected: location.pathname.startsWith(
                "/upsell/modal/configuration"
              ),
              url: "/upsell/modal/configuration/design",
              subNavigationItems: [
                {
                  label: t(
                    "navigationMenu.upsellModalApp.settings.customDesign"
                  ),
                  url: "/upsell/modal/configuration/design",
                  matches:
                    location.pathname === "/upsell/modal/configuration/design"
                },
                {
                  label: t("navigationMenu.upsellModalApp.settings.general"),
                  url: "/upsell/modal/configuration/general",
                  matches:
                    location.pathname === "/upsell/modal/configuration/general"
                }
              ]
            }
          ]}
        />
        <Navigation.Section
          separator
          title={t("navigationMenu.orderConfirmationApp.title")}
          items={[
            {
              label: t("navigationMenu.orderConfirmationApp.offers.title"),
              icon: ProductsMajor,
              url: "/upsell/order-confirmation/offers",
              selected: location.pathname.startsWith(
                "/upsell/order-confirmation/offers"
              ),
              subNavigationItems: [
                {
                  label: t(
                    "navigationMenu.orderConfirmationApp.offers.listOffers"
                  ),
                  url: "/upsell/order-confirmation/offers",
                  matches:
                    location.pathname === "/upsell/order-confirmation/offers"
                },
                {
                  label: t(
                    "navigationMenu.orderConfirmationApp.offers.newOffer"
                  ),
                  url: "/upsell/order-confirmation/offers/new",
                  matches:
                    location.pathname ===
                    "/upsell/order-confirmation/offers/new"
                }
              ]
            },
            {
              label: t("navigationMenu.orderConfirmationApp.settings.title"),
              icon: SettingsMajor,
              selected: location.pathname.startsWith(
                "/upsell/order-confirmation/configuration"
              ),
              url: "/upsell/order-confirmation/configuration"
            }
          ]}
        />
        <Navigation.Section
          separator
          title="Support"
          items={[
            {
              label: t("navigationMenu.support.troubleshooting.title"),
              icon: TroubleshootMajor,
              url: "/support/troubleshooting-guide/upsell-modal",
              selected: location.pathname.startsWith(
                "/support/troubleshooting-guide/upsell-modal"
              ),
              subNavigationItems: [
                {
                  label: t("navigationMenu.upsellModalApp.title"),
                  url: "/support/troubleshooting-guide/upsell-modal",
                  matches:
                    location.pathname ===
                    "/support/troubleshooting-guide/upsell-modal"
                },
                {
                  label: t("navigationMenu.orderConfirmationApp.title"),
                  url: "/support/troubleshooting-guide/order-confirmation",
                  matches:
                    location.pathname ===
                    "/support/troubleshooting-guide/order-confirmation"
                }
              ]
            }
          ]}
        />
        {isAdmin && !isAdminLoggedOnExternalStore && (
          <AdminNavigationSection location={location} />
        )}
        {isAffiliate && <AffiliationsNavigationSection location={location} />}

        <div className="p2">
          <SelectLanguage />
        </div>
      </Navigation>
    );
  }
}

export default connect(state => ({
  isAdmin: isAdmin(state),
  isAffiliate: isAffiliate(state),
  isAdminLoggedOnExternalStore: isAdminLoggedOnExternalStore(state)
}))(withRouter(withTranslation()(NavigationMenu)));
