import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";
import * as R from "ramda";

import { Card, Layout, TextField } from "@shopify/polaris";
import { QuestionMarkMinor } from "@shopify/polaris-icons";

import ColorPickerWithFont from "components/ColorPickerWithFont";
import { getFontStyle } from "components/ColorPickerWithFont/options";

import Switch from "components/Switch";

import LabelField from "./LabelField";

import { convertInlineStyleToCss } from "./utils";

import "./style.scss";

class DiscountCartStyleForm extends Component {
  state = {
    isOverridingDiscountCartStyle: R.propOr(
      false,
      "isOverridingDiscountCartStyle",
      this.props.config
    ),
    cartDiscountNote: R.propOr("", "cartDiscountNote", this.props.config),
    originalPriceColor: R.propOr(
      "#000000",
      "originalPriceColor",
      this.props.config
    ),
    originalPriceFontSize: R.propOr(
      "",
      "originalPriceFontSize",
      this.props.config
    ),
    originalPriceFontStyle: R.propOr(
      "",
      "originalPriceFontStyle",
      this.props.config
    ),
    savedAmountPriceColor: R.propOr(
      "#000000",
      "savedAmountPriceColor",
      this.props.config
    ),
    savedAmountPriceFontSize: R.propOr(
      "",
      "savedAmountPriceFontSize",
      this.props.config
    ),
    savedAmountPriceFontStyle: R.propOr(
      "",
      "savedAmountPriceFontStyle",
      this.props.config
    ),
    discountPriceColor: R.propOr(
      "#000000",
      "discountPriceColor",
      this.props.config
    ),
    discountPriceFontSize: R.propOr(
      "",
      "discountPriceFontSize",
      this.props.config
    ),
    discountPriceFontStyle: R.propOr(
      "",
      "discountPriceFontStyle",
      this.props.config
    ),
    discountNoteColor: R.propOr(
      "#000000",
      "discountNoteColor",
      this.props.config
    ),
    discountNoteFontSize: R.propOr(
      "",
      "discountNoteFontSize",
      this.props.config
    ),
    discountNoteFontStyle: R.propOr(
      "",
      "discountNoteFontStyle",
      this.props.config
    )
  };

  componentDidUpdate(prevProps) {
    const { config } = this.props;
    const keys = [
      "isOverridingDiscountCartStyle",
      "originalPriceColor",
      "originalPriceFontSize",
      "originalPriceFontStyle",
      "savedAmountPriceColor",
      "savedAmountPriceFontSize",
      "savedAmountPriceFontStyle",
      "discountPriceColor",
      "discountPriceFontSize",
      "discountPriceFontStyle",
      "discountNoteColor",
      "discountNoteFontSize",
      "discountNoteFontStyle"
    ];

    if (!R.equals(config, prevProps.config)) {
      this.setState(R.pick(keys, config));
    }
  }

  onChangeColor = field => colorHex => {
    const { onChange, config } = this.props;
    this.setState({ [field]: colorHex }, () =>
      onChange({ ...config, ...this.state, discountCartCSS: this.formatCSS() })
    );
  };

  onChangeValue = field => value => {
    const { onChange, config } = this.props;
    this.setState({ [field]: value }, () =>
      onChange({ ...config, ...this.state, discountCartCSS: this.formatCSS() })
    );
  };

  toggleIsOverridingDiscountCartStyle = value => {
    const { onChange, config } = this.props;
    this.setState({ isOverridingDiscountCartStyle: value }, () =>
      onChange({ ...config, ...this.state, discountCartCSS: this.formatCSS() })
    );
  };

  formatCSS = () => {
    const {
      isOverridingDiscountCartStyle,
      originalPriceColor,
      originalPriceFontSize,
      originalPriceFontStyle,
      savedAmountPriceColor,
      savedAmountPriceFontSize,
      savedAmountPriceFontStyle,
      discountPriceColor,
      discountPriceFontSize,
      discountPriceFontStyle,
      discountNoteColor,
      discountNoteFontSize,
      discountNoteFontStyle
    } = this.state;

    if (!isOverridingDiscountCartStyle) {
      return "";
    }

    const originalPriceStyle = {
      color: originalPriceColor,
      fontSize: originalPriceFontSize,
      ...getFontStyle(originalPriceFontStyle)
    };

    const cssOriginalPrice = convertInlineStyleToCss(
      ".as-original-price",
      originalPriceStyle
    );

    const savedAmountPriceStyle = {
      color: savedAmountPriceColor,
      fontSize: savedAmountPriceFontSize,
      ...getFontStyle(savedAmountPriceFontStyle)
    };

    const cssSavedAmountPrice = convertInlineStyleToCss(
      ".as-summary-line-saved-amount",
      savedAmountPriceStyle
    );

    const discountPriceStyle = {
      color: discountPriceColor,
      fontSize: discountPriceFontSize,
      ...getFontStyle(discountPriceFontStyle)
    };

    const cssDiscountPrice = convertInlineStyleToCss(
      ".as-summary-line-discount-value",
      discountPriceStyle
    );

    const discountNoteStyle = {
      color: discountNoteColor,
      fontSize: discountNoteFontSize,
      ...getFontStyle(discountNoteFontStyle)
    };

    const cssDiscountNote = convertInlineStyleToCss(
      ".as-summary-line-note",
      discountNoteStyle
    );

    return `${cssOriginalPrice} ${cssSavedAmountPrice} ${cssDiscountPrice} ${cssDiscountNote}`;
  };

  render() {
    const { t } = this.props;
    const {
      isOverridingDiscountCartStyle,
      cartDiscountNote,
      originalPriceColor,
      originalPriceFontSize,
      originalPriceFontStyle,
      savedAmountPriceColor,
      savedAmountPriceFontSize,
      savedAmountPriceFontStyle,
      discountPriceColor,
      discountPriceFontSize,
      discountPriceFontStyle,
      discountNoteColor,
      discountNoteFontSize,
      discountNoteFontStyle
    } = this.state;

    return (
      <div className="DiscountCartStyleForm">
        <Layout.AnnotatedSection
          title={t("upsellModalApp.configuration.form.texts.title")}
          description={
            <Trans i18nKey="upsellModalApp.configuration.cart.layoutDescription">
              <strong>Note</strong>
              <QuestionMarkMinor className="iconQuestionMark" />
            </Trans>
          }
        >
          <Card sectioned>
            <div className="flex flex-center">
              <LabelField
                label={t(
                  "upsellModalApp.configuration.form.texts.inputs.overrideCartStyle.label"
                )}
                toolTip={t(
                  "upsellModalApp.configuration.form.texts.inputs.overrideCartStyle.tooltip"
                )}
              />
              <Switch
                onChange={this.toggleIsOverridingDiscountCartStyle}
                checked={isOverridingDiscountCartStyle}
              />
            </div>
            <div className="flex flex-center mt2">
              <LabelField
                label={t(
                  "upsellModalApp.configuration.form.texts.inputs.originalPrice.label"
                )}
                toolTip={"CSS: as-original-price"}
                copyText="as-original-price"
                isDisabled={!isOverridingDiscountCartStyle}
              />
              <ColorPickerWithFont
                color={originalPriceColor}
                onChange={this.onChangeColor("originalPriceColor")}
                fontSize={originalPriceFontSize}
                onChangeFontSize={this.onChangeValue("originalPriceFontSize")}
                fontStyle={originalPriceFontStyle}
                onChangeFontStyle={this.onChangeValue("originalPriceFontStyle")}
                className="ml1"
                isDisabled={!isOverridingDiscountCartStyle}
              />
            </div>
            <div className="flex flex-center mt2">
              <LabelField
                label={t(
                  "upsellModalApp.configuration.form.texts.inputs.savedAmountPrice.label"
                )}
                toolTip={"CSS: as-summary-line-saved-amount"}
                copyText="as-summary-line-saved-amount"
                isDisabled={!isOverridingDiscountCartStyle}
              />
              <ColorPickerWithFont
                color={savedAmountPriceColor}
                onChange={this.onChangeColor("savedAmountPriceColor")}
                fontSize={savedAmountPriceFontSize}
                onChangeFontSize={this.onChangeValue(
                  "savedAmountPriceFontSize"
                )}
                fontStyle={savedAmountPriceFontStyle}
                onChangeFontStyle={this.onChangeValue(
                  "savedAmountPriceFontStyle"
                )}
                className="ml1"
                isDisabled={!isOverridingDiscountCartStyle}
              />
            </div>
            <div className="flex flex-center mt2">
              <LabelField
                label={t(
                  "upsellModalApp.configuration.form.texts.inputs.discountPrice.label"
                )}
                toolTip={"CSS: as-summary-line-discount-value"}
                copyText="as-summary-line-discount-value"
                isDisabled={!isOverridingDiscountCartStyle}
              />
              <ColorPickerWithFont
                color={discountPriceColor}
                onChange={this.onChangeColor("discountPriceColor")}
                fontSize={discountPriceFontSize}
                onChangeFontSize={this.onChangeValue("discountPriceFontSize")}
                fontStyle={discountPriceFontStyle}
                onChangeFontStyle={this.onChangeValue("discountPriceFontStyle")}
                className="ml1"
                isDisabled={!isOverridingDiscountCartStyle}
              />
            </div>
            <div className="flex flex-center mt2">
              <LabelField
                label={t(
                  "upsellModalApp.configuration.form.texts.inputs.discountNote.label"
                )}
                toolTip={"CSS: as-summary-line-note"}
                copyText="as-summary-line-note"
                isDisabled={!isOverridingDiscountCartStyle}
              />
              <ColorPickerWithFont
                color={discountNoteColor}
                onChange={this.onChangeColor("discountNoteColor")}
                fontSize={discountNoteFontSize}
                onChangeFontSize={this.onChangeValue("discountNoteFontSize")}
                fontStyle={discountNoteFontStyle}
                onChangeFontStyle={this.onChangeValue("discountNoteFontStyle")}
                className="ml1"
                isDisabled={!isOverridingDiscountCartStyle}
              />
            </div>
            <div className="flex flex-center mt2">
              <LabelField
                label={t(
                  "upsellModalApp.configuration.form.others.inputs.cartDiscountNote.label"
                )}
              />
              <TextField
                value={cartDiscountNote}
                onChange={this.onChangeValue("cartDiscountNote")}
              />
            </div>
          </Card>
        </Layout.AnnotatedSection>
      </div>
    );
  }
}

export default withTranslation()(DiscountCartStyleForm);
