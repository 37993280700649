import React, { Component } from "react";

import { Frame } from "@shopify/polaris";

import LayoutTopBar from "./LayoutTopBar";
import NavigationMenu from "./NavigationMenu";
import AdminExternalBanner from "./AdminExternalBanner";
import TrialBanner from "./TrialBanner";
import ChargeIssueBanner from "./ChargeIssueBanner";
import CheckAwesomeQuantityPopup from "../CheckAwesomeQuantityPopup";

export default class Layout extends Component {
  state = {
    showMobileNavigation: false
  };

  onToggleMobileNavigationMenu = () =>
    this.setState(({ showMobileNavigation }) => ({
      showMobileNavigation: !showMobileNavigation
    }));

  render() {
    const { children } = this.props;
    const { showMobileNavigation } = this.state;

    return (
      <Frame
        topBar={
          <LayoutTopBar
            onNavigationToggle={this.onToggleMobileNavigationMenu}
          />
        }
        showMobileNavigation={showMobileNavigation}
        onNavigationDismiss={this.onToggleMobileNavigationMenu}
        navigation={
          <NavigationMenu
            onDismiss={() => this.setState({ showMobileNavigation: false })}
          />
        }
      >
        <AdminExternalBanner />
        <TrialBanner />
        <ChargeIssueBanner />
        <CheckAwesomeQuantityPopup />
        {children}
      </Frame>
    );
  }
}
