import React from "react";

import { Heading } from "@shopify/polaris";

import "./style.scss";

export default function SnippetsCodes() {
  return (
    <div className="SnippetsCodes">
      <Heading>Snippets codes</Heading>
      <div className="mt3">
        <pre>
          {`<span class="as-original-cart-total">
    <span class="as-original-price">{{ cart.total_price | money }}</span>
</span>
<span class="as-cart-total">
    <span class="as-messages">
        <div id="as-summary-item"></div>
    </span>
    <span class="as-discount-price"></span>
</span>`}
        </pre>

        <pre>
          {`'<span class="as-original-cart-total"><span class="as-original-price">' + theme.Currency.formatMoney(state.total_price,theme.moneyFormatWithCurrency) + '</span></span><span class="as-cart-total"><span class="as-messages"><div id="as-summary-item"></div></span><span class="as-discount-price"></span></span>'`}
        </pre>

        <pre>
          {`window.isApplied = false;

setInterval(() => {
  if ($('#ajaxifyModal').hasClass('is-visible')) {
    if (!window.isApplied) {
      AwesomeUpsell.processUpsell(true);
      window.isApplied = true;
    }
  } else {
    window.isApplied = false;
  }
}, 1000);`}
        </pre>
      </div>
    </div>
  );
}
