import { getCookieValue, setCookie } from "./cookies";

export const hideUpsell = (ruleId, expirationMinutes = 1440) => {
  let currentSkipIds = getCookieValue("as-skip-ids");
  if (currentSkipIds && currentSkipIds !== "") {
    currentSkipIds += ",";
  }
  const allSkipIds = currentSkipIds + ruleId;

  setCookie("as-skip-ids", allSkipIds, expirationMinutes);
};

export const cs = (...params) => params.join(" ");
