import React, { Component } from "react";
import { connect } from "react-redux";
import { checkRecurringApplicationCharge } from "actions/charges";
import { getStoreId } from "selectors/auth";
import LoadingPage from "components/LoadingPage";

class CheckingCharge extends Component {
  componentDidMount() {
    const { history, storeId, checkRecurringApplicationCharge } = this.props;

    checkRecurringApplicationCharge(storeId)
      .then(res => {
        if (res.status === 200) {
          history.push("/getting-started");
        }
      })
      .catch(err => {
        history.push("/charges");
      });
  }

  render() {
    return <LoadingPage />;
  }
}

export default connect(
  state => ({ storeId: getStoreId(state) }),
  { checkRecurringApplicationCharge }
)(CheckingCharge);
