import React from "react";
import { useTranslation } from "react-i18next";
import { Banner, List } from "@shopify/polaris";

export default function WarningPriceBanner({ price }) {
  const { t } = useTranslation();
  return (
    <Banner
      title={t("upsellModalApp.form.general.banner.title", { value: price })}
      status="warning"
    >
      <List>
        <List.Item>
          {t("upsellModalApp.form.general.banner.description")}
        </List.Item>
      </List>
    </Banner>
  );
}
