import React, { Component, Fragment } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Page,
  Layout,
  Loading,
  Toast,
  Heading,
  PageActions
} from "@shopify/polaris";

import { getStoreId } from "selectors/auth";

import {
  fetchConfiguration,
  updateConfiguration
} from "actions/configurations";

import { DEFAULT_ORDER_CONFIRMATION_GRID_SETTINGS_VALUES } from "data/settings";

import OrderConfirmationSettingsForm from "./components/OrderConfirmationSettingsForm";
import PreviewOrderConfirmationGrid from "./components/PreviewOrderConfirmationGrid";

import "./style.scss";

class OrderConfirmationCustomizeDesign extends Component {
  state = {
    data: null,
    config: {},
    initConfig: {},
    isLoading: true,
    isModified: false,
    isSaving: false,
    showToast: false
  };

  componentDidMount() {
    const { storeId, fetchConfiguration } = this.props;
    fetchConfiguration(storeId)
      .then(res => {
        this.setState({
          data: res.data.configuration,
          config: res.data.configuration.grid_style || {},
          initConfig: res.data.configuration.grid_style || {}
        });

        return res;
      })
      .then(() => this.setState({ isLoading: false }));
  }

  setDefaultValues = () => {
    this.setState({ config: DEFAULT_ORDER_CONFIRMATION_GRID_SETTINGS_VALUES });
  };

  onChange = value => {
    this.setState({ config: value });
  };

  onSave = () => {
    const { storeId, updateConfiguration } = this.props;
    const { config, data } = this.state;
    const configId = data.id;

    this.setState({ isSaving: true }, () => {
      updateConfiguration(configId, storeId, {
        grid_style: config
      })
        .then(res => {
          this.setState({
            data: res.data,
            config: res.data.grid_style,
            initConfig: res.data.grid_style,
            isSaving: false,
            showToast: true
          });
        })
        .catch(err => {
          this.setState({ isSaving: false });
        });
    });
  };

  toggleToast = () => {
    this.setState(({ showToast }) => ({ showToast: !showToast }));
  };

  render() {
    const { t } = this.props;
    const { isLoading, isSaving, showToast, config, initConfig } = this.state;
    const isModified = !R.equals(config, initConfig);

    return (
      <Page
        title={t("orderConfirmationApp.configuration.title")}
        separator
        primaryAction={{
          content: t("orderConfirmationApp.configuration.submitButton"),
          disabled: !isModified,
          onClick: this.onSave,
          loading: isSaving
        }}
      >
        {showToast && (
          <Toast
            content={t("orderConfirmationApp.configuration.toastSave")}
            onDismiss={this.toggleToast}
          />
        )}

        <div className="Configuration">
          {isLoading ? (
            <Loading size="large" color="teal" />
          ) : (
            <Fragment>
              <Layout>
                <Layout.Section>
                  <div className="p3">
                    <Heading>
                      {t("orderConfirmationApp.configuration.previewTitle")}
                    </Heading>
                    <div className="mt2 mb2">
                      {t("orderConfirmationApp.configuration.previewSubtitle")}
                    </div>
                    <PreviewOrderConfirmationGrid configuration={config} />
                  </div>
                </Layout.Section>
                <Layout.Section>
                  <div className="mt3">
                    <OrderConfirmationSettingsForm
                      config={config}
                      onChange={this.onChange}
                    />
                  </div>
                </Layout.Section>
              </Layout>
              <PageActions
                primaryAction={{
                  content: t("orderConfirmationApp.configuration.submitButton"),
                  disabled: !isModified,
                  onClick: this.onSave,
                  loading: isSaving
                }}
                secondaryActions={[
                  {
                    content: t(
                      "orderConfirmationApp.configuration.resetButton"
                    ),
                    onClick: this.setDefaultValues
                  }
                ]}
              />
            </Fragment>
          )}
        </div>
      </Page>
    );
  }
}

export default connect(
  state => ({
    storeId: getStoreId(state)
  }),
  {
    fetchConfiguration,
    updateConfiguration
  }
)(withTranslation()(OrderConfirmationCustomizeDesign));
