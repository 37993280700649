import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import * as R from "ramda";

import { Banner } from "@shopify/polaris";

import OrderConfirmationGrid from "components/OrderConfirmationGrid";

class PreviewOrderConfirmation extends Component {
  formatProducts = () => {
    const { products } = this.props;

    return products.map(product => product.data);
  };

  onClickAdd = () =>
    new Promise((_resolve, reject) => {
      setTimeout(reject, 3000);
    });

  render() {
    const {
      moneyFormat,
      configuration,
      products,
      title,
      subtitle,
      discountValue,
      discountType,
      shop,
      t
    } = this.props;

    if (R.isEmpty(products)) {
      return (
        <div className="PreviewUpsellContent p3">
          <Banner
            title={t("orderConfirmationApp.form.preview.component.title")}
            status="info"
          >
            <p>
              {t("orderConfirmationApp.form.preview.component.notAvailable")}
            </p>
          </Banner>
        </div>
      );
    }

    return (
      <div className="PreviewUpsellContent p3">
        <OrderConfirmationGrid
          moneyFormat={moneyFormat}
          shop={shop}
          title={title}
          subtitle={subtitle}
          products={this.formatProducts()}
          discountValue={discountValue}
          discountType={discountType}
          configuration={configuration}
          onClickAdd={this.onClickAdd}
        />
      </div>
    );
  }
}

export default withTranslation()(PreviewOrderConfirmation);
