import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import Cookie from "js-cookie";

import { fetchShopifyToken } from "actions/auth";
import LoadingPage from "components/LoadingPage";

class RedirectOauth extends Component {
  componentDidMount() {
    const { location, history, fetchShopifyToken } = this.props;
    const { search } = location;
    const parsed = queryString.parse(search);
    const { code, hmac, shop, state, timestamp } = parsed;

    const affiliationCode = Cookie.get("affiliation_code");

    fetchShopifyToken(code, hmac, shop, state, timestamp, affiliationCode)
      .then(() => {
        history.push("/charges/checking");

        try {
            // eslint-disable-next-line
          $FPROM.trackSignup(
            {
              uid: shop
            },
            function() {
              console.log("Callback received!");
            }
          );
        } catch (e) {
          console.log(e);
        }
      })
      .catch(() => {
        history.push("/login");
      });
  }

  render() {
    return <LoadingPage />;
  }
}

export default connect(
  null,
  { fetchShopifyToken }
)(RedirectOauth);
