import React, { Component } from "react";
import { TextField } from "@shopify/polaris";
import * as R from "ramda";
import cx from "classnames";

import ColorPicker from "components/ColorPicker";

import "./style.scss";

export default class TextFieldWithColor extends Component {
  render() {
    const propsPicker = [
      "color",
      "onChangeColor",
      "onChangeSecondColor",
      "className",
      "hintLabel",
    ];
    const propsTextField = R.omit(propsPicker, this.props);

    const {
      color,
      className,
      secondColor,
      onChangeColor,
      onChangeSecondColor,
      hintLabel,
      secondHintLabel
    } = this.props;

    return (
      <div className={cx("TextFieldWithColor flex w100", className)}>
        <TextField {...propsTextField} style={{ width: "100%" }} />

        <ColorPicker color={color} onChange={onChangeColor} hintLabel={hintLabel} />

        {secondColor && (
          <ColorPicker color={secondColor} onChange={onChangeSecondColor} hintLabel={secondHintLabel} />
        )}
      </div>
    );
  }
}
