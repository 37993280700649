export const toastsInitState = [];

export default (state = toastsInitState, action) => {
  switch (action.type) {
    case "TOAST_ADD":
      return [...state, action.payload];
    case "TOAST_REMOVE":
      const id = action.payload;
      const index = state.findIndex(t => t.id === id);

      if (index === -1) {
        return state;
      }

      return state.filter(t => t.id !== id);
    default:
      return state;
  }
};
