import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { isAdmin, getStoreName, getCredentials } from "selectors/auth";
import { isTrialing } from "selectors/charge";

import "./style.scss";

class TrialBanner extends Component {
  render() {
    const { t, isAdmin, store, isTrialing } = this.props;

    if (isAdmin || !isTrialing) {
      return null;
    }

    return (
      <Fragment>
        <Link to="/charges" className="link--silent">
          <div className="TrialBanner p2 full-flex-center">
            {t("components.TrialBanner.title", { count: store.orders_count })}
          </div>
        </Link>
      </Fragment>
    );
  }
}

export default connect(state => ({
  isAdmin: isAdmin(state),
  storeName: getStoreName(state),
  store: getCredentials(state),
  isTrialing: isTrialing(state)
}))(withTranslation()(TrialBanner));
