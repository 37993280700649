import React, { Component } from "react";
import { Layout, Page, Card } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import EmailContactButton from "components/EmailContactButton";

class OrderConfirmationTroubleshootingGuide extends Component {
  render() {
    const { t } = this.props;

    return (
      <Page title={t("support.orderConfirmationApp.title")} separator>
        <Layout>
          <Layout.AnnotatedSection
            title={t("support.orderConfirmationApp.firstSection.title")}
            description={t(
              "support.orderConfirmationApp.firstSection.description"
            )}
          >
            <Card sectioned>
              <div className="full-flex-center">
                <EmailContactButton type="Order Confirmation App" />
              </div>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
      </Page>
    );
  }
}

export default withTranslation()(OrderConfirmationTroubleshootingGuide);
