import { createAction } from "redux-actions";

export const getCurrentShopifyTheme = createAction(
  "API:THEMES_GET_CURRENT_SHOPIFY_THEME",
  storeId => ({
    method: "GET",
    url: `/api/v1/stores/${storeId}/themes/main`
  })
);

export const getCurrentShopifyThemeByShopId = createAction(
  "API:THEMES_GET_CURRENT_SHOPIFY_THEME_BY_SHOP_ID",
  (storeId, shopId) => ({
    method: "GET",
    url: `/api/v1/stores/${storeId}/shop/${shopId}/themes/main`
  })
);
