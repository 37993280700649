import React from "react";

export default function HeartIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path
        d="M14.123 1C12.06 1 10.756 2.005 10 2.913 9.244 2.005 7.941 1 5.876 1 2.361 1 0 4.256 0 7.297c0 4.427 8.504 10.36 9.473 11.022a.935.935 0 001.054 0C11.497 17.657 20 11.724 20 7.297 20 4.257 17.639 1 14.123 1z"
        fill="#ED4956"
      />
    </svg>
  );
}
