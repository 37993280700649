import React, { Component } from "react";
import Sticky from "react-stickynode";
import { withTranslation } from "react-i18next";

import { Link, withRouter } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import cx from "classnames";

import { Icon } from "@shopify/polaris";

import {
  MobileCancelMajor,
  MobileHamburgerMajor
} from "@shopify/polaris-icons";

import SelectLanguage from "components/SelectLanguage";

import "./style.scss";

class NavigationTopBar extends Component {
  state = {
    openMenu: false,
    isSticky: false,
    isMobile: window.innerWidth <= 767
  };

  componentDidMount() {
    window.addEventListener("resize", this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  onResize = () => {
    this.setState(({ openMenu }) => ({
      isMobile: window.innerWidth <= 767,
      openMenu: window.innerWidth <= 767 ? openMenu : false
    }));
  };

  toggleMenu = () => this.setState(({ openMenu }) => ({ openMenu: !openMenu }));

  onCloseMenu = () => this.setState({ openMenu: false });

  onStickyStateChange = state => {
    this.setState({ isSticky: state.status > 0 });
  };

  render() {
    const { location, t } = this.props;
    const { openMenu, isMobile, isSticky } = this.state;

    const { hash, pathname } = location;

    const isLoginPage = pathname === "/login";

    return (
      <Sticky
        innerZ={1000}
        onStateChange={this.onStickyStateChange}
        className="Sticky"
      >
        <header className={cx("NavigationTopBar", { isSticky, isLoginPage })}>
          <div
            className="container flex flex-content-between flex-center"
            style={{ height: "100%" }}
          >
            <div className="flex flex-center">
              <Link
                to="/"
                className="link--silent flex flex-center"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                <img
                  className={cx("logo mr2", { isSticky })}
                  src={
                    isSticky
                      ? "/images/logo_awesome_apps_full.svg"
                      : "/images/logo_awesome_apps.svg"
                  }
                  alt="AwesomeApps"
                />
                <img
                  className="logoText mr2"
                  src={
                    isSticky
                      ? "/images/awesome-upsell-icon-second.svg"
                      : "/images/awesome-upsell-icon.svg"
                  }
                  alt="Awesome Upsell"
                />
              </Link>
              <SelectLanguage inputColor={isSticky ? "#555555" : "#fff"} />
            </div>

            <ul
              className={cx("linksContainer", {
                mobile: isMobile,
                open: openMenu
              })}
            >
              <li onClick={this.onCloseMenu}>
                <HashLink
                  smooth
                  to="/#features"
                  className={cx("navigationButton link--silent", {
                    active: hash === "#features"
                  })}
                >
                  {t("homepage.navigation.features")}
                </HashLink>
              </li>
              <li onClick={this.onCloseMenu}>
                <HashLink
                  smooth
                  to="/#demo"
                  className={cx("navigationButton link--silent", {
                    active: hash === "#demo"
                  })}
                >
                  {t("homepage.navigation.demo")}
                </HashLink>
              </li>
              <li onClick={this.onCloseMenu}>
                <HashLink
                  smooth
                  to="/#pricing"
                  className={cx("navigationButton link--silent", {
                    active: hash === "#pricing"
                  })}
                >
                  {t("homepage.navigation.pricing")}
                </HashLink>
              </li>
              <li onClick={this.onCloseMenu}>
                <Link to="/login" className="link--silent">
                  <div className={cx("loginButton", { isLoginPage })}>
                    {t("homepage.navigation.login")}
                  </div>
                </Link>
              </li>
            </ul>

            {isMobile && (
              <div className="menuIcon" onClick={this.toggleMenu}>
                <Icon
                  source={
                    openMenu
                      ? MobileCancelMajor
                      : MobileHamburgerMajor
                  }
                  color={isSticky ? "inkLighter" : "white"}
                />
              </div>
            )}
          </div>
        </header>
      </Sticky>
    );
  }
}

export default withRouter(withTranslation()(NavigationTopBar));
