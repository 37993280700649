import React from "react";
import { useTranslation } from "react-i18next";
import { Banner, List } from "@shopify/polaris";

export default function NoDiscountPriceBanner() {
  const { t } = useTranslation();
  return (
    <Banner
      title={t("upsellModalApp.form.general.noDiscountBanner.title")}
      status="info"
    >
      <List>
        <List.Item>
          {t("upsellModalApp.form.general.noDiscountBanner.description")}
        </List.Item>
      </List>
    </Banner>
  );
}
