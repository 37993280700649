import * as R from "ramda";

export const chargeInitState = {};

export default (state = {}, action) => {
  switch (action.type) {
    case "CHARGES_CHECK_RECURRING_APPLICATION_CHARGE_ERROR":
    case "CHARGES_CHECK_RECURRING_APPLICATION_CHARGE_SUCCESS":
    case "CHARGES_CREATE_RECURRING_APPLICATION_CHARGE_SUCCESS":
      // For Shopify billing, just set a trialing charge before redirecting
      if (R.path(["payload", "data", "confirmation_url"], action)) {
        return {
          status: "trialing"
        };
      }

      // For Stripe billing
      return action.payload.data;
    default:
      return state;
  }
};
