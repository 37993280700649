import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import * as R from "ramda";
import {
  Button,
  ButtonGroup,
  Card,
  Layout,
  RangeSlider,
  TextField
} from "@shopify/polaris";
import Switch from "components/Switch";
import { AddProductMajor, ReplaceMajor } from "@shopify/polaris-icons";

import TextFieldWithColor from "components/TextFieldWithColor";

import ColorPickerWithFont from "components/ColorPickerWithFont";
import ColorPicker from "components/ColorPicker";

import { DEFAULT_MODAL_SETTINGS_VALUES } from "data/settings";

import "./style.scss";

const UPSELL_TYPE = {
  ADD_PRODUCT: "add_product",
  REPLACE_PRODUCT: "replace_product"
};

const getInitialState = (defaultKeyValues, props) =>
  R.compose(
    R.fromPairs,
    R.map(([key, value]) => {
      return [key, R.propOr(value, key, props)];
    }),
    R.toPairs
  )(defaultKeyValues);

class SettingsForm extends Component {
  static propTypes = {
    onChange: PropTypes.func
  };

  state = {
    ...getInitialState(DEFAULT_MODAL_SETTINGS_VALUES, this.props.config),
    upsellType:
      R.prop("upsellType", this.props.config) || UPSELL_TYPE.ADD_PRODUCT
  };

  componentDidUpdate(prevProps) {
    const { config } = this.props;
    if (!R.equals(config, prevProps.config)) {
      this.setState({ ...config });
    }
  }

  onChangeColor = field => colorHex => {
    const { onChange } = this.props;
    this.setState({ [field]: colorHex }, () => onChange(this.state));
  };

  onChangeValue = field => value => {
    const { onChange } = this.props;
    this.setState({ [field]: value }, () => onChange(this.state));
  };

  render() {
    const { t } = this.props;
    const {
      titleColor,
      titleFontSize,
      titleFontStyle,
      subtitleColor,
      subtitleFontSize,
      subtitleFontStyle,
      productNameColor,
      productNameFontSize,
      productNameFontStyle,
      productDescriptionColor,
      productDescriptionFontSize,
      productDescriptionFontStyle,
      addCartLabelColor,
      addCartBackgroundColor,
      addCartValue,
      addToCartBorderRadius,
      replaceCartLabelColor,
      replaceCartBackgroundColor,
      replaceCartValue,
      cancelLabelColor,
      cancelBackgroundColor,
      cancelValue,
      modalBackgroundColor,
      modalPadding,
      modalBorderRadius,
      priceTextColor,
      priceTextFontSize,
      priceTextFontStyle,
      discountPriceTextColor,
      discountPriceTextFontSize,
      discountPriceTextFontStyle,
      upsellType,

      variantOptionLabelColor,
      variantOptionLabelFontSize,
      variantOptionLabelFontStyle,
      variantOptionButtonPaddingTopBottom,
      variantOptionButtonPaddingLeftRight,
      variantOptionButtonBackgroundColor,
      variantOptionButtonSelectedBackgroundColor,
      variantOptionButtonBorderColor,
      variantOptionButtonSelectedBorderColor,
      variantOptionButtonColor,
      variantOptionButtonSelectedColor,
      variantOptionButtonFontSize,
      variantOptionButtonFontStyle,
      variantOptionButtonBorderRadius,

      variantOptionSwatchSize,
      variantOptionSwatchBorderRadius,
      variantOptionSwatchBorderColor,
      variantOptionSwatchSelectedBorderColor,
      variantOptionSwatchEnableTooltip,

      variantLabelOutOfStockValue
    } = this.state;

    return (
      <div className="SettingsForm w100 mr2 mb3">
        <Layout>
          <Layout.AnnotatedSection
            title={t("upsellModalApp.configuration.form.buttons.title")}
            description={t(
              "upsellModalApp.configuration.form.buttons.description"
            )}
          >
            <Card>
              <Card.Section>
                <div>
                  <div className="mb1">
                    {t("upsellModalApp.configuration.modal.upsellType.label")}
                  </div>
                  <ButtonGroup fullWidth segmented>
                    <Button
                      fullWidth
                      size="medium"
                      icon={AddProductMajor}
                      primary={upsellType === UPSELL_TYPE.ADD_PRODUCT}
                      onClick={() =>
                        this.onChangeValue("upsellType")(
                          UPSELL_TYPE.ADD_PRODUCT
                        )
                      }
                    >
                      {t(
                        "upsellModalApp.configuration.modal.upsellType.buttonAddProduct"
                      )}
                    </Button>
                    <Button
                      fullWidth
                      size="medium"
                      icon={ReplaceMajor}
                      primary={upsellType === UPSELL_TYPE.REPLACE_PRODUCT}
                      onClick={() =>
                        this.onChangeValue("upsellType")(
                          UPSELL_TYPE.REPLACE_PRODUCT
                        )
                      }
                    >
                      {t(
                        "upsellModalApp.configuration.modal.upsellType.buttonReplaceProduct"
                      )}
                    </Button>
                  </ButtonGroup>
                </div>
              </Card.Section>
              <Card.Section>
                <TextFieldWithColor
                  label={t(
                    "upsellModalApp.configuration.form.buttons.inputs.cartButton.label"
                  )}
                  className="mt2"
                  color={addCartLabelColor}
                  secondColor={addCartBackgroundColor}
                  value={addCartValue}
                  onChange={this.onChangeValue("addCartValue")}
                  onChangeColor={this.onChangeColor("addCartLabelColor")}
                  onChangeSecondColor={this.onChangeColor(
                    "addCartBackgroundColor"
                  )}
                  hintLabel={t("generic.form.textColor")}
                  secondHintLabel={t("generic.form.backgroundColor")}
                />
                <TextFieldWithColor
                  label={t(
                    "upsellModalApp.configuration.form.buttons.inputs.replaceButton.label"
                  )}
                  className="mt2"
                  color={replaceCartLabelColor}
                  secondColor={replaceCartBackgroundColor}
                  value={replaceCartValue}
                  onChange={this.onChangeValue("replaceCartValue")}
                  onChangeColor={this.onChangeColor("replaceCartLabelColor")}
                  onChangeSecondColor={this.onChangeColor(
                    "replaceCartBackgroundColor"
                  )}
                  hintLabel={t("generic.form.textColor")}
                  secondHintLabel={t("generic.form.backgroundColor")}
                />
                <TextFieldWithColor
                  label={t(
                    "upsellModalApp.configuration.form.buttons.inputs.cancelButton.label"
                  )}
                  className="mt2"
                  color={cancelLabelColor}
                  secondColor={cancelBackgroundColor}
                  value={cancelValue}
                  onChange={this.onChangeValue("cancelValue")}
                  onChangeColor={this.onChangeColor("cancelLabelColor")}
                  onChangeSecondColor={this.onChangeColor(
                    "cancelBackgroundColor"
                  )}
                  hintLabel={t("generic.form.textColor")}
                  secondHintLabel={t("generic.form.backgroundColor")}
                />
              </Card.Section>
              <Card.Section>
                <RangeSlider
                  label={t(
                    "upsellModalApp.configuration.form.buttons.inputs.addToCartBorderRadius.label"
                  )}
                  value={addToCartBorderRadius}
                  onChange={this.onChangeValue("addToCartBorderRadius")}
                  min={0}
                  max={100}
                  output
                />
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title={t("upsellModalApp.configuration.form.texts.title")}
            description={t(
              "upsellModalApp.configuration.form.texts.description"
            )}
          >
            <Card sectioned>
              <div className="flex flex-center">
                <div className="labelField">
                  {t(
                    "upsellModalApp.configuration.form.texts.inputs.title.label"
                  )}
                </div>
                <ColorPickerWithFont
                  color={titleColor}
                  onChange={this.onChangeColor("titleColor")}
                  fontSize={titleFontSize}
                  onChangeFontSize={this.onChangeValue("titleFontSize")}
                  fontStyle={titleFontStyle}
                  onChangeFontStyle={this.onChangeValue("titleFontStyle")}
                  className="ml1"
                />
              </div>

              <div className="flex flex-center mt2">
                <div className="labelField">
                  {t(
                    "upsellModalApp.configuration.form.texts.inputs.subtitle.label"
                  )}
                </div>
                <ColorPickerWithFont
                  color={subtitleColor}
                  onChange={this.onChangeColor("subtitleColor")}
                  fontSize={subtitleFontSize}
                  onChangeFontSize={this.onChangeValue("subtitleFontSize")}
                  fontStyle={subtitleFontStyle}
                  onChangeFontStyle={this.onChangeValue("subtitleFontStyle")}
                  className="ml1"
                />
              </div>

              <div className="flex flex-center mt2">
                <div className="labelField">
                  {t(
                    "upsellModalApp.configuration.form.texts.inputs.productName.label"
                  )}
                </div>
                <ColorPickerWithFont
                  color={productNameColor}
                  onChange={this.onChangeColor("productNameColor")}
                  fontSize={productNameFontSize}
                  onChangeFontSize={this.onChangeValue("productNameFontSize")}
                  fontStyle={productNameFontStyle}
                  onChangeFontStyle={this.onChangeValue("productNameFontStyle")}
                  className="ml1"
                />
              </div>

              <div className="flex flex-center mt2">
                <div className="labelField">
                  {t(
                    "upsellModalApp.configuration.form.texts.inputs.productDescription.label"
                  )}
                </div>
                <ColorPickerWithFont
                  color={productDescriptionColor}
                  onChange={this.onChangeColor("productDescriptionColor")}
                  fontSize={productDescriptionFontSize}
                  onChangeFontSize={this.onChangeValue(
                    "productDescriptionFontSize"
                  )}
                  fontStyle={productDescriptionFontStyle}
                  onChangeFontStyle={this.onChangeValue(
                    "productDescriptionFontStyle"
                  )}
                  className="ml1"
                />
              </div>

              <div className="flex flex-center mt2">
                <div className="labelField">
                  {t(
                    "upsellModalApp.configuration.form.texts.inputs.priceText.label"
                  )}
                </div>
                <ColorPickerWithFont
                  color={priceTextColor}
                  onChange={this.onChangeColor("priceTextColor")}
                  fontSize={priceTextFontSize}
                  onChangeFontSize={this.onChangeValue("priceTextFontSize")}
                  fontStyle={priceTextFontStyle}
                  onChangeFontStyle={this.onChangeValue("priceTextFontStyle")}
                  className="ml1"
                />
              </div>

              <div className="flex flex-center mt2">
                <div className="labelField">
                  {t(
                    "upsellModalApp.configuration.form.texts.inputs.discountPriceText.label"
                  )}
                </div>
                <ColorPickerWithFont
                  color={discountPriceTextColor}
                  onChange={this.onChangeColor("discountPriceTextColor")}
                  fontSize={discountPriceTextFontSize}
                  onChangeFontSize={this.onChangeValue(
                    "discountPriceTextFontSize"
                  )}
                  fontStyle={discountPriceTextFontStyle}
                  onChangeFontStyle={this.onChangeValue(
                    "discountPriceTextFontStyle"
                  )}
                  className="ml1"
                />
              </div>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title={t("upsellModalApp.configuration.form.others.title")}
            description={t(
              "upsellModalApp.configuration.form.others.description"
            )}
          >
            <Card sectioned>
              <div className="flex flex-center">
                <div className="labelField">
                  {t(
                    "upsellModalApp.configuration.form.others.inputs.modalBackground.label"
                  )}
                </div>
                <ColorPicker
                  color={modalBackgroundColor}
                  onChange={this.onChangeColor("modalBackgroundColor")}
                  className="ml1"
                  hintLabel="Background color"
                />
              </div>
              <div className="mt2">
                <RangeSlider
                  label={t(
                    "upsellModalApp.configuration.form.others.inputs.modalPadding.label"
                  )}
                  value={modalPadding}
                  onChange={this.onChangeValue("modalPadding")}
                  min={10}
                  max={100}
                  output
                />
              </div>
              <div className="mt2">
                <RangeSlider
                  label={t(
                    "upsellModalApp.configuration.form.others.inputs.modalBorderRadius.label"
                  )}
                  value={modalBorderRadius}
                  onChange={this.onChangeValue("modalBorderRadius")}
                  min={0}
                  max={100}
                  output
                />
              </div>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title={t("upsellModalApp.configuration.form.variants.title")}
            description={t(
              "upsellModalApp.configuration.form.variants.description"
            )}
          >
            <Card
              sectioned
              title={t("upsellModalApp.configuration.form.variants.sections.1")}
            >
              <div className="fieldContainer mt2">
                <div className="labelField">
                  {t(
                    "upsellModalApp.configuration.form.variants.inputs.optionLabel.label"
                  )}
                </div>
                <ColorPickerWithFont
                  color={variantOptionLabelColor}
                  onChange={this.onChangeColor("variantOptionLabelColor")}
                  fontSize={variantOptionLabelFontSize}
                  onChangeFontSize={this.onChangeValue(
                    "variantOptionLabelFontSize"
                  )}
                  fontStyle={variantOptionLabelFontStyle}
                  onChangeFontStyle={this.onChangeValue(
                    "variantOptionLabelFontStyle"
                  )}
                />
              </div>
              <div className="mt3">
                <Card>
                  <Card.Section title="Badge option">
                    <div className="mt2">
                      <RangeSlider
                        label={t(
                          "upsellModalApp.configuration.form.variants.inputs.variantOptionButton.paddingTopBottom.label"
                        )}
                        value={variantOptionButtonPaddingTopBottom}
                        onChange={this.onChangeValue(
                          "variantOptionButtonPaddingTopBottom"
                        )}
                        min={0}
                        max={30}
                        output
                      />
                    </div>
                    <div className="mt2">
                      <RangeSlider
                        label={t(
                          "upsellModalApp.configuration.form.variants.inputs.variantOptionButton.paddingLeftRight.label"
                        )}
                        value={variantOptionButtonPaddingLeftRight}
                        onChange={this.onChangeValue(
                          "variantOptionButtonPaddingLeftRight"
                        )}
                        min={0}
                        max={30}
                        output
                      />
                    </div>

                    <div className="fieldContainer mt2">
                      <div className="labelField">
                        {t(
                          "upsellModalApp.configuration.form.variants.inputs.variantOptionButton.button.label"
                        )}
                      </div>
                      <ColorPickerWithFont
                        color={variantOptionButtonColor}
                        selectedColor={variantOptionButtonSelectedColor}
                        onChange={this.onChangeColor(
                          "variantOptionButtonColor"
                        )}
                        onChangeSelected={this.onChangeColor(
                          "variantOptionButtonSelectedColor"
                        )}
                        fontSize={variantOptionButtonFontSize}
                        onChangeFontSize={this.onChangeValue(
                          "variantOptionButtonFontSize"
                        )}
                        fontStyle={variantOptionButtonFontStyle}
                        onChangeFontStyle={this.onChangeValue(
                          "variantOptionButtonFontStyle"
                        )}
                      />
                    </div>

                    <div className="fieldContainer mt2">
                      <div className="labelField">
                        {t(
                          "upsellModalApp.configuration.form.variants.inputs.variantOptionButton.backgroundColor.label"
                        )}
                      </div>
                      <div className="flex">
                        <ColorPicker
                          color={variantOptionButtonBackgroundColor}
                          onChange={this.onChangeColor(
                            "variantOptionButtonBackgroundColor"
                          )}
                          hintLabel={t("generic.form.backgroundColor")}
                        />
                        <ColorPicker
                          className="ml1"
                          color={variantOptionButtonSelectedBackgroundColor}
                          onChange={this.onChangeColor(
                            "variantOptionButtonSelectedBackgroundColor"
                          )}
                          hintLabel={t("generic.form.selectedBackgroundColor")}
                        />
                      </div>
                    </div>

                    <div className="fieldContainer mt2">
                      <div className="labelField">
                        {t(
                          "upsellModalApp.configuration.form.variants.inputs.variantOptionButton.borderColor.label"
                        )}
                      </div>
                      <div className="flex">
                        <ColorPicker
                          color={variantOptionButtonBorderColor}
                          onChange={this.onChangeColor(
                            "variantOptionButtonBorderColor"
                          )}
                          hintLabel={t("generic.form.backgroundColor")}
                        />
                        <ColorPicker
                          className="ml1"
                          color={variantOptionButtonSelectedBorderColor}
                          onChange={this.onChangeColor(
                            "variantOptionButtonSelectedBorderColor"
                          )}
                          hintLabel={t("generic.form.selectedBackgroundColor")}
                        />
                      </div>
                    </div>

                    <div className="mt2">
                      <RangeSlider
                        label={t(
                          "upsellModalApp.configuration.form.variants.inputs.variantOptionButton.borderRadius.label"
                        )}
                        value={variantOptionButtonBorderRadius}
                        onChange={this.onChangeValue(
                          "variantOptionButtonBorderRadius"
                        )}
                        min={0}
                        max={30}
                        output
                      />
                    </div>
                  </Card.Section>
                  <Card.Section title="Swatch option">
                    <div className="mt2">
                      <RangeSlider
                        label={t(
                          "upsellModalApp.configuration.form.variants.inputs.variantSwatchButton.size.label"
                        )}
                        value={variantOptionSwatchSize}
                        onChange={this.onChangeValue("variantOptionSwatchSize")}
                        min={10}
                        max={100}
                        output
                      />
                    </div>
                    <div className="mt2">
                      <RangeSlider
                        label={t(
                          "upsellModalApp.configuration.form.variants.inputs.variantSwatchButton.borderRadius.label"
                        )}
                        value={variantOptionSwatchBorderRadius}
                        onChange={this.onChangeValue(
                          "variantOptionSwatchBorderRadius"
                        )}
                        min={0}
                        max={100}
                        output
                      />
                    </div>
                    <div className="fieldContainer mt2">
                      <div className="labelField">
                        {t(
                          "upsellModalApp.configuration.form.variants.inputs.variantSwatchButton.borderColor.label"
                        )}
                      </div>
                      <div className="flex">
                        <ColorPicker
                          color={variantOptionSwatchBorderColor}
                          onChange={this.onChangeColor(
                            "variantOptionSwatchBorderColor"
                          )}
                          hintLabel={t(
                            "upsellModalApp.configuration.form.variants.inputs.variantSwatchButton.borderColor.label"
                          )}
                        />
                        <ColorPicker
                          className="ml1"
                          color={variantOptionSwatchSelectedBorderColor}
                          onChange={this.onChangeColor(
                            "variantOptionSwatchSelectedBorderColor"
                          )}
                          hintLabel={t(
                            "upsellModalApp.configuration.form.variants.inputs.variantSwatchButton.selectedBorderColor.label"
                          )}
                        />
                      </div>
                    </div>
                    <div className="mb1 mt3">
                      {t(
                        "upsellModalApp.configuration.form.variants.inputs.variantSwatchButton.enableTooltip.label"
                      )}
                    </div>
                    <div>
                      <Switch
                        onChange={this.onChangeValue(
                          "variantOptionSwatchEnableTooltip"
                        )}
                        checked={variantOptionSwatchEnableTooltip}
                      />
                    </div>
                  </Card.Section>
                </Card>
                <div className="fieldContainer mt3">
                  <TextField
                    label={t(
                      "upsellModalApp.configuration.form.variants.inputs.outOfStock.label"
                    )}
                    value={variantLabelOutOfStockValue}
                    onChange={this.onChangeValue("variantLabelOutOfStockValue")}
                  />
                </div>
              </div>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
      </div>
    );
  }
}

export default withTranslation()(SettingsForm);
