import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  BehaviorMajor,
  ExternalMinor,
  TickMinor
} from "@shopify/polaris-icons";
import { Banner } from "@shopify/polaris";

import { getStoreId, getStoreName } from "selectors/auth";
import { getScriptTags, installScriptTags } from "actions/scriptTags";

class MigrationOrderConfirmationAppBanner extends Component {
  state = {
    isLoading: true,
    isSyncDone: false
  };

  componentDidMount() {
    const { storeId, getScriptTags } = this.props;

    getScriptTags(storeId)
      .then(res => {
        const isSyncDone =
          res.data.filter(script => script.src.includes("awesome-upsell"))
            .length === 2;

        this.setState({ isLoading: false, isSyncDone });
      })
      .catch(() => this.setState({ isLoading: false }));
  }

  syncScripts = () => {
    const { storeId, installScriptTags } = this.props;
    this.setState({ isLoading: true }, () => {
      installScriptTags(storeId)
        .then(res => {
          const isSyncDone =
            res.data.filter(script => script.src.includes("awesome-upsell"))
              .length === 2;

          this.setState({ isLoading: false, isSyncDone });
        })
        .catch(() => this.setState({ isLoading: false }));
    });
  };

  render() {
    const { t, storeName } = this.props;
    const { isLoading, isSyncDone } = this.state;

    return (
      <div className="MigrationOrderConfirmationAppBanner">
        <Banner
          title={t("components.migrationOrderConfirmationAppBanner.title")}
          action={{
            id: "updateScript",
            accessibilityLabel: t(
              "components.migrationOrderConfirmationAppBanner.primaryButton.accessibilityLabel"
            ),
            content: isSyncDone
              ? t(
                  "components.migrationOrderConfirmationAppBanner.primaryButton.done"
                )
              : t(
                  "components.migrationOrderConfirmationAppBanner.primaryButton.label"
                ),
            loading: isLoading,
            disabled: isSyncDone,
            onAction: this.syncScripts,
            icon: isSyncDone ? TickMinor : BehaviorMajor
          }}
          secondaryAction={{
            id: "goToAdditionalScripts",
            accessibilityLabel: t(
              "components.migrationOrderConfirmationAppBanner.secondaryButton.accessibilityLabel"
            ),
            content: t(
              "components.migrationOrderConfirmationAppBanner.secondaryButton.label"
            ),
            onAction: () =>
              window.open(
                `https://${storeName}/admin/settings/checkout#shop_checkout_configuration_content`
              ),
            icon: ExternalMinor
          }}
          status="info"
        >
          <p>
            {isSyncDone
              ? t(
                  "components.migrationOrderConfirmationAppBanner.description.done"
                )
              : t(
                  "components.migrationOrderConfirmationAppBanner.description.content"
                )}
          </p>
        </Banner>
      </div>
    );
  }
}

export default connect(
  state => ({
    storeId: getStoreId(state),
    storeName: getStoreName(state)
  }),
  { installScriptTags, getScriptTags }
)(withTranslation()(MigrationOrderConfirmationAppBanner));
