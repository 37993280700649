export const VARIANTS_DATA = [
  {
    title: "Promo !",
    variants: [
      {
        id: 36946770034752,
        product_id: 1560419434560,
        name: "Red / XS",
        price: "21.00",
        sku: "",
        position: 1,
        inventory_policy: "deny",
        compare_at_price: "29.90",
        fulfillment_service: "manual",
        inventory_management: "shopify",
        option1: "Red",
        option2: "XS",
        option3: null,
        created_at: "2020-12-08T04:03:03-05:00",
        updated_at: "2021-09-21T14:45:42-04:00",
        taxable: true,
        barcode: "",
        grams: 0,
        image_id: 4610009301056,
        weight: 0,
        weight_unit: "kg",
        inventory_item_id: 39039573884992,
        inventoryQuantity: 100,
        imageUrl: "/images/t-shirt-red.jpg"
      },
      {
        id: 36944732618816,
        product_id: 1560419434560,
        name: "Red / S",
        price: "22.00",
        sku: "",
        position: 2,
        inventory_policy: "deny",
        compare_at_price: "40.00",
        fulfillment_service: "manual",
        inventory_management: "shopify",
        option1: "Red",
        option2: "S",
        option3: null,
        created_at: "2020-12-04T06:13:23-05:00",
        updated_at: "2021-07-25T09:55:35-04:00",
        taxable: true,
        barcode: "",
        grams: 0,
        image_id: 4610009301056,
        weight: 0,
        weight_unit: "kg",
        inventory_item_id: 39037531914304,
        inventory_quantity: 0,
        old_inventory_quantity: 0,
        requires_shipping: true,
        imageUrl: "/images/t-shirt-red.jpg"
      },
      {
        id: 31671449256000,
        product_id: 1560419434560,
        name: "Red / M",
        price: "23.00",
        sku: "",
        position: 3,
        inventory_policy: "deny",
        compare_at_price: null,
        fulfillment_service: "manual",
        inventory_management: "shopify",
        option1: "Red",
        option2: "M",
        option3: null,
        created_at: "2020-04-25T17:28:23-04:00",
        updated_at: "2021-07-25T10:16:26-04:00",
        taxable: true,
        barcode: "",
        grams: 0,
        image_id: null,
        weight: 0,
        weight_unit: "kg",
        inventory_item_id: 33182037442624,
        inventory_quantity: 16,
        old_inventory_quantity: 16,
        requires_shipping: true,
        imageUrl: "/images/t-shirt-red.jpg"
      },
      {
        id: 36944710434880,
        product_id: 1560419434560,
        name: "Red / L",
        price: "24.00",
        sku: "",
        position: 4,
        inventory_policy: "deny",
        compare_at_price: null,
        fulfillment_service: "manual",
        inventory_management: "shopify",
        option1: "Red",
        option2: "L",
        option3: null,
        created_at: "2020-12-04T04:32:05-05:00",
        updated_at: "2020-12-10T02:20:06-05:00",
        taxable: true,
        barcode: "",
        grams: 0,
        image_id: 4610009301056,
        weight: 0,
        weight_unit: "kg",
        inventory_item_id: 39037509730368,
        inventory_quantity: 20,
        old_inventory_quantity: 20,
        requires_shipping: true,
        imageUrl: "/images/t-shirt-red.jpg"
      },
      {
        id: 36946769903680,
        product_id: 1560419434560,
        name: "Red / XL",
        price: "25.00",
        sku: "",
        position: 5,
        inventory_policy: "deny",
        compare_at_price: null,
        fulfillment_service: "manual",
        inventory_management: "shopify",
        option1: "Red",
        option2: "XL",
        option3: null,
        created_at: "2020-12-08T04:02:46-05:00",
        updated_at: "2021-01-01T19:42:30-05:00",
        taxable: true,
        barcode: "",
        grams: 0,
        image_id: null,
        weight: 0,
        weight_unit: "kg",
        inventory_item_id: 39039573753920,
        inventory_quantity: 0,
        old_inventory_quantity: 0,
        requires_shipping: true,
        imageUrl: "/images/t-shirt-red.jpg"
      },
      {
        id: 36946770067520,
        product_id: 1560419434560,
        name: "Red / XXL",
        price: "26.00",
        sku: "",
        position: 6,
        inventory_policy: "deny",
        compare_at_price: null,
        fulfillment_service: "manual",
        inventory_management: "shopify",
        option1: "Red",
        option2: "XXL",
        option3: null,
        created_at: "2020-12-08T04:03:13-05:00",
        updated_at: "2020-12-10T02:20:06-05:00",
        taxable: true,
        barcode: "",
        grams: 0,
        image_id: null,
        weight: 0,
        weight_unit: "kg",
        inventory_item_id: 39039573917760,
        inventory_quantity: 100,
        old_inventory_quantity: 100,
        requires_shipping: true,
        imageUrl: "/images/t-shirt-red.jpg"
      },
      {
        id: 32919587717184,
        product_id: 1560419434560,
        name: "Blue / S",
        price: "27.00",
        sku: "",
        position: 7,
        inventory_policy: "deny",
        compare_at_price: null,
        fulfillment_service: "manual",
        inventory_management: "shopify",
        option1: "Blue",
        option2: "S",
        option3: null,
        created_at: "2020-11-10T06:41:24-05:00",
        updated_at: "2020-12-10T02:20:06-05:00",
        taxable: true,
        barcode: "",
        grams: 0,
        image_id: 18563172991040,
        weight: 0,
        weight_unit: "kg",
        inventory_item_id: 34666588667968,
        inventory_quantity: 20,
        old_inventory_quantity: 20,
        requires_shipping: true,
        imageUrl: "/images/t-shirt-blue.jpg"
      },
      {
        id: 36944732651584,
        product_id: 1560419434560,
        name: "Blue / M",
        price: "28.00",
        sku: "",
        position: 8,
        inventory_policy: "deny",
        compare_at_price: null,
        fulfillment_service: "manual",
        inventory_management: "shopify",
        option1: "Blue",
        option2: "M",
        option3: null,
        created_at: "2020-12-04T06:14:14-05:00",
        updated_at: "2021-07-25T09:55:35-04:00",
        taxable: true,
        barcode: "",
        grams: 0,
        image_id: 18563172991040,
        weight: 0,
        weight_unit: "kg",
        inventory_item_id: 39037531947072,
        inventory_quantity: 18,
        old_inventory_quantity: 18,
        requires_shipping: true,
        imageUrl: "/images/t-shirt-blue.jpg"
      }
    ],
    options: [
      {
        id: 6245862998080,
        product_id: 1560419434560,
        name: "Color",
        position: 1,
        values: ["Red", "Blue"]
      },
      {
        id: 2158758002752,
        product_id: 1560419434560,
        name: "Size",
        position: 2,
        values: ["XS", "S", "M", "L", "XL", "XXL"]
      }
    ],
    images: [
      {
        id: 4610009301056,
        product_id: 1560419434560,
        position: 1,
        created_at: "2018-11-29T17:35:34-05:00",
        updated_at: "2020-12-09T11:09:11-05:00",
        alt: null,
        width: 861,
        height: 1000,
        src:
          "https://cdn.shopify.com/s/files/1/0023/3350/7648/products/t-shirt2.jpg?v=1607530151",
        variant_ids: [36944710434880, 36944732618816, 36946770034752],
        admin_graphql_api_id: "gid://shopify/ProductImage/4610009301056"
      },
      {
        id: 18563172991040,
        product_id: 1560419434560,
        position: 2,
        created_at: "2020-12-09T11:09:11-05:00",
        updated_at: "2020-12-09T11:09:11-05:00",
        alt: null,
        width: 827,
        height: 1000,
        src:
          "https://cdn.shopify.com/s/files/1/0023/3350/7648/products/t-shirt-blue_329a64da-bbc7-4a06-a5b3-dd477a631e82.jpg?v=1607530151",
        variant_ids: [32919587717184, 36944732651584],
        admin_graphql_api_id: "gid://shopify/ProductImage/18563172991040"
      }
    ],
    image: {
      id: 4610009301056,
      product_id: 1560419434560,
      position: 1,
      created_at: "2018-11-29T17:35:34-05:00",
      updated_at: "2020-12-09T11:09:11-05:00",
      alt: null,
      width: 861,
      height: 1000,
      src:
        "https://cdn.shopify.com/s/files/1/0023/3350/7648/products/t-shirt2.jpg?v=1607530151",
      variant_ids: [36944710434880, 36944732618816, 36946770034752],
      admin_graphql_api_id: "gid://shopify/ProductImage/4610009301056"
    }
  }
];
