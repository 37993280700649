import React, { Component } from 'react';
import cx from 'classnames';

import './style.scss';

export default class LoadingPage extends Component {

  render() {
    const { className } = this.props;
    return <div className={cx("LoadingPage w100 flex flex-center flex-content-center", className)}>
      <div className="loader"><div></div><div></div><div></div></div>
    </div>
  }
}
