import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Button, ButtonGroup, Loading } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import cx from "classnames";

import UpsellContent from "components/UpsellModal/UpsellContent";
import UpsellContentMultipleProducts from "components/UpsellModal/UpsellContentMultipleProducts";

import { fetchUpsellModalOffers } from "actions/upsellModalOffers";
import { getUpsellModalByRuleId } from "actions/upsell";
import { getStoreId, getShopifyMoneyFormat } from "selectors/auth";

import { VARIANTS_DATA } from "./data";

import "./style.scss";

class PreviewUpsell extends Component {
  state = {
    rules: [],
    isLoadingRules: true,
    isFetchingRule: false,
    title: "Here is the title",
    subtitle: "Here is the subtitle",
    description: "Here is the description of your product",
    productName: "My product",
    upsellType: "add_product",
    products: VARIANTS_DATA,
    currentRuleId: null,
    variantSelectorType: "select",
    variantOptionsConfig: []
  };

  componentDidMount() {
    const { fetchUpsellModalOffers, storeId } = this.props;
    fetchUpsellModalOffers(storeId).then(res => {
      this.setState({
        rules: res.data,
        isLoadingRules: false
      });
    });
  }

  componentDidUpdate(prevProps) {
    const { upsellType: upsellTypeProp } = this.props;
    const { upsellType } = this.state;

    if (upsellTypeProp !== upsellType) {
      this.setState({ upsellType: upsellTypeProp });
    }
  }

  onChangeSelect = option => {
    const { getUpsellModalByRuleId, storeId } = this.props;
    this.setState({ isFetchingRule: true }, () =>
      getUpsellModalByRuleId(storeId, option.value).then(res =>
        this.setState({
          currentRuleId: res.data.rule.id,
          isFetchingRule: false,
          title: res.data.rule.title,
          subtitle: res.data.rule.subtitle,
          description: res.data.rule.description,
          upsellType: res.data.rule.upsell_type,
          products: res.data.products,
          variantSelectorType: res.data.rule.variant_selector_type,
          variantOptionsConfig: res.data.rule.variant_options_config
        })
      )
    );
  };

  render() {
    const {
      shopifyMoneyFormat,
      className,
      styleAddToCart,
      styleReplaceCart,
      styleNoThanks,
      labelAddToCart,
      labelReplaceCart,
      labelCancel,
      styleModal,
      styleTitle,
      styleSubtitle,
      styleDiscountPrice,
      stylePrice,
      styleProductName,
      styleProductDescription,
      styleVariants,
      t
    } = this.props;

    const {
      rules,
      isLoadingRules,
      isFetchingRule,
      title,
      subtitle,
      description,
      products,
      upsellType,
      variantSelectorType,
      variantOptionsConfig,
      currentRuleId
    } = this.state;

    const options = rules.map(rule => ({ label: rule.name, value: rule.id }));

    const hasOnlyOneProduct = products.length === 1;

    return (
      <div className="PreviewUpsell">
        <Select
          className="PreviewUpsell--select mb2"
          options={options}
          placeholder={t(
            "upsellModalApp.configuration.modal.selectRule.placeholder"
          )}
          onChange={this.onChangeSelect}
          isLoading={isLoadingRules}
        />
        <div
          className={cx("PreviewUpsell--container p3 bg-grey-500", className)}
        >
          {hasOnlyOneProduct ? (
            <UpsellContent
              key={currentRuleId}
              upsellType={upsellType}
              moneyFormat={shopifyMoneyFormat}
              variants={products[0].variants}
              productOptions={products[0].options}
              styleAddToCart={styleAddToCart}
              styleReplaceCart={styleReplaceCart}
              styleNoThanks={styleNoThanks}
              labelAddToCart={labelAddToCart}
              labelReplaceCart={labelReplaceCart}
              labelCancel={labelCancel}
              styleModal={styleModal}
              title={title}
              styleTitle={styleTitle}
              subtitle={subtitle}
              styleSubtitle={styleSubtitle}
              styleDiscountPrice={styleDiscountPrice}
              stylePrice={stylePrice}
              description={description}
              productName={products[0].title}
              styleProductName={styleProductName}
              styleProductDescription={styleProductDescription}
              variantSelectorType={variantSelectorType}
              variantOptionsConfig={variantOptionsConfig}
              styleVariants={styleVariants}
            />
          ) : (
            <UpsellContentMultipleProducts
              upsellType={upsellType}
              moneyFormat={shopifyMoneyFormat}
              products={products}
              styleAddToCart={styleAddToCart}
              styleReplaceCart={styleReplaceCart}
              styleNoThanks={styleNoThanks}
              labelAddToCart={labelAddToCart}
              labelReplaceCart={labelReplaceCart}
              labelCancel={labelCancel}
              styleModal={styleModal}
              title={title}
              styleTitle={styleTitle}
              subtitle={subtitle}
              styleSubtitle={styleSubtitle}
              styleDiscountPrice={styleDiscountPrice}
              stylePrice={stylePrice}
              styleProductName={styleProductName}
              styleProductDescription={styleProductDescription}
            />
          )}

          {!currentRuleId && (
            <div className="bg-white p2 mt3 rounded">
              <div className="mb1">
                {t(
                  "upsellModalApp.configuration.modal.preview.variantSelectorType.label"
                )}
              </div>
              <ButtonGroup fullWidth segmented>
                <Button
                  fullWidth
                  size="medium"
                  primary={variantSelectorType === "select"}
                  onClick={() =>
                    this.setState({ variantSelectorType: "select" })
                  }
                >
                  {t(
                    "upsellModalApp.configuration.modal.preview.variantSelectorType.select"
                  )}
                </Button>
                <Button
                  fullWidth
                  size="medium"
                  primary={variantSelectorType === "badge"}
                  onClick={() =>
                    this.setState({ variantSelectorType: "badge" })
                  }
                >
                  {t(
                    "upsellModalApp.configuration.modal.preview.variantSelectorType.badge"
                  )}
                </Button>
              </ButtonGroup>
            </div>
          )}
          {isFetchingRule && (
            <div className="loadingContainer">
              <Loading size="large" color="teal" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    storeId: getStoreId(state),
    shopifyMoneyFormat: getShopifyMoneyFormat(state)
  }),
  { fetchUpsellModalOffers, getUpsellModalByRuleId }
)(withTranslation()(PreviewUpsell));
