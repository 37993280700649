import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Banner } from "@shopify/polaris";
import { hasChargeIssue } from "selectors/charge";
import { withTranslation, Trans } from "react-i18next";

class ChargeIssueBanner extends Component {
  render() {
    const { hasChargeIssue, t } = this.props;

    if (!hasChargeIssue) {
      return null;
    }

    return (
      <Banner title={t("components.ChargeIssueBanner.title")} status="warning">
        <Trans i18nKey="components.ChargeIssueBanner.description">
          <Link to="/charges">Payment link</Link>
        </Trans>
      </Banner>
    );
  }
}

export default connect(state => ({
  hasChargeIssue: hasChargeIssue(state)
}))(withTranslation()(ChargeIssueBanner));
