import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";
import * as R from "ramda";
import parse from "url-parse";
import queryString from "query-string";
import Cookie from "js-cookie";

import { TextField, Button, Banner, Page } from "@shopify/polaris";

import NavigationTopBar from "pages/homepage/components/NavigationTopBar";

class Login extends Component {
  state = {
    shop: "",
    showInfoBanner: true,
    hasError: false,
    affiliationCode: null
  };

  componentDidMount() {
    const queryParams = queryString.parse(window.location.search);
    const affiliationCode = R.prop("via", queryParams);

    if (affiliationCode) {
      Cookie.set("affiliation_code", R.prop("via", queryParams), { expires: 1 });
    }
  }

  onInstall = e => {
    e.preventDefault();
    const { shop } = this.state;
    const { REACT_APP_BACKEND_URL, REACT_APP_CLIENT_URL } = process.env;

    if (shop) {
      if (!shop.includes(".myshopify.com")) {
        this.setState({ hasError: true });
        return;
      }

      const formattedUrl = shop.startsWith("http") ? shop : `https://${shop}`;
      const formattedShopName = parse(formattedUrl, true).hostname;

      if (formattedShopName === "localhost") {
        this.setState({ hasError: true });
        return;
      }

      this.setState({ hasError: false });

      const redirectUri = `${REACT_APP_CLIENT_URL}/oauth/callback`;
      const state = Math.floor(Math.random() * 16777215);
      const url = `${REACT_APP_BACKEND_URL}/api/v1/oauth?shop=${formattedShopName}&redirect_uri=${redirectUri}&state=${state}`;
      window.location = url;
    }
  };

  render() {
    const { t } = this.props;
    const { shop, hasError, showInfoBanner } = this.state;

    return (
      <div>
        <NavigationTopBar />
        <Page title={t("login.title")}>
          <div className="mt3">
            {showInfoBanner && (
              <div className="mb2">
                <Banner
                  title={<span>{t("login.installTitle")}</span>}
                  status="info"
                  onDismiss={() => this.setState({ showInfoBanner: false })}
                >
                  <Trans i18nKey="login.installDescription">
                    <b>your-shop-name.myshopify.com</b>
                  </Trans>
                </Banner>
              </div>
            )}

            {hasError && (
              <div className="mb2">
                <Banner
                  title={<span>{t("login.titleError")}</span>}
                  status="warning"
                >
                  <Trans i18nKey="login.descriptionError" />
                </Banner>
              </div>
            )}
            <form onSubmit={this.onInstall}>
              <div className="mb2">
                <TextField
                  value={shop}
                  placeholder={t("login.inputPlaceholder")}
                  onChange={value =>
                    this.setState({ shop: value, hasError: false })
                  }
                  helpText={t("login.inputHelpText")}
                />
              </div>
              <Button
                primary
                fullWidth
                onClick={this.onInstall}
                disabled={!shop}
              >
                {t("login.labelButton")}
              </Button>
            </form>
          </div>
        </Page>
      </div>
    );
  }
}

export default withTranslation()(Login);
