import React from "react";
import { useTranslation } from "react-i18next";

import IncreasesBox from "./IncreasesBox";

import "./style.scss";

export default function IncreasesSection() {
  const { t } = useTranslation();

  const CONFIG_BOXES = [
    {
      backgroundColor: "#2ABAFF",
      imageSrc: "/images/homepage/cart-icon.svg",
      title: t("homepage.increasesSection.titleBox1"),
      description: t("homepage.increasesSection.descriptionBox1")
    },
    {
      backgroundColor: "#FFBD59",
      imageSrc: "/images/homepage/2-in-1-icon.svg",
      title: t("homepage.increasesSection.titleBox2"),
      description: t("homepage.increasesSection.descriptionBox2")
    },
    {
      backgroundColor: "#73C5FF",
      imageSrc: "/images/homepage/cash-icon.svg",
      title: t("homepage.increasesSection.titleBox3"),
      description: t("homepage.increasesSection.descriptionBox3")
    },
    {
      backgroundColor: "#FFDE59",
      imageSrc: "/images/homepage/install-icon.svg",
      title: t("homepage.increasesSection.titleBox4"),
      description: t("homepage.increasesSection.descriptionBox4")
    },
    {
      backgroundColor: "#FF8CF9",
      imageSrc: "/images/homepage/devices-icon.svg",
      title: t("homepage.increasesSection.titleBox5"),
      description: t("homepage.increasesSection.descriptionBox5")
    },
    {
      backgroundColor: "#58EB6B",
      imageSrc: "/images/homepage/customized-icon.svg",
      title: t("homepage.increasesSection.titleBox6"),
      description: t("homepage.increasesSection.descriptionBox6")
    }
  ];

  return (
    <section className="IncreasesSection container">
      <div
        id="titleIncreases"
        className="sectionTitle"
        data-aos="fade-down"
        data-aos-easing="ease"
        data-aos-duration="500"
        data-aos-offset="300"
        data-aos-anchor="#titleIncreases"
      >
        <h3 className="title">{t("homepage.increasesSection.title")}</h3>
        <p className="subtitle">{t("homepage.increasesSection.subtitle")}</p>
      </div>
      <div className="flex flex-wrap flex-content-around">
        {CONFIG_BOXES.map((box, index) => (
          <div
            key={index}
            data-aos="fade-up"
            data-aos-easing="ease"
            data-aos-delay={(index + 1) * 100}
            data-aos-duration="400"
            data-aos-offset="300"
            data-aos-anchor="#titleIncreases"
          >
            <IncreasesBox
              backgroundColor={box.backgroundColor}
              imageSrc={box.imageSrc}
              title={box.title}
              description={box.description}
            />
          </div>
        ))}
      </div>
    </section>
  );
}
