import { getFontStyle } from "../ColorPickerWithFont/options";

export const getOrderConfirmationGridStyles = config => {
  const styleMoreInfo = {
    color: config.moreInfoLabelColor,
    backgroundColor: config.moreInfoBackgroundColor
  };

  const styleAddToOrder = {
    color: config.addOrderLabelColor,
    backgroundColor: config.addOrderBackgroundColor
  };

  const styleTitle = {
    color: config.titleColor,
    fontSize: config.titleFontSize,
    ...getFontStyle(config.titleFontStyle)
  };

  const styleSubtitle = {
    color: config.subtitleColor,
    fontSize: config.subtitleFontSize,
    ...getFontStyle(config.subtitleFontStyle)
  };

  const styleDiscountPrice = {
    color: config.discountPriceTextColor,
    fontSize: config.discountPriceTextFontSize,
    ...getFontStyle(config.discountPriceTextFontStyle)
  };

  const stylePrice = {
    color: config.priceTextColor,
    fontSize: config.priceTextFontSize,
    ...getFontStyle(config.priceTextFontStyle)
  };

  const styleProductName = {
    color: config.productNameColor,
    fontSize: config.productNameFontSize,
    ...getFontStyle(config.productNameFontStyle)
  };

  const styleImage = {
    height: config.imageHeight
  };

  return {
    styleMoreInfo,
    styleAddToOrder,
    styleTitle,
    styleSubtitle,
    styleDiscountPrice,
    stylePrice,
    styleProductName,
    styleImage
  };
};
