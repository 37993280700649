import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Page, PageActions } from "@shopify/polaris";

import {
  updateUpsellModalOffer,
  fetchUpsellModalOffer
} from "actions/upsellModalOffers";

import { addToast } from "actions/toasts";
import { getStoreId } from "selectors/auth";

import OfferForm from "../components/OfferForm";

import "./style.scss";
import LoadingUpdateOfferPage from "./LoadingUpdateOfferPage";

class UpdateUpsellModalOffer extends Component {
  state = {
    shouldDisableAddButton: false,
    isFetching: true,
    isSaving: false,
    data: null
  };

  componentDidMount() {
    this.fetchUpsellModalOffer();
  }

  fetchUpsellModalOffer = () => {
    const { match } = this.props;
    const { params } = match;
    const { offerId } = params;
    const { storeId, fetchUpsellModalOffer } = this.props;

    return fetchUpsellModalOffer(storeId, offerId).then(res => {
      this.setState({ data: res.data, isFetching: false });
    });
  };

  getDefaultValues = () => {
    const { data } = this.state;

    if (!data) {
      return {};
    }

    return {
      name: data.name,
      discountType: data.discount_type,
      discountValue: data.discount_value,
      title: data.title,
      subtitle: data.subtitle,
      description: data.description,
      selectedProductId: data.product_ids,
      conditionProductsIds: data.show_condition,
      conditionCollectionsIds: data.show_condition_collections,
      prefixDiscountCode: data.prefix_discount_code,
      minQuantity: data.min_quantity,
      minQuantityType: data.min_quantity_type,
      upsellType: data.upsell_type,
      removeItemMethod: data.remove_item_method,
      hideOutOfStockVariants: data.hide_out_of_stock_variants,
      variantSelectorType: data.variant_selector_type,
      variantOptionsConfig: data.variant_options_config
    };
  };

  onChange = (data, shouldDisableAddButton) => {
    this.setState({ data, shouldDisableAddButton });
  };

  onUpdate = () => {
    const { history, updateUpsellModalOffer, storeId, match } = this.props;
    const { params } = match;
    const { offerId } = params;
    const { data } = this.state;
    const {
      name,
      conditionProducts,
      conditionCollections,
      selectedProducts,
      discountType,
      discountValue,
      title,
      subtitle,
      description,
      prefixDiscountCode,
      minQuantity,
      minQuantityType,
      upsellType,
      removeItemMethod,
      hideOutOfStockVariants,
      variantSelectorType,
      variantOptionsConfig
    } = data;

    const conditionProductsIds = conditionProducts.map(p => p.value);
    const conditionCollectionsIds = conditionCollections.map(p => p.value);
    const selectedProductsIds = selectedProducts.map(p => p.value);

    this.setState({ isSaving: true }, () => {
      return updateUpsellModalOffer(storeId, offerId, {
        name,
        title,
        subtitle,
        description,
        show_condition: conditionProductsIds,
        show_condition_collections: conditionCollectionsIds,
        product_ids: selectedProductsIds,
        discount_value: discountValue,
        discount_type: discountType.value,
        prefix_discount_code: prefixDiscountCode,
        min_quantity: minQuantity,
        min_quantity_type: minQuantityType.value,
        upsell_type: upsellType,
        remove_item_method: removeItemMethod[0],
        hide_out_of_stock_variants: hideOutOfStockVariants,
        variant_selector_type: variantSelectorType.value,
        variant_options_config: variantOptionsConfig
      })
        .then(() => {
          addToast("Offer has been created!", "success");
          history.push("/upsell/modal/offers");
        })
        .catch(() => this.setState({ isSaving: false }));
    });
  };

  onCancel = () => {
    const { history } = this.props;
    history.push("/upsell/modal/offers");
  };

  render() {
    const { history, t } = this.props;
    const { shouldDisableAddButton, isSaving, data } = this.state;

    if (!data) {
      return <LoadingUpdateOfferPage history={history} />;
    }

    return (
      <Page
        title={t("upsellModalApp.update.title")}
        separator
        breadcrumbs={[
          {
            content: t("upsellModalApp.update.backToList"),
            onAction: () => history.push("/upsell/modal/offers")
          }
        ]}
        primaryAction={{
          content: t("upsellModalApp.update.submitButton"),
          disabled: shouldDisableAddButton,
          onClick: this.onUpdate,
          loading: isSaving
        }}
      >
        <OfferForm
          defaultValues={this.getDefaultValues()}
          onChange={this.onChange}
        />

        <div className="mt3">
          <PageActions
            primaryAction={{
              content: t("upsellModalApp.update.submitButton"),
              disabled: shouldDisableAddButton,
              onClick: this.onUpdate,
              loading: isSaving
            }}
            secondaryActions={[
              {
                content: t("upsellModalApp.update.cancelButton"),
                onClick: this.onCancel
              }
            ]}
          />
        </div>
      </Page>
    );
  }
}

export default connect(
  state => ({
    storeId: getStoreId(state)
  }),
  {
    fetchUpsellModalOffer,
    updateUpsellModalOffer,
    addToast
  }
)(withTranslation()(UpdateUpsellModalOffer));
