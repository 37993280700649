import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import { Banner } from "@shopify/polaris";

const DiscountCartBanner = ({ className }) => {
  const { t } = useTranslation();
  const [showBanner, setShowBanner] = useState(true);

  return (
    showBanner && (
      <div className={cx("DiscountCartBanner", className)}>
        <Banner
          title={t(
            "upsellModalApp.configuration.discountCartPage.banner.title"
          )}
          status="info"
          onDismiss={() => setShowBanner(false)}
        >
          {t(
            "upsellModalApp.configuration.discountCartPage.banner.description"
          )}
        </Banner>
      </div>
    )
  );
};

export default DiscountCartBanner;
