import React, { PureComponent } from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { withTranslation, Trans } from "react-i18next";

import { Link } from "react-router-dom";
import { TextField, Layout, Card, Tooltip, Icon } from "@shopify/polaris";
import { InfoMinor } from "@shopify/polaris-icons";

import UpsellGeneralForm from "components/UpsellGeneralForm";
import PrefixDiscountCodeBanner from "components/PrefixDiscountCodeBanner";
import Switch from "components/Switch";

import { getShopifyProducts } from "actions/products";
import { getShopifyCollections } from "actions/collections";
import { fetchConfiguration } from "actions/configurations";

import { getStoreId, getShopifyMoneyFormat } from "selectors/auth";
import { DEFAULT_MODAL_SETTINGS_VALUES } from "data/settings";

import PreviewUpsellContent from "./PreviewUpsellContent";
import VariantSettingsForm, {
  VARIANT_SELECTOR_TYPE_OPTIONS
} from "./VariantSettingsForm";

import { formatPrefixDiscountCode } from "./utils";

import "./style.scss";

const NEW_MODAL_STYLE_CONFIG = {
  replaceCartBackgroundColor: "#e63235",
  replaceCartLabelColor: "#ffffff",
  replaceCartValue: "REPLACE",
  ...DEFAULT_MODAL_SETTINGS_VALUES
};

class OfferForm extends PureComponent {
  static defaultProps = {
    defaultValues: {}
  };

  constructor(props) {
    super(props);

    const variantSelectorTypeOptions = VARIANT_SELECTOR_TYPE_OPTIONS(
      this.props.t
    );

    this.state = {
      name: props.defaultValues.name || "",
      productsOptions: [],
      collectionsOptions: [],
      discountType: {},
      discountValue: props.defaultValues.discountValue || 20,
      title: props.defaultValues.title || "Buy this amazing product!",
      subtitle: props.defaultValues.subtitle || "",
      description: props.defaultValues.description || "",
      upsellType: props.defaultValues.upsellType || "add_product",
      selectedProducts: [],
      conditionProducts: [],
      conditionCollections: [],
      configuration: {},
      isLoadingProducts: true,
      isLoadingCollections: true,
      prefixDiscountCode: props.defaultValues.prefixDiscountCode || "",
      hideOutOfStockVariants: !!props.defaultValues.hideOutOfStockVariants,
      variantOptionsConfig: props.defaultValues.variantOptionsConfig || [],
      variantSelectorType:
        variantSelectorTypeOptions.find(
          o => o.value === props.defaultValues.variantSelectorType
        ) || variantSelectorTypeOptions[0],
      hasError: false
    };
  }

  componentDidMount() {
    this.fetchConfiguration();
  }

  componentDidUpdate(prevProps, prevState) {
    const { onChange } = this.props;
    if (!R.equals(prevState, this.state)) {
      if (onChange) {
        onChange(this.state, this.state.hasError);
      }
    }
  }

  fetchConfiguration = () => {
    const { storeId, fetchConfiguration } = this.props;
    fetchConfiguration(storeId).then(res => {
      this.setState({
        configuration: {
          ...res.data.configuration,
          modal_style: {
            ...NEW_MODAL_STYLE_CONFIG,
            ...res.data.configuration.modal_style
          }
        }
      });

      return res;
    });
  };

  onCancel = () => {
    const { history } = this.props;
    history.push("/offers");
  };

  onChangeGeneralSettings = (state, hasError) => {
    this.setState({ ...state, hasError });
  };

  onChangeValue = key => value => this.setState({ [key]: value });

  render() {
    const { t, defaultValues, shopifyMoneyFormat } = this.props;
    const {
      discountType,
      discountValue,
      title,
      subtitle,
      description,
      selectedProducts,
      configuration,
      prefixDiscountCode,
      upsellType,
      hideOutOfStockVariants,
      variantSelectorType,
      variantOptionsConfig
    } = this.state;

    return (
      <div className="OfferForm">
        <Layout>
          <Layout.AnnotatedSection
            title={t("upsellModalApp.form.general.title")}
            description={t("upsellModalApp.form.general.description")}
          >
            <UpsellGeneralForm
              enableUpsellType
              enableQuantity
              configuration={configuration}
              defaultValues={defaultValues}
              onChange={this.onChangeGeneralSettings}
              maxOptions={5}
            />
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title={t("upsellModalApp.form.optional.title")}
            description={t("upsellModalApp.form.optional.description")}
          >
            <Card sectioned>
              <div>
                <div className="mb1">
                  {t("upsellModalApp.form.optional.inputs.title.label")}
                </div>
                <div style={{ zIndex: 0, position: "relative" }}>
                  <TextField
                    value={title}
                    maxLength={500}
                    showCharacterCount
                    onChange={value => this.setState({ title: value })}
                  />
                </div>
              </div>
              <div className="mt3">
                <div className="mb1">
                  {t("upsellModalApp.form.optional.inputs.subtitle.label")}
                </div>
                <div style={{ zIndex: 0, position: "relative" }}>
                  <TextField
                    value={subtitle}
                    maxLength={500}
                    showCharacterCount
                    onChange={value => this.setState({ subtitle: value })}
                  />
                </div>
              </div>
              <div className="mt3">
                <div className="mb1 flex flex-center">
                  <div className="mr1">
                    {t("upsellModalApp.form.optional.inputs.description.label")}
                  </div>
                  <Tooltip
                    content={t(
                      "upsellModalApp.form.optional.inputs.description.tooltip"
                    )}
                  >
                    <Icon source={InfoMinor} color="inkLight" />
                  </Tooltip>
                </div>
                <div style={{ zIndex: 0, position: "relative" }}>
                  <TextField
                    value={description}
                    onChange={value => this.setState({ description: value })}
                    maxLength={500}
                    multiline={3}
                    showCharacterCount
                  />
                </div>
              </div>
              <div className="mt3">
                <div className="mb1">
                  {t("upsellModalApp.form.optional.inputs.discountCode.label")}
                </div>
                <div style={{ zIndex: 0, position: "relative" }}>
                  <TextField
                    value={prefixDiscountCode}
                    onChange={value =>
                      this.setState({
                        prefixDiscountCode: value
                      })
                    }
                    maxLength={50}
                    showCharacterCount
                    helpText={t(
                      "upsellModalApp.form.optional.inputs.discountCode.helpText"
                    )}
                  />
                  {prefixDiscountCode !==
                    formatPrefixDiscountCode(prefixDiscountCode) && (
                    <PrefixDiscountCodeBanner
                      className="mt2"
                      prefixDiscountCode={formatPrefixDiscountCode(
                        prefixDiscountCode
                      )}
                    />
                  )}
                </div>
              </div>

              <div className="mt3">
                <div className="mb1">
                  {t(
                    "upsellModalApp.form.optional.inputs.hideOutOfStockVariant.label"
                  )}
                </div>
                <Switch
                  onChange={value =>
                    this.setState({ hideOutOfStockVariants: value })
                  }
                  checked={hideOutOfStockVariants}
                />
                <div className="helpText">
                  {t(
                    "upsellModalApp.form.optional.inputs.hideOutOfStockVariant.helpText"
                  )}
                </div>
              </div>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title={t("upsellModalApp.form.variants.title")}
            description={t("upsellModalApp.form.variants.description")}
          >
            <Card>
              <VariantSettingsForm
                variantOptionsConfig={variantOptionsConfig}
                variantSelectorType={variantSelectorType}
                onChange={this.onChangeValue}
              />
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title={t("upsellModalApp.form.preview.title")}
            description={
              <span>
                <Trans i18nKey="upsellModalApp.form.preview.description">
                  <Link
                    className="shopify-link"
                    to="/upsell/modal/configuration"
                  >
                    Link configuration
                  </Link>
                </Trans>
              </span>
            }
          >
            <Card>
              <PreviewUpsellContent
                moneyFormat={shopifyMoneyFormat}
                configuration={configuration}
                upsellType={upsellType}
                products={selectedProducts}
                title={title}
                subtitle={subtitle}
                description={description}
                discountValue={discountValue}
                discountType={discountType.value}
                variantSelectorType={variantSelectorType.value}
                variantOptionsConfig={variantOptionsConfig}
              />
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
      </div>
    );
  }
}

export default connect(
  state => ({
    storeId: getStoreId(state),
    shopifyMoneyFormat: getShopifyMoneyFormat(state)
  }),
  {
    getShopifyProducts,
    getShopifyCollections,
    fetchConfiguration
  }
)(withTranslation()(OfferForm));
