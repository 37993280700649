import { useEffect } from "react";
import Cookie from "js-cookie";

export default function RedirectToAppStore({ match }) {
  const { params } = match;
  const { affiliation_code } = params;

  useEffect(() => {
    if (affiliation_code) {
      Cookie.set("affiliation_code", affiliation_code, { expires: 5 });
      window.top.location.href = "https://apps.shopify.com/awesome-upsell";
    }
  }, []);

  return null;
}
