import React, { Component } from "react";
import { Tooltip, Icon } from "@shopify/polaris";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { EditMajor, DeleteMajor } from "@shopify/polaris-icons";

import DeleteOfferModal from "./DeleteOfferModal";

class MoreActionsButton extends Component {
  state = {
    openDeleteModal: false,
    isDeleting: false
  };

  onDeleteOffer = () => {
    const { onDeleteOffer } = this.props;
    this.setState({ isDeleting: true }, () => {
      onDeleteOffer().then(() =>
        this.setState({
          isDeleting: false,
          openDeleteModal: false
        })
      );
    });
  };

  onOpenDeleteModal = () => this.setState({ openDeleteModal: true });

  onCloseDeleteModal = () => this.setState({ openDeleteModal: false });

  render() {
    const { rule, history, t } = this.props;
    const { openDeleteModal, isDeleting } = this.state;

    return (
      <div className="MoreActionsButtons flex flex-center">
        <Tooltip content={t("orderConfirmationApp.list.actions.edit")}>
          <div
            className="p2"
            onClick={() =>
              history.push(`/upsell/order-confirmation/offers/${rule.id}`)
            }
          >
            <Icon source={EditMajor} color="inkLight" />
          </div>
        </Tooltip>
        <Tooltip content={t("orderConfirmationApp.list.actions.delete")}>
          <div className="p2" onClick={this.onOpenDeleteModal}>
            <Icon source={DeleteMajor} color="inkLight" />
          </div>
        </Tooltip>

        <DeleteOfferModal
          open={openDeleteModal}
          rule={rule}
          onClose={this.onCloseDeleteModal}
          onSubmit={this.onDeleteOffer}
          isLoading={isDeleting}
        />
      </div>
    );
  }
}

export default withRouter(withTranslation()(MoreActionsButton));
