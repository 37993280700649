import { combineReducers } from "redux";

import auth, { authInitState } from "./auth";
import toasts, { toastsInitState } from "./toasts";
import charge, { chargeInitState } from "./charge";
import adminAuth, { adminAuthInitState } from "./adminAuth";
import products, { productsInitState } from "./products";
import collections, { collectionsInitState } from "./collections";

export const initialState = {
  adminAuth: adminAuthInitState,
  auth: authInitState,
  charge: chargeInitState,
  toasts: toastsInitState,
  products: productsInitState,
  collections: collectionsInitState
};

const appReducer = combineReducers({
  adminAuth,
  auth,
  charge,
  toasts,
  products,
  collections
});

const rootReducer = (state, action) => {
  if (action.type === "AUTH_USER_LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
