import React, { Component } from "react";
import { Spinner } from "@shopify/polaris";

import AssetRow from "./AssetRow";

import "./style.scss";

class AssetsList extends Component {
  state = {
    selectedAssetKey: null
  };

  componentDidUpdate(prevProps) {
    const { asset } = this.props;
    const { selectedAssetKey } = this.state;

    if (asset && asset.key !== selectedAssetKey) {
      this.setState({ selectedAssetKey: asset.key });
    }
  }

  onSelectAsset = assetKey => () => {
    const { onSelectAsset } = this.props;
    this.setState({ selectedAssetKey: assetKey }, () => {
      if (onSelectAsset) {
        onSelectAsset(assetKey);
      }
    });
  };

  render() {
    const { assets, isLoading } = this.props;
    const { selectedAssetKey } = this.state;
    if (isLoading) {
      return (
        <div className="AssetsList full-flex-center">
          <Spinner size="large" color="teal" />
        </div>
      );
    }

    return (
      <div className="AssetsList">
        {assets.map(a => (
          <AssetRow
            onClick={this.onSelectAsset(a.key)}
            isSelected={a.key === selectedAssetKey}
            key={a.key}
            name={a.key}
          />
        ))}
      </div>
    );
  }
}

export default AssetsList;
