import React, { Component } from "react";

import { Badge, DataTable, Modal } from "@shopify/polaris";

export default class AffiliatesListModal extends Component {
  getBadgeStatus = status => {
    switch (status) {
      case "active":
        return "success";
      case "trialing":
        return "default";
      default:
        return "warning";
    }
  };

  getRows = () => {
    const { affiliates } = this.props;
    return affiliates.map(a => [
      a.name,
      <Badge status={this.getBadgeStatus(a.subscription_status)}>
        {a.subscription_status}
      </Badge>
    ]);
  };

  render() {
    const { open, onClose } = this.props;
    return (
      <Modal
        open={open}
        onClose={onClose}
        title="Affiliates"
        secondaryActions={[
          {
            content: "Close",
            onAction: onClose
          }
        ]}
      >
        <DataTable
          columnContentTypes={["text", "text"]}
          headings={["Shop name", "Subscription status"]}
          rows={this.getRows()}
        />
      </Modal>
    );
  }
}
