import React, { useState, useEffect } from "react";
import { useCountUp } from "react-countup";
import { useTranslation } from "react-i18next";
import useScroll from "hooks/useScroll";

import StartFreeTrialButton from "../../StartFreeTrialButton";

import "./style.scss";

function isElementInViewport(el) {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight ||
        document.documentElement.clientHeight) /* or $(window).height() */ &&
    rect.right <=
      (window.innerWidth ||
        document.documentElement.clientWidth) /* or $(window).width() */
  );
}

export default function StatsSection() {
  const { t } = useTranslation();
  const containerRef = React.createRef(null);
  const scrollRef = React.useRef(document.body);
  const { scrollY } = useScroll(scrollRef);
  const [hasStarted, setHasStarted] = useState(false);
  const commonConfig = {
    start: 0,
    delay: 1000,
    duration: 3,
    easingFn: function(t, b, c, d) {
      return -c * (t /= d) * (t - 2) + b;
    }
  };

  const { countUp: countCA, start: startCA } = useCountUp({
    end: 92153,
    ...commonConfig
  });

  const { countUp: countUpsells, start: startUpsell } = useCountUp({
    end: 1674,
    ...commonConfig
  });

  const { countUp: countPercent, start: startPercent } = useCountUp({
    end: 20,
    ...commonConfig
  });

  useEffect(() => {
    if (containerRef.current) {
      if (isElementInViewport(containerRef.current) && !hasStarted) {
        startCA();
        startUpsell();
        startPercent();
        setHasStarted(true);
      }
    }
  }, [scrollY]);

  const CONFIG_STATS = [
    {
      count: countUpsells,
      label: t("homepage.statsSection.stat1")
    },
    {
      count: `${countCA}€`,
      label: t("homepage.statsSection.stat2")
    },
    {
      count: `+${countPercent}%`,
      label: t("homepage.statsSection.stat3")
    }
  ];

  return (
    <section
      className="StatsSection full-flex-center"
      id="statsSection"
      ref={containerRef}
    >
      <div className="container full-flex-center flex-column">
        <div>
          <h3
            className="title center mb2"
            data-aos="fade-up"
            data-aos-easing="ease"
            data-aos-duration="500"
            data-aos-offset="200"
            data-aos-anchor="#statsSection"
          >
            {t("homepage.statsSection.title")}
          </h3>
        </div>
        <div className="statsContainer flex full-flex-center flex-wrap">
          {CONFIG_STATS.map((stat, index) => (
            <div
              key={index}
              className="statContent"
              data-aos="fade-up"
              data-aos-easing="ease"
              data-aos-delay={(index + 1) * 100}
              data-aos-duration="400"
              data-aos-offset="200"
              data-aos-anchor="#statsSection"
            >
              <div className="statNumber">{stat.count}</div>
              <p className="label mt2">{stat.label}</p>
            </div>
          ))}
        </div>
        <div
          className="full-flex-center mt2"
          id="startFreeTrialButton"
          data-aos="fade-left"
          data-aos-easing="ease"
          data-aos-delay="200"
          data-aos-offset="200"
          data-aos-duration="400"
          data-aos-anchor="#statsSection"
        >
          <StartFreeTrialButton />
        </div>
      </div>
    </section>
  );
}
