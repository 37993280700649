import React, { Component } from "react";
import equals from "ramda/es/equals";

import { formatShopifyMoney } from "utils/price";
import styles from "./style.module.scss";
import { cs } from "../../../../utils/upsell";

export default class ProductRow extends Component {
  state = {
    variantId: null
  };

  componentDidMount() {
    this.setState({ variantId: this.getDefaultVariantId() });
  }

  getDefaultVariantId = () => {
    const { cart, variants } = this.props;

    if (!cart) {
      return variants ? variants[0].id : null;
    }

    if (!cart.items.length) {
      return variants ? variants[0].id : null;
    }

    const optionsValues = cart.items.map(i => {
      return i.options_with_values.map(o => o.value);
    });

    const possibilities = optionsValues.reduce((accumulator, value) => {
      const newOptions = value.reduce((accumulator, value) => {
        const newValue = [...accumulator, value].join(" / ");
        accumulator.push(newValue);
        return accumulator;
      }, []);

      accumulator.push(newOptions);

      return accumulator;
    }, []);

    const allCartVariantPossibilities = possibilities.reduce(
      (acc, val) => acc.concat(val),
      []
    );

    const intersection = (array1, array2) =>
      array1.filter(value => array2.includes(value));

    const filteredVariants = variants.filter(v => {
      const values = v.name.split("/").map(v => v.trim());
      return intersection(allCartVariantPossibilities, values).length;
    });

    if (filteredVariants.length) {
      const sameVariant = filteredVariants.find(v =>
        allCartVariantPossibilities.includes(v.name)
      );

      if (sameVariant) {
        return sameVariant.id;
      }

      const lastVariant = filteredVariants.pop();
      return lastVariant.id;
    }

    return variants ? variants[0].id : null;
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { variants } = prevProps;
    const { variants: currentVariants } = this.props;

    if (!equals(variants, currentVariants)) {
      this.setState({
        variantId: currentVariants ? currentVariants[0].id : null
      });
    }
  }

  onChangeSelect = event =>
    this.setState({ variantId: parseInt(event.target.value) });

  formatPrice = value => {
    const { moneyFormat } = this.props;
    return formatShopifyMoney(value, moneyFormat);
  };

  addToCart = () => {
    try {
      const { onSubmit } = this.props;
      const { variantId } = this.state;

      const data = {
        id: variantId,
        quantity: 1
      };

      if (onSubmit) {
        onSubmit(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const {
      styleModal,
      stylePrice,
      styleDiscountPrice,
      styleAddToCart,
      styleReplaceCart,
      labelAddToCart,
      labelReplaceCart,
      variants,
      productName,
      styleProductName,
      upsellType
    } = this.props;
    const { variantId } = this.state;

    const hasOnlyOneVariant = variants.length <= 1;

    const isVariantId = variantId => v => v.id === variantId;
    const currentVariant = variants.find(isVariantId(variantId)) || {};
    const hasDiscountPrice =
      currentVariant.price > currentVariant.discountPrice;

    return (
      <div
        className={cs(styles.productRow, "awesome-quantity-product-row")}
        style={styleModal}
      >
        <div
          className={cs(
            styles.containerProduct,
            "awesome-quantity-product-row-container-product"
          )}
        >
          <div
            className={cs(
              styles.containerProductImage,
              "awesome-quantity-product-row-container-product-image"
            )}
          >
            <img
              className={cs(
                styles.productImage,
                "awesome-quantity-product-row-product-image"
              )}
              src={currentVariant.imageUrl}
              alt={productName}
            />
          </div>
          <div
            className={cs(
              styles.containerButtons,
              "awesome-quantity-product-row-container-buttons"
            )}
          >
            <div
              className={cs(
                styles.productName,
                "awesome-quantity-product-row-product-name"
              )}
              style={styleProductName}
            >
              {productName}
            </div>

            <div
              className={cs(
                styles.containerPrice,
                "awesome-quantity-product-row-container-price"
              )}
            >
              <div
                className={cs(
                  styles.price,
                  "awesome-quantity-product-row-price"
                )}
                style={{
                  ...stylePrice,
                  ...(!hasDiscountPrice ? { textDecoration: "none" } : {})
                }}
                dangerouslySetInnerHTML={{
                  __html: this.formatPrice(currentVariant.price)
                }}
              />
              {hasDiscountPrice && (
                <div
                  className={cs(
                    styles.discountPrice,
                    "awesome-quantity-product-row-discount-price"
                  )}
                  style={styleDiscountPrice}
                  dangerouslySetInnerHTML={{
                    __html: this.formatPrice(currentVariant.discountPrice)
                  }}
                />
              )}
            </div>

            {!hasOnlyOneVariant && (
              <div
                className={cs(
                  styles.containerSelect,
                  "awesome-quantity-product-row-container-select"
                )}
              >
                <select
                  className={cs(
                    styles.select,
                    "awesome-quantity-product-row-select"
                  )}
                  value={variantId}
                  onChange={this.onChangeSelect}
                >
                  {variants.map(v => (
                    <option key={v.id} value={v.id}>
                      {v.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
          {upsellType === "replace_product" ? (
            <div
              className={cs(
                styles.btn,
                styles.addToCart,
                "awesome-quantity-product-row-replace-button"
              )}
              onClick={this.addToCart}
              style={styleReplaceCart}
            >
              {labelReplaceCart}
            </div>
          ) : (
            <div
              className={cs(
                styles.btn,
                styles.addToCart,
                "awesome-quantity-product-row-add-button"
              )}
              onClick={this.addToCart}
              style={styleAddToCart}
            >
              {labelAddToCart}
            </div>
          )}
        </div>
      </div>
    );
  }
}

ProductRow.defaultProps = {
  labelAddToCart: "Add to cart",
  labelReplaceCart: "Replace",
  styleAddToCard: {},
  styleNoThanks: {},
  styleModal: {}
};
