import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Cookie from "js-cookie";
import { Page, Banner, Frame } from "@shopify/polaris";

import { getShopifyStorePlan } from "selectors/auth";
import {
  getChargeStatus,
  isTrialing,
  getMaxTrialCount
} from "selectors/charge";
import LayoutTopBar from "components/Layout/LayoutTopBar";

// import { hasAffiliation } from "selectors/auth";

import PlansList from "./PlansList";

class Charges extends Component {
  componentDidMount() {
    Cookie.remove("affiliation_code");
  }

  render() {
    const {
      chargeStatus,
      hasAffiliation,
      isTrialing,
      maxTrialCount,
      storePlanName,
      t
    } = this.props;

    const hasChargeIssue =
      chargeStatus !== "no_charge" &&
      chargeStatus !== "active" &&
      chargeStatus !== "trialing";

    const isChargeOk = chargeStatus === "active";

    return (
      <Frame topBar={<LayoutTopBar />}>
        <Page title={t("charges.title")} separator>
          {hasChargeIssue && (
            <Banner title={t("charges.failTitle")} status="warning">
              <p>{t("charges.failDescription")}</p>
            </Banner>
          )}

          {!hasChargeIssue && !isTrialing && !!maxTrialCount && (
            <Banner
              title={t("charges.trialEndedTitle", { maxTrialCount })}
              status="info"
            >
              <p>{t("charges.trialEndedDescription")}</p>
            </Banner>
          )}
          <div className="mt2">
            <PlansList
              storePlanName={storePlanName}
              hasAffiliation={hasAffiliation}
              hasChargeIssue={hasChargeIssue}
              isActive={isChargeOk}
            />
          </div>
        </Page>{" "}
      </Frame>
    );
  }
}

export default connect(state => ({
  chargeStatus: getChargeStatus(state),
  isTrialing: isTrialing(state),
  maxTrialCount: getMaxTrialCount(state),
  storePlanName: getShopifyStorePlan(state),
  hasAffiliation: false
  // hasAffiliation: hasAffiliation(state)
}))(withTranslation()(Charges));
