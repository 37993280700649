import React, { Component } from "react";
import AceEditor from "react-ace";
import * as R from "ramda";

import { Spinner } from "@shopify/polaris";

import "ace-builds/src-noconflict/mode-xml";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-searchbox";

import "./style.scss";

export default class AssetCode extends Component {
  state = {
    value: "",
    width: 0
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateWidth);
    this.updateWidth();
  }

  componentDidUpdate(prevProps) {
    const { asset, onChange } = this.props;
    const { width } = this.state;

    if (!R.equals(asset, prevProps.asset)) {
      const value = R.propOr("", "value", asset);
      this.setState({ value }, () => {
        if (onChange) {
          onChange(value);
        }
      });
    }

    const containerWidth = this.container
      ? this.container.getBoundingClientRect().width
      : 0;

    if (width !== containerWidth) {
      this.updateWidth();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth);
  }

  onChange = value => {
    const { onChange } = this.props;
    this.setState({ value }, () => {
      if (onChange) {
        onChange(value);
      }
    });
  };

  updateWidth = () => {
    if (this.container) {
      this.setState({
        width: this.container.getBoundingClientRect().width
      });
    }
  };

  render() {
    const { isLoading } = this.props;
    const { value, width } = this.state;

    if (isLoading) {
      return (
        <div className="AssetCode full-flex-center">
          <Spinner size="large" color="teal" />
        </div>
      );
    }

    return (
      <div className="AssetCode w100" ref={ref => (this.container = ref)}>
        <AceEditor
          style={{ width, height: 650 }}
          mode="xml"
          theme="tomorrow"
          onChange={this.onChange}
          name="ASSET_CODE"
          value={value}
          editorProps={{ $blockScrolling: true }}
        />
      </div>
    );
  }
}
