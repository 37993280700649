export const collectionsInitState = [];

export default (state = {}, action) => {
  switch (action.type) {
    case "COLLECTIONS_GET_SHOPIFY_COLLECTIONS_SUCCESS":
      return action.payload.data;
    default:
      return state;
  }
};
