export const DEFAULT_MODAL_SETTINGS_VALUES = {
  addCartBackgroundColor: "#e63235",
  addCartLabelColor: "#ffffff",
  addCartValue: "ADD TO CART",
  addToCartBorderRadius: 4,

  replaceCartBackgroundColor: "#e63235",
  replaceCartLabelColor: "#ffffff",
  replaceCartValue: "REPLACE",

  cancelBackgroundColor: "#ffffff",
  cancelLabelColor: "#9ca0a6",
  cancelValue: "No thanks",
  modalBackgroundColor: "#fff",
  modalPadding: 32,
  modalBorderRadius: 10,

  priceTextColor: "#9ca0a6",
  priceTextFontSize: "20px",
  priceTextFontStyle: "normal bold",

  discountPriceTextColor: "#e63235",
  discountPriceTextFontSize: "20px",
  discountPriceTextFontStyle: "normal bold",

  subtitleColor: "#212b36",
  subtitleFontSize: "inherit",
  subtitleFontStyle: "normal normal",

  titleColor: "#171c8e",
  titleFontSize: "24px",
  titleFontStyle: "normal bold",

  productDescriptionColor: "#000",
  productDescriptionFontSize: "inherit",
  productDescriptionFontStyle: "normal normal",

  productNameColor: "#000",
  productNameFontSize: "inherit",
  productNameFontStyle: "normal bold",

  isOverridingDiscountCartStyle: false,
  originalPriceColor: "#000000",
  originalPriceFontSize: "inherit",
  originalPriceFontStyle: "inherit",
  savedAmountPriceColor: "#000000",
  savedAmountPriceFontSize: "inherit",
  savedAmountPriceFontStyle: "inherit",
  discountPriceColor: "#000000",
  discountPriceFontSize: "inherit",
  discountPriceFontStyle: "inherit",
  discountNoteColor: "#000000",
  discountNoteFontSize: "inherit",
  discountNoteFontStyle: "inherit",

  variantOptionLabelColor: "#000000",
  variantOptionLabelFontSize: "inherit",
  variantOptionLabelFontStyle: "normal bold",
  variantOptionButtonPaddingLeftRight: 8,
  variantOptionButtonPaddingTopBottom: 2,
  variantOptionButtonBackgroundColor: "#FFFFFF",
  variantOptionButtonSelectedBackgroundColor: "#6e9fffff",
  variantOptionButtonSelectedColor: "#FFFFFF",
  variantOptionButtonColor: "#000000",
  variantOptionButtonFontSize: "inherit",
  variantOptionButtonFontStyle: "normal normal",
  variantOptionButtonBorderColor: "#000000",
  variantOptionButtonSelectedBorderColor: "#000000",
  variantOptionButtonBorderRadius: 3,
  variantOptionSwatchSize: 28,
  variantOptionSwatchBorderRadius: 100,
  variantOptionSwatchBorderColor: "#f2f2f2",
  variantOptionSwatchSelectedBorderColor: "#000000",
  variantOptionSwatchEnableTooltip: false,
  variantLabelOutOfStockValue: ""
};

export const DEFAULT_ORDER_CONFIRMATION_GRID_SETTINGS_VALUES = {
  addOrderBackgroundColor: "#e63235",
  addOrderLabelColor: "#ffffff",
  addOrderValue: "ADD TO ORDER",
  moreInfoBackgroundColor: "#ffffff",
  moreInfoLabelColor: "#545454",
  moreInfoValue: "MORE INFO",

  priceTextColor: "#9ca0a6",
  priceTextFontSize: "16px",
  priceTextFontStyle: "normal bold",

  discountPriceTextColor: "#e63235",
  discountPriceTextFontSize: "16px",
  discountPriceTextFontStyle: "normal bold",

  titleColor: "#171c8e",
  titleFontSize: "24px",
  titleFontStyle: "normal bold",

  subtitleColor: "#212b36",
  subtitleFontSize: "inherit",
  subtitleFontStyle: "normal normal",

  productNameColor: "#000",
  productNameFontSize: "inherit",
  productNameFontStyle: "normal bold",

  position: "after_first",
  imageHeight: 190
};
