import React, { Component } from "react";
import { TextField, PageActions } from "@shopify/polaris";
import Select from "react-select";

class AffiliationForm extends Component {
  state = {
    firstName: this.props.defaultValues.firstName || "",
    lastName: this.props.defaultValues.lastName || "",
    email: this.props.defaultValues.email || "",
    affiliationShop: this.props.defaultValues.affiliationShop || "",
    affiliationCode: this.props.defaultValues.affiliationCode || "",
    affiliationPercent: this.props.defaultValues.affiliationPercent || 0
  };

  onChangeValue = key => value => this.setState({ [key]: value });

  onSubmit = () => {
    const { onSubmit } = this.props;
    if (onSubmit) {
      onSubmit({
        ...this.state,
        affiliationCode: this.state.affiliationCode.toLowerCase()
      });
    }
  };

  onCancel = () => {
    const { onCancel } = this.props;
    if (onCancel) {
      onCancel();
    }
  };

  render() {
    const {
      storesOptions,
      isSubmitting,
      isLoading,
      labelSubmit,
      onDelete
    } = this.props;
    const {
      firstName,
      lastName,
      email,
      affiliationShop,
      affiliationCode,
      affiliationPercent
    } = this.state;

    const isDisabled =
      !firstName ||
      !lastName ||
      !email ||
      !affiliationShop ||
      !affiliationCode ||
      !affiliationPercent;

    const secondaryActions = [
      {
        content: "Cancel",
        onClick: this.onCancel
      }
    ];

    if (onDelete) {
      secondaryActions.push({
        content: "Delete",
        onClick: onDelete,
        destructive: true
      });
    }

    return (
      <div className="AffiliationForm">
        <TextField
          label="First name"
          value={firstName}
          onChange={this.onChangeValue("firstName")}
        />
        <div className="mt2">
          <TextField
            label="Last name"
            value={lastName}
            onChange={this.onChangeValue("lastName")}
          />
        </div>
        <div className="mt2">
          <TextField
            label="Email"
            value={email}
            onChange={this.onChangeValue("email")}
          />
        </div>
        <div className="mt2">
          <div className="mb1">Affiliation shop</div>
          <Select
            isSearchable
            label="Affiliation shop"
            options={storesOptions}
            isLoading={isLoading}
            value={affiliationShop}
            onChange={this.onChangeValue("affiliationShop")}
            styles={{
              container: base => ({
                ...base,
                zIndex: "500"
              })
            }}
          />
        </div>
        <div className="mt2">
          <TextField
            label="Affiliation code"
            value={affiliationCode}
            onChange={this.onChangeValue("affiliationCode")}
          />
        </div>
        <div className="mt2">
          <TextField
            label="Affiliation percentage (%)"
            value={affiliationPercent}
            type="number"
            onChange={this.onChangeValue("affiliationPercent")}
          />
        </div>
        <PageActions
          primaryAction={{
            content: labelSubmit,
            onClick: this.onSubmit,
            loading: isSubmitting,
            disabled: isDisabled
          }}
          secondaryActions={secondaryActions}
        />
      </div>
    );
  }
}

export default AffiliationForm;
