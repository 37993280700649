import React, { Component } from "react";

import { Page } from "@shopify/polaris";

class AdminDashboard extends Component {
  render() {
    return (
      <Page title="Admin Dashboard" separator>
        Work in progress
      </Page>
    );
  }
}

export default AdminDashboard;
