import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import * as R from "ramda";

import Select from "react-select";
import { TextField } from "@shopify/polaris";
import Switch from "components/Switch";

import {
  Page,
  Layout,
  Loading,
  Card,
  SkeletonBodyText,
  Toast
} from "@shopify/polaris";

import { getStoreId } from "selectors/auth";

import {
  fetchConfiguration,
  updateConfiguration
} from "actions/configurations";

import "./style.scss";

class GeneralSettings extends Component {
  state = {
    config: {},
    isLoading: true,
    isSaving: false,
    delayCancelModal: 0,
    modalPageType: null,
    scriptApplied: null,
    redirectToCheckout: false,
    hideAdditionalCheckoutButtons: false,
    showToast: false,
    isUpdating: false
  };

  componentDidMount() {
    const { storeId, fetchConfiguration } = this.props;
    fetchConfiguration(storeId)
      .then(res => {
        this.setState({
          config: res.data.configuration,
          modalPageType: this.getOptions().find(
            o => o.value === res.data.configuration.modal_page_type
          ),
          delayCancelModal: this.getDelayCancelModalOptions().find(
            o => o.value === res.data.configuration.delay_cancel_modal
          ),
          scriptApplied: res.data.configuration.script_applied,
          hideAdditionalCheckoutButtons:
            res.data.configuration.hide_additional_checkout_buttons,
          redirectToCheckout: res.data.configuration.redirect_to_checkout
        });

        return res;
      })
      .then(() => this.setState({ isLoading: false }));
  }

  getOptions = () => {
    const { t } = this.props;

    return [
      {
        label: t(
          "upsellModalApp.configuration.general.sections.0.options.0.label"
        ),
        value: "cart"
      },
      {
        label: t(
          "upsellModalApp.configuration.general.sections.0.options.1.label"
        ),
        value: "product"
      },
      {
        label: t(
            "upsellModalApp.configuration.general.sections.0.options.2.label"
        ),
        value: "checkout-button"
      }
    ];
  };

  getDelayCancelModalOptions = () => {
    const { t } = this.props;

    return [
      {
        label: t(
          "upsellModalApp.configuration.general.sections.0.delayCancelModal.options.0"
        ),
        value: 0
      },
      {
        label: t(
          "upsellModalApp.configuration.general.sections.0.delayCancelModal.options.1"
        ),
        value: 5
      },
      {
        label: t(
          "upsellModalApp.configuration.general.sections.0.delayCancelModal.options.2"
        ),
        value: 10
      },
      {
        label: t(
          "upsellModalApp.configuration.general.sections.0.delayCancelModal.options.3"
        ),
        value: 30
      },
      {
        label: t(
          "upsellModalApp.configuration.general.sections.0.delayCancelModal.options.4"
        ),
        value: 60
      },
      {
        label: t(
          "upsellModalApp.configuration.general.sections.0.delayCancelModal.options.5"
        ),
        value: 120
      },
      {
        label: t(
          "upsellModalApp.configuration.general.sections.0.delayCancelModal.options.6"
        ),
        value: 1440
      }
    ];
  };

  onChangeModalType = value => {
    this.setState({ modalPageType: value });
  };

  onChangeDelayCancelModal = value => {
    this.setState({ delayCancelModal: value });
  };

  onSave = () => {
    const { storeId, updateConfiguration } = this.props;
    const {
      modalPageType,
      config,
      hideAdditionalCheckoutButtons,
      scriptApplied,
      redirectToCheckout,
      delayCancelModal
    } = this.state;
    const { id: configId } = config;

    this.setState({ isSaving: true, showToast: false }, () => {
      updateConfiguration(configId, storeId, {
        modal_page_type: modalPageType.value,
        delay_cancel_modal: delayCancelModal.value,
        hide_additional_checkout_buttons: hideAdditionalCheckoutButtons,
        script_applied: scriptApplied,
        redirect_to_checkout:
          modalPageType.value === "product" && redirectToCheckout
      })
        .then(res => {
          this.setState({
            config: res.data,
            redirectToCheckout:
              modalPageType.value === "product" && redirectToCheckout,
            isSaving: false,
            showToast: true
          });
        })
        .catch(err => {
          this.setState({ isSaving: false });
        });
    });
  };

  toggleToast = () =>
    this.setState(({ showToast }) => ({ showToast: !showToast }));

  togglePaypal = value =>
    this.setState({ hideAdditionalCheckoutButtons: value });

  toggleRedirectToCheckout = value =>
    this.setState({ redirectToCheckout: value });

  onChangeScript = value => this.setState({ scriptApplied: value });

  render() {
    const { t } = this.props;
    const {
      isLoading,
      modalPageType,
      scriptApplied,
      hideAdditionalCheckoutButtons,
      redirectToCheckout,
      showToast,
      config,
      isUpdating,
      delayCancelModal
    } = this.state;

    const shouldDisableAddButton =
      config.script_applied === scriptApplied &&
      modalPageType &&
      config.modal_page_type === modalPageType.value &&
      config.hide_additional_checkout_buttons ===
        hideAdditionalCheckoutButtons &&
      config.redirect_to_checkout === redirectToCheckout &&
      delayCancelModal.value === config.delay_cancel_modal;

    return (
      <Page
        title={t("upsellModalApp.configuration.general.title")}
        separator
        primaryAction={{
          content: t("upsellModalApp.configuration.general.submitButton"),
          disabled: shouldDisableAddButton || isLoading,
          onClick: this.onSave,
          loading: isUpdating
        }}
      >
        <div className="GeneralSettings">
          {isLoading && <Loading />}

          {showToast && (
            <Toast
              content={t("upsellModalApp.configuration.general.toastSuccess")}
              onDismiss={this.toggleToast}
            />
          )}

          {isLoading ? (
            <Layout>
              <Layout.AnnotatedSection
                title={t(
                  "upsellModalApp.configuration.general.sections.0.title"
                )}
              >
                <Card sectioned>
                  <SkeletonBodyText lines={4} />
                </Card>
              </Layout.AnnotatedSection>
            </Layout>
          ) : (
            <Layout>
              <Layout.AnnotatedSection
                title={t(
                  "upsellModalApp.configuration.general.sections.0.title"
                )}
              >
                <Card sectioned>
                  <div style={{ minHeight: 150 }}>
                    <div>
                      <div className="mb1">
                        {t(
                          "upsellModalApp.configuration.general.sections.0.label"
                        )}
                      </div>
                      <div className="flex">
                        <Select
                          className="w100 mr2"
                          value={modalPageType}
                          options={this.getOptions()}
                          onChange={this.onChangeModalType}
                        />
                      </div>
                    </div>
                    {R.prop("value", modalPageType) === "product" && (
                      <div>
                        <div className="mb1 mt3">
                          {t(
                            "upsellModalApp.configuration.general.sections.0.others.0.label"
                          )}
                        </div>
                        <div>
                          <Switch
                            onChange={this.toggleRedirectToCheckout}
                            checked={redirectToCheckout}
                            disabled={isUpdating}
                          />
                        </div>
                      </div>
                    )}

                    <div className="mt3">
                      <div className="mb1">
                        {t(
                          "upsellModalApp.configuration.general.sections.0.delayCancelModal.label"
                        )}
                      </div>
                      <div className="flex">
                        <Select
                          className="w100 mr2"
                          value={delayCancelModal}
                          options={this.getDelayCancelModalOptions()}
                          onChange={this.onChangeDelayCancelModal}
                          styles={{
                            menu: provided => ({
                              ...provided,
                              zIndex: 9999
                            })
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Card>
              </Layout.AnnotatedSection>
              <Layout.AnnotatedSection
                title={t(
                  "upsellModalApp.configuration.general.sections.1.title"
                )}
              >
                <Card sectioned>
                  <div style={{ minHeight: 100 }}>
                    <TextField
                      showCharacterCount
                      value={scriptApplied}
                      onChange={this.onChangeScript}
                      multiline={4}
                      maxLength={2000}
                      label={t(
                        "upsellModalApp.configuration.general.sections.1.labelScript"
                      )}
                      helpText={t(
                        "upsellModalApp.configuration.general.sections.1.hintTextScript"
                      )}
                    />
                    <div className="mb1 mt3">
                      {t(
                        "upsellModalApp.configuration.general.sections.1.label"
                      )}
                    </div>
                    <div>
                      <Switch
                        onChange={this.togglePaypal}
                        checked={hideAdditionalCheckoutButtons}
                        disabled={isUpdating}
                      />
                      <div className="mt1 hintText">
                        {t(
                          "upsellModalApp.configuration.general.sections.1.hintText"
                        )}
                      </div>
                    </div>
                  </div>
                </Card>
              </Layout.AnnotatedSection>
            </Layout>
          )}
        </div>
      </Page>
    );
  }
}

export default connect(
  state => ({
    storeId: getStoreId(state)
  }),
  {
    fetchConfiguration,
    updateConfiguration
  }
)(withTranslation()(GeneralSettings));
