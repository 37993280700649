const BASIC_PRODUCT_ID = process.env.REACT_APP_STRIPE_PLAN_ID;

export const PRODUCT_PLANS = [
  {
    id: BASIC_PRODUCT_ID,
    price: 19.9,
    trialDays: 30,
    affiliateTrialDays: 30
  }
];
