import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Page,
  Card,
  DataTable,
  Loading,
  Badge,
  SkeletonBodyText,
  Layout
} from "@shopify/polaris";

import { getUserAffiliates, getAffiliation } from "actions/affiliations";
import { getStoreId } from "selectors/auth";
import AffiliationUrlCopy from "./AffiliationUrlCopy";

import { calculateAffiliationPotentialGain } from "../utils";

class MyAffiliations extends Component {
  state = {
    affiliates: [],
    affiliation: {},
    isLoading: true
  };

  componentDidMount() {
    const { storeId, getUserAffiliates } = this.props;

    getUserAffiliates(storeId)
      .then(res =>
        this.setState({
          isLoading: false,
          affiliates: res.data.affiliates,
          affiliation: res.data.affiliation
        })
      )
      .catch(() => this.setState({ isLoading: false }));
  }

  getBadgeStatus = status => {
    switch (status) {
      case "active":
        return "success";
      case "trialing":
        return "default";
      default:
        return "warning";
    }
  };

  calculatePotentialGain = () => {
    const { affiliates, affiliation } = this.state;
    return calculateAffiliationPotentialGain(affiliates, affiliation);
  };

  render() {
    const { affiliates, affiliation, isLoading } = this.state;

    const potentialGain = this.calculatePotentialGain();

    const affiliationUrl = `${process.env.REACT_APP_CLIENT_URL}/login?via=${affiliation.affiliation_code}`;

    const rows = affiliates.map(a => [
      a.name,
      <Badge status={this.getBadgeStatus(a.subscription_status)}>
        {a.subscription_status}
      </Badge>
    ]);

    return (
      <Page title="My affiliations" separator>
        {isLoading ? (
          <Card sectioned>
            <Loading />
            <SkeletonBodyText />
          </Card>
        ) : (
          <div>
            <Layout>
              <Layout.AnnotatedSection title="General information">
                <Card sectioned>
                  <div className="flex flex-center flex-content-between">
                    <div className="bold">Number of affiliates:</div>
                    <div className="c-blue-500 bold">{affiliates.length}</div>
                  </div>
                  <div className="flex flex-center flex-content-between mt1">
                    <div className="bold">Affiliation percentage:</div>
                    <div className="c-blue-500 bold">
                      {affiliation.affiliation_percent}%
                    </div>
                  </div>
                  <div className="flex flex-center flex-content-between mt1">
                    <div className="bold">Potential gain:</div>
                    <div className="c-blue-500 bold">
                      {potentialGain}€ / month
                    </div>
                  </div>
                </Card>
              </Layout.AnnotatedSection>

              <Layout.AnnotatedSection title="Get your affiliation link">
                <Card sectioned>
                  <AffiliationUrlCopy affiliationUrl={affiliationUrl} />
                </Card>
              </Layout.AnnotatedSection>

              <Layout.AnnotatedSection title="Your affiliates">
                <Card>
                  <DataTable
                    columnContentTypes={["text", "text"]}
                    headings={["Shop name", "Subscription status"]}
                    rows={rows}
                  />
                </Card>
              </Layout.AnnotatedSection>
            </Layout>
          </div>
        )}
      </Page>
    );
  }
}

export default connect(
  state => ({ storeId: getStoreId(state) }),
  {
    getUserAffiliates,
    getAffiliation
  }
)(MyAffiliations);
