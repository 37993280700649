import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import * as R from "ramda";
import { Card, Layout, RangeSlider } from "@shopify/polaris";
import Select from "react-select";

import TextFieldWithColor from "components/TextFieldWithColor";
import ColorPickerWithFont from "components/ColorPickerWithFont";
import Switch from "components/Switch";

import "./style.scss";

class OrderConfirmationSettingsForm extends Component {
  static propTypes = {
    onChange: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      titleColor: R.propOr("#dedede", "titleColor", this.props.config),
      titleFontSize: R.prop("titleFontSize", this.props.config),
      titleFontStyle: R.prop("titleFontStyle", this.props.config),
      subtitleColor: R.propOr("#000000", "subtitleColor", this.props.config),
      subtitleFontSize: R.prop("subtitleFontSize", this.props.config),
      subtitleFontStyle: R.prop("subtitleFontStyle", this.props.config),
      productNameColor: R.propOr("#000", "productNameColor", this.props.config),
      productNameFontSize: R.prop("productNameFontSize", this.props.config),
      productNameFontStyle: R.prop("productNameFontStyle", this.props.config),
      addOrderLabelColor: R.propOr(
        "#dedede",
        "addOrderLabelColor",
        this.props.config
      ),
      addOrderBackgroundColor: R.propOr(
        "#dedede",
        "addOrderBackgroundColor",
        this.props.config
      ),
      addOrderValue: R.propOr(
        "ADD TO ORDER",
        "addOrderValue",
        this.props.config
      ),
      moreInfoLabelColor: R.propOr(
        "#dedede",
        "moreInfoLabelColor",
        this.props.config
      ),
      moreInfoBackgroundColor: R.propOr(
        "#dedede",
        "moreInfoBackgroundColor",
        this.props.config
      ),
      moreInfoValue: R.propOr("MORE INFO", "moreInfoValue", this.props.config),
      hideMoreInfoButton: R.propOr(
        false,
        "hideMoreInfoButton",
        this.props.config
      ),
      priceTextColor: R.propOr("#dedede", "priceTextColor", this.props.config),
      priceTextFontSize: R.prop("priceTextFontSize", this.props.config),
      priceTextFontStyle: R.prop("priceTextFontStyle", this.props.config),
      discountPriceTextColor: R.propOr(
        "#dedede",
        "discountPriceTextColor",
        this.props.config
      ),
      discountPriceTextFontSize: R.prop(
        "discountPriceTextFontSize",
        this.props.config
      ),
      discountPriceTextFontStyle: R.prop(
        "discountPriceTextFontStyle",
        this.props.config
      ),
      position: R.propOr(
        this.getPositionOptions()[1].value,
        "position",
        this.props.config
      ),
      imageHeight: R.propOr(
          190,
          "imageHeight",
          this.props.config
      )
    };
  }

  componentDidUpdate(prevProps) {
    const { config } = this.props;
    if (!R.equals(config, prevProps.config)) {
      this.setState({ ...config, position: this.getPositionValue() });
    }
  }

  getPositionValue = () =>
    this.getPositionOptions().find(
      o => o.value === R.prop("position", this.props.config)
    ) || this.getPositionOptions()[1];

  getPositionOptions = () => {
    const { t } = this.props;
    return [
      {
        label: t(
          "orderConfirmationApp.configuration.others.position.options.first"
        ),
        value: "first"
      },
      {
        label: t(
          "orderConfirmationApp.configuration.others.position.options.after_first"
        ),
        value: "after_first"
      },
      {
        label: t(
          "orderConfirmationApp.configuration.others.position.options.before_last"
        ),
        value: "before_last"
      },
      {
        label: t(
          "orderConfirmationApp.configuration.others.position.options.last"
        ),
        value: "last"
      }
    ];
  };

  onChangeColor = field => colorHex => {
    const { onChange } = this.props;
    this.setState({ [field]: colorHex }, () => onChange(this.state));
  };

  onChangeValue = field => value => {
    const { onChange } = this.props;
    this.setState({ [field]: value }, () => onChange(this.state));
  };

  render() {
    const { t } = this.props;
    const {
      titleColor,
      titleFontSize,
      titleFontStyle,
      subtitleColor,
      subtitleFontSize,
      subtitleFontStyle,
      productNameColor,
      productNameFontSize,
      productNameFontStyle,
      addOrderLabelColor,
      addOrderBackgroundColor,
      addOrderValue,
      moreInfoLabelColor,
      moreInfoBackgroundColor,
      moreInfoValue,
      priceTextColor,
      priceTextFontSize,
      priceTextFontStyle,
      discountPriceTextColor,
      discountPriceTextFontSize,
      discountPriceTextFontStyle,
      hideMoreInfoButton,
      position,
      imageHeight
    } = this.state;

    const selectedPositionOption = this.getPositionOptions().find(
      o => o.value === position
    );

    return (
      <div className="OrderConfirmationSettingsForm w100 mr2 mb4">
        <Layout>
          <Layout.AnnotatedSection
            title={t("orderConfirmationApp.configuration.form.buttons.title")}
            description={t(
              "orderConfirmationApp.configuration.form.buttons.description"
            )}
          >
            <Card sectioned>
              <div className="mb2">
                <span className="mr2">
                  {t(
                    "orderConfirmationApp.configuration.form.buttons.inputs.switchMoreInfoButton.label"
                  )}
                </span>
                <Switch
                  onChange={this.onChangeValue("hideMoreInfoButton")}
                  checked={hideMoreInfoButton}
                />
              </div>
              <TextFieldWithColor
                label={t(
                  "orderConfirmationApp.configuration.form.buttons.inputs.moreInfoButton.label"
                )}
                color={moreInfoLabelColor}
                secondColor={moreInfoBackgroundColor}
                value={moreInfoValue}
                onChange={this.onChangeValue("moreInfoValue")}
                onChangeColor={this.onChangeColor("moreInfoLabelColor")}
                onChangeSecondColor={this.onChangeColor(
                  "moreInfoBackgroundColor"
                )}
                hintLabel={t("generic.form.textColor")}
                secondHintLabel={t("generic.form.backgroundColor")}
              />
              <TextFieldWithColor
                className="mt2"
                label={t(
                  "orderConfirmationApp.configuration.form.buttons.inputs.addToOrderButton.label"
                )}
                color={addOrderLabelColor}
                secondColor={addOrderBackgroundColor}
                value={addOrderValue}
                onChange={this.onChangeValue("addOrderValue")}
                onChangeColor={this.onChangeColor("addOrderLabelColor")}
                onChangeSecondColor={this.onChangeColor(
                  "addOrderBackgroundColor"
                )}
                hintLabel={t("generic.form.textColor")}
                secondHintLabel={t("generic.form.backgroundColor")}
              />
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title={t("orderConfirmationApp.configuration.form.texts.title")}
            description={t(
              "orderConfirmationApp.configuration.form.texts.description"
            )}
          >
            <Card sectioned>
              <div className="flex flex-center">
                <div className="labelField">
                  {t(
                    "orderConfirmationApp.configuration.form.texts.inputs.title.label"
                  )}
                </div>
                <ColorPickerWithFont
                  color={titleColor}
                  onChange={this.onChangeColor("titleColor")}
                  fontSize={titleFontSize}
                  onChangeFontSize={this.onChangeValue("titleFontSize")}
                  fontStyle={titleFontStyle}
                  onChangeFontStyle={this.onChangeValue("titleFontStyle")}
                  className="ml1"
                />
              </div>

              <div className="flex flex-center mt2">
                <div className="labelField">
                  {t(
                    "orderConfirmationApp.configuration.form.texts.inputs.subtitle.label"
                  )}
                </div>
                <ColorPickerWithFont
                  color={subtitleColor}
                  onChange={this.onChangeColor("subtitleColor")}
                  fontSize={subtitleFontSize}
                  onChangeFontSize={this.onChangeValue("subtitleFontSize")}
                  fontStyle={subtitleFontStyle}
                  onChangeFontStyle={this.onChangeValue("subtitleFontStyle")}
                  className="ml1"
                />
              </div>

              <div className="flex flex-center mt2">
                <div className="labelField">
                  {t(
                    "orderConfirmationApp.configuration.form.texts.inputs.productName.label"
                  )}
                </div>
                <ColorPickerWithFont
                  color={productNameColor}
                  onChange={this.onChangeColor("productNameColor")}
                  fontSize={productNameFontSize}
                  onChangeFontSize={this.onChangeValue("productNameFontSize")}
                  fontStyle={productNameFontStyle}
                  onChangeFontStyle={this.onChangeValue("productNameFontStyle")}
                  className="ml1"
                />
              </div>

              <div className="flex flex-center mt2">
                <div className="labelField">
                  {" "}
                  {t(
                    "orderConfirmationApp.configuration.form.texts.inputs.priceText.label"
                  )}
                </div>
                <ColorPickerWithFont
                  color={priceTextColor}
                  onChange={this.onChangeColor("priceTextColor")}
                  fontSize={priceTextFontSize}
                  onChangeFontSize={this.onChangeValue("priceTextFontSize")}
                  fontStyle={priceTextFontStyle}
                  onChangeFontStyle={this.onChangeValue("priceTextFontStyle")}
                  className="ml1"
                />
              </div>

              <div className="flex flex-center mt2">
                <div className="labelField">
                  {t(
                    "orderConfirmationApp.configuration.form.texts.inputs.discountPriceText.label"
                  )}
                </div>
                <ColorPickerWithFont
                  color={discountPriceTextColor}
                  onChange={this.onChangeColor("discountPriceTextColor")}
                  fontSize={discountPriceTextFontSize}
                  onChangeFontSize={this.onChangeValue(
                    "discountPriceTextFontSize"
                  )}
                  fontStyle={discountPriceTextFontStyle}
                  onChangeFontStyle={this.onChangeValue(
                    "discountPriceTextFontStyle"
                  )}
                  className="ml1"
                />
              </div>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title={t("orderConfirmationApp.configuration.others.title")}
            description={t(
              "orderConfirmationApp.configuration.others.description"
            )}
          >
            <Card sectioned>
              <div>
              <div className="mb1">
                {t("orderConfirmationApp.configuration.others.position.title")}
              </div>
              <Select
                className="PreviewOrderConfirmationGrid--select mb2"
                options={this.getPositionOptions()}
                onChange={this.onChangeValue("position")}
                value={selectedPositionOption}
              />
              </div>

              <div className="mt3">
                <RangeSlider
                    label={t("orderConfirmationApp.configuration.others.imageHeight.title")}
                    value={imageHeight}
                    onChange={this.onChangeValue('imageHeight')}
                    min={0}
                    max={300}
                    output
                />
              </div>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
      </div>
    );
  }
}

export default withTranslation()(OrderConfirmationSettingsForm);
