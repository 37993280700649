import React, { Component } from "react";
import { Navigation } from "@shopify/polaris";
import {
  AnalyticsMajor,
  AffiliateMajor,
  CodeMajor
} from "@shopify/polaris-icons";

export default class AdminNavigationSection extends Component {
  render() {
    const { location } = this.props;

    return (
      <Navigation.Section
        separator
        title="Admin"
        items={[
          {
            label: "Dashboard",
            icon: AnalyticsMajor,
            url: "/admin/dashboard",
            selected: location.pathname.startsWith("/admin/dashboard")
          },
          {
            label: "Affiliations",
            icon: AffiliateMajor,
            url: "/admin/affiliations",
            selected: location.pathname.startsWith("/admin/affiliations")
          },
          {
            label: "Edit templates",
            icon: CodeMajor,
            url: "/admin/edit-templates",
            selected: location.pathname.startsWith("/admin/edit-templates")
          }
        ]}
      />
    );
  }
}
