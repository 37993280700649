import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Card, Icon, Button, Banner } from "@shopify/polaris";
import { TickSmallMinor } from "@shopify/polaris-icons";

import { createShopifyRecurringApplicationCharge } from "actions/shopifyCharges";
import { getStoreId } from "selectors/auth";

import "./style.scss";

class ShopifyPlanItem extends Component {
  state = {
    isLoading: false
  };

  onSubmit = () => {
    const { storeId, createShopifyRecurringApplicationCharge } = this.props;

    this.setState({ isLoading: true }, () => {
      createShopifyRecurringApplicationCharge(storeId)
        .then(res => {
          window.location.href = res.data.confirmation_url;
        })
        .catch(() => {
          this.setState({ isLoading: false });
        })
        .then(() => this.setState({ isLoading: false }));
    });
  };

  render() {
    const {
      name,
      details,
      price,
      trialDays,
      affiliateTrialDays,
      hasAffiliation,
      hideTrialDays,
      isActive,
      t
    } = this.props;
    const { isLoading } = this.state;

    return (
      <Card sectioned>
        <div className="ShopifyPlanItem">
          <div className="name">{name}</div>
          {!hideTrialDays && (
            <div className="trialDays mt1">
              {hasAffiliation ? (
                <div className="flex">
                  <div style={{ textDecoration: "line-through" }}>
                    {t("charges.trialDays", { trialDays: trialDays })}
                  </div>
                  <div className="c-blue-500 ml1 bold">
                    {t("charges.trialDays", { trialDays: affiliateTrialDays })}
                  </div>
                </div>
              ) : (
                <div>{t("charges.trialDays", { trialDays: trialDays })}</div>
              )}
            </div>
          )}
          <div className="price mt3">
            {t("charges.plans.basic.price", {
              price: parseFloat(price).toFixed(2)
            })}
          </div>
          <div className="description mt3 mb3">
            {details.map((description, index) => (
              <div key={index} className="description--item flex mt1">
                <Icon source={TickSmallMinor} color="green" />
                <div className="ml2">{description}</div>
              </div>
            ))}
          </div>

          {isActive ? (
            <Banner status="success">{t("charges.subscribed")}</Banner>
          ) : (
            <Button
              primary
              fullWidth
              loading={isLoading}
              onClick={this.onSubmit}
            >
              {t("charges.plans.basic.selectButton")}
            </Button>
          )}
        </div>
      </Card>
    );
  }
}

export default connect(
  state => ({ storeId: getStoreId(state) }),
  { createShopifyRecurringApplicationCharge }
)(withRouter(withTranslation()(ShopifyPlanItem)));
