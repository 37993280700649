import React from "react";
import { useTranslation, Trans } from "react-i18next";
import PlanBox from "./PlanBox";

import "./style.scss";

export default function PricingSection() {
  const { t } = useTranslation();

  const CONFIG_PLANS = [
    {
      title: t("homepage.pricingSection.plans.0.title"),
      price: `0$`,
      description: t("homepage.pricingSection.plans.0.description"),
      borderTopStyle: "10px solid #DEDEDE",
      buttonLabel: t("homepage.pricingSection.plans.0.buttonLabel"),
      rows: [
        {
          label: t("homepage.pricingSection.plans.0.features.0")
        },
        {
          label: t("homepage.pricingSection.plans.0.features.1")
        },
        {
          label: t("homepage.pricingSection.plans.0.features.2")
        },
        {
          label: t("homepage.pricingSection.plans.0.features.3")
        }
      ]
    },
    {
      title: t("homepage.pricingSection.plans.1.title"),
      price: `19.99$`,
      description: (
        <Trans i18nKey={"homepage.pricingSection.plans.1.description"}>
          <s>39.99$</s>
        </Trans>
      ),
      borderTopStyle: "10px solid #27A3FC",
      buttonLabel: t("homepage.pricingSection.plans.1.buttonLabel"),
      rows: [
        {
          label: t("homepage.pricingSection.plans.1.features.0"),
          style: {
            fontWeight: 700
          }
        },
        {
          label: t("homepage.pricingSection.plans.1.features.1"),
          style: {
            fontWeight: 700
          }
        },
        {
          label: t("homepage.pricingSection.plans.1.features.2"),
          style: {
            fontWeight: 700
          }
        },
        {
          label: t("homepage.pricingSection.plans.1.features.3")
        },
        {
          label: t("homepage.pricingSection.plans.1.features.4")
        }
      ]
    },
    {
      title: t("homepage.pricingSection.plans.2.title"),
      price: `29.99$`,
      description: (
        <Trans i18nKey={"homepage.pricingSection.plans.2.description"}>
          <s>39.99$</s>
        </Trans>
      ),
      borderTopStyle: "10px solid #27A3FC",
      buttonLabel: t("homepage.pricingSection.plans.2.buttonLabel"),
      rows: [
        {
          label: t("homepage.pricingSection.plans.2.features.0"),
          style: {
            fontWeight: 700
          }
        },
        {
          label: t("homepage.pricingSection.plans.2.features.1"),
          style: {
            fontWeight: 700
          }
        },
        {
          label: t("homepage.pricingSection.plans.2.features.2"),
          style: {
            fontWeight: 700
          }
        },
        {
          label: t("homepage.pricingSection.plans.2.features.3")
        },
        {
          label: t("homepage.pricingSection.plans.2.features.4")
        }
      ]
    },
    {
      title: t("homepage.pricingSection.plans.3.title"),
      price: `44.99$`,
      description: (
        <Trans i18nKey={"homepage.pricingSection.plans.3.description"}>
          <s>39.99$</s>
        </Trans>
      ),
      borderTopStyle: "10px solid #27A3FC",
      buttonLabel: t("homepage.pricingSection.plans.3.buttonLabel"),
      rows: [
        {
          label: t("homepage.pricingSection.plans.3.features.0"),
          style: {
            fontWeight: 700
          }
        },
        {
          label: t("homepage.pricingSection.plans.3.features.1"),
          style: {
            fontWeight: 700
          }
        },
        {
          label: t("homepage.pricingSection.plans.3.features.2"),
          style: {
            fontWeight: 700
          }
        },
        {
          label: t("homepage.pricingSection.plans.3.features.3")
        },
        {
          label: t("homepage.pricingSection.plans.3.features.4")
        }
      ]
    }
  ];
  return (
    <section className="PricingSection" id="pricingSection">
      <div className="container flex flex-content-center flex-column flex-center">
        <div
          className="sectionTitle"
          data-aos="fade-down"
          data-aos-offset="200"
          data-aos-easing="ease"
          data-aos-duration="500"
          data-aos-anchor="#pricingSection"
        >
          <h3 className="title"> {t("homepage.pricingSection.mainTitle")}</h3>
          <p className="subtitle">{t("homepage.pricingSection.description")}</p>
        </div>
        <div className="mt4 flex flex-content-around flex-wrap w100">
          {CONFIG_PLANS.map((plan, index) => (
            <div
              key={index}
              className="planContainer"
              data-aos="flip-left"
              data-aos-easing="ease"
              data-aos-offset="200"
              data-aos-duration="400"
              data-aos-delay={index * 100 + 100}
              data-aos-anchor="#pricingSection"
            >
              <PlanBox
                borderTopStyle={plan.borderTopStyle}
                title={plan.title}
                description={plan.description}
                price={plan.price}
                rows={plan.rows}
                buttonLabel={plan.buttonLabel}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
