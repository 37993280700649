import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import cx from "classnames";

import Select from "react-select";
import { Modal } from "@shopify/polaris";
import {
  isAdmin,
  isAdminLoggedOnExternalStore,
  getStoreId,
  getStoreName,
  getAdminCredentials
} from "selectors/auth";

import { setStoreCredentials } from "actions/auth";
import { getStores } from "actions/stores";

import "./style.scss";
import { withRouter } from "react-router-dom";

class AdminExternalBanner extends Component {
  state = {
    openModal: false,
    stores: [],
    selectedStore: null
  };

  componentDidMount() {
    const {
      isAdmin,
      storeId,
      getStores,
      isAdminLoggedOnExternalStore
    } = this.props;

    if (isAdmin && !isAdminLoggedOnExternalStore) {
      getStores(storeId).then(res =>
        this.setState({
          stores: res.data
        })
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { getStores, storeId, isAdminLoggedOnExternalStore } = this.props;
    if (
      prevProps.isAdminLoggedOnExternalStore &&
      !isAdminLoggedOnExternalStore
    ) {
      getStores(storeId).then(res =>
        this.setState({
          stores: res.data
        })
      );
    }
  }

  onClick = () => {
    const { isAdminLoggedOnExternalStore } = this.props;
    if (isAdminLoggedOnExternalStore) {
      this.backToAdmin();
    } else {
      this.setState({ openModal: true });
    }
  };

  getStoreOptions = () => {
    const { stores } = this.state;

    return stores.map(s => ({
      label: s.name,
      value: s.id,
      data: s
    }));
  };

  onSelectStore = option => this.setState({ selectedStore: option });

  onSwitchStore = () => {
    const { setStoreCredentials, history } = this.props;
    const { selectedStore } = this.state;
    setStoreCredentials(selectedStore.data);
    this.setState({ openModal: false }, () => history.push("/"));
  };

  backToAdmin = () => {
    const { adminCredentials, setStoreCredentials } = this.props;
    setStoreCredentials(adminCredentials);
  };

  onCloseModal = () => {
    this.setState({ openModal: false });
  };

  render() {
    const { t, storeName, isAdminLoggedOnExternalStore, isAdmin } = this.props;
    const { openModal, selectedStore } = this.state;

    if (!isAdmin) {
      return null;
    }

    return (
      <Fragment>
        <div
          className={cx("AdminExternalBanner p2 full-flex-center", {
            isOutside: isAdminLoggedOnExternalStore
          })}
          onClick={this.onClick}
        >
          {isAdminLoggedOnExternalStore
            ? t("components.AdminExternalBanner.title", { name: storeName })
            : t("components.AdminExternalBanner.externalStoreTitle")}
        </div>
        <Modal
          open={openModal}
          onClose={this.onCloseModal}
          title="Switch shop session"
          primaryAction={{
            content: "Change the session",
            onAction: this.onSwitchStore
          }}
          secondaryActions={[
            {
              content: "Cancel",
              onAction: this.onCloseModal
            }
          ]}
        >
          <div className="p3" style={{ minHeight: 250 }}>
            <Select
              value={selectedStore}
              options={this.getStoreOptions()}
              onChange={this.onSelectStore}
            />
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default connect(
  state => ({
    storeId: getStoreId(state),
    isAdmin: isAdmin(state),
    adminCredentials: getAdminCredentials(state),
    storeName: getStoreName(state),
    isAdminLoggedOnExternalStore: isAdminLoggedOnExternalStore(state)
  }),
  {
    getStores,
    setStoreCredentials
  }
)(withTranslation()(withRouter(AdminExternalBanner)));
