import { PRODUCT_PLANS } from "../charges/PlansList/config";

export const calculateAffiliationPotentialGain = (affiliates, affiliation) => {
  const activeAffiliatesCount = affiliates.filter(
    a => a.subscription_status === "active"
  ).length;

  if (!affiliation) {
    return 0;
  }

  return (
    ((activeAffiliatesCount * parseInt(affiliation.affiliation_percent)) /
      100) *
    PRODUCT_PLANS[0].price
  ).toFixed(2);
};
