import React from "react";
import { ExternalMinor } from "@shopify/polaris-icons";

import "./style.scss";

export default function DescriptionDemoBox({
  title,
  subtitle,
  description,
  buttonLabel,
  buttonURL,
  note
}) {
  return (
    <div className="DescriptionDemoBox">
      <h5 className="subtitle">{subtitle}</h5>
      <h3 className="title">{title}</h3>
      <div className="description">{description}</div>

      <a
        className="link--silent"
        href={buttonURL}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div className="demoButton flex flex-center">
          <div>{buttonLabel}</div>
          <div className="iconContainer">
            <ExternalMinor />
          </div>
        </div>
      </a>

      {note && <div className="note">{note}</div>}
    </div>
  );
}
