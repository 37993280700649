import { createAction } from "redux-actions";

export const getStores = createAction("API:STORES_GET_STORES", storeId => ({
  url: `/api/v1/stores/${storeId}/stores`
}));

export const getStoreInfo = createAction(
  "API:STORES_GET_STORE_INFO",
  storeId => ({
    url: `/api/v1/stores/${storeId}`
  })
);
