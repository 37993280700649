import { createAction } from "redux-actions";

export const getStatistics = createAction(
  "API:DASHBOARD_GET_STATISTICS",
  (storeId, countAttempts) => ({
    url: `/api/v1/stores/${storeId}/statistics${
      countAttempts ? `?attempts=${countAttempts}` : ""
    }`
  })
);
