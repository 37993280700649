import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Modal,
  Icon,
  Stack,
  TextContainer,
  Tooltip,
  Heading
} from "@shopify/polaris";
import { InfoMinor } from "@shopify/polaris-icons";

export default function InfoVariantSelectorTypeIcon() {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  const toggleModal = useCallback(() => setOpenModal(value => !value), []);

  return (
    <>
      <span className="pointer ml1" style={{ zIndex: 0 }} onClick={toggleModal}>
        <Tooltip
          content={t(
            "upsellModalApp.form.variants.inputs.variantSelectorType.tooltip.info"
          )}
        >
          <Icon source={InfoMinor} color="inkLight" />
        </Tooltip>
      </span>
      <Modal
        open={openModal}
        onClose={toggleModal}
        title={t(
          "upsellModalApp.form.variants.inputs.variantSelectorType.tooltip.title"
        )}
        primaryAction={{
          content: t(
            "upsellModalApp.form.variants.inputs.variantSelectorType.tooltip.closeButton"
          ),
          onAction: toggleModal
        }}
      >
        <Modal.Section>
          <Stack vertical>
            <Stack.Item>
              <TextContainer>
                <Heading>
                  {t(
                    "upsellModalApp.form.variants.inputs.variantSelectorType.options.0"
                  )}
                </Heading>
                <p>
                  {t(
                    "upsellModalApp.form.variants.inputs.variantSelectorType.tooltip.description.0"
                  )}
                </p>
                <img
                  src="/images/quantitybreak/variant_popover.png"
                  alt="Dropdown"
                  style={{}}
                />
              </TextContainer>
              <div className="mt3">
                <TextContainer>
                  <Heading>
                    {t(
                      "upsellModalApp.form.variants.inputs.variantSelectorType.options.1"
                    )}
                  </Heading>
                  <p>
                    {t(
                      "upsellModalApp.form.variants.inputs.variantSelectorType.tooltip.description.1"
                    )}
                  </p>
                  <img
                    src="/images/quantitybreak/variant_dropdown.png"
                    alt="Dropdown"
                    style={{}}
                  />
                </TextContainer>
              </div>
            </Stack.Item>
          </Stack>
        </Modal.Section>
      </Modal>
    </>
  );
}
