import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";

import { Banner, Tooltip, Icon } from "@shopify/polaris";
import { InfoMinor } from "@shopify/polaris-icons";

import CopyToClipboard from "components/CopyToClipboard";

import { getCurrentShopifyTheme } from "actions/themes";
import { getStoreId, getStoreName } from "selectors/auth";

import "./style.scss";

class StepsManualInstallation extends Component {
  state = {
    theme: null
  };

  componentDidMount() {
    const { getCurrentShopifyTheme, storeId } = this.props;
    getCurrentShopifyTheme(storeId).then(res =>
      this.setState({ theme: res.data })
    );

    try {
      // https://github.com/google/code-prettify
      PR.prettyPrint(); // eslint-disable-line
    } catch (e) {}
  }

  renderHtmlCode = value => {
    const { t } = this.props;
    return (
      <Tooltip content={t("components.StepsManualInstallation.toolTip")}>
        <CopyToClipboard
          value={value}
          contentToast={t("components.StepsManualInstallation.contentToast", {
            value
          })}
        >
          <pre class="prettyprint lang-html pointer flex flex-wrap">
            {value}
          </pre>
        </CopyToClipboard>
      </Tooltip>
    );
  };

  render() {
    const { t, storeName } = this.props;
    const { theme } = this.state;

    const urlEditCode = theme
      ? `https://${storeName}/admin/themes/${theme.id}`
      : "";

    return (
      <div className="StepsManualInstallation">
        <div className="mb2">
          {t("components.StepsManualInstallation.description")}
        </div>

        <div className="content">
          <Banner
            title={t("components.StepsManualInstallation.banner.title")}
            status="info"
          >
            {t("components.StepsManualInstallation.banner.description")}
          </Banner>
          <p className="mt3">
            <Trans i18nKey="components.StepsManualInstallation.text1">
              <a
                className="link"
                href={urlEditCode}
                target="_blank"
                rel="noopener noreferrer"
              >
                Url edit code
              </a>
            </Trans>
          </p>

          <p className="mt2">
            <Trans i18nKey="components.StepsManualInstallation.text2">
              <strong>sections/cart-template.liquid</strong>)
            </Trans>
          </p>

          <table className="tableHtmlTag mt3">
            <thead>
              <td>
                {t("components.StepsManualInstallation.table.headings.0")}
              </td>
              <td>
                {t("components.StepsManualInstallation.table.headings.1")}
              </td>
            </thead>
            <tr>
              <td>{t("components.StepsManualInstallation.table.cells.0")}</td>
              <td>
                {this.renderHtmlCode(
                  `<div class="as-summary-line-saved-amount"></div>`
                )}
              </td>
            </tr>
            <tr>
              <td>{t("components.StepsManualInstallation.table.cells.1")}</td>
              <td>
                {this.renderHtmlCode(
                  `<div class="as-summary-line-discount-value"></div>`
                )}
              </td>
            </tr>
            <tr>
              <td>{t("components.StepsManualInstallation.table.cells.2")}</td>
              <td>
                {this.renderHtmlCode(
                  `<div class="as-summary-line-note"></div>`
                )}
              </td>
            </tr>
            <tr>
              <td>
                <div className="flex">
                  <div>
                    {t("components.StepsManualInstallation.table.cells.3")}
                  </div>
                  <div>
                    <Tooltip
                      content={t(
                        "components.StepsManualInstallation.table.tooltips.0"
                      )}
                    >
                      <Icon source={InfoMinor} color="inkLight" />
                    </Tooltip>
                  </div>
                </div>
              </td>
              <td>
                {this.renderHtmlCode(
                  `<span class="as-original-price">{{ cart.total_price | money }}</span>`
                )}
              </td>
            </tr>
          </table>

          <div className="mt3">
            <Trans i18nKey="components.StepsManualInstallation.text3">
              <strong>sections/cart-template.liquid</strong>)
            </Trans>
            {this.renderHtmlCode(
              `<span class="as-original-cart-total"><span class="as-original-price">{{ cart.total_price | money }}</span></span><span class="as-cart-total"><span class="as-messages"><div id="as-summary-item"></div></span><span class="as-discount-price"></span></span>`
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    storeId: getStoreId(state),
    storeName: getStoreName(state)
  }),
  { getCurrentShopifyTheme }
)(withTranslation()(StepsManualInstallation));
