import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import { Icon } from "@shopify/polaris";
import { MobileCancelMajor } from "@shopify/polaris-icons";
import { useTranslation, Trans } from "react-i18next";

import { getAwesomeQuantityNotificationPopup } from "actions/events";
import { getStoreId } from "selectors/auth";

import "./style.scss";

const AWESOME_QUANTITY_SHOPIFY_APPS_URL =
  "https://apps.shopify.com/awesome-quantity";

const CheckAwesomeQuantityPopup = () => {
  const dispatch = useDispatch();
  const storeId = useSelector(getStoreId);

  const { t } = useTranslation();

  const [open, setOpen] = useState(true);

  const closePopup = () => setOpen(false);

  const sendSlackNotification = () => {
    dispatch(getAwesomeQuantityNotificationPopup(storeId));
  };

  return (
    <div className={cx("CheckAwesomeQuantityPopup", { hidden: !open })}>
      <div className="content">
        <div className="closeButton" onClick={closePopup}>
          <Icon source={MobileCancelMajor} color="inkLighter" />
        </div>
        <div>
          <div>{t("components.CheckAwesomeQuantityPopup.textOne")}</div>
          <Trans i18nKey="components.CheckAwesomeQuantityPopup.textTwo">
            <a
              href={AWESOME_QUANTITY_SHOPIFY_APPS_URL}
              target="_blank"
              rel="noopener noreferrer"
              onClick={sendSlackNotification}
            >
              AwesomeQuantity
            </a>
          </Trans>
        </div>
      </div>
    </div>
  );
};

export default CheckAwesomeQuantityPopup;
