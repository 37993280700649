import { createAction } from "redux-actions";

export const checkRecurringApplicationCharge = createAction(
  "API:CHARGES_CHECK_RECURRING_APPLICATION_CHARGE",
  storeId => ({
    method: "GET",
    url: `/api/v1/stores/${storeId}/charges/check`,
    noToast: true
  })
);

export const createRecurringApplicationCharge = createAction(
  "API:CHARGES_CREATE_RECURRING_APPLICATION_CHARGE",
  (storeId, data) => ({
    method: "post",
    url: `/api/v1/stores/${storeId}/charges`,
    data
  })
);
