import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as EmailValidator from "email-validator";
import { withTranslation } from "react-i18next";

import { TextField, Modal, Toast } from "@shopify/polaris";

import { sendEmail } from "actions/email";
import { getStoreId, getStoreName } from "selectors/auth";

const MESSAGE_MIN_CHAR = 50;

class EmailContactButtonModal extends Component {
  state = {
    name: "",
    email: "",
    message: "",
    hasErrorName: false,
    hasErrorEmail: false,
    hasErrorMessage: false,
    isSending: false,
    showToast: false
  };

  sendEmail = () => {
    const {
      sendEmail,
      storeId,
      storeName,
      type,
      onCustomSendEmail,
      t
    } = this.props;
    const { name, email, message } = this.state;
    const isValidName = !!name;
    const isValidEmail = EmailValidator.validate(email);
    const isValidMessage = !!message && message.length >= MESSAGE_MIN_CHAR;

    this.setState({
      hasErrorName: !isValidName,
      hasErrorEmail: !isValidEmail,
      hasErrorMessage: !isValidMessage
    });

    if (isValidName && isValidEmail && isValidMessage) {
      this.setState({ isSending: true }, () => {
        if (onCustomSendEmail) {
          return onCustomSendEmail(name, email, message)
            .then(() => {
              alert(t("components.EmailContactButton.toastSuccess"));
              this.setState({ isSending: false }, this.onClose);
            })
            .catch(() => this.setState({ isSending: false }));
        }

        sendEmail(storeId, {
          storeId,
          storeName,
          name,
          email,
          message,
          type
        })
          .then(() =>
            this.setState(
              {
                isSending: false,
                showToast: true
              },
              this.onClose
            )
          )
          .catch(() => {
            this.setState({ isSending: false });
          });
      });
    }
  };

  onChange = (field, errorField) => value =>
    this.setState({ [field]: value, [errorField]: false });

  onClose = () =>
    this.setState(
      {
        name: "",
        email: "",
        message: "",
        hasErrorName: false,
        hasErrorEmail: false,
        hasErrorMessage: false,
        isSending: false
      },
      this.props.onClose
    );

  toggleToast = () =>
    this.setState(({ showToast }) => ({ showToast: !showToast }));

  render() {
    const { open, onClose, t } = this.props;
    const {
      name,
      email,
      message,
      hasErrorEmail,
      hasErrorName,
      hasErrorMessage,
      isSending,
      showToast
    } = this.state;

    const isDisabled = !name || !message || !email;

    return (
      <Fragment>
        {showToast && (
          <Toast
            content={t("components.EmailContactButton.toastSuccess")}
            onDismiss={this.toggleToast}
          />
        )}

        <Modal
          open={open}
          onClose={onClose}
          title={t("components.EmailContactButton.title")}
          primaryAction={{
            content: t("components.EmailContactButton.sendButton"),
            onAction: this.sendEmail,
            disabled: isDisabled,
            loading: isSending
          }}
          secondaryActions={{
            content: t("components.EmailContactButton.cancelButton"),
            onAction: onClose
          }}
          sectioned
        >
          <div className="EmailContactButtonModal">
            <TextField
              label={t("components.EmailContactButton.name.label")}
              value={name}
              onChange={this.onChange("name", "hasErrorName")}
              error={
                hasErrorName
                  ? t("components.EmailContactButton.name.error")
                  : null
              }
            />
            <div className="mt2">
              <TextField
                label={t("components.EmailContactButton.email.label")}
                value={email}
                onChange={this.onChange("email", "hasErrorEmail")}
                error={
                  hasErrorEmail
                    ? t("components.EmailContactButton.email.error")
                    : null
                }
                type="email"
              />
            </div>
            <div className="mt2">
              <TextField
                showCharacterCount
                minLength={MESSAGE_MIN_CHAR}
                label={t("components.EmailContactButton.message.label")}
                value={message}
                onChange={this.onChange("message", "hasErrorMessage")}
                multiline={6}
                error={
                  hasErrorMessage
                    ? t("components.EmailContactButton.message.error", {
                        minChar: MESSAGE_MIN_CHAR
                      })
                    : null
                }
              />
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default connect(
  state => ({
    storeId: getStoreId(state),
    storeName: getStoreName(state)
  }),
  {
    sendEmail
  }
)(withTranslation()(EmailContactButtonModal));
