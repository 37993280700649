import React from "react";
import ReactSwitch from "react-switch";

const Switch = ({ onChange, checked, disabled }) => (
  <ReactSwitch
    onColor="#86d3ff"
    onHandleColor="#2693e6"
    handleDiameter={16}
    uncheckedIcon={
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          fontWeight: "bold",
          color: "#fff",
          paddingRight: 2
        }}
      >
        OFF
      </div>
    }
    checkedIcon={
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontWeight: "bold",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2
        }}
      >
        ON
      </div>
    }
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
    height={22}
    width={50}
    onChange={onChange}
    checked={checked}
    disabled={disabled}
  />
);

export default Switch;
