import { createAction } from "redux-actions";

export const installScriptTags = createAction(
  "API:SCRIPT_TAGS_INSTALL_SCRIPT_TAGS",
  storeId => ({
    method: "post",
    url: `/api/v1/stores/${storeId}/scriptTags`
  })
);

export const getScriptTags = createAction(
  "API:SCRIPT_TAGS_GET_SCRIPT_TAGS",
  storeId => ({
    method: "get",
    url: `/api/v1/stores/${storeId}/scriptTags`
  })
);
