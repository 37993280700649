import React, { Component } from "react";
import cx from "classnames";
import * as R from "ramda";

import { Card, Heading, Spinner } from "@shopify/polaris";

import "./style.scss";

const getfontSize = value => {
  const countChar = !R.isNil(value) ? value.toString().length : 0;
  if (countChar <= 9) {
    return 36;
  }

  if (countChar <= 13) {
    return 27;
  }

  if (countChar <= 17) {
    return 22;
  }

  if (countChar <= 21) {
    return 18;
  }

  if (countChar <= 23) {
    return 16;
  }
};

export default class NumberBox extends Component {
  render() {
    const { className, title, value, isLoading } = this.props;

    return (
      <div className={cx("NumberBox", className)}>
        <Card>
          <div className="p3 NumberBox-content full-flex-center flex-column">
            <div className="mb2 title">
              <Heading>{title}</Heading>
            </div>
            {isLoading ? (
              <div
                className="value flex flex-center flex-content-center"
                style={{ fontSize: getfontSize(value) }}
              >
                <Spinner size="large" color="teal" />
              </div>
            ) : (
              <div
                className="value flex flex-center flex-content-center"
                style={{ fontSize: getfontSize(value) }}
                dangerouslySetInnerHTML={{ __html: value }}
              />
            )}
          </div>
        </Card>
      </div>
    );
  }
}
