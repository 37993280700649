import React, { useState, useEffect } from "react";
import {
  Page,
  Card,
  Icon,
  Button,
  Heading,
  SkeletonBodyText
} from "@shopify/polaris";
import cx from "classnames";
import {
  AddMajor,
  ToolsMajor,
  ViewMajor,
  CircleTickMajor
} from "@shopify/polaris-icons";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import TabsCard from "components/TabsCard";
import EmailContactButton from "components/EmailContactButton";
import AskReviewCard from "components/AskReviewCard";

import { getStoreInfo } from "actions/stores";
import { getStoreId, getStoreName, getStoreOwner } from "selectors/auth";

export default function GettingStarted() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [hasModalOffer, setHasModalOffer] = useState(false);
  const [hasOrderConfirmationOffer, setHasOrderConfirmationOffer] = useState(
    false
  );
  const [isModalCustomized, setIsModalCustomized] = useState(false);
  const [
    isOrderConfirmationCustomized,
    setIsOrderConfirmationCustomized
  ] = useState(false);
  const [defaultTabIndex, setDefaultTabIndex] = useState(0);

  const storeId = useSelector(getStoreId);
  const storeName = useSelector(getStoreName);
  const storeOwner = useSelector(getStoreOwner);

  useEffect(() => {
    const init = async storeId => {
      const info = await dispatch(getStoreInfo(storeId));

      const {
        count_upsell_modal_rules,
        has_order_confirmation_custom_design,
        has_upsell_modal_custom_design,
        order_confirmation_rules
      } = info.data;

      const hasModalOffer = count_upsell_modal_rules > 0;
      const hasOrderConfirmationOffer = order_confirmation_rules > 0;

      setHasModalOffer(hasModalOffer);
      setHasOrderConfirmationOffer(hasOrderConfirmationOffer);

      setIsModalCustomized(has_upsell_modal_custom_design);
      setIsOrderConfirmationCustomized(has_order_confirmation_custom_design);

      if (!hasModalOffer) {
        setDefaultTabIndex(0);
      } else if (!has_upsell_modal_custom_design) {
        setDefaultTabIndex(1);
      } else if (!hasOrderConfirmationOffer) {
        setDefaultTabIndex(2);
      } else if (!has_order_confirmation_custom_design) {
        setDefaultTabIndex(3);
      } else {
        setDefaultTabIndex(4);
      }

      setIsLoading(false);
    };

    init(storeId);
  }, [storeId]);

  const isAllDone =
    hasModalOffer &&
    isModalCustomized &&
    hasOrderConfirmationOffer &&
    isOrderConfirmationCustomized;

  const shopURL = `https://${storeName}`;

  const TABS_CONFIG = [
    {
      title: t("gettingStarted.tabs.0.label"),
      content: (
        <div>
          <Heading>
            {hasModalOffer
              ? t("gettingStarted.tabs.0.title.active")
              : t("gettingStarted.tabs.0.title.default")}
          </Heading>
          <p className="mt2 mb3">
            {hasModalOffer
              ? t("gettingStarted.tabs.0.description.active")
              : t("gettingStarted.tabs.0.description.default")}
          </p>
          <Link className="link--silent" to={"/upsell/modal/offers/new"}>
            <Button primary>{t("gettingStarted.tabs.0.button.label")}</Button>
          </Link>
        </div>
      ),
      IconComponent: (
        <Icon
          source={hasModalOffer ? CircleTickMajor : AddMajor}
          color={hasModalOffer ? "teal" : "inkLighter"}
        />
      )
    },
    {
      title: t("gettingStarted.tabs.1.label"),
      content: (
        <div>
          <Heading>
            {isModalCustomized
              ? t("gettingStarted.tabs.1.title.active")
              : t("gettingStarted.tabs.1.title.default")}
          </Heading>
          <p className="mt2 mb3">
            {isModalCustomized
              ? t("gettingStarted.tabs.1.description.active")
              : t("gettingStarted.tabs.1.description.default")}
          </p>
          <Link
            className="link--silent"
            to={"/upsell/modal/configuration/design"}
          >
            <Button primary>{t("gettingStarted.tabs.1.button.label")}</Button>
          </Link>
        </div>
      ),
      IconComponent: (
        <Icon
          source={isModalCustomized ? CircleTickMajor : ToolsMajor}
          color={isModalCustomized ? "teal" : "inkLighter"}
        />
      )
    },
    {
      title: t("gettingStarted.tabs.2.label"),
      content: (
        <div>
          <Heading>
            {hasOrderConfirmationOffer
              ? t("gettingStarted.tabs.2.title.active")
              : t("gettingStarted.tabs.2.title.default")}
          </Heading>
          <p className="mt2 mb3">
            {hasOrderConfirmationOffer
              ? t("gettingStarted.tabs.2.description.active")
              : t("gettingStarted.tabs.2.description.default")}
          </p>
          <Link
            className="link--silent"
            to={"/upsell/order-confirmation/offers/new"}
          >
            <Button primary>{t("gettingStarted.tabs.2.button.label")}</Button>
          </Link>
        </div>
      ),
      IconComponent: (
        <Icon
          source={hasOrderConfirmationOffer ? CircleTickMajor : AddMajor}
          color={hasOrderConfirmationOffer ? "teal" : "inkLighter"}
        />
      )
    },
    {
      title: t("gettingStarted.tabs.3.label"),
      content: (
        <div>
          <Heading>
            {isOrderConfirmationCustomized
              ? t("gettingStarted.tabs.3.title.active")
              : t("gettingStarted.tabs.3.title.default")}
          </Heading>
          <p className="mt2 mb3">
            {isOrderConfirmationCustomized
              ? t("gettingStarted.tabs.3.description.active")
              : t("gettingStarted.tabs.3.description.default")}
          </p>
          <Link
            className="link--silent"
            to={"/upsell/order-confirmation/configuration"}
          >
            <Button primary>{t("gettingStarted.tabs.3.button.label")}</Button>
          </Link>
        </div>
      ),
      IconComponent: (
        <Icon
          source={isOrderConfirmationCustomized ? CircleTickMajor : ToolsMajor}
          color={isOrderConfirmationCustomized ? "teal" : "inkLighter"}
        />
      )
    },
    {
      title: t("gettingStarted.tabs.4.label"),
      content: (
        <div>
          <Heading>
            {isAllDone
              ? t("gettingStarted.tabs.4.title.active")
              : t("gettingStarted.tabs.4.title.default")}
          </Heading>
          <p className="mt2 mb3">
            {isAllDone
              ? t("gettingStarted.tabs.4.description.active")
              : t("gettingStarted.tabs.4.description.default")}
          </p>
          <a
            className="link--silent"
            href={shopURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button primary>{t("gettingStarted.tabs.4.button.label")}</Button>
          </a>
        </div>
      ),
      IconComponent: (
        <Icon
          source={isAllDone ? CircleTickMajor : ViewMajor}
          color={isAllDone ? "teal" : "inkLighter"}
        />
      )
    }
  ];

  const QUESTIONS_CONFIG = [
    {
      question: t("gettingStarted.support.questions.0.question"),
      answer: (
        <Trans i18nKey="gettingStarted.support.questions.0.answer">
          <Link to="/support/troubleshooting-guide/upsell-modal" />
        </Trans>
      )
    },
    {
      question: t("gettingStarted.support.questions.1.question"),
      answer: t("gettingStarted.support.questions.1.answer")
    }
  ];

  return (
    <Page
      title={t("gettingStarted.title", { storeOwner })}
      subtitle={t("gettingStarted.subtitle")}
      separator
    >
      {isLoading ? (
        <div className="GettingStarted">
          <Card sectioned>
            <SkeletonBodyText />
            <div className="mt2">
              <SkeletonBodyText />
            </div>
          </Card>

          <div className="mt4">
            <Card sectioned>
              <SkeletonBodyText />
            </Card>
          </div>
        </div>
      ) : (
        <div className="GettingStarted">
          <TabsCard
            description={t("gettingStarted.tabTitle")}
            defaultTabIndex={defaultTabIndex}
            tabs={TABS_CONFIG}
          />

          <div className="mt4">
            <Card title={t("gettingStarted.support.title")} sectioned>
              <div className="mb3">
                {QUESTIONS_CONFIG.map((data, index) => (
                  <div key={index} className={cx({ mt3: !!index })}>
                    <div className="bold">{data.question}</div>
                    <div className="mt2">{data.answer}</div>
                  </div>
                ))}
              </div>
              <EmailContactButton type="Upsell Modal App" />
            </Card>
          </div>

          <div className="mt4">
            <AskReviewCard />
          </div>
        </div>
      )}
    </Page>
  );
}
