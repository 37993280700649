import React, { Component } from "react";
import cx from "classnames";

import { SketchPicker } from "react-color";

import "./style.scss";

export default class ColorPicker extends Component {
  state = {
    openPicker: false,
    hexColor: this.props.color
  };

  componentDidMount() {
    document.addEventListener("click", this.onClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    const { color, onChange } = this.props;
    const { openPicker, hexColor } = this.state;
    const { openPicker: prevOpenPicker } = prevState;

    if (color !== prevProps.color) {
      this.setState({ hexColor: color });
    }

    if (!openPicker && prevOpenPicker && onChange && hexColor !== color) {
      onChange(hexColor);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutside);
  }

  onChangeColor = color => {
    this.setState({ hexColor: color.hex });
  };

  onClickOutside = event => {
    const { target } = event;

    if (
      !this.previewColor.contains(target) &&
      (this.picker && !this.picker.contains(target))
    ) {
      this.setState({ openPicker: false });
    }
  };

  toggleOpen = () =>
    this.setState(prevState => ({ openPicker: !prevState.openPicker }));

  render() {
    const { color, className, hintLabel, isDisabled } = this.props;
    const { openPicker, hexColor } = this.state;

    return (
      <div className={cx("ColorPicker relative", className)}>
        <div
          className={cx("previewColor", {
            "hint--top": !!hintLabel,
            isDisabled
          })}
          onClick={this.toggleOpen}
          ref={ref => {
            this.previewColor = ref;
          }}
          style={{ backgroundColor: color }}
          aria-label={hintLabel}
        />
        {openPicker && !isDisabled && (
          <div
            className="picker"
            ref={ref => {
              this.picker = ref;
            }}
          >
            <SketchPicker
              disableAlpha
              color={hexColor}
              onChangeComplete={this.onChangeColor}
            />
          </div>
        )}
      </div>
    );
  }
}
