import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import * as R from "ramda";

import { Banner } from "@shopify/polaris";

import UpsellContent from "components/UpsellModal/UpsellContent";

import {
  getStyles,
  getVariantsWithDiscountPrice
} from "components/ContainerUpsellModal/utils";
import UpsellContentMultipleProducts from "components/UpsellModal/UpsellContentMultipleProducts";

class PreviewUpsellContent extends Component {
  formatVariants = (product, discountType, discountValue) => {
    const { variants, data } = product;
    const { images, image } = data;

    const variantWithDiscountPrice = getVariantsWithDiscountPrice(
      variants,
      discountType,
      discountValue
    );

    return variantWithDiscountPrice.map(variant => {
      const currentImage = images.find(img =>
        img.variant_ids.find(id => id === variant.id)
      ) ||
        image || { src: data.imageUrl };

      return {
        ...variant,
        id: variant.id,
        price: parseFloat(variant.price).toFixed(2) * 100,
        imageUrl: currentImage.src,
        name: variant.title,
        discountPrice: variant.discountPrice
      };
    });
  };

  render() {
    const {
      moneyFormat,
      configuration,
      products,
      title,
      subtitle,
      description,
      discountValue,
      discountType,
      upsellType,
      variantSelectorType,
      variantOptionsConfig,
      t
    } = this.props;

    const {
      styleNoThanks,
      styleAddToCart,
      styleReplaceCart,
      styleTitle,
      styleSubtitle,
      styleDiscountPrice,
      stylePrice,
      styleModal,
      styleVariants
    } = getStyles(R.propOr({}, "modal_style", configuration));

    const { cancelValue, addToCartValue, replaceCartValue } = R.propOr(
      {},
      "modal_style",
      configuration
    );

    if (products.length === 0) {
      return (
        <div className="PreviewUpsellContent p3">
          <Banner
            title={t("upsellModalApp.form.preview.component.title")}
            status="info"
          >
            <p>{t("upsellModalApp.form.preview.component.notAvailable")} </p>
          </Banner>
        </div>
      );
    }

    if (products.length === 1) {
      const product = products[0];
      
      return (
        <div className="PreviewUpsellContent p3 bg-grey-500">
          <UpsellContent
            upsellType={upsellType}
            moneyFormat={moneyFormat}
            productName={product.name}
            variants={this.formatVariants(product, discountType, discountValue)}
            productOptions={product.data.options}
            description={description}
            discountType={discountType}
            discountValue={discountValue}
            labelAddToCart={addToCartValue}
            labelReplaceCart={replaceCartValue}
            styleReplaceCart={styleReplaceCart}
            styleAddToCard={styleAddToCart}
            styleNoThanks={styleNoThanks}
            styleAddToCart={styleAddToCart}
            labelCancel={cancelValue}
            styleModal={styleModal}
            title={title}
            styleTitle={styleTitle}
            subtitle={subtitle}
            styleSubtitle={styleSubtitle}
            styleDiscountPrice={styleDiscountPrice}
            stylePrice={stylePrice}
            variantSelectorType={variantSelectorType}
            variantOptionsConfig={variantOptionsConfig}
            styleVariants={styleVariants}
          />
        </div>
      );
    } else {
      const formattedProducts = products.map(product => ({
        ...product,
        title: product.label,
        variants: this.formatVariants(product, discountType, discountValue)
      }));

      return (
        <div className="PreviewUpsellContent p3 bg-grey-500">
          <UpsellContentMultipleProducts
            moneyFormat={moneyFormat}
            upsellType={upsellType}
            products={formattedProducts}
            description={description}
            discountType={discountType}
            discountValue={discountValue}
            labelAddToCart={addToCartValue}
            labelReplaceCart={replaceCartValue}
            styleReplaceCart={styleReplaceCart}
            styleAddToCard={styleAddToCart}
            styleNoThanks={styleNoThanks}
            styleAddToCart={styleAddToCart}
            labelCancel={cancelValue}
            styleModal={styleModal}
            title={title}
            styleTitle={styleTitle}
            subtitle={subtitle}
            styleSubtitle={styleSubtitle}
            styleDiscountPrice={styleDiscountPrice}
            stylePrice={stylePrice}
          />
        </div>
      );
    }
  }
}

export default withTranslation()(PreviewUpsellContent);
