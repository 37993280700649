import { createAction } from 'redux-actions';
import shortid from 'shortid';

const TOAST_DELAY = 5e3;

export const addToastAction = createAction('TOAST_ADD');
export const removeToast = createAction('TOAST_REMOVE');

export const addToast = (message, type = 'info', opts = {}) => dispatch => {
  const toast = {
    id: shortid.generate(),
    type,
    message,
    ...opts,
  };

  dispatch(addToastAction(toast));

  if (!opts.noAutoClose && !opts.keepOpen) {
    setTimeout(() => {
      dispatch(removeToast(toast.id));
    }, TOAST_DELAY);
  }
};
