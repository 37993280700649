import React, { PureComponent } from "react";
import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import * as R from "ramda";

import { Link } from "react-router-dom";
import { TextField, Layout, Card, Tooltip, Icon } from "@shopify/polaris";
import { InfoMinor } from "@shopify/polaris-icons";

import UpsellGeneralForm from "components/UpsellGeneralForm";
import Switch from "components/Switch";
import PrefixDiscountCodeBanner from "components/PrefixDiscountCodeBanner";

import { fetchConfiguration } from "actions/configurations";

import {
  getStoreId,
  getStoreName,
  getShopifyMoneyFormat
} from "selectors/auth";

import PreviewOrderConfirmation from "./PreviewOrderConfirmation";

import { formatPrefixDiscountCode } from "./utils";

import "./style.scss";

class OfferForm extends PureComponent {
  static defaultProps = {
    defaultValues: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      name: props.defaultValues.name || "",
      productsAndCategoriesOptions: [],
      productsOptions: [],
      discountType: {},
      discountValue: props.defaultValues.discountValue || 20,
      title: props.defaultValues.title || "Take off 20% any of the items",
      subtitle: props.defaultValues.subtitle || "",
      selectedProducts: [],
      conditionProducts: [],
      conditionCollections: [],
      configuration: {},
      isLoadingProducts: true,
      prefixDiscountCode: props.defaultValues.prefixDiscountCode || "",
      hiddenAfterUpsell: props.defaultValues.hiddenAfterUpsell || false,
      hasError: false
    };
  }

  componentDidMount() {
    this.fetchConfiguration();
  }

  componentDidUpdate(prevProps, prevState) {
    const { onChange } = this.props;
    if (!R.equals(prevState, this.state)) {
      if (onChange) {
        onChange(this.state, this.state.hasError);
      }
    }
  }

  onCancel = () => {
    const { history } = this.props;
    history.push("/offers");
  };

  fetchConfiguration = () => {
    const { storeId, fetchConfiguration } = this.props;
    fetchConfiguration(storeId).then(res => {
      this.setState({
        configuration: R.pathOr(
          {},
          ["data", "configuration", "grid_style"],
          res
        )
      });

      return res;
    });
  };

  onChangeGeneralSettings = (state, hasError) => {
    this.setState({
      ...state,
      hasError
    });
  };

  render() {
    const { storeName, t, defaultValues, shopifyMoneyFormat } = this.props;

    const {
      discountType,
      discountValue,
      title,
      subtitle,
      selectedProducts,
      configuration,
      prefixDiscountCode,
      hiddenAfterUpsell
    } = this.state;

    return (
      <div className="OfferForm">
        <Layout>
          <Layout.AnnotatedSection
            title={t("orderConfirmationApp.form.general.title")}
            description={t("orderConfirmationApp.form.general.description")}
          >
            <UpsellGeneralForm
              configuration={configuration}
              isMultipleProducts
              defaultValues={defaultValues}
              onChange={this.onChangeGeneralSettings}
            />
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title={t("orderConfirmationApp.form.optional.title")}
            description={t("orderConfirmationApp.form.optional.description")}
          >
            <Card sectioned>
              <div>
                <div className="mb1">
                  {t("orderConfirmationApp.form.optional.inputs.title.label")}
                </div>
                <div style={{ zIndex: 0, position: "relative" }}>
                  <TextField
                    value={title}
                    maxLength={500}
                    showCharacterCount
                    onChange={value => this.setState({ title: value })}
                  />
                </div>
              </div>
              <div className="mt3">
                <div className="mb1">
                  {t(
                    "orderConfirmationApp.form.optional.inputs.subtitle.label"
                  )}
                </div>
                <div style={{ zIndex: 0, position: "relative" }}>
                  <TextField
                    value={subtitle}
                    maxLength={500}
                    showCharacterCount
                    onChange={value => this.setState({ subtitle: value })}
                  />
                </div>
              </div>
              <div className="mt3">
                <div className="mb1">
                  {t(
                    "orderConfirmationApp.form.optional.inputs.discountCode.label"
                  )}
                </div>
                <div style={{ zIndex: 0, position: "relative" }}>
                  <TextField
                    value={prefixDiscountCode}
                    onChange={value =>
                      this.setState({
                        prefixDiscountCode: value
                      })
                    }
                    maxLength={50}
                    showCharacterCount
                    helpText={t(
                      "orderConfirmationApp.form.optional.inputs.discountCode.helpText"
                    )}
                  />
                  {prefixDiscountCode !==
                    formatPrefixDiscountCode(prefixDiscountCode) && (
                    <PrefixDiscountCodeBanner
                      className="mt2"
                      prefixDiscountCode={formatPrefixDiscountCode(
                        prefixDiscountCode
                      )}
                    />
                  )}
                </div>
              </div>
              <div className="mt3">
                <div className="mb1 flex">
                  <div className="mr1">
                    {t(
                      "orderConfirmationApp.form.optional.inputs.hiddenAfterUpsell.label"
                    )}
                  </div>
                  <Tooltip
                    content={t(
                      "orderConfirmationApp.form.optional.inputs.hiddenAfterUpsell.tooltip"
                    )}
                  >
                    <Icon source={InfoMinor} color="inkLight" />
                  </Tooltip>
                </div>
                <div style={{ zIndex: 0, position: "relative" }}>
                  <Switch
                    checked={hiddenAfterUpsell}
                    onChange={value =>
                      this.setState({ hiddenAfterUpsell: value })
                    }
                  />
                </div>
              </div>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title={t("orderConfirmationApp.form.preview.title")}
            description={
              <span>
                <Trans i18nKey="orderConfirmationApp.form.preview.description">
                  <Link
                    className="shopify-link"
                    to="/upsell/order-confirmation/configuration"
                  >
                    Link configuration
                  </Link>
                </Trans>
              </span>
            }
          >
            <Card>
              <PreviewOrderConfirmation
                moneyFormat={shopifyMoneyFormat}
                shop={storeName}
                configuration={configuration}
                products={selectedProducts}
                title={title}
                subtitle={subtitle}
                discountValue={discountValue}
                discountType={discountType.value}
              />
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
      </div>
    );
  }
}

export default connect(
  state => ({
    storeId: getStoreId(state),
    storeName: getStoreName(state),
    shopifyMoneyFormat: getShopifyMoneyFormat(state)
  }),
  {
    fetchConfiguration
  }
)(withTranslation()(OfferForm));
