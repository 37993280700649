import React from "react";

import styles from "./style.module.scss";

export default function Tooltip({ children, text, enabled = false }) {
  if (!enabled) {
    return children;
  }

  return (
    <div className={styles.Tooltip}>
      <div data-tooltip={text}>{children}</div>
    </div>
  );
}
